import { combineReducers } from "redux";
import authReducer from "./authentication/reducer";
import statusReducer from "./status/reducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import navigationReducer from "./navigation/reducer";
import profileReducer from "./profile/reducer";
import abdmPatientsReducer from "./abdmPatients/reducer";
import abdmQRreducer from "./abdmQR/reducer";
import doctorSearchReducer from "./doctorSearch/reducer";
import abdmReducer from "./abdm/reducer";
import abhaReducer from "./abha/reducer";
import historyReducer from "./history/reducer";
import connectionReducer from "./connection/reducer";
import notificationsReducer from "./notification/reducer";
import formsReducer from "./form/reducer";
import documentSearchReducer from "./documentSearch/reducer";
import documentsReducer from "./documents/reducer";
import queriesReducer from "./queries/reducer";

const authPersistConfig = {
  key: "auth",
  storage: storage,
  blacklist: ["loading", "credentials"]
};

const profilePersistConfig = {
  key: "profile",
  storage: storage,
  blacklist: ["loading"]
};

const statusPersistConfig = {
  key: "status",
  storage: storage,
  blacklist: ["code", "message"]
};

const queriesPersistConfig = {
  key: "queries",
  storage: storage,
  blacklist: ["loading"]
};

const historyPersistConfig = {
  key: "history",
  storage: storage,
  blacklist: [
    "loading",
    "downloadUrlLoading",
    "paginationLoading",
    "documents",
    "pagination"
  ]
};

const documentPersistConfig = {
  key: "documents",
  storage: storage,
  blacklist: ["loading"]
};

const notificationPersistConfig = {
  key: "notifications",
  storage: storage,
  blacklist: ["loading", "data", "paginationLoading", "pagination"]
};

const navigationPersistConfig = {
  key: "navigation",
  storage: storage,
  blacklist: ["loading", "primary", "secondary", "tertiary"]
};

const doctorSearchPersistConfig = {
  key: "doctorSearch",
  storage: storage,
  blacklist: ["loading", "searchedDoctorPhoneNumber", "selectedDoctor", "data"]
};

const documentSearchPersistConfig = {
  key: "documentSearch",
  storage: storage,
  blacklist: [
    "loading",
    "searchedDocumentPhoneNumber",
    "selectedDocument",
    "data"
  ]
};

const formsPersistConfig = {
  key: "forms",
  storage: storage,
  blacklist: ["loading"]
};

const abdmPersistConfig = {
  key: "abdm",
  storage: storage,
  blacklist: ["loading"]
};

const abdmPatientsConfig = {
  key: "abdmPatients",
  storage: storage,
  blacklist: ["loading", "searchKey", "selectedPatient", "searchResults"]
};

const abdmQRreducerConfig = {
  key: "abdmQR",
  storage: storage,
  blacklist: ["loading"]
};

const abhaConfig = {
  key: "abha",
  storage: storage,
  blacklist: [
    "loading",
    "transactionId",
    "sentAadhaarOtp",
    "aadhaarVerified",
    "sentMobileOtp",
    "mobileVerified",
    "sentAbhaOtp",
    "login"
  ]
};

const connectionPersistConfig = {
  key: "connection",
  storage: storage,
  blacklist: ["loading"]
};

const rootPersistConfig = {
  key: "root",
  storage: storage,
  blacklist: [
    "auth",
    "profile",
    "connection",
    "status",
    "history",
    "documents",
    "notification",
    "navigation",
    "doctorSearch",
    "documentSearch",
    "forms",
    "abdm",
    "abdmPatients",
    "abdmQR",
    "abha"
  ]
};

//root reducer
const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  abha: persistReducer(abhaConfig, abhaReducer),
  abdm: persistReducer(abdmPersistConfig, abdmReducer),
  abdmPatients: persistReducer(abdmPatientsConfig, abdmPatientsReducer),
  abdmQR: persistReducer(abdmQRreducerConfig, abdmQRreducer),
  profile: persistReducer(profilePersistConfig, profileReducer),
  connection: persistReducer(connectionPersistConfig, connectionReducer),
  doctorSearch: persistReducer(doctorSearchPersistConfig, doctorSearchReducer),
  documents: persistReducer(documentPersistConfig, documentsReducer),
  documentSearch: persistReducer(
    documentSearchPersistConfig,
    documentSearchReducer
  ),
  forms: persistReducer(formsPersistConfig, formsReducer),
  history: persistReducer(historyPersistConfig, historyReducer),
  notification: persistReducer(notificationPersistConfig, notificationsReducer),
  navigation: persistReducer(navigationPersistConfig, navigationReducer),
  queries: persistReducer(queriesPersistConfig, queriesReducer),
  status: persistReducer(statusPersistConfig, statusReducer)
});

export const persistedReducer = persistReducer(rootPersistConfig, rootReducer);
