import store from "../store/store";
import { abdmPatientsActionTypes } from "./saga";

/**
 * @module AbdmPatientsSaga
 */

/**
 * Find patient with phone number or healthId
 * @param {string} searchKey
 * @param {string} preSelectResult
 */
export function findPatient(searchKey, preSelectResult) {
  store.dispatch({
    type: abdmPatientsActionTypes.FIND_PATIENT,
    payload: {
      searchKey: searchKey,
      preSelectResult: preSelectResult
    }
  });
}

/**
 * Set selected patient
 * @param {string} patientId
 */
export function setAbdmSelectedPatients(patientId) {
  store.dispatch({
    type: abdmPatientsActionTypes.PUT_ABDM_SELECTED_PATIENT,
    payload: {
      patientId: patientId
    }
  });
}

/**
 * To check if patient connected to ABDM
 * @param {object} data
 */
export function checkIfPatientConnectedToAbdm(data) {
  store.dispatch({
    type: abdmPatientsActionTypes.CHECK_IF_PATIENT_CONNECTED_TO_ABDM,
    payload: data
  });
}

/**
 * To check if consent request sent to patient
 * @param {object} data
 */
export function checkIfConsentRequestSentToAbdm(data) {
  store.dispatch({
    type: abdmPatientsActionTypes.CHECK_IF_CONSENT_REQUEST_SENT_TO_ABDM,
    payload: data
  });
}

/**
 * rTo send subscription request to patient
 */
export function sendSubscriptionRequest() {
  store.dispatch({
    type: abdmPatientsActionTypes.SEND_SUBSCRIPTION_REQUEST_TO_PATIENT
  });
}

/**
 * To send consent request  to patient
 * @param {object} data
 */
export function sendConsentRequest(data) {
  store.dispatch({
    type: abdmPatientsActionTypes.SEND_CONSENT_REQUEST_TO_PATIENT,
    payload: data
  });
}

/**
 * Create new patient demographics
 * @param {object} demographicsFormData
 */
export function addPatientDemographics(demographicsFormData) {
  store.dispatch({
    type: abdmPatientsActionTypes.ADD_PATIENT_DEMOGRAPHICS,
    payload: demographicsFormData
  });
}

/**
 * Clear patient search data
 */
export function clearPatientSearchData() {
  store.dispatch({
    type: abdmPatientsActionTypes.REMOVE_ABDM_PATIENT_SEARCH_DATA
  });
}
