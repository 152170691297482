import React from "react";
import Button from "../../front-end-global-components/components/Button/Button";
import querySubmittedSuccessImage from "../../assets/images/querySubmittedSuccess.svg";

function QuerySuccess(props) {
  return (
    <section className="inherit-parent-height inherit-parent-width flex-place-children-page-center">
      <main className="flex-place-children-page-center">
        <img src={querySubmittedSuccessImage} alt="querySubmittedSuccess" />
        <h1 className="font-weight-normal font-family-gilroy-bold font-size-medium font-color-secondary margin-top-larger ">
          Done! Your feedback is sent.
        </h1>
        <p className=" font-family-gilroy-medium font-size-medium font-color-tertiary text-align-center margin-top-larger">
          We appreciate your feedback and queries which can help us make Ninto
          even better for future experiences.
        </p>
        <Button
          data-cy="button"
          type="submit"
          text="Okay"
          className="inherit-parent-width max-width-130px margin-top-larger"
          onClick={props.okayOnClick}
          boxShadow={false}
        />
      </main>
    </section>
  );
}

export default QuerySuccess;
