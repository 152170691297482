import { setErrorStatus } from "../redux/status/actions";
import { api } from "../utils/constants";

export async function checkAccountAvailability(phoneNumber) {
  const response = await fetch(
    `${
      api.baseUrl
    }/nintoProfileManagement/accountExists?phoneNumber=${encodeURIComponent(
      phoneNumber
    )}&type=clinicStaff`,
    {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function sendMobileOtpForLinking({
  patientId,
  clinicId,
  accessToken
}) {
  const response = await fetch(
    `${api.baseUrl}/consentManager/authorization/mobile/request`,
    {
      method: "post",
      headers: {
        Accept: "application/json",
        "x-auth-token": `Bearer ${accessToken}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        patientId,
        clinicId
      })
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function sendAadhaarOtpForLinking({
  patientId,
  clinicId,
  accessToken
}) {
  const response = await fetch(
    `${api.baseUrl}/consentManager/authorization/aadhaar/request`,
    {
      method: "post",
      headers: {
        Accept: "application/json",
        "x-auth-token": `Bearer ${accessToken}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        patientId,
        clinicId
      })
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function confirmOtpForLinking({
  otp,
  healthId,
  clinicId,
  transactionId,
  accessToken
}) {
  const response = await fetch(
    `${api.baseUrl}/consentManager/authorization/confirm/verifyOtp`,
    {
      method: "post",
      headers: {
        Accept: "application/json",
        "x-auth-token": `Bearer ${accessToken}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        otp,
        healthId,
        clinicId,
        transactionId
      })
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function findPatientWithHealthIdOrPhoneNumber(
  data,
  clinicId,
  accessToken
) {
  const response = await fetch(
    `${api.baseUrl}/nintoProfileManagement/findPatient`,
    {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        ...data,
        clinicId: clinicId
      })
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function sendSubscriptionRequestToPatient(
  accessToken,
  clinicUid,
  patientHealthId,
  purpose,
  period
) {
  const response = await fetch(
    `${api.baseUrl}/consentManager/subscription/init`,
    {
      method: "post",
      body: JSON.stringify({
        healthId: patientHealthId,
        purpose: purpose,
        period: {
          from: period.from,
          to: period.to
        },
        clinicUid: clinicUid
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${accessToken}`
      }
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function sendConsentRequestToPatient(
  accessToken,
  clinicId,
  patientId,
  // subscriptionId,
  clinicName,
  dateRange
) {
  const response = await fetch(
    `${api.baseUrl}/consentManager/consentRequest/init`,
    {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        patientId: patientId,
        // subscriptionId: subscriptionId,
        dateRange: {
          from: dateRange.from,
          to: dateRange.to
        },
        requesterName: clinicName,
        clinicId: clinicId
      })
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function sendAadhaarOtp(accessToken, aadhaarNumber, clinicId) {
  const response = await fetch(
    `${api.baseUrl}/identityManager/register/aadhaar/sendOtp`,
    {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        aadhaarNumber: aadhaarNumber,
        clinicId: clinicId
      })
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function verifyAadhaarOtp(
  accessToken,
  otp,
  transactionId,
  clinicId
) {
  const response = await fetch(
    `${api.baseUrl}/identityManager/register/aadhaar/verifyOtp`,
    {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        otp: otp,
        transactionId: transactionId,
        clinicId: clinicId
      })
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function sendMobileOtp(
  accessToken,
  mobileNumber,
  transactionId,
  clinicId
) {
  const response = await fetch(
    `${api.baseUrl}/identityManager/register/mobile/sendOtp`,
    {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        mobileNumber: mobileNumber,
        transactionId: transactionId,
        clinicId: clinicId
      })
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function verifyMobileOtp(
  accessToken,
  otp,
  transactionId,
  clinicId
) {
  const response = await fetch(
    `${api.baseUrl}/identityManager/register/mobile/verifyOtp`,
    {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        otp: otp,
        transactionId: transactionId,
        clinicId: clinicId
      })
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function createAbhaNumber(
  accessToken,
  transactionId,
  healthId,
  clinicId
) {
  const response = await fetch(
    `${api.baseUrl}/identityManager/register/abhaNumber/create`,
    {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        transactionId: transactionId,
        healthId: healthId,
        clinicId: clinicId
      })
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function initializeAbha(accessToken, loginValue, mode, clinicId) {
  // "MOBILE_OTP" / "AADHAAR_OTP"
  const response = await fetch(`${api.baseUrl}/identityManager/login/init`, {
    method: "post",
    headers: {
      Accept: "application/json",
      "x-auth-token": `Bearer ${accessToken}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      healthId: loginValue,
      mode: mode,
      clinicId: clinicId
    })
  });
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function confirmAbha(
  accessToken,
  transactionId,
  mode,
  otp,
  clinicId
) {
  const response = await fetch(`${api.baseUrl}/identityManager/login/confirm`, {
    method: "post",
    headers: {
      "x-auth-token": `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      transactionId: transactionId,
      mode: mode,
      otp: otp,
      clinicId: clinicId
    })
  });
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function abhaSearch(accessToken, abhaValue, clinicId) {
  const response = await fetch(`${api.baseUrl}/identityManager/search`, {
    method: "post",
    headers: {
      "x-auth-token": `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      healthId: abhaValue,
      clinicId: clinicId
    })
  });
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function getStaffs(entityId, accessToken) {
  const response = await fetch(
    `${api.baseUrl}/nintoProfileManagement/staffManagement`,
    {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        entityData: {
          id: entityId,
          type: "clinic"
        },
        clinicId: entityId,
        method: "get"
      })
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function addStaff(
  data = {
    entityData: {
      id: "",
      type: "clinic",
      brandId: "",
      name: ""
    },

    staffData: {
      phoneNumber: "",
      type: ""
    },
    method: "add"
  },
  accessToken
) {
  const response = await fetch(
    `${api.baseUrl}/nintoProfileManagement/staffManagement`,
    {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${accessToken}`
      },
      body: JSON.stringify({ ...data, clinicId: data.entityData.id })
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function removeStaff(entityId, phoneNumber, accessToken) {
  const response = await fetch(
    `${api.baseUrl}/nintoProfileManagement/staffManagement`,
    {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        entityData: {
          id: entityId,
          type: "clinic"
        },
        clinicId: entityId,
        staffData: {
          phoneNumber: phoneNumber
        },
        method: "remove"
      })
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function uploadDocument(data, accessToken) {
  const response = await fetch(`${api.baseUrl}/uploadDocument/upload`, {
    method: "post",
    headers: {
      "x-auth-token": `Bearer ${accessToken}`
    },
    body: data
  });

  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export const connectPatientUsingDemographic = async (token, bodyData) => {
  const response = await fetch(
    `${api.baseUrl}/consentManager/authorization/demographic/request`,
    {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${token}`
      },
      body: JSON.stringify({
        name: bodyData.name,
        gender: bodyData.gender,
        dateOfBirth: bodyData.dateOfBirth,
        patientId: bodyData.patientId,
        clinicId: bodyData.clinicId,
        ...(bodyData.identifier ? { identifier: bodyData.identifier } : {})
      })
    }
  );
  const result = await response.json();
  return result;
};

export const retryDataTransferDetails = async (token, bodyData) => {
  const response = await fetch(
    `${api.baseUrl}/consentManager/authorization/healthInformation/request`,
    {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${token}`
      },
      body: JSON.stringify({
        clinicId: bodyData.hiuClinicId,
        consentRequestId: bodyData.consentRequestId,
        patientId: bodyData.patientId,
        ...(bodyData.consentId ? { consentId: bodyData.consentId } : {}),
        ...(bodyData.subscriptionId
          ? { subscriptionId: bodyData.subscriptionId }
          : {})
      })
    }
  );
  const result = await response.json();
  return result;
};

export async function linkOrUnlinkAbhaAddressFromAbhaNumber(
  firebaseToken,
  accessToken,
  abhaAddress,
  type
) {
  const response = await fetch(
    `${api.baseUrl}/identityManager/abhaAddress/${type}`,
    {
      method: "POST",
      body: JSON.stringify({
        token: accessToken,
        abhaAddress: abhaAddress
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${firebaseToken}`
      }
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }

  return result;
}

export async function getPatientQrDetails(
  firebaseToken,
  accessToken,
  clinicId
) {
  const response = await fetch(`${api.baseUrl}/identityManager/profile/card`, {
    method: "POST",
    body: JSON.stringify({
      token: accessToken,
      clinicId: clinicId
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-auth-token": `Bearer ${firebaseToken}`
    }
  });
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function createABHAAddressInit(
  firebaseToken,
  accessToken,
  clinicId
) {
  const response = await fetch(
    `${api.baseUrl}/identityManager/abhaAddress/create/init`,
    {
      method: "POST",
      body: JSON.stringify({
        token: accessToken,
        clinicId: clinicId
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${firebaseToken}`
      }
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function createABHAAddressConfirm(
  firebaseToken,
  accessToken,
  clinicId,
  transactionId,
  searchKey
) {
  const response = await fetch(
    `${api.baseUrl}/identityManager/abhaAddress/create/confirm`,
    {
      method: "POST",
      body: JSON.stringify({
        token: accessToken,
        transactionId: transactionId,
        abhaAddress: searchKey,
        clinicId: clinicId,
        alreadyExistedPHR: false
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${firebaseToken}`
      }
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}
