import store from "../store/store";
import { abhaActionTypes } from "./saga";

/**
 * @module AbhaSaga
 */

/**
 * Send OTP to aadhaar for abha creation
 * @param {string} aadhaarNumber
 */
export function sendAadhaarOtp(aadhaarNumber) {
  store.dispatch({
    type: abhaActionTypes.SEND_AADHAAR_OTP,
    payload: {
      aadhaarNumber: aadhaarNumber
    }
  });
}

/**
 * Verify OTP to aadhaar for abha creation
 * @param {string} otp
 * @param {string} transactionId
 */
export function verifyAadhaarOtp(otp, transactionId) {
  store.dispatch({
    type: abhaActionTypes.VERIFY_AADHAAR_OTP,
    payload: {
      otp: otp,
      transactionId: transactionId
    }
  });
}

/**
 * Confirm unlink ABHA address from ABHA number
 * @param {string} transactionId
 * @param {string} otp
 * @param {string} mode
 */
export function confirmLinkOrUnLinkAbhaAddress(mode, linkAddress, navigate) {
  store.dispatch({
    type: abhaActionTypes.CONFIRM_LINK_OR_UNLINK_ABHA_ADDRESS,
    payload: {
      mode: mode,
      linkAbhaAddress: linkAddress,
      navigate: navigate
    }
  });
}

/**
 * Send OTP to mobile for abha creation
 * @param {string} mobileNumber
 * @param {string} transactionId
 */
export function sendMobileOtp(mobileNumber, transactionId) {
  store.dispatch({
    type: abhaActionTypes.SEND_MOBILE_OTP,
    payload: {
      mobileNumber: mobileNumber,
      transactionId: transactionId
    }
  });
}

/**
 * clear abha creation
 */
export function clearCreationData() {
  store.dispatch({
    type: abhaActionTypes.CLEAR_CREATION_DATA
  });
}

/**
 * Verify OTP to mobile for abha creation
 * @param {string} otp
 * @param {string} transactionId
 */
export function createAbhaNumber(otp, transactionId) {
  store.dispatch({
    type: abhaActionTypes.CREATE_ABHA_NUMBER,
    payload: {
      otp: otp,
      transactionId: transactionId
    }
  });
}

/**
 * send otp for abha login
 * @param {object} loginData
 */
export function sendAbhaOtp(loginData) {
  store.dispatch({
    type: abhaActionTypes.SEND_ABHA_OTP,
    payload: {
      loginData: loginData
    }
  });
}

/**
 * confirm otp for abha login
 * @param {string} otp
 * @param {string} loginData
 */
export function verifyAbhaOtpAndLogin(otp, loginData) {
  store.dispatch({
    type: abhaActionTypes.VERIFY_ABHA_OTP_AND_LOGIN,
    payload: {
      otp: otp,
      loginData: loginData
    }
  });
}

/**
 * Resets abha
 */
export function resetStatus() {
  store.dispatch({
    type: abhaActionTypes.RESET_STATUS
  });
}

/**
 * Resets abha
 */
export function getPatientQrDetails() {
  store.dispatch({
    type: abhaActionTypes.GET_PATIENT_QR
  });
}

/**
 * Resets abha
 */
export function createAbhaAddress(data) {
  store.dispatch({
    type: abhaActionTypes.CREATE_ABHA_ADDRESS,
    payload: {
      abhaAddress: data
    }
  });
}

/**
 * Resets abha
 */
export function setLoginStatus(data) {
  store.dispatch({
    type: abhaActionTypes.SET_LOGIN_STATUS,
    payload: {
      data: data
    }
  });
}
