export const isStoreHasAllProfiles = (data) => {
  if (
    data &&
    typeof data === "object" &&
    Object.values(data).every((profile) => {
      return (
        profile.hasOwnProperty("escalationContact") &&
        profile.hasOwnProperty("public")
      );
    })
  ) {
    return true;
  } else {
    return false;
  }
};

export const isValidObject = (object) => {
  if (Array.isArray(object)) return false;
  return (
    object !== null &&
    object !== undefined &&
    typeof object === "object" &&
    Object.keys(object).length > 0
  );
};

export const isValidArray = (array) => {
  return Array.isArray(array) && array.length > 0;
};

export const isValidString = (text) => {
  return typeof text === "string" && text.trim().length > 0;
};

//check escalation contact completed

export const isEscalationContact = (profile) => {
  if (isValidObject(profile)) {
    const clinicId = Object.keys(profile)[0];
    if (
      !(
        isValidObject(profile[clinicId]) &&
        isValidObject(profile[clinicId].escalationContact) &&
        isValidString(profile[clinicId].escalationContact.email) &&
        isValidString(profile[clinicId].escalationContact.fullName) &&
        isValidString(profile[clinicId].escalationContact.phoneNumber)
      )
    ) {
      return false;
    }
  } else {
    return true;
  }
};
