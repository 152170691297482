import React from "react";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import add from "../../front-end-global-components/assets/add_grey.svg";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import {
  ArrowIcon,
  TickIcon
} from "../../front-end-global-components/assets/assets";
import "./ProfileCards.css";

export default function ProfileCards(props) {
  return (
    <>
      {!props.type && (
        <div
          data-cy={`${
            props.data?.connectionId
              ? props.data.connectionId + "-card"
              : props.data?.documentId
              ? props.data?.documentId + "-card"
              : props.data?.name + "-card"
          }`}
          className={` ${props.background ? "background-color-grey" : ""} ${
            props.className ? props.className : ""
          } inherit-parent-width flex-justify-content-space-between padding-top-default padding-bottom-default  border-radius-default cursor-pointer text-overflow-ellipsis`}
          onClick={props.onCardClick}
        >
          <div className="flex-center-children-vertically font-family-gilroy-regular">
            <RoundedProfilePicture
              size="medium"
              userType="corporate"
              removeResponsive={true}
              src={
                props.data?.downloadURL
                  ? props.data.downloadURL
                  : props.connection?.brands &&
                    props.connection?.brands[props.data?.brandId]?.downloadURL
              }
            />
            <div className="padding-left-large ">
              <div className=" font-size-large max-width-130px white-space-nowrap overflow-hidden text-overflow-ellipsis">
                {props.data?.companyName
                  ? props.data?.companyName
                  : props.data?.name}
              </div>
              <div className=" font-size-small">{props.data?.locality}</div>
            </div>
          </div>
          <ConditionalRender condition={props.selected === true}>
            <div className="flex-center-children-vertically">
              <TickIcon color="#00A000" />
            </div>
          </ConditionalRender>
          <ConditionalRender condition={props.selected === false}>
            <div className="flex-center-children-vertically position-sticky">
              <ArrowIcon />
            </div>
          </ConditionalRender>
        </div>
      )}
      {props.type === "add" && (
        <div
          data-cy="add-card"
          className={`flex-justify-content-space-between flex-center-children-vertically cursor-pointer text-overflow-ellipsis ${
            props.className ? props.className : ""
          }`}
          onClick={props.addOnClick}
        >
          <div className=" display-flex flex-center-children-vertically ">
            <div className="background-color-lite-grey border-radius-100-percentage height-and-width-44px flex-place-children-page-center">
              <img src={add} alt="add" />
            </div>
            <div className="font-size-large padding-left-large">
              {props.text}
            </div>
          </div>
          {/* <div className={` flex-center-children-vertically`}>
            <ArrowIcon />
          </div> */}
        </div>
      )}
    </>
  );
}
