import React from "react";
import { setErrorStatus } from "../../redux/status/actions";
import ProfileCards from "../ProfileCards/ProfileCards";

export default function ManageClinic(props) {
  return (
    <div className="user-select-none" data-cy="editAccountInformation">
      <div className="font-family-gilroy-regular inherit-parent-height inherit-parent-width flex-justify-content-space-between flex-direction-column">
        <div className="">
          <div className="text-align-center font-color-secondary padding-bottom-larger">
            {props.title}
          </div>
          <div className=" inherit-parent-width">
            {props.connection.currentConnection &&
              Object.values(props.connection.data).map((data, index) => (
                <ProfileCards
                  className="padding-default"
                  connection={props.connection}
                  key={index}
                  data={data}
                  background={
                    data.connectionId === props.connection.selectedClinic
                      ? true
                      : false
                  }
                  selected={false}
                  onCardClick={() => {
                    if (data.userType !== "staff") {
                      props.onProfileCardClick(data);
                    } else {
                      props.closeTertiary();
                      setErrorStatus({
                        code: "custom",
                        message: "Does not have permission to manage"
                      });
                    }
                  }}
                />
              ))}
            <div className="">
              <ProfileCards
                type="add"
                className="padding-default"
                text="Add Clinic"
                addOnClick={() => {
                  props.navigate("/create");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
