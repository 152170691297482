import store from "../store/store";
import { authActionTypes } from "./saga";

/**
 * @module AuthSaga
 */

/**
 * Removes the display name in auth
 */
export function removeDisplayName() {
  store.dispatch({
    type: authActionTypes.REMOVE_DISPLAY_NAME
  });
}

/**
 * Sends OTP to the Phone number in payload
 * Type indicates the authentication type login or sign in
 * @param {string} phoneNumber
 * @param {string} type
 */
export function sendOtp(phoneNumber, type) {
  store.dispatch({
    type: authActionTypes.SEND_OTP,
    payload: {
      phoneNumber: phoneNumber,
      type: type
    }
  });
}

/**
 * Verifies OTP from the Phone number in payload
 * clinicName indicates the name from payload
 * Type indicates the authentication type login or sign in
 * @param {string} loginOtp
 * @param {string} clinicName
 * @param {string} phoneNumber
 * @param {string} type
 */
export function verifyOtp(loginOtp, clinicName, phoneNumber, type) {
  store.dispatch({
    type: authActionTypes.VERIFY_OTP,
    payload: {
      loginOtp: loginOtp,
      clinicName: clinicName,
      phoneNumber: phoneNumber,
      type: type
    }
  });
}

/**
 * Sign in using clinicName and phoneNumber from payload
 * @param {string} clinicName
 * @param {string} phoneNumber
 */
export function signUp(clinicName, phoneNumber) {
  store.dispatch({
    type: authActionTypes.SIGNUP,
    payload: {
      clinicName: clinicName,
      phoneNumber: phoneNumber
    }
  });
}

/**
 * Login in using OTP from payload
 * @param {string} loginOtp
 */
export function login(loginOtp) {
  store.dispatch({
    type: authActionTypes.LOGIN,
    payload: {
      loginOtp: loginOtp
    }
  });
}

/**
 * Clears the credentials after login
 */
export function clearCredentials() {
  store.dispatch({
    type: authActionTypes.CLEAR_CREDENTIAL
  });
}

/**
 * For logging out of user
 */
export function logout() {
  store.dispatch({
    type: authActionTypes.LOGOUT
  });
}

/**
 * Deletes the account
 */
export function deleteAccountRequest() {
  store.dispatch({
    type: "DELETE_ACCOUNT"
  });
}

/**
 * Update Access token
 * @param {string} accessToken
 */
export function addAccessToken(accessToken) {
  store.dispatch({
    type: authActionTypes.ADD_ACCESS_TOKEN,
    payload: {
      accessToken: accessToken
    }
  });
}
