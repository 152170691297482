import React, { useState, useEffect } from "react";
import "cropperjs/dist/cropper.css";
import Button from "../../front-end-global-components/components/Button/Button";
import "./EditProfile.css";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import {
  getDistrictCodeFromState,
  getStateCodeFromState,
  stateCodeWithDistricts,
  stateOptionsWithCode
} from "../../utils/constants";
import NativeSelect from "../../front-end-global-components/components/NativeSelect/NativeSelect";
import { isValidObject } from "../../utils/validators";

export default function EditProfile(props) {
  const [currentProfilePubicData, setCurrentProfilePubicData] = useState(null);
  const [editAccountInfoFormData, setEditAccountInfoFormData] = useState({
    phoneNumber: "",
    email: "",
    website: "",
    address: "",
    locality: "",
    pincode: "",
    state: "",
    city: ""
  });
  const [isValidFormData, setIsValidFormData] = useState({
    phoneNumber: true,
    email: true,
    pincode: true
  });

  //save current profile data to the state from store

  useEffect(() => {
    if (isValidObject(props.data)) {
      setCurrentProfilePubicData(props.data);
    }
    // eslint-disable-next-line
  }, [props.data]);

  //set data from store to state
  useEffect(() => {
    if (currentProfilePubicData) {
      let publicObj = {
        phoneNumber: currentProfilePubicData.phoneNumber,
        email: currentProfilePubicData.email,
        website: currentProfilePubicData.website,
        address: currentProfilePubicData.address,
        locality: currentProfilePubicData.locality,
        pincode: currentProfilePubicData.pincode,
        state: getStateCodeFromState(currentProfilePubicData.state),
        city: getDistrictCodeFromState(
          getStateCodeFromState(currentProfilePubicData.state),
          currentProfilePubicData.city
        )
      };
      setEditAccountInfoFormData(publicObj);
    }
  }, [currentProfilePubicData]);

  function editAccountInfoHandlerOnClick(event) {
    event.preventDefault();
    props.updateProfile(editAccountInfoFormData);
  }

  const handleDisableButton = () => {
    if (
      Object.values(isValidFormData).every((data) => data === true) &&
      editAccountInfoFormData.website &&
      editAccountInfoFormData.address &&
      editAccountInfoFormData.locality &&
      editAccountInfoFormData.city &&
      editAccountInfoFormData.state &&
      (editAccountInfoFormData.phoneNumber !==
        currentProfilePubicData.phoneNumber ||
        editAccountInfoFormData.email !== currentProfilePubicData.email ||
        editAccountInfoFormData.website !== currentProfilePubicData.website ||
        editAccountInfoFormData.address !== currentProfilePubicData.address ||
        editAccountInfoFormData.locality !== currentProfilePubicData.locality ||
        editAccountInfoFormData.city !==
          getDistrictCodeFromState(
            getStateCodeFromState(currentProfilePubicData.state),
            currentProfilePubicData.city
          ) ||
        editAccountInfoFormData.pincode !== currentProfilePubicData.pincode ||
        editAccountInfoFormData.state !==
          getStateCodeFromState(currentProfilePubicData.state))
    ) {
      return false;
    } else {
      return true;
    }
  };

  function editAccountInfoFormOnChange(event) {
    setEditAccountInfoFormData({
      ...editAccountInfoFormData,
      [event.target.name]: event.target.value
    });
  }

  return (
    <>
      {props.loading === true ? (
        <EditProfileSuspense />
      ) : (
        <>
          <div data-cy="edit-profile">
            <form
              className="inherit-parent-width inherit-parent-height"
              onChange={(event) => {
                editAccountInfoFormOnChange(event);
              }}
              onSubmit={(event) => {
                editAccountInfoHandlerOnClick(event);
              }}
            >
              <h1 className="font-weight-normal padding-top-larger font-family-gilroy-medium font-size-medium font-color-secondary inherit-parent-width text-align-center">
                EDIT CLINIC INFORMATION
              </h1>
              <div className="padding-bottom-large">
                <div className="edit-Profile-scroll padding-horizontal-larger">
                  {/* <InputBox
                    removeResponsive={true}
                    className="inherit-parent-width border-radius-default padding-bottom-large"
                    name="clinicName"
                    value={editAccountInfoFormData.clinicName}
                    data-cy="typeClinicName"
                    onClinicNameValidationError={(isValid) => {
                      setIsNameValid(isValid);
                    }}
                    label="Clinic/lab name"
                    type="text"
                    required
                  /> */}
                  <InputBox
                    removeResponsive={true}
                    className="inherit-parent-width  border-radius-default margin-vertical-larger"
                    value={editAccountInfoFormData.phoneNumber}
                    name="phoneNumber"
                    data-cy="branch-contact-number-field"
                    label="Phone Number"
                    type="text"
                    isValidInput={(value) => {
                      setIsValidFormData({
                        ...isValidFormData,
                        phoneNumber: value
                      });
                    }}
                    required
                  />
                  <InputBox
                    removeResponsive={true}
                    className="inherit-parent-width border-radius-default padding-bottom-large"
                    name="email"
                    value={editAccountInfoFormData.email}
                    data-cy="company-email-field"
                    label="Email"
                    type="email"
                    isValidInput={(value) => {
                      setIsValidFormData({
                        ...isValidFormData,
                        email: value
                      });
                    }}
                    required
                  />
                  {/* <InputBox
                    removeResponsive={true}
                    className="inherit-parent-width border-radius-default padding-bottom-large"
                    name="description"
                    value={editAccountInfoFormData.description}
                    data-cy="typeDescription"
                    label="Description"
                    type="text"
                    category="textarea"
                    required
                  /> */}
                  <InputBox
                    removeResponsive={true}
                    className="inherit-parent-width  border-radius-default padding-bottom-large"
                    name="website"
                    value={editAccountInfoFormData.website}
                    data-cy="company-website-field"
                    label="Website"
                    type="text"
                    required
                  />
                  <InputBox
                    removeResponsive={true}
                    className="inherit-parent-width  border-radius-default padding-bottom-large"
                    name="address"
                    value={editAccountInfoFormData.address}
                    data-cy="branch-address-field"
                    label="Address"
                    type="text"
                    required
                  />
                  <div className=" display-flex-direction-row">
                    <InputBox
                      removeResponsive={true}
                      className="inherit-parent-width max-width-300px border-radius-default padding-right-default padding-bottom-large"
                      name="locality"
                      size="small"
                      value={editAccountInfoFormData.locality}
                      data-cy="branch-locality-field"
                      label="Locality"
                      type="text"
                      required
                    />
                    <InputBox
                      removeResponsive={true}
                      className="inherit-parent-width max-width-300px border-radius-default padding-left-default padding-bottom-large"
                      name="pincode"
                      size="small"
                      value={editAccountInfoFormData.pincode}
                      data-cy="branch-pincode-field"
                      label="Pincode"
                      isValidInput={(value) => {
                        setIsValidFormData({
                          ...isValidFormData,
                          pincode: value
                        });
                      }}
                      type="number"
                      required
                    />
                  </div>
                  <div className="display-flex-direction-row">
                    <NativeSelect
                      labelClassName="letter-spacing-4-percentage"
                      className="padding-right-default"
                      selectClassName="text-transform-capitalize padding-top-large"
                      optionsClassName="text-transform-capitalize"
                      name="state"
                      defaultValue={editAccountInfoFormData.state}
                      label="State"
                      required
                      size="small"
                      data-cy="branch-state-field"
                      options={stateOptionsWithCode}
                    />

                    <NativeSelect
                      labelClassName="letter-spacing-4-percentage"
                      className="padding-left-default"
                      selectClassName="text-transform-capitalize padding-top-large"
                      optionsClassName="text-transform-capitalize"
                      name="city"
                      label="city"
                      required
                      size="small"
                      data-cy="branch-city-field"
                      defaultValue={editAccountInfoFormData.city}
                      options={
                        stateCodeWithDistricts[editAccountInfoFormData.state]
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="flex-justify-content-space-between padding-horizontal-larger padding-bottom-larger">
                <div className="inherit-parent-width font-family-gilroy-bold padding-bottom-large">
                  <Button
                    type="submit"
                    data-cy="save-button"
                    boxShadow={false}
                    className="max-width-160px max-height-50px"
                    text="Save"
                    disabled={handleDisableButton()}
                  />
                </div>

                <div className="inherit-parent-width padding-left-large font-family-gilroy-medium padding-bottom-large">
                  <Button
                    className="max-width-160px max-height-50px"
                    boxShadow={false}
                    text="Cancel"
                    variant="secondary"
                    onClick={props.cancelButtonClick}
                  />
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
}

function EditProfileSuspense() {
  return (
    <article>
      <div>
        <div className="margin-larger">
          {[...Array(6).keys()].map((index) => (
            <div
              key={index}
              className="shimmer border-radius-default height-48px margin-bottom-large"
            />
          ))}
        </div>

        <div>
          <div className=" flex-justify-content-space-around margin-larger">
            <div className="shimmer width-50-percentage height-48px margin-right-default border-radius-default" />
            <div className="shimmer width-50-percentage height-48px margin-left-default border-radius-default" />
          </div>
        </div>
      </div>
    </article>
  );
}
