import store from "../store/store";
import { notificationActionTypes } from "./saga";
/**
 * @module NotificationSaga
 */

/**
 * set notification info
 * @param {string} notifications
 */
export function setNotifications(notifications, lastVisible, profileId) {
  store.dispatch({
    type: notificationActionTypes.SET_NOTIFICATION,
    payload: {
      data: notifications,
      lastVisible: lastVisible,
      profileId: profileId
    }
  });
}

/**
 * get next set of notification
 * @param {number} lastDocPageNumber
 */
export function updateNotifications(lastDocPageNumber) {
  store.dispatch({
    type: notificationActionTypes.UPDATE_NOTIFICATION,
    payload: {
      lastDocPageNumber: lastDocPageNumber
    }
  });
}

/**
 * set selected notification
 * @param {object} profile
 */
export function setSelectedNotification(profile) {
  store.dispatch({
    type: notificationActionTypes.ADD_SELECTED_NOTIFICATION,
    payload: {
      data: profile
    }
  });
}

/**
 * set notification read
 * @param {object} notifications
 */
export function setNotificationRead(notifications) {
  store.dispatch({
    type: notificationActionTypes.SET_NOTIFICATION_READ,
    payload: {
      notification: notifications
    }
  });
}
