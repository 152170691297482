import React, { useState } from "react";
import Button from "../../front-end-global-components/components/Button/Button";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import TextArea from "../../front-end-global-components/components/TextArea/TextArea";
import "./Query.css";

export default function Query(props) {
  const [queryForm, setQueryForm] = useState({
    topic: "",
    description: ""
  });

  const queryFormOnChangeHandler = (event) => {
    setQueryForm({ ...queryForm, [event.target.name]: event.target.value });
  };

  const querySubmitHandler = (event) => {
    event.preventDefault();
    let data = {
      topic: queryForm.topic,
      description: queryForm.description,
      userType: "clinicStaff"
    };
    props.createQuery(data);
  };

  return (
    <div className="inherit-parent-height">
      <form
        className="padding-top-larger"
        onChange={(event) => {
          queryFormOnChangeHandler(event);
        }}
      >
        <div className="font-family-gilroy-bold font-size-medium font-color-secondary text-align-center">
          Please let us know your issue
        </div>
        <div className=" font-family-gilroy-medium text-align-center font-size-medium font-color-secondary padding-large">
          Describe the issue you’ve encountered.
        </div>
        <InputBox
          className="inherit-parent-width border-radius-default margin-vertical-large"
          name="topic"
          label="What did you try to do?"
          removeResponsive={true}
          value={queryForm.topic}
        />
        <TextArea
          className="inherit-parent-width border-radius-default margin-vertical-large"
          type="text"
          name="description"
          removeResponsive={true}
          label="Provide more information"
          value={queryForm.description}
        />

        {/* <TextBox
					label="What action did you try to perform?"
					name="topic"
					category="dropdown"
					className=""
					dataList={["Doctor notes", "Other"]}
					value={queryForm.type}
					alwaysActive={true}
				/> */}
        {/* {queryForm.topic === "Other" && (
					<TextBox
						type="text"
						label="Provide additional information"
						name="other"
						className="margin-top-large"
						value={queryForm.other}
					/>
				)} */}
        {/* <TextBox
					label="Provide additional information"
					name="description"
					category="textarea"
					className="margin-top-large"
					// textAreaClasses=" text-area "
					value={queryForm.description}
				/> */}
      </form>
      <div className="flex-justify-content-space-between padding-bottom-larger">
        <div className="inherit-parent-width font-family-gilroy-bold padding-bottom-large">
          <Button
            boxShadow={false}
            disabled={
              queryForm.topic === null ||
              queryForm.topic === "" ||
              queryForm.description === ""
                ? true
                : false
            }
            loading={props.queries.loading}
            className="max-width-160px max-height-50px"
            text="Submit"
            onClick={(event) => querySubmitHandler(event)}
          />
        </div>

        <div className="inherit-parent-width padding-left-large font-family-gilroy-medium padding-bottom-large">
          <Button
            boxShadow={false}
            className="max-width-160px max-height-50px"
            variant="secondary"
            text="Cancel"
            onClick={() => {
              props.navigate(-1);
            }}
          />
        </div>
      </div>
    </div>
  );
}
