import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./Login.css";
import nintoClinicLogo from "../../assets/images/logo/ninto-clinic-logo.png";
import { reCaptchaVerifier } from "../../services/authentication";
import placeholderImage from "../../assets/images/Placeholder_image_clinic.png";
import Button from "../../front-end-global-components/components/Button/Button";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import OTPInputBox from "../../front-end-global-components/components/OTPInputBox/OTPInputBox";
import {
  clearCredentials,
  sendOtp,
  verifyOtp
} from "../../redux/authentication/actions";
import { mockAuthVerifyOtp } from "../../redux/mock/actions";

function Login(props) {
  const [loginFormData, setLoginFormData] = useState({
    phoneNumber: "+91",
    OTP: null
  });

  //invisible recaptcha for authentication
  useEffect(() => {
    window.reCaptchaVerifier = reCaptchaVerifier();
  }, []);

  const setOTPValue = (otp) => {
    if (loginFormData.phoneNumber !== null) {
      setLoginFormData({
        ...loginFormData,
        OTP: otp
      });
    }
  };

  const loginFormChange = (event) => {
    if (
      event.target.name === "phoneNumber" &&
      props.auth.credentials.verificationId &&
      loginFormData.phoneNumber !== event.target.value
    ) {
      props.clearCredentials();
    }
    if (event.target.name.includes("otp")) {
      return;
    } else {
      setLoginFormData({
        ...loginFormData,
        [event.target.name]: event.target.value
      });
    }
  };

  const otpRequestHandler = (event) => {
    event.preventDefault();
    props.sendOtp(loginFormData.phoneNumber, "login");
  };

  const otpVerifyHandler = (event) => {
    event.preventDefault();
    if (process.env.REACT_APP_STAGING === "local") {
      props.mockAuthVerifyOtp("login");
    } else {
      props.verifyOtp(loginFormData.OTP);
    }
  };

  const isOtpButtonDisabled = () => {
    if (
      typeof loginFormData.phoneNumber === "string" &&
      loginFormData.phoneNumber.trim().length >= 13
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <div className="grey-background-image" data-cy="login-screen">
        <div data-cy="background-image">
          <img
            draggable="false"
            data-cy="placeholder-image"
            className="icon-img"
            src={placeholderImage}
            alt="img"
          />
        </div>
        <div className="flex-center-children-horizontally flex-center-children-vertically box-shadow-default inherit-parent-height width-30percent position-fixed  background-white ">
          <div className="inherit-parent-width max-width-300px">
            <form
              className="flex-place-children-page-center padding-top-large"
              onChange={(event) => {
                loginFormChange(event);
              }}
              onSubmit={(event) => event.preventDefault()}
            >
              <img
                draggable="false"
                className="width-155px margin-bottom-larger"
                src={nintoClinicLogo}
                alt="login-logo"
              />
              <div className="font-weight-normal font-family-gilroy-medium font-size-small font-color-secondary inherit-parent-width text-align-center">
                CLINIC LOGIN
              </div>
              <InputBox
                className="inherit-parent-width max-width-300px border-radius-default margin-vertical-larger"
                name="phoneNumber"
                removeResponsive={true}
                data-cy="login-screen-phone-number-field"
                label="Phone Number"
                type="tel"
                value={loginFormData.phoneNumber}
                buttonTileId="login-button"
                buttonTile={
                  props.auth.credentials.verificationId !== null
                    ? "RESEND OTP"
                    : "GET OTP"
                }
                buttonType={
                  typeof props.auth.credentials.verificationId === "string"
                    ? "button"
                    : "submit"
                }
                onButtonClick={(event) => {
                  otpRequestHandler(event);
                }}
                disabled={props.auth.credentials.verificationId}
                suffixButtonDisabled={isOtpButtonDisabled()}
                required
              />
              <ConditionalRender
                condition={
                  typeof props.auth.credentials.verificationId === "string"
                }
              >
                <OTPInputBox
                  name="otp"
                  setValue={setOTPValue}
                  className="margin-bottom-large"
                  required
                />
              </ConditionalRender>
              <Button
                data-cy={
                  props.auth.loading === true
                    ? "login-loading-button"
                    : "login-button"
                }
                autoFocus={"on"}
                loading={props.auth.loading}
                type={
                  typeof props.auth.credentials.verificationId === "string"
                    ? "submit"
                    : "button"
                }
                className="max-width-300px margin-bottom-larger"
                text="Login"
                onClick={(event) => {
                  otpVerifyHandler(event);
                }}
                disabled={
                  !(
                    typeof loginFormData?.OTP === "string" &&
                    loginFormData?.OTP.length === 6
                  )
                }
                boxShadow={false}
              />

              <div className="font-weight-normal font-family-gilroy-medium font-size-small font-color-secondary inherit-parent-width text-align-center">
                Want to register your clinic?
                <span
                  data-cy="signup-page-link"
                  className={`margin-left-default font-color-primary ${
                    props.auth.loading === true
                      ? "pointer-events-none"
                      : " cursor-pointer"
                  }`}
                  onClick={() => {
                    props.navigate("/signup");
                    props.clearCredentials();
                  }}
                >
                  Signup
                </span>
              </div>
            </form>
          </div>
        </div>
        <div id="recaptcha-placeholder"></div>
      </div>
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = function () {
  return {
    verifyOtp: (loginOtp) => verifyOtp(loginOtp),
    sendOtp: (phoneNumber, type) => sendOtp(phoneNumber, type),
    mockAuthVerifyOtp: (type) => mockAuthVerifyOtp(type),
    clearCredentials: () => clearCredentials()
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
