import store from "../store/store";
import { navigationActionTypes } from "./saga";

/**
 * @module NavigationSaga
 */

/**
 * To view primary component
 * @param {string} component
 */
export function showPrimary(component) {
  store.dispatch({
    type: navigationActionTypes.SHOW_PRIMARY,
    payload: {
      primary: component
    }
  });
}

/**
 * To view secondary component
 * @param {string} component
 */
export function showSecondary(component) {
  store.dispatch({
    type: navigationActionTypes.SHOW_SECONDARY,
    payload: {
      secondary: component
    }
  });
}

/**
 * To view tertiary component
 * @param {string} component
 */
export function showTertiary(component) {
  store.dispatch({
    type: navigationActionTypes.SHOW_TERTIARY,
    payload: {
      tertiary: component
    }
  });
}
