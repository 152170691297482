import React, { useState, useEffect, useRef, useCallback } from "react";
import { isValidArray, isValidObject } from "../../utils/validators";
import "./pinPatientToDoctor.css";
import Button from "../../front-end-global-components/components/Button/Button";
import drag from "../../assets/icons/Drag.svg";
import close from "../../assets/icons/close.svg";
import Sortable from "sortablejs";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";

export default function PinPatientToDoctor(props) {
  const [searchDoctorData, setSearchDoctorData] = useState("+91");
  const [isValidNumber, setIsValidNumber] = useState(false);
  const [searchResults, setSearchResults] = useState(null);

  const sortableRef = useRef(null);
  const sortedArrayRef = useRef(null);
  const sortableCallBackRef = useRef(null);
  const scrollElementRef = useRef(null);

  const [doctorsPinnedPatients, setDoctorsPinnedPatients] = useState([]);
  const doctorsPinnedPatientsRef = useRef([]);
  const [isPinButtonDisabled, setIsPinButtonDisabled] = useState(true);
  const isPinnedPatientsModified = useRef(false);

  const createSortable = useCallback(() => {
    sortableCallBackRef.current = Sortable.create(sortableRef.current, {
      dataIdAttr: "data-sort",
      ghostClass: ".sortable-ghost",
      handle: ".handle", // handle's class
      animation: 150,
      onSort: function () {
        sortedArrayRef.current = sortableCallBackRef.current.toArray();
        props.setSortedPatients(sortedArrayRef.current);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //remove doctor search data
  useEffect(() => {
    return () => {
      props.removeDoctorSearchData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //create sortable init
  useEffect(() => {
    createSortable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // add selected doctor
  useEffect(() => {
    if (isValidObject(props.doctorSearch.data)) {
      const doctorId = Object.keys(props.doctorSearch.data)[0];
      setSearchResults({
        profileId: doctorId,
        ...props.doctorSearch.data[doctorId]
      });
      props.addSelectedDoctor(doctorId);
    } else {
      setSearchResults(null);
      setDoctorsPinnedPatients([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.doctorSearch.searchedDoctorPhoneNumber,
    // searchDoctorData,
    props.doctorSearch.data
  ]);

  //set doctor pinned patients
  useEffect(() => {
    if (isValidObject(props.doctorSearch.data)) {
      if (
        props.doctorSearch.selectedDoctor &&
        isValidObject(props.doctorSearch.selectedDoctor)
      ) {
        setDoctorsPinnedPatients(
          Object.values(props.doctorSearch.selectedDoctor)[0].pinnedPatients
        );
      }
    }
  }, [props.doctorSearch.data, props.doctorSearch.selectedDoctor]);

  // set is pinned patient button disabled
  useEffect(() => {
    doctorsPinnedPatientsRef.current = [...doctorsPinnedPatients];
    if (!isValidArray(doctorsPinnedPatients)) {
      setIsPinButtonDisabled(false);
      return;
    }

    if (isValidObject(props?.abdmPatients.selectedPatient)) {
      const patientId = props?.abdmPatients.selectedPatient;
      let _isPinButtonDisabled = true;
      for (let index = 0; index < doctorsPinnedPatients.length; index++) {
        _isPinButtonDisabled = false;
        if (patientId === doctorsPinnedPatients[index]?.patient.patientId) {
          _isPinButtonDisabled = true;
          break;
        }
      }
      if (isPinButtonDisabled !== _isPinButtonDisabled) {
        setIsPinButtonDisabled(_isPinButtonDisabled);
      }
    } else if (isPinButtonDisabled === true) {
      setIsPinButtonDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorsPinnedPatients]);

  const handleSearch = (event) => {
    event.preventDefault();
    if (isValidNumber) {
      props.getSearchDoctorData(searchDoctorData, "CONNECTED");
    }
  };

  const searchDoctorFormChange = (event) => {
    if (
      props.doctorSearch.searchedDoctorPhoneNumber !== "" ||
      props.doctorSearch.searchedDoctorPhoneNumber !== null
    ) {
      props.removeDoctorSearchData();
    }
    setSearchDoctorData(event.target.value);
  };

  const onPinPatientBtnClick = () => {
    props.pinPatient();
    const element = scrollElementRef.current;
    if (element) {
      element.scroll({
        top: element.scrollHeight,
        behavior: "smooth"
      });
    }
  };

  return (
    <>
      <div
        ref={scrollElementRef}
        className="hide-scroll-bar inherit-parent-height padding-larger "
      >
        {props.doctorSearch.loading === true ? (
          <>
            <div className="shimmer padding-larger margin-left-default margin-right-default margin-top-large margin-bottom-medium border-radius-default" />
            <div className="padding-smaller margin-default border-radius-default" />
          </>
        ) : (
          <form
            onChange={(event) => {
              searchDoctorFormChange(event);
            }}
            onSubmit={(event) => {
              handleSearch(event);
            }}
          >
            <InputBox
              className="inherit-parent-width max-width-300px border-radius-default padding-bottom-large"
              name="phoneNumber"
              data-cy="doctor-number-field"
              label="Phone Number"
              type="tel"
              value={searchDoctorData && searchDoctorData}
              removeResponsive={true}
              isValidInput={(data) => {
                setIsValidNumber(data);
              }}
              autoComplete={"on"}
              otpButtonData-cy="typeOTPButton"
              buttonTile="Search"
              buttonTileId="doctor-search-button"
              onButtonClick={(event) => {
                handleSearch(event);
              }}
              required
            />
          </form>
        )}

        <div className="background-white border-radius-default">
          <article className="doctor-patient font-family-gilroy-medium font-size-medium list-style-none text-align-left padding-bottom-default">
            {props.doctorSearch.loading === false &&
            typeof props.doctorSearch.searchedDoctorPhoneNumber === "string" &&
            isValidObject(searchResults) ? (
              <>
                <section
                  className="cursor-pointer font-size-medium flex-start flex-align-items-center font-color-secondary background-color-grey border-radius-default padding-default "
                  key={`doctor-pinned-patient-1`}
                  onClick={() => {}}
                  data-cy={`jsjmsm`}
                >
                  <RoundedProfilePicture
                    size="small"
                    loading={false}
                    onClick={() => {}}
                    src={null}
                    data-cy={"pinned-patient-profile-image"}
                  />
                  <div
                    className="margin-left-large text-overflow-ellipsis"
                    data-cy={`pin-patient-to-${searchResults.fullName}`}
                  >
                    <h5 className="font-weight-normal text-overflow-ellipsis font-family-gilroy-regular font-size-medium font-weight-normal margin-vertical-small ">
                      {searchResults.fullName}
                    </h5>
                    <p className="font-size-small margin-vertical-small">
                      {searchResults.phoneNumber}
                    </p>
                  </div>
                </section>
                <Button
                  className="max-height-50px margin-vertical-default"
                  text="Pin Patient"
                  data-cy="pin-patient-button"
                  onClick={onPinPatientBtnClick}
                  boxShadow={false}
                  disabled={isPinButtonDisabled || props.doctorSearch.loading}
                />
              </>
            ) : props.doctorSearch.loading === true ? (
              <>
                <div
                  data-cy="pin-patient-loading"
                  className="shimmer padding-larger margin-left-default margin-right-default margin-top-large margin-bottom-medium border-radius-default"
                >
                  <div className=" padding-large border-radius-default" />
                </div>
                <div className="shimmer padding-large margin-left-default margin-right-default margin-top-large margin-bottom-medium border-radius-default" />
              </>
            ) : (
              typeof props.doctorSearch.searchedDoctorPhoneNumber ===
                "string" &&
              !isValidObject(searchResults) && (
                <div
                  data-cy="no-pinned-patients-found"
                  className="font-color-secondary font-size-medium padding-default flex-center-children-horizontally"
                >
                  No results found
                </div>
              )
            )}

            <ul ref={sortableRef}>
              {doctorsPinnedPatients &&
                doctorsPinnedPatients.map((_doctorsPinnedPatient, index) => {
                  return (
                    <li
                      key={
                        _doctorsPinnedPatient?.docId
                          ? _doctorsPinnedPatient.docId
                          : _doctorsPinnedPatient?.patient.patientId
                      }
                      data-sort={index}
                      data-cy={`pin-patient-${_doctorsPinnedPatient?.patient.patientId}`}
                      className=" border-radius-default "
                    >
                      {props.doctorSearch.loading === true ? (
                        <div className="shimmer padding-larger margin-left-default margin-right-default margin-top-large margin-bottom-large border-radius-default" />
                      ) : (
                        <section className=" padding-top-small padding-bottom-small padding-left-medium padding-right-medium border-radius-default display-flex flex-direction-row">
                          <div className="flex-basis-100">
                            <p
                              data-cy="pinned-patients-fullName"
                              className="font-weight-normal font-family-gilroy-regular font-size-medium font-weight-normal margin-vertical-small cursor-default "
                            >
                              {_doctorsPinnedPatient?.patient.fullName}
                            </p>
                            <p className="font-size-small margin-vertical-small cursor-default">
                              {_doctorsPinnedPatient?.patient.phoneNumber
                                ? _doctorsPinnedPatient?.patient.phoneNumber
                                : _doctorsPinnedPatient?.patient.healthId}
                            </p>
                          </div>
                          <img
                            className="margin-horizontal-large cursor-pointer "
                            src={close}
                            alt="drag"
                            onClick={() => {
                              props.unPinPatient(
                                _doctorsPinnedPatient?.patient.patientId
                              );
                              isPinnedPatientsModified.current = true;
                            }}
                            data-cy={`close-pin-patient-${_doctorsPinnedPatient?.patient.patientId}`}
                          />
                          <img
                            className="handle cursor-grab"
                            src={drag}
                            alt="drag"
                            data-cy={`drag-pin-patient-${_doctorsPinnedPatient?.patient.patientId}`}
                          />
                        </section>
                      )}
                    </li>
                  );
                })}
            </ul>
          </article>
        </div>
      </div>
    </>
  );
}
