import waitingFallbackIcon from "../../assets/waitingFallbackIcon.svg";

export default function FeatureFlagsFallback(props) {
  return (
    <div
      className={` ${
        props.height || "inherit-parent-height"
      } padding-default flex-direction-column flex-justify-content-center flex-align-items-center font-size-large font-weight-normal font-family-gilroy-medium text-align-center `}
      data-cy={props.dataCy || "feature-flag-fallback"}
    >
      <img src={waitingFallbackIcon} alt="waiting fallback icon" />
      <div className=" padding-top-large">Under maintenance</div>

      <div className=" font-family-gilroy-regular padding-top-medium ">
        Thanks for being patience. Our team is working on this feature, please
        check back after sometimes
      </div>
    </div>
  );
}
