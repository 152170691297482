import store from "../store/store";
import { queriesActionTypes } from "./saga";

/**
 * @module QueriersSaga
 */

/**
 * create query
 * @param {string} queryData
 */
export function createQuery(queryData) {
  store.dispatch({
    type: queriesActionTypes.CREATE_QUERY,
    payload: {
      queryData: queryData
    }
  });
}
