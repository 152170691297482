import store from "../store/store";
import { documentsActionTypes } from "./saga";

/**
 * @module DocumentSaga
 */

/**
 * get dataTransfer data
 * @param {object} data
 */
export function getDataTransferInfo(data) {
  store.dispatch({
    type: documentsActionTypes.GET_DATA_TRANSFER,
    payload: data
  });
}

/**
 * retry dataTransfer
 * @param {object} data
 */
export function retryDataTransfer(data) {
  store.dispatch({
    type: documentsActionTypes.RETRY_DATA_TRANSFER,
    payload: data
  });
}

/**
 * set documents from database
 * @param {object} data
 */
export function setDocuments(data) {
  store.dispatch({
    type: documentsActionTypes.SET_DOCUMENTS,
    payload: data
  });
}

/**
 * set selected documents
 * @param {object} documentData
 */
export function setSelectedDocument(documentData) {
  store.dispatch({
    type: documentsActionTypes.SET_SELECTED_DOCUMENTS,
    payload: {
      data: documentData
    }
  });
}

/**
 * set download URL
 * @param {string} url
 * @param {string} documentId
 */
export function setDownloadUrl(url, documentId) {
  store.dispatch({
    type: documentsActionTypes.SET_DOWNLOAD_URL,
    payload: {
      url: url,
      documentId: documentId
    }
  });
}

/**
 * upload document
 * @param {object} data
 */
export function uploadDocument(data) {
  store.dispatch({
    type: documentsActionTypes.UPLOAD_DOCUMENT,
    payload: {
      data: data
    }
  });
}

/**
 * delete document
 * @param {object} data
 */
export function deleteDocument(data) {
  store.dispatch({
    type: documentsActionTypes.DELETE_DOCUMENT
  });
}

/**
 * add vitals
 * @param {object} vitalsData
 */
export function addVitals(vitalsData) {
  store.dispatch({
    type: documentsActionTypes.ADD_VITALS,
    payload: {
      vitalsData: vitalsData
    }
  });
}

/**
 * add document filter
 * @param {string} type
 * @param {string} filterValue
 */
export function addDocumentFilter(type, filterValue) {
  store.dispatch({
    type: documentsActionTypes.ADD_DOCUMENT_FILTER,
    payload: {
      type: type,
      filterValue: filterValue
    }
  });
}

/**
 * remove document filter
 * @param {string} type
 */
export function removeDocumentFilter(type) {
  store.dispatch({
    type: documentsActionTypes.REMOVE_DOCUMENT_FILTER,
    payload: {
      clear: type
    }
  });
}
