import React, { useState, useEffect } from "react";
import "./Menu.css";
import rightIcon from "../../assets/icons/Next.svg";
import nintoIcon from "../../assets/icons/Ninto_header_icon.svg";
import ninto_logo_clinic from "../../assets/icons/ninto_log_clinic.svg";
import {
  // FormIcon,
  // HomeIcon,
  // RequestIcon,
  // StaffIcon,
  ABHAIcon,
  PatientsIcon,
  SettingIcon,
  UploadIcon
} from "../../assets/assets";

export default function Menu(props) {
  const [showFullView, setShowFullView] = useState(false);

  //handle click outside listener
  useEffect(() => {
    function handleClickOutside() {
      if (showFullView) {
        setShowFullView(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showFullView]);

  const menuList = [
    // {
    //   displayName: "Home",
    //   id: "home",
    //   datacy: "menu-home-button",
    //   onClick: props.homeClick,
    //   icon: (
    //     <HomeIcon color={props.navigation.primary === "home" ? "white" : ""} />
    //   )
    // },
    // {
    //   displayName: "Requests",
    //   datacy: "menu-request-button",
    //   id: "request",
    //   onClick: props.requestClick,
    //   icon: (
    //     <RequestIcon
    //       color={props.navigation.primary === "request" ? "white" : ""}
    //     />
    //   )
    // },
    {
      displayName: "Patients",
      id: "patientSearch",
      datacy: "menu-patient-button",
      onClick: props.patientClick,
      icon: (
        <PatientsIcon
          color={props.navigation.primary === "patientSearch" ? "white" : ""}
        />
      )
    },
    // {
    //   displayName: "Staff",
    //   id: "staff",
    //   datacy: "menu-staff-button",
    //   onClick: props.staffClick,
    //   icon: (
    //     <StaffIcon
    //       color={props.navigation.primary === "staff" ? "white" : ""}
    //     />
    //   )
    // },
    {
      displayName: "ABHA",
      id: "abha",
      datacy: "menu-abha-button",
      onClick: props.abhaClick,
      icon: (
        <ABHAIcon color={props.navigation.primary === "abha" ? "white" : ""} />
      )
    },
    {
      displayName: "Upload history",
      id: "documentSearch",
      datacy: "menu-uploadHistory-button",
      onClick: props.uploadHistoryClick,
      icon: (
        <UploadIcon
          color={props.navigation.primary === "documentSearch" ? "white" : ""}
        />
      )
    },
    // {
    //   displayName: "Forms",
    //   id: "form",
    //   onClick: props.formClick,
    //   datacy: "menu-form-button",
    //   icon: (
    //     <FormIcon color={props.navigation.primary === "form" ? "white" : ""} />
    //   )
    // },
    {
      displayName: "Settings",
      id: "accountInformation",
      onClick: props.settingClick,
      datacy: "menu-setting-button",
      icon: (
        <SettingIcon
          color={
            props.navigation.primary === "accountInformation" ? "white" : ""
          }
        />
      )
    }
  ];

  return (
    <>
      <div
        data-cy="el-1"
        className="inherit-parent-height inherit-parent-width"
      >
        {showFullView === true ? (
          <div
            data-cy="menu-full"
            className="menu-width min-width-300px inherit-parent-width padding-top-larger background-white font-family-gilroy-medium box-shadow-default inherit-parent-height flex-column-space-between font-color-tertiary"
          >
            <div>
              <div className=" menu-remove padding-right-default padding-left-default">
                <div className="logo-view ">
                  <img draggable="false" src={nintoIcon} alt="ninto" />
                </div>
              </div>
              <div className="padding-left-larger display-flex-direction-column flex-center-children-horizontally">
                <img
                  data-cy="menuLogo"
                  draggable="false"
                  className="width-100px height-32px "
                  src={ninto_logo_clinic}
                  alt="login-logo"
                />
                <div className=" inherit-parent-width padding-right-larger">
                  <div className="padding-top-large border-bottom-2px"></div>
                </div>
              </div>
              <div className="font-size-medium padding-top-large">
                <ul className="primary-menu cursor-pointer list-style-none">
                  {menuList.map(
                    (data, index) =>
                      data.id !== "accountInformation" && (
                        <div key={index}>
                          <MenuCard navigation={props.navigation} data={data} />
                        </div>
                      )
                  )}
                </ul>
              </div>
            </div>
            <div className="font-size-medium cursor-pointer padding-bottom-large">
              <ul className="secondary-menu list-style-none">
                {menuList.map(
                  (data, index) =>
                    data.id === "accountInformation" && (
                      <div key={index}>
                        <MenuCard navigation={props.navigation} data={data} />
                      </div>
                    )
                )}
              </ul>
            </div>
          </div>
        ) : (
          <div
            data-cy="menu-shrink"
            className="menu-view inherit-parent-height position-fixed min-width-14-percent padding-top-large background-white font-family-gilroy-medium box-shadow-default inherit-parent-height flex-column-space-between font-color-tertiary"
          >
            <div>
              <div className="padding-right-default padding-left-default">
                <div className="logo-view padding-left-default padding-top-default ">
                  <img
                    className="padding-left-default padding-top-smaller"
                    draggable="false"
                    src={nintoIcon}
                    alt="ninto"
                  />
                </div>
              </div>
              <div className="padding-left-larger menu-remove display-flex-direction-column flex-center-children-horizontally ">
                <img
                  data-cy="menuLogo"
                  draggable="false"
                  className="width-100px height-32px menu-remove"
                  src={ninto_logo_clinic}
                  alt="login-logo"
                />
                <div className=" inherit-parent-width padding-right-larger">
                  <div className="padding-top-medium border-bottom-2px "></div>
                </div>
              </div>
              <div className="font-size-medium padding-top-large">
                <ul className="primary-menu cursor-pointer list-style-none">
                  {menuList.map(
                    (data, index) =>
                      data.id !== "accountInformation" && (
                        <div key={index}>
                          <MenuCard
                            hide={true}
                            navigation={props.navigation}
                            data={data}
                          />
                        </div>
                      )
                  )}
                </ul>
              </div>
            </div>
            <div className="inherit-parent-height list-style-none flex-center-children-horizontally ">
              <div className={props.rightClassName}>
                <div className="padding-left-large remove-menu padding-default ">
                  <div className="padding-right-medium ">
                    <img
                      className="height-width-16px padding-top-smaller"
                      draggable="false"
                      src={rightIcon}
                      alt="history icon"
                      onClick={() => {
                        setShowFullView(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="font-size-medium cursor-pointer padding-bottom-large">
              <ul className="secondary-menu list-style-none">
                {menuList.map(
                  (data, index) =>
                    data.id === "accountInformation" && (
                      <div key={index}>
                        <MenuCard
                          hide={true}
                          navigation={props.navigation}
                          data={data}
                        />
                      </div>
                    )
                )}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

const MenuCard = (props) => {
  return (
    <li
      className={
        props.navigation.primary === props.data.id
          ? "hover-effect-menu width-90-percentage border-radius-right-default cursor-pointer"
          : ""
      }
      onClick={props.data.onClick}
      data-cy={`menu-${props.data.id}-button`}
    >
      <div className="padding-default flex-center-children-vertically">
        <div className="padding-right-medium flex-center-children-horizontally">
          {props.data.icon}
        </div>
        <div className="flex-center-children-horizontally height-18px">
          <div
            className={`white-space-nowrap ${
              props.navigation.primary === props.data.id
                ? "font-color-white"
                : "font-color-secondary"
            } ${props.hide ? "menu-hide" : ""}`}
          >
            {props.data.displayName}
          </div>
        </div>
      </div>
    </li>
  );
};
