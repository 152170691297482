import React from "react";

export default function EditAccountInformation(props) {
  const editAccountList = [
    {
      displayName: "Edit profile",
      id: "editProfile",
      description: "Visible to doctors and patients.",
      datacy: "Edit-profile",
      onClick: props.editProfileOnClick
    }
    // {
    //   displayName: "Escalation contact",
    //   id: "escalationContact",
    //   description: "Used for escalation or emergency purposes.",
    //   datacy: "Escalation-contact",
    //   onClick: props.escalationContactOnClick
    // },
    // {
    //   displayName: "Verification",
    //   id: "verification",
    //   description: "Verify your account to unlock all features",
    //   datacy: "Verification",
    //   onClick: props.verificationOnClick
    // }
  ];
  return (
    <div className="user-select-none" data-cy="editAccountInformation">
      <ul className="account-menu-setting cursor-pointer list-style-none text-align-left">
        {editAccountList.map((data, index) => (
          <div key={index}>
            <EditAccountCard
              onClick={data.onClick}
              data={data}
              navigation={props.navigation}
            />
          </div>
        ))}
      </ul>
    </div>
  );
}

const EditAccountCard = (props) => {
  return (
    <li
      className={`margin-bottom-larger ${
        props.navigation.tertiary === props.data.id
          ? "hover-effect cursor-pointer"
          : "box-shadow-default"
      }`}
      data-cy={props.data.datacy}
      onClick={props.onClick}
    >
      {props.data.displayName}
      <br />
      <span className=" font-color-secondary font-family-gilroy-medium font-size-small ">
        {props.data.description}
      </span>
    </li>
  );
};
