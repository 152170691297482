import store from "../store/store";
import { documentSearchActionTypes } from "./saga";

/**
 * @module DocumentSearchSaga
 */

/**
 * get searched document data
 * @param {string} phoneNumber
 */
export function getSearchDocumentData(phoneNumber) {
  store.dispatch({
    type: documentSearchActionTypes.GET_SEARCH_DOCUMENT_DATA,
    payload: {
      phoneNumber: phoneNumber
    }
  });
}

/**
 * add selected document
 * @param {string} documentId
 * @param {string} type
 */
export function addSelectedDocument(documentId, type) {
  store.dispatch({
    type: documentSearchActionTypes.ADD_SELECTED_DOCUMENT,
    payload: {
      documentId: documentId,
      type: type
    }
  });
}

/**
 * remove Document search Data
 * @param {string} deepClear
 */
export function removeDocumentSearchData(deepClear) {
  store.dispatch({
    type: documentSearchActionTypes.REMOVE_DOCUMENT_SEARCH_DATA,
    payload: {
      deepClear: deepClear
    }
  });
}
