const abdmReducer = (
  state = {
    loading: false,
    data: {},
    subscriptions: null,
    consentRequest: null,
    hiuId: null
  },
  action
) => {
  switch (action.type) {
    case "SET_ABDM_LOADING":
      return {
        ...state,
        loading: action.payload.loading
      };

    case "SET_ABDM_HIU_ID":
      return {
        ...state,
        hiuId: action.payload.hiuId
      };

    case "SET_SUBSCRIPTION":
      return {
        ...state,
        subscriptions: action.payload.data
      };

    case "SET_CONSENT_REQUEST":
      return {
        ...state,
        consentRequest: action.payload.data
      };

    case "RESET":
      return {
        loading: false,
        data: {},
        subscriptions: null,
        consentRequest: null,
        hiuId: null
      };

    default:
      return state;
  }
};
export default abdmReducer;
