import React, { useEffect } from "react";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import closeIcon from "../../assets/icons/Close_green.svg";

import "./ManageABHA.css";

export default function ManageABHA(props) {
  const data = [
    // {
    //   title: "Link ABHA Address",
    //   subTitle: "Add ABHA address to ABHA number",
    //   isSelected: "",
    //   onClick: () => {
    //     props.showTertiary("linkAbhaAddress");
    //   }
    // },
    (!props.abha.isHealthIdLogin ||
      (props.abha.isHealthIdLogin &&
        props.abha.abhaNumberCreated.healthIdNumber)) && {
      title: "Create ABHA Address",
      subTitle: "Add ABHA address to ABHA number",
      isSelected: "createABHAAddress",
      onClick: () => {
        props.showTertiary("createABHAAddress");
      }
    },
    {
      title: "Show ABHA Card",
      subTitle: "View ABHA card",
      isSelected: "showAbhaCard",
      onClick: () => {
        props.showTertiary("showAbhaCard");
      }
    },
    // {
    //   title: "Unlink ABHA Address",
    //   subTitle: "Remove ABHA address from ABHA number",
    //   isSelected: "",
    //   onClick: () => {
    //     props.showTertiary("unLinkAbhaAddress");
    //   }
    // },
    {
      title: "Logout",
      subTitle: "Logout from ABHA number",
      isSelected: "",
      onClick: () => {
        props.showTertiary(null);
        props.logout();
      }
    }
  ];

  useEffect(() => {
    if (!props.abha.accessToken) {
      props.logout();
    }
    // eslint-disable-next-line
  }, [props.abha.accessToken]);

  return (
    <>
      <div className="padding-vertical-larger padding-bottom-medium">
        <div
          data-cy="selected-patient-tile"
          className="display-flex inherit-parent-width background-color-grey overflow-hidden border-radius-capsule flex-justify-content-space-between flex-align-items-center"
        >
          <section className="display-flex text-overflow-ellipsis flex-justify-content-space-between flex-align-items-center">
            <RoundedProfilePicture
              size="medium"
              src={`data:image/png;base64, ${props.abha.abhaNumberCreated.profilePhoto}`}
            />
            <div className="background-color-grey padding-left-default text-overflow-ellipsis">
              <p className="font-color-tertiary font-size-medium padding-top-default padding-bottom-small">
                {props.abha.abhaNumberCreated.name}
              </p>
              <p className="font-color-secondary font-size-small padding-bottom-default font-family-gilroy-medium text-overflow-ellipsis">
                {props.abha.abhaNumberCreated.healthIdNumber}
              </p>
            </div>
          </section>
          <section className="padding-right-default cursor-pointer">
            <img
              draggable="false"
              src={closeIcon}
              alt="close"
              onClick={() => {
                // handleProfileClose();
              }}
            />
          </section>
        </div>
      </div>
      <div
        className="account-menu padding-top-larger padding-smaller list-style-none text-align-left"
        style={{ overflow: "hidden" }}
      >
        {data.map(
          (data, index) =>
            data && (
              <React.Fragment key={index}>
                <TileCard
                  isSelected={props.navigation.tertiary === data?.isSelected}
                  title={data?.title}
                  subTitle={data?.subTitle}
                  onClick={data?.onClick}
                />
              </React.Fragment>
            )
        )}
      </div>
    </>
  );
}

function TileCard({ isSelected, onClick, title, subTitle }) {
  return (
    <div
      className={`${
        isSelected
          ? `hover-effect cursor-pointer margin-bottom-larger font-family-gilroy-bold font-size-large ${
              title === "Logout" ? "font-color-red" : ""
            }`
          : `box-shadow-default cursor-pointer margin-bottom-larger font-family-gilroy-bold font-size-large ${
              title === "Logout" ? "font-color-red" : ""
            }`
      }`}
      data-cy="Add-report"
      onClick={onClick}
    >
      {title} <br />
      <span
        className={`font-color-secondary font-family-gilroy-medium font-size-small ${
          title === "Logout" ? "font-color-red" : ""
        }`}
      >
        {subTitle}
      </span>
    </div>
  );
}
