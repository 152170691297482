import React from "react";
import "./AccountInformation.css";
import {
  ManageClinicStaffsIcon,
  PatientsIcon
  // PartneredCompanyIcon,
} from "../../assets/assets";
import {
  LogoutIcon,
  SupportIcon,
  EditIcon
} from "../../front-end-global-components/assets/assets";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";

export default function AccountInformation(props) {
  const accountInformationList = [
    {
      displayName: "Account information",
      id: "editAccountInformation",
      datacy: "accountInformation-edit-button",
      onClick: props.editInformationClick,
      icon: (
        <PatientsIcon
          color={
            props.navigation.secondary === "editAccountInformation"
              ? "white"
              : ""
          }
        />
      )
    },
    {
      displayName: "Edit clinics information",
      id: "clinicsInformation",
      datacy: "accountInformation-clinicsInformation-button",
      onClick: props.clinicsInformationClick,
      icon: (
        <EditIcon
          color={
            props.navigation.secondary === "clinicsInformation" ? "white" : ""
          }
        />
      )
    },
    {
      displayName: "Manage clinic staffs",
      id: "manageClinic",
      datacy: "accountInformation-manageClinic-button",
      onClick: props.manageClinicClick,
      icon: (
        <ManageClinicStaffsIcon
          color={props.navigation.secondary === "manageClinic" ? "white" : ""}
        />
      )
    },
    // {
    //   displayName: "Partnered companies",
    //   id: "partneredCompany",
    //   datacy: "accountInformation-partneredCompany-button",
    //   onClick: props.partneredCompanyClick,
    //   icon: (
    //     <PartneredCompanyIcon
    //       color={
    //         props.navigation.secondary === "partneredCompany" ? "white" : ""
    //       }
    //     />
    //   )
    // },
    {
      displayName: "Query",
      id: "query",
      datacy: "accountInformation-query-button",
      onClick: props.queryClick,
      icon: (
        <SupportIcon
          color={
            props.navigation.secondary === "query" ||
            props.navigation.secondary === "querySuccess"
              ? "white"
              : ""
          }
        />
      )
    },
    {
      displayName: "Logout",
      id: "logout",
      datacy: "accountInformation-logout-button",
      onClick: props.logoutClick,
      icon: <LogoutIcon color="red" />
    }
  ];
  return (
    <div className="user-select-none" data-cy="accountInformation">
      <div className="padding-top-larger inherit-parent-width">
        <UserCard
          data={
            props.profile.data &&
            props.profile.data[Object.keys(props.profile.data)[0]]
          }
          connection={props.connection}
        />
      </div>
      <ul className="setting-menu cursor-pointer font-size-large list-style-none text-align-left">
        {accountInformationList.map((data, index) => (
          <div key={index}>
            <SettingCard
              data={data}
              navigation={props.navigation}
              onClick={data.onClick}
            />
          </div>
        ))}
      </ul>
    </div>
  );
}

const SettingCard = (props) => {
  return (
    <li
      className={`margin-bottom-larger ${
        props.navigation.secondary === props.data.id ||
        (props.data.id === "query" &&
          props.navigation.secondary === "querySuccess")
          ? "hover-effect cursor-pointer"
          : "box-shadow-default"
      }`}
      data-cy={`button-${props.data.id}-setting`}
      onClick={props.onClick}
    >
      <div className="flex-center-children-vertically">
        <div className="margin-right-large flex-center-children-horizontally">
          {props.data.icon}
        </div>
        <div className="flex-center-children-horizontally height-18px">
          <div
            className={`font-family-gilroy-regular ${
              props.navigation.secondary === props.data.id ||
              (props.data.id === "query" &&
                props.navigation.secondary === "querySuccess")
                ? "font-color-white"
                : "font-color-secondary"
            } ${props.data.id === "logout" ? "font-color-red" : ""}`}
          >
            {props.data.displayName}
          </div>
        </div>
      </div>
    </li>
  );
};

const UserCard = (props) => {
  return (
    <div
      data-cy={`${props.connection.currentConnection}-userCard`}
      className="border-bottom-1px-lite-grey  padding-bottom-larger display-flex flex-justify-content-space-between"
    >
      <div className="padding-right-default">
        <div className=" font-size-large font-family-gilroy-medium text-capitalize ">
          {props.data?.fullName
            ? props.data?.fullName
            : props.data?.phoneNumber}
        </div>
        <div className=" font-size-small font-family-gilroy-medium padding-vertical-default">
          {props.data?.phoneNumber}
        </div>
        <div className="display-flex flex-justify-content-space-between">
          <div className="background-color-grey font-size-small padding-default height-fit-to-content width-fit-content border-radius-default text-capitalize">
            {props.connection.currentConnection &&
              props.connection.data[props.connection.currentConnection]
                ?.userType}
          </div>
        </div>
      </div>
      <div>
        <RoundedProfilePicture
          size="medium"
          src={
            props.connection.brands[
              props.connection.currentConnection &&
                props.connection.data[props.connection.currentConnection]
                  .brandId
            ].downloadURL
          }
        />
      </div>
    </div>
  );
};
