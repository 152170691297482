import React, { useState, useEffect } from "react";
// import RoundedProfilePicture from "../roundedProfilePicture/RoundedProfilePicture";
import Button from "../../front-end-global-components/components/Button/Button";
import pdfIcon from "../../assets/icons/PDFICON.png";
import { isValidArray, isValidObject } from "../../utils/validators";
import closeIcon from "../../assets/icons/close.svg";
import backIcon from "../../assets/icons/Back.svg";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import { validDocumentType } from "../../utils/constants";
import FHIRRenderer from "../../front-end-global-components/components/FHIRRenderer/FHIRRenderer";
import PDFRenderer from "../../front-end-global-components/components/PDFRenderer/PDFRenderer";
import { setErrorStatus } from "../../redux/status/actions";

export default function SelectedDocument(props) {
  const [showHistory, setShowHistory] = useState(true);
  const [showDocumentViewModal, setShowDocumentViewModal] = useState(false);
  const [selectedDocumentData, setSelectedDocumentData] = useState(null);
  const [PDFfromFHIR, setPDFfromFHIR] = useState(null);
  const [jsonData, setJsonData] = useState(null);
  const [readOnly] = useState(true);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [fileType, setFileType] = useState("");
  const [pdfArrayBuffer, setPdfArrayBuffer] = useState({});

  //set selected document
  useEffect(() => {
    if (
      props.documentSearch.selectedDocument &&
      props.connection.currentConnection &&
      isValidObject(props.history.documents)
    ) {
      setSelectedDocumentData(
        Object.values(
          props.history.documents[props.connection.currentConnection]
        ).find(
          (documentData) =>
            documentData.documentId === props.documentSearch.selectedDocument
        )
      );
    }
  }, [
    props.documentSearch.selectedDocument,
    props.history.documents,
    props.connection.currentConnection
  ]);

  //remove previous search data
  useEffect(() => {
    return () => {
      props.removeDocumentSearchData(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // set download URL
  useEffect(() => {
    if (
      selectedDocumentData &&
      selectedDocumentData.url &&
      !selectedDocumentData.downloadUrl
    ) {
      props.setDownloadUrl(
        selectedDocumentData.url,
        selectedDocumentData.documentId
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDocumentData]);

  //to decide document search list or upload history data to show
  useEffect(() => {
    if (
      isValidObject(props.notification.selectedNotification) &&
      typeof props.notification.selectedNotification.documentId === "string" &&
      typeof props.documentSearch.selectedDocument === "string" &&
      isValidArray(props.history.documents)
    ) {
      const documentHistory = props.history.documents;

      const historyDocumentData = documentHistory.find(
        (documentData) =>
          documentData.documentId ===
          props.notification.selectedNotification.documentId
      );

      if (!isValidObject(historyDocumentData)) {
        if (showHistory) setShowHistory(false);
      } else {
        if (isValidObject(props.documentSearch.data)) {
          // props.removeDocumentSearchData(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.documentSearch, props.documentSearch.selectedDocument]);

  // to fetch download data
  useEffect(() => {
    if (selectedDocumentData?.downloadUrl) {
      (async () => {
        await fetchDownloadURL(selectedDocumentData);
      })();
    }
    // eslint-disable-next-line
  }, [selectedDocumentData]);

  async function fetchDownloadURL(document) {
    let url;
    if (document.url.length > 0) {
      url = document.downloadUrl;
      fetch(url)
        .then(async (res) => {
          const url = res.url.split("?")[0];
          const lastElement = url.split(".").length - 1;
          setFileType(url.split(".")[lastElement]);
          if (url.split(".")[lastElement] === "json") {
            const response = await fetch(res.url, {
              method: "GET"
            });
            const text = await response.json();
            setJsonData(text);
          } else {
            setPdfArrayBuffer({
              ...pdfArrayBuffer,
              base: await res.arrayBuffer()
            });
          }
        })
        .catch((error) => {
          setErrorStatus({
            custom: "custom",
            message: "Unable to download"
          });
          console.error(error);
        });
    }
  }
  return (
    <>
      {props.documentSearch.loading === false &&
      (props.history.downloadUrlLoading === true ||
        !selectedDocumentData?.downloadUrl) ? (
        <SelectedDocumentSuspense />
      ) : (
        <div data-cy="selectedDocument">
          {isValidObject(selectedDocumentData) && (
            <div>
              <div className="display-flex flex-justify-content-space-between padding-bottom-large">
                <div className="border-radius-default width-48px background-color-grey font-family-gilroy-medium margin-right-default">
                  <div className="font-color-tertiary font-size-small text-align-left padding-default padding-bottom-small ">
                    Day
                  </div>
                  <div className="font-color-secondary text-align-left font-size-medium padding-left-default padding-right-default padding-bottom-default">
                    {new Date(
                      selectedDocumentData && selectedDocumentData.timestamp
                    ).toLocaleString("en-IN", { day: "2-digit" })}
                  </div>
                </div>
                <div className="border-radius-default width-125px background-color-grey font-family-gilroy-medium margin-right-default">
                  <div className="font-color-tertiary text-align-left font-size-small padding-default padding-bottom-small">
                    Month
                  </div>
                  <div className="font-color-secondary text-align-left font-size-medium padding-left-default padding-right-default padding-bottom-default">
                    {new Date(
                      selectedDocumentData && selectedDocumentData.timestamp
                    ).toLocaleString("en-IN", { month: "long" })}
                  </div>
                </div>
                <div className="border-radius-default width-70px background-color-grey font-family-gilroy-medium">
                  <div className="font-color-tertiary text-align-left font-size-small padding-default padding-bottom-small">
                    Year
                  </div>
                  <div className="font-color-secondary text-align-left font-size-medium padding-left-default padding-right-default padding-bottom-default">
                    {new Date(
                      selectedDocumentData && selectedDocumentData.timestamp
                    ).toLocaleString("en-IN", { year: "numeric" })}
                  </div>
                </div>
              </div>
              <div className="inherit-parent-width height-180px cursor-pointer border-radius-default background-color-grey">
                <div className="padding-right-default font-family-gilroy-medium padding-left-default white-space-nowrap">
                  <div className="font-color-tertiary font-size-small padding-top-default padding-bottom-small">
                    Title
                  </div>
                  <div className="font-color-secondary font-size-medium padding-bottom-default text-overflow-ellipsis">
                    {selectedDocumentData && selectedDocumentData.name}
                  </div>
                </div>
                <div
                  className="inherit-parent-width padding-left-default padding-right-default padding-bottom-default"
                  onClick={() => {
                    setShowDocumentViewModal(true);
                  }}
                >
                  <div className="inherit-parent-height flex-center-children-horizontally flex-center-children-vertically border-radius-default height-120px background-white">
                    <img
                      className="height-width-48px"
                      draggable="false"
                      data-cy="pdfRenderer"
                      aria-label={`${
                        selectedDocumentData && selectedDocumentData.documentId
                      }`}
                      src={pdfIcon}
                      alt="pdf icon"
                    />
                  </div>
                </div>
              </div>
              {!isValidObject(selectedDocumentData?.redacted) && (
                <div className="inherit-parent-width padding-top-larger flex-center-children-horizontally font-family-gilroy-medium margin-bottom-larger">
                  <Button
                    type="submit"
                    data-cy="remove-button"
                    className="inherit-parent-width max-height-50px"
                    text="Remove record"
                    onClick={props.documentDeleteOnClick}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <Modal
        show={showDocumentViewModal}
        width="width-90-percent"
        height="height-90-percent"
        maxWidth="max-width-800px"
        maxHeight="max-height-850px"
        modalBodyClassName=" overflow-hidden"
        onClick={() => {
          setShowDocumentViewModal(!showDocumentViewModal);
        }}
      >
        <section className="font-color-secondary flex-justify-content-center padding-top-large padding-bottom-large ">
          {PDFfromFHIR !== null && (
            <img
              src={backIcon}
              onClick={() => {
                setPDFfromFHIR(null);
              }}
              alt="back"
              data-cy="modal-close-button"
              className=" cursor-pointer padding-left-large padding-right-large position-absolute left-0"
            />
          )}

          <div className=" display-flex padding-left-large padding-right-large">
            <div className="padding-right-large">
              {selectedDocumentData?.documentName && (
                <div className=" text-transform-capitalize font-family-gilroy-regular font-size-medium">
                  {selectedDocumentData?.documentName}
                </div>
              )}
            </div>
          </div>
          <img
            src={closeIcon}
            onClick={() => {
              setShowDocumentViewModal(!showDocumentViewModal);
            }}
            alt="close"
            data-cy="modal-close-button"
            className=" cursor-pointer padding-left-large padding-right-large position-absolute right-0"
          />
        </section>
        <div className="max-height-92-percentage padding-left-larger padding-right-larger hide-scroll-bar overflow-auto inherit-parent-height">
          <div className="inherit-parent-height">
            <div className="inherit-parent-width height-94-percent">
              <section className=" inherit-parent-height inherit-parent-width ">
                {[
                  "reports",
                  "prescriptions",
                  "admissions",
                  "vitals",
                  "vaccines",
                  "others",
                  "consultations"
                ].includes(
                  selectedDocumentData?.documentType &&
                    validDocumentType(selectedDocumentData?.documentType)
                ) && (
                  <>
                    {fileType === "json" &&
                      jsonData !== null &&
                      PDFfromFHIR === null && (
                        <FHIRRenderer
                          document={selectedDocumentData}
                          userType="clinic"
                          connection={props.connection}
                          data={jsonData}
                          documentOnClick={(document) => {
                            setPDFfromFHIR(document);
                          }}
                        />
                      )}

                    {((fileType && fileType !== "json") || PDFfromFHIR) && (
                      <>
                        <PDFRenderer
                          readOnly={readOnly}
                          pdfArrayBuffer={PDFfromFHIR && PDFfromFHIR}
                          saveWithBackground={false}
                          onPDFChange={() => {
                            if (isPdfLoading === false) {
                              setIsPdfLoading(true);
                            }
                          }}
                          onRender={() => {
                            if (isPdfLoading === true) {
                              setIsPdfLoading(false);
                            }
                          }}
                        />
                      </>
                    )}
                  </>
                )}
              </section>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

function SelectedDocumentSuspense() {
  return (
    <article>
      <div className="inherit-parent-width" data-cy="document-loading">
        <div className="display-flex flex-justify-content-space-between padding-bottom-large">
          <div className="shimmer border-radius-default width-48px background-color-grey font-family-gilroy-medium margin-right-default">
            <div className="shimmer padding-default border-radius-default">
              <div className="shimmer padding-default margin-default border-radius-default" />
            </div>
          </div>
          <div className="shimmer border-radius-default width-125px background-color-grey font-family-gilroy-medium margin-right-default">
            <div className="shimmer padding-default border-radius-default">
              <div className="shimmer padding-default margin-default border-radius-default" />
            </div>
          </div>
          <div className="shimmer border-radius-default width-70px background-color-grey font-family-gilroy-medium">
            <div className="shimmer padding-default border-radius-default">
              <div className="shimmer padding-default margin-default border-radius-default" />
            </div>
          </div>
        </div>
        <div className="shimmer inherit-parent-width height-180px cursor-pointer border-radius-default background-color-grey">
          <div className="shimmer padding-default height-180px border-radius-default"></div>
        </div>
        <div className="flex-center-children-vertically margin-top-large">
          <div className="shimmer inherit-parent-width padding-small margin-vertical-default border-radius-default">
            <div className="padding-default margin-default border-radius-default" />
          </div>
        </div>
      </div>
    </article>
  );
}
