const connectionReducer = (
  state = {
    loading: false,
    data: null,
    currentConnection: null,
    brands: null,
    corporateBrands: null,
    profilePic: null,
    selectedClinic: null,
    connectedStaffs: null,
    corporatePackages: null
  },
  action
) => {
  switch (action.type) {
    case "SET_CONNECTION_LOADING":
      return {
        ...state,
        loading: action.payload.loading
      };

    case "SET_CONNECTIONS_DATA":
      return {
        ...state,
        data: action.payload.data
      };

    case "SET_CURRENT_CONNECTION_DATA":
      return {
        ...state,
        currentConnection: action.payload.data
      };

    case "SET_CONNECTED_STAFF_DATA": {
      return {
        ...state,
        connectedStaffs: action.payload.data
      };
    }

    case "SET_SELECTED_CLINICS": {
      return {
        ...state,
        selectedClinic: action.payload.data
      };
    }

    case "SET_CORPORATE_PACKAGES": {
      return {
        ...state,
        corporatePackages: {
          ...(state.corporatePackages ? state.corporatePackages : {}),
          ...action.payload.data
        }
      };
    }
    case "SET_CONNECTIONS_BRAND_DATA":
      return {
        ...state,
        brands: {
          ...state.brands,
          ...action.payload.data
        }
      };

    case "SET_CORPORATE_BRANDS_LIST":
      return {
        ...state,
        corporateBrands: {
          ...state.corporateBrands,
          ...action.payload.data
        }
      };

    case "RESET":
      return {
        loading: false,
        data: null,
        currentConnection: null,
        brands: null,
        corporateBrands: null,
        profilePic: null,
        selectedClinic: null,
        connectedStaffs: null,
        corporatePackages: null
      };

    default:
      return state;
  }
};
export default connectionReducer;
