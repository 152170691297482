import React from "react";
import Button from "../../front-end-global-components/components/Button/Button";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";

export default function EditVerification(props) {
  return (
    <section className="padding-larger">
      {props.profile &&
      props.profile.loading &&
      props.profile.loading === true ? (
        <EditVerificationSuspense />
      ) : (
        <div>
          <form className="flex-start-place-children-vertically">
            <InputBox
              removeResponsive={true}
              className="inherit-parent-width max-width-300px border-radius-default padding-bottom-large"
              name="registrationNumber"
              data-cy="typeRegistrationNumber"
              label="Registration number"
              type="text"
              required
              disabled={true}
            />
            <InputBox
              removeResponsive={true}
              className="inherit-parent-width border-radius-default padding-bottom-large"
              name="registrationCouncil"
              data-cy="typeRegistrationCouncil"
              label="Registration council"
              type="text"
              required
              disabled={true}
            />
            <InputBox
              removeResponsive={true}
              className="inherit-parent-width border-radius-default padding-bottom-large"
              name="registrationYear"
              data-cy="typeRegistrationYear"
              label="Registration year"
              type="number"
              required
              disabled={true}
            />
            <InputBox
              removeResponsive={true}
              className="inherit-parent-width max-height-100px border-radius-default padding-bottom-large"
              name="registrationProof"
              data-cy="typeRegistrationProof"
              label="Registration proof"
              type="text"
              disabled={true}
            />
          </form>
          <div className="flex-justify-content-space-between padding-top-smaller ">
            <div className="inherit-parent-width font-family-gilroy-bold padding-bottom-large">
              <Button
                type="submit"
                className="max-width-160px max-height-50px"
                boxShadow={false}
                disabled={true}
                text="Save"
                data-cy="button"
              ></Button>
            </div>
            <div className="inherit-parent-width padding-left-large font-family-gilroy-medium padding-bottom-large">
              <Button
                type="submit"
                className="max-width-160px max-height-50px"
                boxShadow={false}
                text="Cancel"
                variant="secondary"
                onClick={props.cancelButtonClick}
              ></Button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

function EditVerificationSuspense() {
  return (
    <div>
      <div className="  margin-default">
        <div className="shimmer  border-radius-default height-48px" />
        <div className="shimmer margin-top-medium border-radius-default height-48px" />
        <div className="shimmer margin-top-medium border-radius-default height-48px" />
        <div className="shimmer margin-top-medium border-radius-default height-48px" />
      </div>
      <div className=" flex-justify-content-space-around margin-default margin-top-large">
        <div className="shimmer width-50-percentage height-48px margin-right-default border-radius-default" />
        <div className="shimmer width-50-percentage height-48px margin-left-default border-radius-default" />
      </div>
    </div>
  );
}
