import React from "react";
import ReactDOM from "react-dom";
import "./front-end-global-components/design-system.css";
import App from "./App";
import LogRocket from "logrocket";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import store, { persistor } from "./redux/store/store";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";

ReactDOM.render(
  <ErrorBoundary
    handleComponentDidCatch={(error, errorInfo) => {
      // window.gtag("event", "exception", {
      //   code: error.code,
      //   userType: "clinic",
      //   message: error.message,
      //   fatal: true
      // });
      LogRocket.captureMessage(error.message, {
        tags: {
          userType: "clinic"
        },
        extra: {
          fatal: true,
          code: error.code
        }
      });
    }}
  >
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </React.StrictMode>
  </ErrorBoundary>,
  document.getElementById("root")
);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register(`../firebase-messaging-sw.js`)
    .then(function (registration) {
      console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function (err) {
      console.log("Service worker registration failed, error:", err);
    });
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (window.Cypress) {
  window.store = store;
}
