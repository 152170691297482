import store from "../store/store";
import { formsActionTypes } from "./saga";

/**
 * @module FormSaga
 */

/**
 * Get forms
 */
export function getForms() {
  store.dispatch({
    type: formsActionTypes.GET_FORMS
  });
}

/**
 * Create form
 * @param {object} formData
 */
export function createForm(formData) {
  store.dispatch({
    type: formsActionTypes.CREATE_FORM,
    payload: {
      formData: formData
    }
  });
}

/**
 * update form
 * @param {object} formData
 */
export function updateForm(formData) {
  store.dispatch({
    type: formsActionTypes.UPDATE_FORM,
    payload: {
      formData: formData
    }
  });
}

/**
 * set current form
 * @param {string} formDocumentId
 */
export function putCurrentForm(formDocumentId) {
  store.dispatch({
    type: formsActionTypes.PUT_CURRENT_FORM,
    payload: {
      formDocumentId: formDocumentId
    }
  });
}

/**
 * remove current form
 */
export function removeCurrentForm() {
  store.dispatch({
    type: formsActionTypes.REMOVE_CURRENT_FORM
  });
}

/**
 * upload form
 * @param {string} documentId
 */
export function uploadForm(documentId) {
  store.dispatch({
    type: formsActionTypes.UPLOAD_FORM,
    payload: {
      documentId: documentId
    }
  });
}
