import React, { useEffect, useState } from "react";
import "./ConnectToPatient.css";
import Button from "../../front-end-global-components/components/Button/Button";
import OTPInputBox from "../../front-end-global-components/components/OTPInputBox/OTPInputBox";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import CheckBox from "../../front-end-global-components/components/CheckBox/CheckBox";
import NativeSelect from "../../front-end-global-components/components/NativeSelect/NativeSelect";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";

export default function ConnectToPatient(props) {
  const [otpPhoneNumberType, setOtpPhoneNumberType] = useState("healthId");
  const [otpValue, setOtpValue] = useState("");
  const [userHealthId, setUserHealthId] = useState("");
  const [counter, setCounter] = useState(null);
  const [running, setRunning] = useState(false);

  const [demographicsFormData, setDemographicsFormData] = useState({
    fullName: "",
    dateOfBirth: "",
    gender: ""
  });

  useEffect(() => {
    let counterTimer;
    if (counter && counter !== null && typeof counter === "number" && running) {
      counterTimer = setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    } else {
      setCounter(null);
      setRunning(false);
      return () => clearTimeout(counterTimer);
    }
    // eslint-disable-next-line
  }, [counter]);

  //set user healthId from store
  useEffect(() => {
    if (
      props.abdmPatients?.searchResults[props.abdmPatients?.selectedPatient]
        ?.healthId
    ) {
      setUserHealthId(
        props.abdmPatients?.searchResults[props.abdmPatients?.selectedPatient]
          ?.healthId
      );
    }
    if (props.requireAuthorization) {
      props.clearToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGender = (data) => {
    if (data === "M") {
      return "Male";
    } else if (data === "F") {
      return "Female";
    } else if (data === "O") {
      return "Others";
    } else if (data === "U") {
      return "Unknown";
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (props.selectedPatient) {
      let obj = {
        fullName: props.selectedPatient.name,
        ...(props.selectedPatient?.dob
          ? {
              dateOfBirth: `${props.selectedPatient?.dob?.year}-${
                props.selectedPatient?.dob?.month?.toString().length === 1
                  ? "0" + props.selectedPatient?.dob?.month
                  : props.selectedPatient?.dob?.month
              }-${
                props.selectedPatient?.dob?.day?.toString().length === 1
                  ? "0" + props.selectedPatient?.dob?.day
                  : props.selectedPatient?.dob?.day
              }`
            }
          : { dateOfBirth: "" }),
        gender: getGender(props.selectedPatient.gender)
          ? getGender(props.selectedPatient.gender)
          : ""
      };
      setDemographicsFormData(obj);
    }
    // eslint-disable-next-line
  }, []);

  const toggleAadhaarOtpCheckBox = async function (event) {
    if (event.currentTarget.checked) {
      return setOtpPhoneNumberType("aadhaar");
    } else {
      return setOtpPhoneNumberType("healthId");
    }
  };

  useEffect(() => {
    if (props.abdmQR?.otpLinking?.transactionId) {
      setCounter(60);
      setRunning(true);
    } else {
      setRunning(false);
    }
  }, [props.abdmQR?.otpLinking?.transactionId]);

  return (
    <>
      <ConditionalRender condition={!props.abdm?.loading}>
        <section className="display-flex flex-align-items-center inherit-parent-width inherit-parent-height ">
          <div className="height-fit-content">
            <div className="font-weight-normal font-family-gilroy-medium text-align-center font-color-secondary font-size-default">
              <p className="margin-bottom-larger">Scan to connect</p>
              <p className="margin-bottom-larger">
                Go to{" "}
                <span className="font-color-primary">scan.nintoapp.com</span> in
                a smartphone
              </p>

              <div>
                <div className="text-align-left list-style-type-none">
                  <div className="margin-bottom-default flex-center-children-vertically flex-direction-row margin-top-large">
                    <div className="font-size-small font-color-white flex-center-children-horizontally flex-center-children-vertically margin-right-default margin-bottom-smaller background-color-tertiary padding-top-smaller height-width-1p2em border-radius-100-percentage">
                      1
                    </div>
                    <p className="flex-center-children-vertically">
                      Login with your Phone Number
                    </p>
                  </div>

                  <div className="margin-bottom-default flex-center-children-vertically flex-direction-row">
                    <div className="font-size-small font-color-white flex-center-children-horizontally flex-center-children-vertically margin-right-default margin-bottom-smaller background-color-tertiary padding-top-smaller height-width-1p2em border-radius-50-percentage">
                      2
                    </div>
                    <p className="flex-center-children-vertically">
                      Scan patient’s QR code
                    </p>
                  </div>

                  <div className="margin-bottom-default flex-center-children-vertically flex-direction-row">
                    <div className="font-size-small font-color-white flex-center-children-horizontally flex-center-children-vertically margin-right-default margin-bottom-smaller background-color-tertiary padding-top-smaller height-width-1p2em border-radius-50-percentage">
                      3
                    </div>
                    <p className="flex-center-children-vertically">
                      Patient will be connected!
                    </p>
                  </div>
                </div>
              </div>

              {props.requireAuthorization && (
                <div className="flex-center-children-vertically margin-vertical-large ">
                  <hr
                    style={{ width: "100%" }}
                    className="display-inline-block border-color-secondary-lite border-top-left-right-none flex-basis-1 "
                  />
                  <span className=" margin-horizontal-default display-inline-block font-color-tertiary font-size-small">
                    OR
                  </span>
                  <hr
                    style={{ width: "100%" }}
                    className="display-inline-block border-color-secondary-lite border-top-left-right-none flex-basis-1 "
                  />
                </div>
              )}
            </div>
            {props.requireAuthorization && (
              <form
                className="inherit-parent-width padding-small "
                onSubmit={(event) => {
                  props.onSubmitOtpLinkingForm(event, {
                    otp: otpValue,
                    healthId: userHealthId
                  });
                }}
              >
                <InputBox
                  className="inherit-parent-width max-width-300px border-radius-default"
                  buttonClassName={"background-color-white"}
                  name="healthIdTextBox"
                  label="Health Id"
                  type="text"
                  removeResponsive={true}
                  data-cy="health-id-text-box-for-linking"
                  value={userHealthId}
                  disabled={userHealthId ? true : false}
                  otpButtonData-cy="send-opt-button"
                  buttonTile={
                    typeof counter === "number" && counter !== 0
                      ? `${
                          counter?.toString().length === 1 ? "0" : ""
                        }${counter?.toString()} seconds`
                      : props.abdmQR?.otpLinking?.transactionId
                      ? "Resend"
                      : "GET OTP"
                  }
                  buttonTileId="send-otp-button"
                  buttonType={
                    props.abdmQR?.otpLinking?.transactionId
                      ? "button"
                      : "submit"
                  }
                  onButtonClick={(event) => {
                    props.OTPButtonClick(event, {
                      patientId: props.abdmPatients?.selectedPatient,
                      type: otpPhoneNumberType
                    });
                  }}
                  onChange={(event) => {
                    setUserHealthId(event.target?.value);
                  }}
                  suffixButtonDisabled={
                    props.abdmQR?.loading ||
                    (counter && typeof counter === "number" ? true : false)
                  }
                  note="OTP will be sent to the phone number linked to this health ID"
                  required
                />
                {props.abdmQR?.otpLinking?.transactionId && (
                  <div className=" padding-small">
                    <OTPInputBox
                      size="small"
                      name="otp"
                      data-cy="typeotpinputbox"
                      className="max-width-300px margin-bottom-large"
                      setValue={setOtpValue}
                      required
                    />
                  </div>
                )}
                {!props.abdmQR?.otpLinking?.transactionId && (
                  <CheckBox
                    className="inherit-parent-width margin-bottom-larger max-width-300px border-radius-default padding-top-default padding-left-small padding-bottom-larger "
                    label="Use Aadhaar phone number"
                    name="useAadhaarPhoneNumber"
                    onChange={(event) => {
                      toggleAadhaarOtpCheckBox(event);
                    }}
                  />
                )}
                <Button
                  type="submit"
                  data-cy="connect-patient-button"
                  className="inherit-parent-width height-40px padding-vertical-default margin-top-larger "
                  text="Connect"
                  loading={props.abdmQR?.loading}
                  disabled={!(otpValue.length === 6)}
                />
              </form>
            )}

            {props.requireAuthorization && (
              <div className="flex-center-children-vertically margin-vertical-large ">
                <hr className="display-inline-block border-color-secondary-lite border-top-left-right-none width-100-percent flex-basis-1 " />
                <span className=" margin-horizontal-default display-inline-block font-color-tertiary font-size-small">
                  OR
                </span>
                <hr className="display-inline-block border-color-secondary-lite border-top-left-right-none width-100-percent flex-basis-1 " />
              </div>
            )}

            {props.requireAuthorization && (
              <>
                <p className="margin-bottom-larger font-color-secondary text-align-center">
                  Connect by verifying demographic
                </p>

                <form
                  className="padding-smaller"
                  onChange={(event) => {
                    setDemographicsFormData({
                      ...demographicsFormData,
                      [event.target.name]: event.target.value
                    });
                  }}
                  onSubmit={(event) => {
                    props.onFormSubmit(event, demographicsFormData);
                  }}
                >
                  <InputBox
                    className="inherit-parent-width max-width-300px border-radius-default margin-bottom-larger"
                    name="fullName"
                    value={demographicsFormData.fullName}
                    data-cy="demographics-patient-name"
                    label="Full Name"
                    type="text"
                    notes="Please provide as per your Medical records."
                    removeResponsive={true}
                    disabled={props.selectedPatient.name ? true : false}
                    required
                  />
                  <div className="inherit-parent-width max-width-300px border-radius-default margin-bottom-default align-items-center flex-justify-content-space-between">
                    <NativeSelect
                      name="gender"
                      label="Gender"
                      className="padding-right-default height-fit-content"
                      removeResponsive={true}
                      size="small"
                      data-cy="demographics-patient-gender"
                      options={["", "Male", "Female", "Others"]}
                      defaultValue={demographicsFormData.gender}
                      selectedValue={(value) => {
                        setDemographicsFormData({
                          ...demographicsFormData,
                          gender: value
                        });
                      }}
                      disabled={props.selectedPatient.gender ? true : false}
                      required
                    />

                    <InputBox
                      removeResponsive={true}
                      className="margin-right-default "
                      name="dateOfBirth"
                      label="Date Of Birth"
                      size="half"
                      value={demographicsFormData.dateOfBirth}
                      type="date"
                      disabled={props.selectedPatient?.dob ? true : false}
                      required
                    />
                  </div>
                  {props.showViewMore && (
                    <div
                      onClick={() => {
                        props.showMoreOnclick(true);
                      }}
                      className="text-align-center padding-bottom-default font-color-primary text-underline-hover cursor-pointer"
                    >
                      Show More
                    </div>
                  )}
                  <Button
                    className="inherit-parent-width max-width-300px border-radius-default margin-top-default margin-bottom-larger"
                    data-cy="demographic-add-button"
                    boxShadow={false}
                    text="Connect"
                    loading={props.abdmQR?.loading}
                    type="submit"
                    disabled={
                      !(
                        typeof demographicsFormData.fullName === "string" &&
                        demographicsFormData.fullName.length > 2 &&
                        demographicsFormData.gender &&
                        demographicsFormData.dateOfBirth &&
                        typeof demographicsFormData.dateOfBirth === "string"
                      )
                    }
                  />
                </form>
              </>
            )}
          </div>
        </section>
      </ConditionalRender>
      <ConditionalRender condition={props.abdm?.loading}>
        <Suspense />
      </ConditionalRender>
    </>
  );
}

const Suspense = () => {
  return (
    <article>
      <div className="inherit-parent-width">
        {[...Array(6).keys()].map((index) => (
          <div
            className="border-bottom-2px flex-center-children-vertically"
            key={index}
          >
            <div className="shimmer inherit-parent-width padding-small margin-vertical-default border-radius-default">
              <div className="padding-default margin-default border-radius-default" />
            </div>
          </div>
        ))}
      </div>
    </article>
  );
};
