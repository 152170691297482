const historyReducer = (
  state = {
    loading: false,
    downloadUrlLoading: false,
    paginationLoading: false,
    documents: null,
    pagination: null
  },
  action
) => {
  switch (action.type) {
    case "SET_HISTORY_LOADING":
      return {
        ...state,
        loading: action.payload.loading
      };

    case "SET_UPDATE_HISTORY_LOADING":
      return {
        ...state,
        paginationLoading: action.payload.loading
      };

    case "SET_DOWNLOAD_URL_LOADING":
      return {
        ...state,
        downloadUrlLoading: action.payload.loading
      };

    case "SET_HISTORY_DATA":
      return {
        ...state,
        documents: action.payload.data
      };

    case "SET_PAGINATION_DATA":
      return {
        ...state,
        pagination: action.payload.data
      };

    case "RESET":
      return {
        loading: false,
        downloadUrlLoading: false,
        paginationLoading: false,
        documents: null,
        pagination: null
      };

    default:
      return state;
  }
};
export default historyReducer;
