import React, { useState, useEffect, useRef } from "react";
import "./Notification.css";
import { connect } from "react-redux";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import rightIcon from "../../assets/icons/Next.svg";
import noNotification from "../../assets/images/No_notification.svg";
import greenIcon from "../../assets/icons/notificationCircle.svg";
import { isValidObject } from "../../utils/validators";
import arrow from "../../front-end-global-components/assets/DropDownArrow.svg";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import ProfileCards from "../../components/ProfileCards/ProfileCards";
import { setCurrentConnection } from "../../redux/connection/actions";
import {
  setNotificationRead,
  setNotifications,
  setSelectedNotification,
  updateNotifications
} from "../../redux/notification/actions";
import { getFCMToken } from "../../services/cloudMessaging";

export function Notification(props) {
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [showFullView, setShowFullView] = useState(false);
  const [permissionStatus, setPermissionStatus] = useState(
    window.Notification.permission
  );
  const [showClinics, setShowClinics] = useState(false);
  const isOpenedAlways = useRef(true);
  const hideFullViewRef = useRef(null);

  function handleNotification(profile) {
    setSelectedNotification(profile.documentId);
    props.setSelectedNotification(profile);
    if (profile.read === false) {
      props.setNotificationRead(profile);
    }

    if (showFullView === true && isOpenedAlways.current === false) {
      setShowFullView(false);
    }
  }

  function dateAndTimeConverter(timestamp) {
    if (!timestamp) return;
    const currentDate = new Date().getDate();
    const currentMonth = new Date().getMonth();
    const userDate = new Date(timestamp).getDate();
    const userMonth = new Date(timestamp).getMonth();

    if (currentDate === userDate && currentMonth === userMonth) {
      let dateObject = new Date(timestamp);
      const date =
        `${"Today"}` +
        "," +
        " " +
        dateObject
          .toLocaleString("en-IN", {
            hour: "numeric" && "2-digit",
            minute: "numeric" && "2-digit",
            hour12: true
          })
          .toUpperCase() +
        " ";
      return date;
    } else {
      let dateObject = new Date(timestamp);

      const date =
        dateObject.toLocaleString("en-IN", { day: "numeric" }) +
        " " +
        dateObject.toLocaleString("en-IN", { month: "short" }) +
        " " +
        dateObject.toLocaleString("en-IN", { year: "numeric" }) +
        " ," +
        " " +
        dateObject
          .toLocaleString("en-IN", {
            hour: "numeric" && "2-digit",
            minute: "numeric" && "2-digit",
            hour12: true
          })
          .toUpperCase() +
        " ";
      return date;
    }
  }

  //set unread notification
  useEffect(() => {
    if (isValidObject(props.notification.data)) {
      const notificationData = Object.values(props.notification.data);
      setUnreadNotificationsCount(
        notificationData.reduce(
          (notification, value) => notification + (value.read === false),
          0
        )
      );
    }
  }, [props.notification.data]);

  // handle click outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        hideFullViewRef.current &&
        !hideFullViewRef.current.contains(event.target) &&
        isOpenedAlways.current === false
      ) {
        setShowFullView(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // handle tab changes to show collapsed notification
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1025px)");
    function handleTabletChange(e) {
      // Check if the media query is true
      if (e.matches) {
        setShowFullView(true);
        isOpenedAlways.current = true;
      } else {
        isOpenedAlways.current = false;
        setShowFullView(false);
      }
    }
    // Register event listener
    mediaQuery.addEventListener("change", handleTabletChange);

    // Initial check
    handleTabletChange(mediaQuery);

    return () => {
      mediaQuery.removeEventListener("change", handleTabletChange);
    };
  }, []);

  function handleScroll(event) {
    event.stopPropagation();
    const bottom =
      parseInt(event.target.scrollHeight - event.target.scrollTop) ===
      event.target.clientHeight;
    if (bottom) {
      if (!Object.values(props.notification.pagination).includes("end")) {
        updateNotifications();
      }
    }
  }

  const handlePermission = async () => {
    await getFCMToken();
    setPermissionStatus(window.Notification.permission);
  };

  return (
    <>
      <div
        ref={hideFullViewRef}
        className={`notification-panel-size ${
          showFullView ? "grow" : "shrink"
        } right-0 width-100-percent background-white box-shadow-default inherit-parent-height`}
      >
        {props.notification.loading === true && <NotificationSuspense />}
        {props.notification.loading === false && (
          <>
            {showFullView === true && (
              <>
                <div
                  data-cy="notification-full"
                  className=" padding-large display-flex-direction-row border-bottom-2px"
                >
                  <NotificationLogoCard
                    notificationCount={
                      unreadNotificationsCount > 0 && unreadNotificationsCount
                    }
                    src={
                      props.profile.profilePicture &&
                      props.profile.profilePicture.data
                        ? props.profile.profilePicture.data
                        : null
                    }
                    data={
                      props.connection.currentConnection &&
                      props.connection.data[props.connection.currentConnection]
                    }
                    connection={props.connection}
                    dropDownOnClick={() => {
                      setShowClinics(!showClinics);
                    }}
                    showClinics={showClinics}
                  />
                </div>

                {!showClinics && (
                  <div
                    className={`padding-left-larger padding-right-larger invisible-scrollbar ${
                      permissionStatus === "default"
                        ? "height-86-percent"
                        : "height-92-percent"
                    }  overflow-y-scroll`}
                    onScroll={(event) => {
                      handleScroll(event);
                    }}
                    data-cy="notificationResults"
                  >
                    {props.notification.data &&
                    Object.keys(props.notification.data).length > 0 ? (
                      <>
                        {Object.values(props.notification.data).map(
                          (profile, index) => (
                            <NotificationCard
                              key={index}
                              onClick={() => {
                                handleNotification(profile);
                              }}
                              data-cy={`notification-${profile.trigger?.profileId}`}
                              aria-label={`${selectedNotification}`}
                              title={profile.title}
                              description={profile.description}
                              timestamp={dateAndTimeConverter(
                                profile.timestamp
                              )}
                              profile={profile && profile.read}
                            />
                          )
                        )}
                        {props.notification.paginationLoading && (
                          <div
                            data-cy="pagination-loading"
                            className="display-flex flex-justify-content-center flex-center-children-vertically padding-top-large padding-bottom-large height-fit-content"
                          >
                            <div className="dot-pulse-primary margin-right-larger inherit-parent-height " />
                            <div className="text-align-center font-size-smaller">
                              LOADING MORE...
                            </div>
                          </div>
                        )}
                        {props.notification.pagination &&
                          Object.values(props.notification.pagination).includes(
                            "end"
                          ) && (
                            <div className="text-align-center padding-top-large padding-bottom-large">
                              No further notification available
                            </div>
                          )}
                      </>
                    ) : (
                      <NotificationUnavailableCard
                        onClick={() => {
                          setShowFullView(true);
                        }}
                      />
                    )}
                  </div>
                )}
                {showClinics && (
                  <div className=" padding-top-default padding-left-large padding-right-large padding-bottom-large">
                    {props.connection.currentConnection &&
                      isValidObject(props.connection.data) &&
                      Object.values(props.connection.data).map(
                        (data, index) => (
                          <ProfileCards
                            connection={props.connection}
                            selected={
                              props.connection.currentConnection ===
                              data.connectionId
                            }
                            key={index}
                            data={data}
                            onCardClick={() => {
                              if (
                                props.connection.currentConnection
                                  .connectionId !== data.connectionId
                              ) {
                                setShowClinics(!showClinics);
                                props.setCurrentConnection(data.connectionId);
                              }
                            }}
                          />
                        )
                      )}
                    <div
                      className={`${
                        isValidObject(props.connection.data) &&
                        Object.keys(props.connection.data).length > 3
                          ? "padding-top-default position-sticky bottom-0 background-color-white"
                          : ""
                      }`}
                    >
                      <ProfileCards
                        type="add"
                        text="Add Clinic"
                        addOnClick={() => {
                          props.navigate("/create");
                        }}
                      />
                    </div>
                  </div>
                )}
                {permissionStatus === "default" && (
                  <EnablePushNotification
                    onClick={() => {
                      handlePermission();
                    }}
                  />
                )}
              </>
            )}
            {showFullView === false && (
              <div>
                <div
                  data-cy="notification-shrink"
                  className=" width-100-percent padding-top-larger view-profile-picture"
                >
                  <div className="inherit-parent-height view-profile-picture flex-center-children-horizontally flex-center-children-vertically">
                    <div className=" remove-menu flex-center-children-vertically">
                      <div className="padding-large">
                        <RoundedProfilePicture
                          size="small"
                          userType="corporate"
                          notificationCount={
                            unreadNotificationsCount > 0 &&
                            unreadNotificationsCount
                          }
                          src={
                            props.connection.currentConnection &&
                            props.connection.brands &&
                            props.connection.brands[
                              props.connection.data[
                                props.connection.currentConnection
                              ].brandId
                            ].downloadURL
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inherit-parent-height padding-top-larger view-profile-picture flex-center-children-horizontally">
                  <div className="padding-left-larger remove-menu padding-default">
                    <div
                      className="padding-right-medium flex-center-children-horizontally"
                      onClick={() => {
                        setShowFullView(true);
                      }}
                    >
                      <img
                        className="turn-left height-width-16px"
                        draggable="false"
                        src={rightIcon}
                        alt="history icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    profile: state.profile,
    connection: state.connection,
    notification: state.notification,
    history: state.history
  };
};

const mapDispatchToProps = function () {
  return {
    setSelectedNotification: (profile) => setSelectedNotification(profile),
    updateNotifications: () => updateNotifications(),
    setNotificationRead: (data) => setNotificationRead(data),
    setNotifications: (data, type) => setNotifications(data, type),
    setCurrentConnection: (data) => setCurrentConnection(data)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);

function NotificationLogoCard(props) {
  return (
    <>
      <div className="inherit-parent-width flex-justify-content-space-between flex-center-children-vertically text-overflow-ellipsis">
        <div
          data-cy="drop-down-button"
          className="display-flex cursor-pointer inherit-parent-width"
          onClick={props.dropDownOnClick}
        >
          <div className=" padding-right-default">
            <RoundedProfilePicture
              size="small"
              userType="corporate"
              notificationCount={props.notificationCount}
              src={
                props.connection?.brands &&
                props.connection?.brands[props.data?.brandId]?.downloadURL
              }
            />
          </div>
          <div className="padding-left-default inherit-parent-width flex-direction-column flex-center-children-horizontally">
            <div className="display-flex flex-center-children-vertically inherit-parent-width flex-justify-content-space-between">
              <div className=" font-size-large padding-right-default">
                {props.data?.companyName
                  ? props.data?.companyName
                  : "All clinics"}
              </div>
              <div className={props.showClinics ? "rotate-180" : ""}>
                <img src={arrow} alt="arrow" />
              </div>
            </div>
            <ConditionalRender
              condition={typeof props.data?.locality === "string"}
            >
              <div className=" font-size-small padding-top-small">
                {props.data?.locality ? props.data?.locality : ""}
              </div>
            </ConditionalRender>
          </div>
        </div>
      </div>
    </>
  );
}

function NotificationCard(props) {
  const [show, setShow] = useState(false);
  return (
    <div>
      <div
        className={`cursor-pointer ${
          show ? "notification-hover" : ""
        } border-bottom-1px flex-center-children-vertically user-select-none`}
      >
        <div
          className="margin-top-large border-radius-default display-flex width-85-percent"
          onClick={() => {
            props.onClick();
          }}
          onMouseOverCapture={() => {
            setShow(true);
          }}
          onMouseLeave={() => {
            setShow(false);
          }}
          data-cy={props["data-cy"]}
          aria-label={props["aria-label"]}
        >
          <div className="inherit-parent-width">
            <div
              className={`${
                props.profile === true
                  ? "font-color-tertiary"
                  : "font-color-secondary"
              } font-size-medium onHover-remove-ellipse text-overflow-ellipsis white-space-nowrap padding-bottom-small`}
            >
              {props.title}
            </div>
            {show && (
              <div
                className={`${
                  props.profile === true
                    ? "font-color-tertiary"
                    : "font-color-secondary"
                } font-size-medium onHover-remove-ellipse text-overflow-ellipsis white-space-nowrap padding-bottom-small`}
              >
                {props.description}
              </div>
            )}
            <div className="font-color-tertiary padding-top-small padding-bottom-small font-size-small">
              {props.timestamp}
            </div>
          </div>
        </div>
        {props.profile === false && (
          <div
            className="padding-left-default"
            data-cy={`notification-${props.title}-read`}
          >
            <img draggable="false" src={greenIcon} alt="green-dot" />
          </div>
        )}
      </div>
    </div>
  );
}

function NotificationUnavailableCard(props) {
  return (
    <div
      className="flex-center-children-horizontally notification-image-position"
      onClick={props.onClick}
    >
      <div className="padding-default-larger user-select-none">
        <div className=" padding-bottom-larger">
          <img
            className="notification-image"
            draggable="false"
            src={noNotification}
            alt="Notification Empty"
          />
        </div>
        <div className="inherit-parent-width">
          <div className="white-space-nowrap font-color-secondary font-family-gilroy-bold font-size-medium text-align-center">
            No new notification
          </div>
        </div>
      </div>
    </div>
  );
}

function NotificationSuspense() {
  return (
    <article>
      <div className=" display-flex padding-horizontal-large border-bottom-2px flex-center-children-vertically ">
        <div className="padding-top-larger padding-bottom-larger cursor-pointer display-flex-direction-row  padding-top-larger">
          <div className="shimmer padding-smaller border-radius-100-percentage">
            <div className="padding-large margin-small border-radius-100-percentage" />
          </div>
        </div>
        <div className="padding-left-large width-225px">
          <div className="shimmer inherit-parent-width padding-small margin-vertical-large border-radius-default">
            <div className="padding-small margin-default border-radius-default" />
          </div>
        </div>
      </div>
      {[...Array(20).keys()].map((index) => (
        <section
          className="padding-top-small padding-horizontal-large padding-bottom-small border-bottom-1px"
          key={index}
        >
          <div className="shimmer inherit-parent-width padding-small margin-vertical-large border-radius-default">
            <div className="padding-small margin-default border-radius-default" />
          </div>
        </section>
      ))}
    </article>
  );
}

const EnablePushNotification = (props) => {
  return (
    <div className="inherit-parent-width display-flex position-absolute bottom-0 flex-justify-content-space-between padding-large text-overflow-ellipsis">
      <div className="display-flex flex-direction-column text-overflow-ellipsis">
        <div className="font-family-gilroy-medium font-size-medium">
          Enable Push Notification
        </div>
        <div className="padding-top-default font-size-small ">
          Get notified for any updates
        </div>
      </div>
      <div className="display-flex flex-center-children-vertically padding-left-default">
        <div
          onClick={props.onClick}
          className=" background-primary padding-default height-fit-content width-fit-content cursor-pointer font-color-white font-size-small border-radius-default"
        >
          Accept
        </div>
      </div>
    </div>
  );
};
