import { all, put, takeEvery } from "@redux-saga/core/effects";
import { isValidArray } from "../../front-end-global-components/services/validators";
import {
  getDoctorsPinnedPatients,
  pinPatient,
  searchConnectedDoctors,
  searchDoctors,
  unPinPinnedPatient,
  updateSortedPatients
} from "../../services/database";
import { throwError } from "../../services/error";
import { isValidObject } from "../../utils/validators";
import { setErrorStatus } from "../status/actions";
import store from "../store/store";
import { getSearchDoctorData } from "./actions";

export const doctorSearchActionTypes = {
  GET_SEARCH_DOCTOR_DATA: "GET_SEARCH_DOCTOR_DATA",
  ADD_SELECTED_DOCTOR: "ADD_SELECTED_DOCTOR",
  CLEAR_SELECTED_DOCTOR: "CLEAR_SELECTED_DOCTOR",
  REMOVE_DOCTOR_SEARCH_DATA: "REMOVE_DOCTOR_SEARCH_DATA",
  SET_SORTED_PATIENTS: "SET_SORTED_PATIENTS",
  UN_PIN_PATIENT: "UN_PIN_PATIENT",
  PIN_PATIENT: "PIN_PATIENT"
};

function* clearSelectedDoctorWorker() {
  try {
    yield put({
      type: "REMOVE_SELECTED_DOCTOR"
    });
  } catch (error) {
    setErrorStatus(error);
  }
}

function* getSearchDoctorDataWorker(action) {
  try {
    if (
      !(
        typeof action.payload.phoneNumber === "string" &&
        action.payload.phoneNumber.trim().length === 13
      )
    ) {
      throw throwError("custom", "Please enter a valid phone number");
    }

    yield doctorSearchLoading(true);

    yield put({
      type: "CLEAR_DOCTOR_SEARCH_DATA"
    });

    yield put({
      type: "SET_SEARCHED_DOCTOR_PHONE_NUMBER",
      payload: {
        phoneNumber: action.payload.phoneNumber
      }
    });

    let searchResults;
    const clinicId =
      store.getState().connection.data[
        store.getState().connection.currentConnection
      ].connectionId;
    if (
      typeof action.payload.searchType === "string" &&
      action.payload.searchType === "CONNECTED"
    ) {
      searchResults = yield searchConnectedDoctors(
        action.payload.phoneNumber,
        clinicId
      );
    } else {
      searchResults = yield searchDoctors(action.payload.phoneNumber);
    }

    if (isValidObject(searchResults)) {
      const doctorId = searchResults[Object.keys(searchResults)[0]].profileId;
      const doctorsPinnedPatient = yield getDoctorsPinnedPatients(
        doctorId,
        clinicId
      );

      let modifiedPinnedPatients = [];
      if (isValidObject(doctorsPinnedPatient)) {
        let pinnedPatientListForSorting = [];
        let allHasSuccessivePinnedPatient = true;
        // successivePatient : PID
        let successivePatientAsKeyPidAsValue = {};

        for (const key in doctorsPinnedPatient) {
          pinnedPatientListForSorting.push(doctorsPinnedPatient[key]);
          if (doctorsPinnedPatient[key].successivePatient === undefined) {
            successivePatientAsKeyPidAsValue[key] = null;
            allHasSuccessivePinnedPatient = false;
          } else if (doctorsPinnedPatient[key].successivePatient === null) {
            successivePatientAsKeyPidAsValue["lastPinnedPatient"] = key;
          } else if (
            typeof doctorsPinnedPatient[key].successivePatient === "string"
          ) {
            successivePatientAsKeyPidAsValue[
              doctorsPinnedPatient[key].successivePatient
            ] = key;
          }
        }

        if (allHasSuccessivePinnedPatient === false) {
          // First sort based on timestamp
          const sortedArray = pinnedPatientListForSorting.sort(
            (firstObject, secondObject) =>
              firstObject.timestamp - secondObject.timestamp
          );

          for (let index = 0; index < sortedArray.length; index++) {
            //last patient
            if (sortedArray[index + 1] === undefined) {
              modifiedPinnedPatients.push({
                ...sortedArray[index],
                successivePatient: null
              });
            } else {
              modifiedPinnedPatients.push({
                ...sortedArray[index],
                successivePatient:
                  sortedArray[index + 1]["patient"]["patientId"]
              });
            }
          }

          yield updateSortedPatients(doctorId, modifiedPinnedPatients);
        } else {
          modifiedPinnedPatients = [];
          let _index = 0;
          // eslint-disable-next-line no-unused-vars
          for (const key in successivePatientAsKeyPidAsValue) {
            if (!modifiedPinnedPatients[0]) {
              modifiedPinnedPatients.push(
                doctorsPinnedPatient[
                  successivePatientAsKeyPidAsValue["lastPinnedPatient"]
                ]
              );
            } else {
              const nextPatientPid =
                successivePatientAsKeyPidAsValue[
                  modifiedPinnedPatients[_index - 1]["patient"]["patientId"]
                ];
              modifiedPinnedPatients.push(doctorsPinnedPatient[nextPatientPid]);
            }
            _index = _index + 1;
          }
          modifiedPinnedPatients.reverse();
        }
      }

      yield put({
        type: "SET_DOCTOR_SEARCH_DATA",
        payload: {
          data: {
            [Object.keys(searchResults)[0]]: {
              ...searchResults[Object.keys(searchResults)[0]],
              pinnedPatients: [...modifiedPinnedPatients]
            }
          }
        }
      });
    }
    // else {
    //   throw throwError("custom", "No search results found");
    // }
    yield doctorSearchLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield doctorSearchLoading(false);
  }
}

function* addSelectedDoctorWorker(action) {
  try {
    if (typeof action.payload.doctorId !== "string") {
      throw throwError(
        "custom",
        "Something went wrong, Please try again later"
      );
    }

    const searchedDoctorsDataFromStore = store.getState().doctorSearch.data;

    if (
      !(
        isValidObject(searchedDoctorsDataFromStore) &&
        isValidObject(searchedDoctorsDataFromStore[action.payload.doctorId])
      )
    ) {
      throw throwError("custom", "Please select a profile");
    }

    yield put({
      type: "SET_SELECTED_DOCTOR",
      payload: {
        selectedDoctor: {
          [action.payload.doctorId]: {
            ...searchedDoctorsDataFromStore[action.payload.doctorId]
          }
        }
      }
    });
  } catch (error) {
    yield doctorSearchLoading(false);

    setErrorStatus(error);
  }
}

function* removeDoctorSearchDataWorker() {
  yield put({
    type: "CLEAR_DOCTOR_SEARCH_DATA"
  });
}

function* setSortedPatientsWorker(action) {
  try {
    yield doctorSearchLoading(true);

    const patientDemographicId =
      store.getState().abdmPatients.searchResults[
        store.getState().abdmPatients.selectedPatient
      ].patientId;

    const doctorId = Object.values(
      store.getState().doctorSearch.selectedDoctor
    )[0].profileId;
    const clinicId = store.getState().auth.data.uid;
    const selectedDoctorFromStore = Object.values(
      store.getState().doctorSearch.selectedDoctor
    )[0];
    const doctorsPinnedPatients = selectedDoctorFromStore["pinnedPatients"];
    const selectedPatientFromStore =
      store.getState().abdmPatients.searchResults[
        store.getState().abdmPatients.selectedPatient
      ];

    if (
      typeof patientDemographicId !== "string" ||
      typeof doctorId !== "string" ||
      typeof clinicId !== "string" ||
      doctorsPinnedPatients === null ||
      !isValidObject(selectedPatientFromStore) ||
      !isValidObject(selectedDoctorFromStore)
    ) {
      throw throwError("custom", "Unable to update sort patient");
    }

    const modifiedPinnedPatients = [];
    const changedItems = [];
    action.payload.sortedOrder.forEach((elementIndex, index) => {
      const successivePatient =
        doctorsPinnedPatients[action.payload.sortedOrder[index + 1]];
      const patient = {
        ...doctorsPinnedPatients[elementIndex],
        successivePatient: successivePatient
          ? successivePatient.patient.patientId
          : null
      };
      modifiedPinnedPatients.push(patient);
      if (
        patient.successivePatient !==
        doctorsPinnedPatients[elementIndex].successivePatient
      ) {
        changedItems.push(patient);
      }
    });

    yield updateSortedPatients(doctorId, changedItems);

    yield put({
      type: "SET_SELECTED_DOCTOR",
      payload: {
        selectedDoctor: {
          [doctorId]: {
            ...selectedDoctorFromStore,
            pinnedPatients: [...modifiedPinnedPatients]
          }
        }
      }
    });
    yield doctorSearchLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield doctorSearchLoading(false);
  }
}

function* unPinPatientWorker(action) {
  try {
    yield doctorSearchLoading(true);
    const doctorId = Object.values(
      store.getState().doctorSearch.selectedDoctor
    )[0].profileId;
    const selectedDoctorFromStore = Object.values(
      store.getState().doctorSearch.selectedDoctor
    )[0];

    const doctorsPinnedPatients = selectedDoctorFromStore["pinnedPatients"];
    if (
      typeof doctorId !== "string" ||
      doctorsPinnedPatients === null ||
      typeof action.payload.pid !== "string" ||
      !isValidObject(selectedDoctorFromStore)
    ) {
      throw throwError("custom", "Unable to update sort patient");
    }

    const filteredPinnedPatients = [];
    let toBeModifiedInDB = {};
    let docIdOfPatientToBeDeletedInDB = null;
    for (let index = 0; index < doctorsPinnedPatients.length; index++) {
      const currentPatient = doctorsPinnedPatients[index];
      const successivePatient = doctorsPinnedPatients[index + 1];

      if (currentPatient.patient.patientId === action.payload.pid) {
        docIdOfPatientToBeDeletedInDB = currentPatient.docId;
        continue;
      }

      if (
        successivePatient !== undefined &&
        successivePatient.patient.patientId === action.payload.pid
      ) {
        if (successivePatient.successivePatient === null) {
          currentPatient.successivePatient = null;
        } else {
          const successivePatientsSuccessivePatient =
            doctorsPinnedPatients[index + 2];
          if (successivePatientsSuccessivePatient !== undefined) {
            currentPatient.successivePatient =
              successivePatientsSuccessivePatient.patient.patientId;
          } else {
            currentPatient.successivePatient = null;
          }
        }
        toBeModifiedInDB = currentPatient;
      }

      filteredPinnedPatients.push(currentPatient);
    }

    yield unPinPinnedPatient(
      doctorId,
      toBeModifiedInDB,
      docIdOfPatientToBeDeletedInDB
    );

    yield getSearchDoctorData(
      store.getState().doctorSearch.searchedDoctorPhoneNumber,
      "CONNECTED"
    );
    yield doctorSearchLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield doctorSearchLoading(false);
  }
}

function* pinPatientWorker() {
  try {
    yield doctorSearchLoading(true);

    const doctorId = Object.values(
      store.getState().doctorSearch.selectedDoctor
    )[0].profileId;

    const clinicId = Object.values(
      store.getState().doctorSearch.selectedDoctor
    )[0].connectionId;

    const staffData =
      store.getState().connection.data[
        store.getState().connection.currentConnection
      ];

    const selectedDoctorFromStore = Object.values(
      store.getState().doctorSearch.selectedDoctor
    )[0];

    const doctorsPinnedPatients = selectedDoctorFromStore["pinnedPatients"];

    const selectedPatientFromStore =
      store.getState().abdmPatients.searchResults[
        store.getState().abdmPatients.selectedPatient
      ];

    if (
      typeof selectedPatientFromStore.patientId !== "string" ||
      typeof doctorId !== "string" ||
      typeof clinicId !== "string" ||
      !isValidObject(selectedDoctorFromStore) ||
      !isValidObject(selectedPatientFromStore)
    ) {
      throw throwError("custom", "Unable to update pinned patients");
    }
    const _docId = yield pinPatient(
      doctorId,
      staffData.profileId,
      {
        ...(doctorsPinnedPatients[doctorsPinnedPatients.length - 1]
          ? {
              ...doctorsPinnedPatients[doctorsPinnedPatients.length - 1],
              successivePatient: selectedPatientFromStore.patientId
            }
          : {})
      },
      {
        patientDemographicId: selectedPatientFromStore.patientId,
        doctorId: doctorId,
        clinicId: clinicId,
        fullName: selectedPatientFromStore.name,
        ...(selectedPatientFromStore.phoneNumber
          ? {
              phoneNumber: selectedPatientFromStore.phoneNumber
            }
          : {}),
        ...(selectedPatientFromStore.healthId
          ? {
              healthId: selectedPatientFromStore.healthId
            }
          : {})
      }
    );

    let modifiedPinnedPatients = doctorsPinnedPatients
      ? [...doctorsPinnedPatients]
      : [];
    const pinnedPatient = {
      patient: {
        fullName: selectedPatientFromStore.name,
        patientId: selectedPatientFromStore.patientId,
        ...(selectedPatientFromStore.phoneNumber
          ? {
              phoneNumber: selectedPatientFromStore.phoneNumber
            }
          : {}),
        ...(selectedPatientFromStore.healthId
          ? {
              healthId: selectedPatientFromStore.healthId
            }
          : {})
      },
      pinnedBy: {
        clinicId: clinicId,
        staffId: staffData.profileId
      },
      permanent: false,
      doctorId: doctorId,
      pinnedAt: +new Date(),
      successivePatient: null,
      docId: _docId
    };
    if (isValidArray(modifiedPinnedPatients)) {
      const precedingPatient =
        doctorsPinnedPatients[doctorsPinnedPatients.length - 1];
      precedingPatient.successivePatient = selectedPatientFromStore.patientId;
      modifiedPinnedPatients.splice(
        doctorsPinnedPatients.length - 1,
        1,
        precedingPatient
      );
      modifiedPinnedPatients.push(pinnedPatient);
    } else {
      modifiedPinnedPatients.push(pinnedPatient);
    }

    yield getSearchDoctorData(
      store.getState().doctorSearch.searchedDoctorPhoneNumber,
      "CONNECTED"
    );
    yield doctorSearchLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield doctorSearchLoading(false);
  }
}

export function* doctorSearchWatcher() {
  yield all([
    takeEvery("GET_SEARCH_DOCTOR_DATA", getSearchDoctorDataWorker),
    takeEvery("ADD_SELECTED_DOCTOR", addSelectedDoctorWorker),
    takeEvery("REMOVE_DOCTOR_SEARCH_DATA", removeDoctorSearchDataWorker),
    takeEvery("CLEAR_SELECTED_DOCTOR", clearSelectedDoctorWorker),
    takeEvery("SET_SORTED_PATIENTS", setSortedPatientsWorker),
    takeEvery("UN_PIN_PATIENT", unPinPatientWorker),
    takeEvery("PIN_PATIENT", pinPatientWorker)
  ]);
}
function* doctorSearchLoading(value) {
  yield put({
    type: "SET_DOCTOR_SEARCH_LOADING",
    payload: {
      loading: value
    }
  });
}
