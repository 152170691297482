import React, { useState } from "react";
import { connect } from "react-redux";
import "cropperjs/dist/cropper.css";
import "./Create.css";
import "../Login/Login.css";

import placeholderImage from "../../assets/images/Placeholder_image_clinic.png";
import nintoClinicLogo from "../../assets/images/logo/ninto-clinic-logo.png";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import {
  stateCodeWithDistricts,
  stateOptionsWithCode
} from "../../utils/constants";
import NativeSelect from "../../front-end-global-components/components/NativeSelect/NativeSelect";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import Button from "../../front-end-global-components/components/Button/Button";
import CropperModal from "../../front-end-global-components/components/CropperModal/CropperModal";
import { useEffect } from "react";
import { isValidObject } from "../../utils/validators";
import ProfileCards from "../../components/ProfileCards/ProfileCards";
import { createClinicProfile } from "../../redux/profile/actions";

function Create(props) {
  const [clinicCreateFromData, setClinicCreateFromData] = useState({
    companyName: "",
    website: "",
    companyLogo: "",
    locality: "",
    email: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    phoneNumber: "+91"
  });
  const [selectedBrands, setSelectedBrands] = useState(null);
  const [step, setStep] = useState("brandSelection");
  const [file, setFile] = useState("");

  // set clinic create form data from store
  useEffect(() => {
    if (selectedBrands && isValidObject(selectedBrands)) {
      setClinicCreateFromData({
        ...clinicCreateFromData,
        companyName:
          props.connection?.brands &&
          props.connection?.brands[selectedBrands.documentId]?.name,
        website:
          props.connection?.brands &&
          props.connection?.brands[selectedBrands.documentId]?.website,
        email:
          props.connection?.brands &&
          props.connection?.brands[selectedBrands.documentId]?.email,
        companyLogo: selectedBrands?.documentId,
        brandId: selectedBrands?.documentId
      });
    }
    // eslint-disable-next-line
  }, [selectedBrands]);

  //set initial step
  useEffect(() => {
    if (!isValidObject(props.connection.data)) {
      setStep("brandSubmission");
    }
    // eslint-disable-next-line
  }, []);

  const handleImageError = (data) => {
    try {
      return URL.createObjectURL(data);
    } catch (error) {}
  };

  return (
    <>
      <div
        data-cy="create-screen"
        className="grey-background-image inherit-parent-width inherit-parent-height"
      >
        <ConditionalRender condition={!file}>
          <div data-cy="background-image">
            <img
              draggable="false"
              data-cy="placeholder-image"
              className="icon-img"
              src={placeholderImage}
              alt="img"
            />
          </div>
          <div className="flex-center-children-horizontally flex-center-children-vertically box-shadow-default inherit-parent-height width-30percent position-fixed background-white ">
            <div className="inherit-parent-width max-width-300px flex-place-children-page-center">
              <img
                draggable="false"
                className="width-155px margin-bottom-larger"
                src={nintoClinicLogo}
                alt="login-logo"
              />
              <ConditionalRender condition={!file}>
                <ConditionalRender condition={step === "brandSelection"}>
                  <BrandSelectionForm
                    addOnClick={() => {
                      setStep("brandSubmission");
                    }}
                    onCardClick={(data) => {
                      setSelectedBrands(data);
                      setStep("brandSubmission");
                    }}
                    connection={props.connection}
                  />
                </ConditionalRender>

                <ConditionalRender condition={step === "brandSubmission"}>
                  <BrandSubmissionForm
                    onChange={(event) => {
                      setClinicCreateFromData({
                        ...clinicCreateFromData,
                        [event.target.name]: event.target.value
                      });
                    }}
                    connection={props.connection}
                    selectedBrands={selectedBrands}
                    onSubmit={(event) => {
                      event.preventDefault();
                      setStep("clinicCreation");
                    }}
                    onProfilePictureSave={(file) => {
                      setClinicCreateFromData({
                        ...clinicCreateFromData,
                        companyLogo: file
                      });
                    }}
                    src={
                      clinicCreateFromData?.companyLogo
                        ? handleImageError(clinicCreateFromData?.companyLogo)
                        : ""
                    }
                    setProfileImageSrc={setFile}
                    formData={clinicCreateFromData}
                  />
                </ConditionalRender>

                <ConditionalRender condition={step === "clinicCreation"}>
                  <ClinicCreationForm
                    onChange={(event) => {
                      setClinicCreateFromData({
                        ...clinicCreateFromData,
                        [event.target.name]: event.target.value
                      });
                    }}
                    onSubmit={(event) => {
                      event.preventDefault();
                      props.createClinicProfile({
                        data: clinicCreateFromData,
                        navigate: props.navigate
                      });
                    }}
                    profile={props.profile}
                    formData={clinicCreateFromData}
                  />
                </ConditionalRender>
              </ConditionalRender>
            </div>
          </div>
        </ConditionalRender>

        <ConditionalRender condition={file && file !== null}>
          <CropperModal
            aspectRatio={1}
            OnBlobCreate={(blob) => {
              const imageFile = new File([blob], `${+new Date()}.png`, {
                type: "image/png"
              });
              setClinicCreateFromData({
                ...clinicCreateFromData,
                companyLogo: imageFile
              });
            }}
            file={file}
            setFile={() => {
              setFile("");
            }}
          />
        </ConditionalRender>
      </div>
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    profile: state.profile,
    connection: state.connection
  };
};

const mapDispatchToProps = function () {
  return {
    createClinicProfile: (data) => createClinicProfile(data)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);

const BrandSelectionForm = (props) => {
  return (
    <div className="padding-larger overflow-y-scroll hide-scrollbar">
      <div className="text-align-center padding-bottom-larger">
        Use the existing public details of your company to create another
        branch/locality for Add new company by entering new information
      </div>
      {props?.connection?.brands &&
        props?.connection?.data &&
        props.connection.currentConnection &&
        Object.values(props.connection.brands).map(
          (data, index) =>
            props.connection.data[props.connection.currentConnection]
              ?.userType === "owner" && (
              <ProfileCards
                connection={props.connection}
                selected={false}
                key={index}
                data={data}
                onCardClick={() => props.onCardClick(data)}
              />
            )
        )}
      <ProfileCards
        type="add"
        text="Add Clinic"
        addOnClick={props.addOnClick}
      />
    </div>
  );
};

const BrandSubmissionForm = (props) => {
  const [isValidFormData, setIsValidFormData] = useState({
    email: true
  });

  return (
    <form
      className="remaining-body-height grey-abstract-background-image flex-center-children-vertically flex-direction-column overflow-y-scroll hide-scrollbar"
      onChange={props.onChange}
      onSubmit={props.onSubmit}
    >
      <div className="padding-right-larger padding-left-larger padding-top-larger inherit-parent-width max-width-500px flex-grow">
        <h1 className="font-weight-normal font-family-gilroy-medium font-size-medium font-color-secondary inherit-parent-width text-align-center">
          {props.connection.brands &&
          props.connection.brands[props.selectedBrands?.documentId]
            ? "VERIFY BASIC INFORMATION"
            : "CLINIC BASIC INFORMATION"}
        </h1>
        <div className=" overflow-y-auto hidden-scroll">
          <div className=" flex-center-children-horizontally margin-top-larger">
            <RoundedProfilePicture
              data-cy="brand-logo"
              userType="corporate"
              size="large"
              editMode={
                props.connection.brands &&
                props.connection.brands[props.selectedBrands?.documentId]
                  ? false
                  : true
              }
              src={
                props.connection.brands &&
                props.connection.brands[props.selectedBrands?.documentId]
                  ?.downloadURL
                  ? props.connection?.brands[props.selectedBrands?.documentId]
                      ?.downloadURL
                  : props.src
                  ? props.src
                  : ""
              }
              removeResponsive={true}
              setFile={props.setProfileImageSrc}
              newProfilePictureName={"profilePicture"}
              onProfilePictureSave={props.onProfilePictureSave}
            />
          </div>
          <div className="">
            <InputBox
              className="inherit-parent-width border-radius-default margin-vertical-large"
              name="companyName"
              removeResponsive={true}
              label="Clinic Name"
              value={props.formData.companyName}
              type="text"
              required
              disabled={
                props.connection.brands &&
                props.connection.brands[props.selectedBrands?.documentId]
                  ? true
                  : false
              }
              data-cy="company-name-field"
            />
            <InputBox
              className="inherit-parent-width border-radius-default margin-vertical-large"
              name="email"
              label="Clinic Email"
              removeResponsive={true}
              value={props.formData.email}
              type="email"
              required
              disabled={
                props.connection.brands &&
                props.connection.brands[props.selectedBrands?.documentId]
                  ? true
                  : false
              }
              isValidInput={(data) => {
                setIsValidFormData({ ...isValidFormData, email: data });
              }}
              data-cy="email-field"
            />
            <InputBox
              className="inherit-parent-width border-radius-default margin-vertical-large"
              name="website"
              label="Clinic Website"
              removeResponsive={true}
              value={props.formData.website}
              type="text"
              required
              disabled={
                props.connection.brands &&
                props.connection.brands[props.selectedBrands?.documentId]
                  ? true
                  : false
              }
              data-cy="website-field"
            />
          </div>
        </div>
      </div>
      <footer className="padding-left-larger padding-right-larger padding-bottom-larger padding-top-larger inherit-parent-width max-width-500px">
        <Button
          data-cy="verify-or-continue-button"
          type="submit"
          text="Continue"
          boxShadow={false}
          disabled={
            !(
              props.formData.companyName &&
              props.formData.email &&
              props.formData.website &&
              props.formData.companyLogo &&
              isValidFormData.email
            )
          }
        />
      </footer>
    </form>
  );
};

const ClinicCreationForm = (props) => {
  const [isValidFormData, setIsValidFormData] = useState({
    phoneNumber: true,
    pincode: true
  });
  return (
    <form
      className="remaining-body-height grey-abstract-background-image flex-center-children-vertically flex-direction-column overflow-y-scroll hide-scrollbar"
      onChange={props.onChange}
      onSubmit={props.onSubmit}
    >
      <div className="padding-right-larger padding-left-larger padding-top-larger inherit-parent-width max-width-500px flex-grow">
        <h1 className="font-weight-normal font-family-gilroy-medium font-size-medium font-color-secondary inherit-parent-width text-align-center">
          CLINIC BRANCH INFO
        </h1>
        <div className="">
          <InputBox
            className="inherit-parent-width border-radius-default margin-vertical-large"
            name="phoneNumber"
            removeResponsive={true}
            label="Branch Contact Number"
            value={props.formData.phoneNumber}
            type="text"
            required
            data-cy="branch-contact-number-field"
            isValidInput={(data) => {
              setIsValidFormData({ ...isValidFormData, phoneNumber: data });
            }}
          />
          {/* <InputBox
              className="inherit-parent-width border-radius-default margin-vertical-large"
              name="email"
              removeResponsive={true}
              label="Branch Email"
              value={props.formData.email}
              type="text"
              required
              data-cy="company-name-field"
            /> */}
          <InputBox
            className="inherit-parent-width border-radius-default margin-vertical-large"
            name="address"
            removeResponsive={true}
            label="Branch Address"
            value={props.formData.address}
            type="text"
            required
            data-cy="branch-address-field"
          />
          <div className=" display-flex">
            <InputBox
              className="inherit-parent-width padding-right-default border-radius-default margin-vertical-large"
              name="locality"
              size="half"
              removeResponsive={true}
              label="Locality"
              value={props.formData.locality}
              type="text"
              required
              data-cy="branch-locality-field"
            />
            <InputBox
              className="inherit-parent-width padding-left-default border-radius-default margin-vertical-large"
              name="pincode"
              removeResponsive={true}
              size="half"
              label="Pincode"
              value={props.formData.pincode}
              type="text"
              required
              isValidInput={(data) => {
                setIsValidFormData({ ...isValidFormData, pincode: data });
              }}
              data-cy="branch-pincode-field"
            />
          </div>
          <div className=" display-flex">
            <NativeSelect
              labelClassName="letter-spacing-4-percentage"
              className="padding-right-default"
              selectClassName="text-transform-capitalize padding-top-large"
              optionsClassName="text-transform-capitalize"
              name="state"
              defaultValue={props.formData.state}
              label="State"
              required
              size="half"
              data-cy="branch-state-field"
              options={stateOptionsWithCode}
            />
            <NativeSelect
              labelClassName="letter-spacing-4-percentage"
              className="padding-left-default"
              selectClassName="text-transform-capitalize padding-top-large"
              optionsClassName="text-transform-capitalize"
              name="city"
              label="city"
              required
              size="half"
              data-cy="branch-city-field"
              defaultValue={props.formData.city}
              options={stateCodeWithDistricts[props.formData.state]}
            />
          </div>
        </div>
      </div>
      <footer className="padding-left-larger padding-right-larger padding-bottom-larger padding-top-larger inherit-parent-width max-width-500px">
        <Button
          data-cy="Continue-button-field"
          loading={props.profile.loading}
          type="submit"
          text="Continue"
          boxShadow={false}
          disabled={
            !(
              props.formData.companyName &&
              props.formData.website &&
              props.formData.companyLogo &&
              props.formData.locality &&
              props.formData.email &&
              props.formData.address &&
              props.formData.city &&
              props.formData.state &&
              props.formData.pincode &&
              isValidFormData.pincode &&
              isValidFormData.phoneNumber
            )
          }
        />
      </footer>
    </form>
  );
};
