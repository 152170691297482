import React, { useEffect, useRef, useState } from "react";
import Button from "../Button/Button";
import { isValidArray, isValidObject } from "../../services/validators";
import emptyDocument from "../../assets/images/fallback.svg";
import Modal from "../Modal/Modal";
import AudioPlayer from "../AudioPlayer/AudioPlayer";
import RoundedProfilePicture from "../RoundedProfilePicture/RoundedProfilePicture";
import { rippleEffect } from "../../utils/Functions";
import "./PatientTimeline.css";
import "../../design-system.css";
import PropTypes from "prop-types";

//Icons import
import infoRed from "../../assets/infoDanger.svg";
import add from "../../assets/add.svg";
import close from "../../assets/closeIconGreen.svg";
// import filter from "../../assets/filter.svg";
import filterGrey from "../../assets/filterGrey.svg";
import symptom from "../../assets/symptom.svg";
import report from "../../assets/report.svg";
import prescription from "../../assets/prescription.svg";
import receipt from "../../assets/receipt.svg";
import admission from "../../assets/admission.svg";
import insurance from "../../assets/insurance.svg";
import vital from "../../assets/vital.svg";
import form from "../../assets/form.svg";
import vaccineIcon from "../../assets/vaccineIcon.svg";
import vaccineActiveIcon from "../../assets/vaccineActiveIcon.svg";
import vitalActiveIcon from "../../assets/vital-active-icon.svg";
import prescriptionActive from "../../assets/prescription-active-icon.svg";
import reportActive from "../../assets/report-active-icon.svg";
import symptomActiveIcon from "../../assets/symptom-active-icon.svg";
import receiptActiveIcon from "../../assets/receipt-active-icon.svg";
import formActiveIcon from "../../assets/form-active.svg";
import admissionActiveIcon from "../../assets/admission-active-icon.svg";
import insuranceActiveIcon from "../../assets/insurance-active-icon.svg";
import createPrescription from "../../assets/create_prescription.svg";
import uploadPrescription from "../../assets/upload_prescription.svg";
import heartRate from "../../assets/heart-rate.svg";
import bloodOxygen from "../../assets/blood-oxygen.svg";
import bloodPressure from "../../assets/blood-pressure.svg";
import bloodSugar from "../../assets/blood-sugar.svg";
import temperature from "../../assets/temperature.svg";
import weight from "../../assets/weight.svg";
import height from "../../assets/height.svg";
import shoe from "../../assets/shoe.png";
import calorie from "../../assets/calorie.png";
import heart from "../../assets/heart.png";
import movement from "../../assets/movement.png";
// import googleFit from "../../assets/images/googleFit.png";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import warning_icon from "../../assets/warning_icon.svg";
import selectAllDocumentsIcon from "../../assets/selectAllDocumentsIcon.svg";
import selectAllDocumentsActiveIcon from "../../assets/selectAllDocumentsActiveIcon.svg";
import consultationIcon from "../../assets/consultation.svg";
import { convertAbdmToNintoDocumentTypes } from "../../utils/constants";
import {
  ArrowIcon,
  CircleWithTickIcon,
  DownArrowIcon,
  ExclamatoryIcon,
  RefreshIcon,
  RotateIcon
} from "../../assets/assets";
import FeatureFlagsFallback from "../FeatureFlagsFallback/FeatureFlagsFallback";

const uniqueNamesForFilter = (data, type) => {
  if (
    data !== null &&
    data !== undefined &&
    Array.isArray(data) &&
    Object.keys(data).length > 0
  ) {
    let names = {};
    data.forEach((doc) => {
      if (doc.clinicId) {
        names = {
          ...names,
          [doc.clinicId]: { name: doc.clinicName, docId: doc.clinicId }
        };
      }
    });
    return names;
  } else return {};
};
//Timestamp to date converter
const dateAndTimeConverter = (timestamp) => {
  if (!timestamp) return;
  let dateObject = new Date(timestamp);
  const date =
    dateObject.toLocaleString("en-IN", { day: "2-digit" }) +
    " " +
    dateObject.toLocaleString("en-IN", { month: "short" }) +
    " " +
    dateObject.toLocaleString("en-IN", { year: "numeric" });
  return date;
};
function PatientTimeline(props) {
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showUploadOptionModal, setShowUploadOptionModal] = useState(false);
  const [sort, setSort] = useState("recentFirst");
  const [filterBy, setFilterBy] = useState("");
  const timeline = useRef(null);
  const timelineMenu = useRef(null);
  const [lockerExpiringSoon, setLockerExpiringSoon] = useState(true);

  //for getting profile picture
  // useEffect(() => {
  //   if (isValidArray(props.documents)) {
  //     for (const document of props.documents) {
  //       if (
  //         document &&
  //         document.from &&
  //         document.from.id &&
  //         document.from.type &&
  //         document.from.type !== "patient" &&
  //         typeof props.getDocumentsCardProfilePicture === "function"
  //       )
  //         props.getDocumentsCardProfilePicture(
  //           document.from.type,
  //           document.from.id
  //         );
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.documents]);

  const onScroll = () => {
    if (timeline.current !== null) {
      const menuElement = timeline.current.getElementsByTagName("section")?.[0];
      const viewportOffset = menuElement.getBoundingClientRect();
      if (viewportOffset.top <= 120) {
        menuElement.style.position = "sticky";
        menuElement.style.top = "0px";
        menuElement.style.zIndex = "1";
        menuElement.style.backgroundColor = "white";
      }
    }
  };

  const convertMouseScrollToHorizontalScroll = (event) => {
    if (timelineMenu.current !== null) {
      const scrollValue = 20; // How many pixels to scroll

      if (event.deltaY > 0)
        // if deltaY greater than 0 means user scroll verticalScroll right
        timelineMenu.current.scrollLeft += scrollValue;
      // Scroll left
      else timelineMenu.current.scrollLeft -= scrollValue;
      event.preventDefault();
    }
  };

  // for converting Mouse Scroll To HorizontalScroll
  useEffect(() => {
    const parentElement = timeline.current.parentElement;
    const timelineMenus = timelineMenu.current;
    parentElement.addEventListener("scroll", onScroll);
    timelineMenu.current.addEventListener(
      "wheel",
      convertMouseScrollToHorizontalScroll,
      false
    );
    return () => {
      parentElement.removeEventListener("scroll", onScroll);
      timelineMenus.removeEventListener(
        "wheel",
        convertMouseScrollToHorizontalScroll
      );
    };
  }, []);

  const addDocumentMenu = [
    {
      name: "Link my health records",
      description:
        "Link health record uploaded for your demographic information",
      onClick: props.linkHealthRecords,
      "data-cy": "share-health-id"
    },
    {
      name: "Upload health records",
      description: "Upload your health records to your health locker",
      onClick: () => {
        setShowUploadOptionModal(false);
        setShowUploadModal(true);
      },
      "data-cy": "change-consent-pin"
    }
  ];

  //Filter menu data array
  const menu = [
    {
      defaultIcon: symptom,
      icon: props.filterState.documentType.includes("symptom")
        ? symptomActiveIcon
        : symptom,
      alt: `${
        props.filterState.documentType.includes("symptom-") ? "active" : ""
      }symptom-icon`,
      type: "symptom",
      text: "Symptom",
      filterMenuText: "Symptoms",
      widthHeight: "filter-icon-height-and-width",
      dataCy: "upload-symptom-button"
    },
    {
      defaultIcon: prescription,
      icon: props.filterState.documentType.includes("prescription")
        ? prescriptionActive
        : prescription,
      alt: `${
        props.filterState.documentType.includes("prescription") ? "active-" : ""
      }prescription-icon`,
      type: "prescription",
      text: "Prescription",
      filterMenuText: "Prescriptions",
      widthHeight: "filter-icon-height-and-width",
      dataCy: "upload-prescription-button"
    },
    {
      defaultIcon: report,
      icon: props.filterState.documentType.includes("report")
        ? reportActive
        : report,
      alt: `${
        props.filterState.documentType.includes("report") ? "active-" : ""
      }report-icon`,
      type: "report",
      text: "Report",
      filterMenuText: "Reports",
      widthHeight: "filter-icon-height-and-width",
      dataCy: "upload-report-button"
    },
    {
      defaultIcon: receipt,
      icon: props.filterState.documentType.includes("receipt")
        ? receiptActiveIcon
        : receipt,
      alt: `${
        props.filterState.documentType.includes("receipt") ? "active-" : ""
      }receipt-icon`,
      type: "receipt",
      text: "Receipt",
      filterMenuText: "Receipts",
      widthHeight: "filter-icon-height-and-width",
      dataCy: "upload-receipt-button"
    },
    {
      defaultIcon: admission,
      icon: props.filterState.documentType.includes("admission")
        ? admissionActiveIcon
        : admission,
      alt: `${
        props.filterState.documentType.includes("admission") ? "active-" : ""
      }admission-icon`,
      type: "admission",
      text: "Admission",
      filterMenuText: "Admissions",
      widthHeight: "filter-icon-height-and-width",
      dataCy: "upload-admission-button"
    },
    {
      defaultIcon: insurance,
      icon: props.filterState.documentType.includes("insurance")
        ? insuranceActiveIcon
        : insurance,
      alt: `${
        props.filterState.documentType.includes("insurance") ? "active-" : ""
      }insurance-icon`,
      type: "insurance",
      text: "Insurance",
      filterMenuText: "Insurances",
      widthHeight: "filter-icon-height-and-width",
      dataCy: "upload-insurance-button"
    },
    {
      defaultIcon: uploadPrescription,
      icon: props.filterState.documentType.includes("uploadPrescription")
        ? uploadPrescription
        : uploadPrescription,
      alt: `${
        props.filterState.documentType.includes("uploadPrescription")
          ? "active-"
          : ""
      }upload-prescription-icon`,
      type: "uploadPrescription",
      text: "Upload Prescription",
      filterMenuText: "",
      widthHeight: "home-add-documents-icon-size",
      dataCy: "prescription-upload-button"
    },
    {
      defaultIcon: createPrescription,
      icon: props.filterState.documentType.includes("createPrescription")
        ? createPrescription
        : createPrescription,
      alt: `${
        props.filterState.documentType.includes("createPrescription")
          ? "active-"
          : ""
      }create-prescription-icon`,
      type: "createPrescription",
      text: "Create Prescription",
      filterMenuText: "",
      widthHeight: "home-add-documents-icon-size",
      dataCy: "create-prescription-button"
    },
    {
      defaultIcon: vital,
      icon: props.filterState.documentType.includes("vital")
        ? vitalActiveIcon
        : vital,
      alt: `${
        props.filterState.documentType.includes("vital") ? "active-" : ""
      }vital-icon`,
      type: "vital",
      text: "Vital",
      filterMenuText: "Vitals",
      widthHeight: "filter-icon-height-and-width",
      dataCy: "upload-vital-button"
    },
    {
      defaultIcon: form,
      icon: props.filterState.documentType.includes("form")
        ? formActiveIcon
        : form,
      alt: `${
        props.filterState.documentType.includes("form") ? "active-" : ""
      }form-icon`,
      type: "form",
      text: "Forms",
      filterMenuText: "Forms",
      widthHeight: "filter-icon-height-and-width",
      dataCy: "upload-form-button"
    },
    {
      defaultIcon: vaccineIcon,
      icon: props.filterState.documentType.includes("vaccine")
        ? vaccineActiveIcon
        : vaccineIcon,
      alt: `${
        props.filterState.documentType.includes("vaccine") ? "active-" : ""
      }vaccine-icon`,
      type: "vaccine",
      text: "Vaccine",
      filterMenuText: "Vaccines",
      widthHeight: "filter-icon-height-and-width",
      dataCy: "upload-vaccine-button"
    },
    {
      defaultIcon: consultationIcon,
      icon: props.filterState.documentType.includes("consultation")
        ? consultationIcon
        : consultationIcon,
      alt: `${
        props.filterState.documentType.includes("vaccine") ? "active-" : ""
      }vaccine-icon`,
      type: "consultation",
      text: "Consultation",
      filterMenuText: "Consultations",
      widthHeight: "filter-icon-height-and-width",
      dataCy: "upload-consultation-button"
    },
    {
      defaultIcon: receipt,
      icon: props.filterState.documentType.includes("others")
        ? receiptActiveIcon
        : receipt,
      alt: `${
        props.filterState.documentType.includes("vaccine") ? "active-" : ""
      }vaccine-icon`,
      type: "others",
      text: "Other",
      filterMenuText: "Others",
      widthHeight: "filter-icon-height-and-width",
      dataCy: "upload-others-button"
    }
  ];

  // set filter modal state
  useEffect(() => {
    if (typeof props.filterModalState === "function") {
      props.filterModalState(showFilterModal);
    }
    // eslint-disable-next-line
  }, [showFilterModal]);

  const showShowUploadButton = () => {
    if (
      props.hideUploadButton !== true &&
      Array.isArray(props.allowedUploadDocuments) &&
      props.allowedUploadDocuments.length > 0 &&
      !props.loading
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className=" inherit-parent-height inherit-parent-width position-relative ">
      {/* Document upload modal button */}

      <div
        ref={timeline}
        className={`${
          isValidArray(props.documents)
            ? "inherit-parent-height"
            : "inherit-parent-height"
        } display-flex flex-direction-column`}
      >
        {showShowUploadButton() === true && (
          <Button
            name="filter-menu"
            data-cy="home-upload-button"
            buttonCategory="floating"
            position="bottom-right"
            src={showUploadModal ? close : add}
            iconClassName="home-upload-icon-height"
            onClick={() => {
              setShowUploadOptionModal(true);
            }}
          />
        )}
        {props.showRetryButton && !props.loading && (
          <div
            onClick={
              props.retryButtonLoading ? () => {} : props.retryButtonOnClick
            }
            className={` ${
              showShowUploadButton() === true ? "margin-bottom-60px" : ""
            } height-width-48px background-color-white border-radius-16px position-absolute bottom-right box-shadow-default flex-justify-content-center cursor-pointer`}
            data-cy="retry-button"
          >
            <div
              className={`padding-large ${
                props.retryButtonLoading ? "rotate-animation" : ""
              }`}
            >
              <RefreshIcon />
            </div>
          </div>
        )}
        {/* Documents filter nav menu */}
        <DocumentsFilterMenu
          kycStatus={props.kycStatus}
          kycStatusClick={props.kycStatusClick}
          ref={timelineMenu}
          menuData={menu}
          menuItems={props.documentFilterMenu}
          menuOnClick={(menuItem) => {
            if (typeof props.onFilterClick === "function") {
              props.onFilterClick("document", menuItem);
            }
          }}
          filterType={props.filterState.documentType}
          doctorsName={props.filterState.doctors}
          clinicsName={props.filterState.clinics}
          filterButtonOnClick={() => {
            setShowFilterModal(!showFilterModal);
          }}
          setSort={(sortBy) => {
            setSort(sortBy);
          }}
          sort={sort}
        />

        {props.transferStatus && (
          <div className="inherit-parent-width flex-direction-column flex-justify-content-center">
            <div className="inherit-parent-width padding-top-default padding-bottom-small text-align-center font-size-small font-family-gilroy-regular">
              Transfer in-progress ...
            </div>
            <div className=" height-1px background-color-primary line inherit-parent-width">
              <div className="animated-gradient"></div>
            </div>
          </div>
        )}

        {props.showExpiringSoon === true &&
          props.showExpired === false &&
          lockerExpiringSoon === true && (
            <LockerSubscriptionExpiringSoon
              remindMeLaterOnClick={() => setLockerExpiringSoon(false)}
              resubscribeOnClick={props.resubscribeOnClick}
              loading={props.resubscribeLoading}
            />
          )}

        {props.showExpired && (
          <LockerSubscriptionExpired
            resubscribeOnClick={props.resubscribeOnClick}
            loading={props.resubscribeLoading}
          />
        )}

        <div className="inherit-parent-width inherit-parent-height hide-scroll-bar">
          {props.loading === true ? (
            <TimelineSuspense />
          ) : (
            <div
              className="max-width-550px margin-horizontal-auto flex-align-items-center inherit-parent-width flex-direction-column flex-grow-1 inherit-parent-height"
              data-cy="home-page-content"
            >
              {isValidArray(props.documents) ? (
                <>
                  <div
                    className={`inherit-parent-width ${
                      props.overflowHidden && "overflow-hidden"
                    } ${props.removeScrollBar ? "" : "hide-scroll-bar"}`}
                  >
                    {props.dataTransfer
                      ?.filter((data) => data.status === "FAILED")
                      ?.map((document, index) => (
                        <>
                          <DocumentCard
                            profilePictureSize={props.profilePictureSize}
                            key={`${document.documentId}-parent`}
                            document={document}
                            documents={props.documents}
                            // dataTransfer={}
                            index={index}
                            documentCardOnClick={(document) => {
                              props.documentCardOnClick(document);
                            }}
                            status="failed"
                            dateAndTimeConverter={dateAndTimeConverter}
                            getAudioUrl={props.getAudioUrl}
                            profilePictures={props.profilePictures}
                            downloads={props.downloads}
                            viewerId={props.viewerId}
                            patientName={props.patientName}
                            viewerType={props.viewerType}
                          />
                        </>
                      ))}
                    {props.documents
                      .sort((first, second) => {
                        if (sort === "oldestFirst") {
                          return second.timestamp - first.timestamp;
                        } else {
                          return first.timestamp - second.timestamp;
                        }
                      })
                      .map(
                        (document, index) =>
                          (props.filterState.documentType.length > 0
                            ? props.filterState.documentType.some((type) => {
                                if (
                                  document.documentType ===
                                    "HealthDocumentRecord" &&
                                  type === "others"
                                ) {
                                  return true;
                                } else {
                                  return (
                                    convertAbdmToNintoDocumentTypes(
                                      document.documentType
                                    ) === `${type}s`
                                  );
                                }
                              })
                            : true) &&
                          (props.filterState.doctors.length > 0
                            ? props.filterState.doctors.some((name) => {
                                return document.name === name;
                              })
                            : true) &&
                          (props.filterState.clinics.length > 0
                            ? props.filterState.clinics.some((name) => {
                                return document.clinicId === name;
                              })
                            : true) && (
                            <React.Fragment key={document.documentId}>
                              {document.processing === true ? (
                                <DocumentsCardSuspense
                                  profilePictureSize={props.profilePictureSize}
                                  keyValue={document.healthId}
                                />
                              ) : (
                                <>
                                  <DocumentCard
                                    profilePictureSize={
                                      props.profilePictureSize
                                    }
                                    key={`${document.documentId}-parent`}
                                    document={document}
                                    documents={props.documents}
                                    index={index}
                                    documentCardOnClick={(document) => {
                                      props.documentCardOnClick(document);
                                    }}
                                    // status="failed"
                                    dateAndTimeConverter={dateAndTimeConverter}
                                    getAudioUrl={props.getAudioUrl}
                                    profilePictures={props.profilePictures}
                                    downloads={props.downloads}
                                    viewerId={props.viewerId}
                                    patientName={props.patientName}
                                    viewerType={props.viewerType}
                                  />
                                </>
                              )}
                            </React.Fragment>
                          )
                      )}
                    {props.consentsEnds && (
                      <PatientAccessCard
                        requested={false}
                        patientAccessOnClick={props.patientAccessOnClick}
                      />
                    )}
                    {props.paginationLoading === true && (
                      <div className="flex-justify-content-center padding-vertical-small margin-bottom-larger inherit-parent-width">
                        <div className="display-flex-direction-row flex-justify-content-center flex-align-items-center inherit-parent-width">
                          <p>Loading</p>{" "}
                          <div className="padding-horizontal-medium" />
                          <div className={`dot-flashing`} />
                          <div className="padding-horizontal-medium" />
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <NoDocumentsFound />
                </>
              )}
            </div>
          )}
        </div>
      </div>

      <Modal
        show={showFilterModal}
        onClose={() => {
          setShowFilterModal(!showFilterModal);
        }}
        canIgnore={true}
        position="position-fixed bottom-0 left-0 right-0"
        boxShadow="none"
        borderRadius="none"
        width="inherit-parent-width"
        background="background-transparent"
        height="height-fit-to-content"
      >
        <div className="background-white border-top-radius-default padding-default font-family-gilroy-medium user-select-none">
          <div className="flex-justify-content-space-between flex-align-items-center font-size-medium font-color-secondary padding-default ">
            <div className=" ">
              Filters
              <img
                src={filterGrey}
                className="padding-left-default"
                height="12px"
                // width="auto"
                alt="filter-icon"
              />
            </div>
            <div
              className="cursor-pointer"
              onClick={() => {
                props.onClearFilterClick();
              }}
              data-cy="clearFilter"
            >
              Clear
            </div>
          </div>
          <div className="display-flex font-size-medium font-family-gilroy-medium text-align-left">
            <div className="border-right-1px padding-right-default flex-basis-30-percentage">
              {/* <div
                className={` cursor-pointer margin-bottom-default padding-default border-radius-default ${
                  filterBy.includes("doctors") && "background-color-grey"
                }`}
                onClick={() => {
                  setFilterBy("doctors");
                }}
                data-cy="doctorFilter"
              >
                Doctors
              </div> */}
              <div
                className={` cursor-pointer margin-bottom-default padding-default border-radius-default ${
                  filterBy.includes("clinics") && "background-color-grey"
                }`}
                onClick={() => {
                  setFilterBy("clinics");
                }}
                data-cy="clinicFilter"
              >
                Clinics
              </div>
            </div>
            <div className="display-flex flex-wrap font-color-secondary padding-left-default">
              {/* doctor names filter chips */}
              {filterBy === "doctors" &&
                isValidObject(
                  uniqueNamesForFilter(props.documents, "doctor")
                ) &&
                Object.values(
                  uniqueNamesForFilter(props.documents, "doctor")
                ).map((data, index) => (
                  <span
                    data-cy={data.docId}
                    key={index}
                    className={`padding-default border-radius-default background-white margin-horizontal-default height-fit-to-content ${
                      props.filterState.doctors.includes(data.docId)
                        ? "background-color-grey"
                        : "box-shadow-secondary"
                    }`}
                    onClick={() => {
                      typeof props.onFilterClick === "function" &&
                        props.onFilterClick("doctor", data.docId);
                      // doctorsNameFilterHandler(name);
                    }}
                  >
                    {data.name}
                  </span>
                ))}
              {/* clinic names filter chips */}
              {filterBy === "clinics" &&
                isValidObject(
                  uniqueNamesForFilter(props.documents, "clinic")
                ) &&
                Object.values(
                  uniqueNamesForFilter(props.documents, "clinic")
                ).map((data, index) => (
                  <span
                    data-cy={data.docId}
                    key={index}
                    className={`padding-default border-radius-default background-white margin-horizontal-default height-fit-to-content ${
                      props.filterState.clinics.includes(data.docId)
                        ? "background-color-grey"
                        : "box-shadow-secondary"
                    }`}
                    onClick={() => {
                      typeof props.onFilterClick === "function" &&
                        props.onFilterClick("clinic", data.docId);
                    }}
                  >
                    {data.name}
                  </span>
                ))}
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        canIgnore={true}
        onClose={() => {
          setShowUploadModal(false);
          setShowUploadOptionModal(true);
        }}
        show={showUploadModal}
        position="position-fixed bottom-0"
        boxShadow="none"
        borderRadius="none"
        width="inherit-parent-width"
        background="background-transparent"
        height="height-fit-to-content"
      >
        <div className="background-white padding-default font-family-gilroy-medium font-color-secondary flex-justify-content-space-around flex-align-items-center font-size-medium padding-larger flex-wrap patient-timeline-upload-icon-gap">
          {!props.featureFlags?.uploadDocument && <FeatureFlagsFallback />}

          {props.featureFlags?.uploadDocument &&
            menu.map(
              (element) =>
                Array.isArray(props.allowedUploadDocuments) &&
                props.allowedUploadDocuments.includes(element.type) && (
                  <div
                    key={element.type}
                    onClick={(event) => {
                      rippleEffect(event);
                      if (typeof props.onNewDocumentCreate === "function") {
                        props.onNewDocumentCreate(element.type);
                      }
                    }}
                    className="text-align-center width-30-percentage cursor-pointer"
                    data-cy={element.dataCy}
                  >
                    <img
                      src={element.defaultIcon}
                      alt={element.alt}
                      className="home-add-documents-icon-size"
                      // width="auto"
                    />
                    <div className="text-transform-capitalize white-space-preline">
                      {element.text}
                    </div>
                  </div>
                )
            )}
        </div>
      </Modal>

      <Modal
        show={showUploadOptionModal}
        canIgnore={true}
        onClose={() => {
          setShowUploadOptionModal(false);
        }}
        position="position-fixed bottom-0"
        boxShadow="none"
        borderRadius="none"
        width="inherit-parent-width"
        background="background-transparent"
        height="height-fit-to-content"
      >
        <div className="background-white padding-default font-family-gilroy-medium font-color-secondary flex-justify-content-space-around flex-align-items-center font-size-medium padding-larger flex-wrap patient-timeline-upload-icon-gap">
          <div className="font-size-smaller font-color-secondary">
            UPLOAD / LINK DOCUMENTS
          </div>
          {addDocumentMenu.map((data, index) => (
            <div
              key={index}
              className="display-flex flex-grow-1 flex-align-items-center padding-top-large"
              data-cy={data["data-cy"]}
            >
              <div
                className="flex-grow-1 flex-direction-column display-flex font-color-secondary"
                onClick={data.onClick}
              >
                <span className="font-size-medium font-family-gilroy-regular">
                  {data.name}
                </span>
                <span className="font-size-small font-family-gilroy-regular text-transform-capitalize padding-top-small">
                  {data.description}
                </span>
              </div>
              <ArrowIcon />
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
}

PatientTimeline.propType = {
  /**
   * array of objects to render the patient timeline
   */
  documents: PropTypes.arrayOf(PropTypes.object.isRequired),
  /**
   * loading state for the timeline shows the suspense of document card
   */
  loading: PropTypes.bool,
  /**
   * state which is used to filter the patient timeline cards, filter state is a object which contains `documentType as array`, `doctors name as array` and `clinics name as array`
   * each array contains the values which is need be filter
   */
  filterState: PropTypes.shape({
    documentType: PropTypes.arrayOf(PropTypes.string),
    doctors: PropTypes.arrayOf(PropTypes.string),
    clinics: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  /**
   * function which gives document uploader userType and userId, to get the profile picture of uploader. Example `getDocumentsCardProfilePicture(userType,userId)`
   */
  getDocumentsCardProfilePicture: PropTypes.func.isRequired,
  /**
   * which is used to control the document filter menu. it will render the menu items only if mentioned.
   * allowed menu Items `symptom` `prescription` `report` `vitals` `receipt` `admission` `insurance`
   */
  documentFilterMenu: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * function which gives clicked menu item type.Example `onFilterClick={(filterType,filterValue)=>{}}`
   */
  onFilterClick: PropTypes.func.isRequired,
  /**
   * which returns the document type and documentId of the clicked document
   */
  documentCardOnClick: PropTypes.func,
  /**
   * which is used to get the audio url of symptom
   * `getAudioUrl={(documentId)=>{}}`
   */
  getAudioUrl: PropTypes.func,
  /**
   * object of profile picture urls `profilePictures={'someId':src for image}
   */
  profilePictures: PropTypes.objectOf({
    patients: PropTypes.object,
    doctors: PropTypes.object,
    clinics: PropTypes.object
  }),
  /**
   * download url for the document assets
   */
  downloads: PropTypes.object,
  /**
   * user who viewing the document
   */
  viewerId: PropTypes.string.isRequired,
  /**
   * patientName
   */
  patientName: PropTypes.string,
  /**
   * viewerType `doctor` `patient` `clinic`
   */
  viewerType: PropTypes.oneOf(["patient", "doctor", "clinic"]),
  /**
   * To clear doctor name filter and clinic name filter
   */
  onClearFilterClick: PropTypes.func,
  /**
   * Used to control which documents are allowed to upload. allowed documents to uploads are `symptom` `prescription` `report` `vitals` `receipt` `admission` `insurance`
   */
  allowedUploadDocuments: PropTypes.arrayOf(PropTypes.string),
  /**
   * onNewDocumentCreate returns the document type to create
   */
  onNewDocumentCreate: PropTypes.func,
  /**
   * profilePictureSize `medium` `small` `smaller` `large`
   */
  profilePictureSize: PropTypes.string
};

PatientTimeline.defaultProps = {
  documents: [],
  loading: false,
  getDocumentsCardProfilePicture: undefined,
  filterState: {
    documentType: [],
    doctors: [],
    clinics: []
  },
  filterType: [],
  documentFilterMenu: ["symptom"],
  onFilterClick: undefined,
  documentCardOnClick: undefined,
  getAudioUrl: undefined,
  profilePictures: {
    patients: null,
    doctors: null,
    clinics: null
  },
  downloads: null,
  viewerId: "",
  viewerType: "patient",
  onClearFilterClick: () => {},
  allowedUploadDocuments: [""],
  onNewDocumentCreate: undefined
};

export default PatientTimeline;

const PatientAccessCard = (props) => {
  return (
    <div
      onClick={props.patientAccessOnClick}
      className="flex-justify-content-space-between padding-large margin-large border-2px-lite-grey border-radius-default flex-center-children-vertically"
      data-cy="extend-consent-request-card"
    >
      <div className="flex-justify-content-center flex-direction-column">
        <div className="font-family-gilroy-regular font-size-default">
          {props.requested
            ? "Older records have been requested"
            : "Patient’s access ends here"}
        </div>
        <div className="font-family-gilroy-regular font-size-small padding-top-default">
          {props.requested
            ? "Click here to resend request"
            : "Click here to request further older records."}
        </div>
      </div>
      <div>
        <ArrowIcon />
      </div>
    </div>
  );
};

function DocumentCard(props) {
  const SeverityScale = (props) => {
    const degree = `${parseInt(props.value) * 18 + 180}deg`;
    return (
      <div className="severity-scale-parent-circle-size border-radius-top overflow-hidden background-color-grey flex-justify-content-center flex-align-items-center cursor-pointer ">
        <div className="position-relative severity-scale-wrap-size border-radius-top overflow-hidden">
          <div
            className="severity-scale-size-top background-color-grey border-radius-top transform-origin-bottom"
            style={{
              transform: `rotate(${degree})`,
              background: `${
                parseInt(props.value) >= 1 && props.value <= 3
                  ? "#00a000"
                  : parseInt(props.value) >= 4 && props.value <= 7
                  ? "#FFE600"
                  : parseInt(props.value) > 7 && props.value <= 10
                  ? "#E64539"
                  : "transparent"
              }`
            }}
          ></div>
          <div className="severity-scale-size-bottom position-absolute background-white"></div>
          <div className="position-absolute-center-self background-white border-radius-50-percentage severity-scale-value-circle-size flex-place-children-page-center font-color-secondary font-size-smaller box-shadow-default">
            {props.value ? props.value : "6"}
          </div>
        </div>
      </div>
    );
  };

  // const authorName = (document) => {
  //   if (props.viewerId === document.from.id) {
  //     return "You";
  //   } else if (isValidObject(document)) {
  //     return document.from?.name;
  //   } else {
  //     return "";
  //   }
  // };
  return (
    <section
      data-cy={
        convertAbdmToNintoDocumentTypes(props.document.documentType) ===
        "symptom"
          ? props.document.description && props.document.description
          : props.document.documentName && props.document.documentName
      }
      className={`${
        props.status === "failed" ? "background-red-gradient" : ""
      } cursor-pointer padding-left-large padding-right-large padding-top-larger flex-justify-content-space-between inherit-parent-width ${
        props.index === props.documents.length - 1
          ? "padding-bottom-for-last-timeline-card"
          : "border-bottom-1px padding-bottom-larger"
      }`}
      onClick={() => {
        if (typeof props.documentCardOnClick === "function") {
          props.documentCardOnClick(props.document);
        }
      }}
      key={`${props.document.documentId}-${props.index}`}
    >
      <div className="inherit-parent-width overflow-hidden">
        <div className="font-color-secondary font-family-gilroy-medium font-size-small text-overflow-ellipsis white-space-no-wrap display-flex flex-justify-content-space-between">
          <div className="display-flex flex-justify-content-space-between inherit-parent-width overflow-hidden">
            {/* <div
              data-cy={`document-uploaded-by-${
                props.document.from
                  ? props.document.from.type === "patient"
                    ? "You"
                    : props.document.from.name
                  : "You"
              }`}
              className="white-space-nowrap text-overflow-ellipsis overflow-hidden flex-start-place-children-vertically text-transform-capitalize"
            >
              {authorName(props.document)}
            </div> */}

            {props.status === "failed" && (
              <div
                data-cy="document-title"
                className="font-weight-normal font-size-large font-family-gilroy-medium font-color-secondary display-flex text-overflow-ellipsis white-space-nowrap overflow-hidden"
              >
                <RotateIcon color="#4E4E4E" removeDot={true} />
                <div className="padding-left-default"> Tap to retry </div>
              </div>
            )}
            {((props.document.hasOwnProperty("type") &&
              props.document.hasOwnProperty("description")) ||
              props.document.hasOwnProperty("name")) &&
              props.status !== "failed" && (
                <h5
                  data-cy="document-title"
                  className="font-weight-normal font-size-large font-family-gilroy-medium font-color-secondary  text-overflow-ellipsis white-space-nowrap overflow-hidden"
                >
                  {props.document.type === "symptom"
                    ? props.document?.description
                    : props.document?.documentName}
                </h5>
              )}

            <time className="flex-start-place-children-vertically">
              {props.dateAndTimeConverter(
                props.document.timestamp,
                props.document.type
              )}
            </time>
          </div>
        </div>
        {props.document?.type === "vital" ? (
          <VitalsCard document={props.document} />
        ) : props.document?.type === "fitness" ? (
          <FitnessCard document={props.document} />
        ) : (
          <React.Fragment>
            <div className="display-flex flex-center-children-vertically padding-top-default">
              <div className="display-flex flex-align-items-center ">
                {props.document.severityScale ? (
                  <SeverityScale value={props.document.severityScale} />
                ) : (
                  <RoundedProfilePicture
                    size={
                      props.profilePictureSize
                        ? props.profilePictureSize
                        : "small"
                    }
                    // src={
                    //   props.document.type === "fitness"
                    //     ? googleFit
                    //     : props.document.from.type === "patient"
                    //     ? props.profilePictures?.patients?.[props.document.from.id]
                    //     : props.document.from.type === "clinic"
                    //     ? props.profilePictures?.clinics?.[props.document.from.id]
                    //     : props.document.from.type === "doctor"
                    //     ? props.profilePictures?.doctors?.[props.document.from.id]
                    //     : null
                    // }
                  />
                )}
              </div>
              <div className="padding-left-default font-family-gilroy-regular">
                <div>
                  {props.document?.transferData?.hip?.name
                    ? props.document?.transferData?.hip?.name
                    : props.document?.clinicName
                    ? props.document?.clinicName
                    : props.document?.sender?.name}
                </div>
                {(props.document.documentType ||
                  props.document?.status === "FAILED") && (
                  <div className="width-fit-content">
                    <DocumentChip
                      status={props.document?.status}
                      document={props.document}
                    />
                  </div>
                )}
              </div>
            </div>
            {props.document.type === "form" &&
              Array.isArray(props.document?.fields) &&
              (props.document?.fields.findIndex(
                (field) => field.required === true
              ) > -1
                ? props.document?.fields.some(
                    (field) =>
                      field?.value === undefined && field?.required === true
                  )
                : props.document?.fields.findIndex(
                    (field) => field.required === false
                  ) > -1
                ? props.document?.fields.every((field) => !field?.value)
                : false) && (
                <div
                  className=" display-flex flex-align-items-center padding-top-default"
                  data-cy={props.document?.name + "incomplete"}
                >
                  <img
                    src={infoRed}
                    alt=""
                    className="check-mark-icon-height"
                    // width={"auto"}
                  />
                  <span className="padding-left-small font-family-gilroy-regular">
                    Incomplete
                  </span>
                </div>
              )}
            {isValidObject(props.document.url) &&
              typeof props.document.url.audio === "string" &&
              props.document.url.audio.length > 0 && (
                <div
                  className=" padding-top-default"
                  onClick={(event) => event.stopPropagation()}
                >
                  <AudioPlayer
                    getDownloadURL={() =>
                      typeof props.getAudioUrl === "function" &&
                      props.getAudioUrl(props.document.documentId)
                    }
                    src={
                      isValidObject(props.downloads) &&
                      isValidObject(props.downloads.symptoms) &&
                      isValidObject(
                        props.downloads.symptoms[props.document.documentId]
                      )
                        ? props.downloads.symptoms[props.document.documentId]
                            .downloadUrl
                        : ""
                    }
                  />
                </div>
              )}
          </React.Fragment>
        )}
      </div>
    </section>
  );
}

const DocumentsCardSuspense = ({ profilePictureSize, keyValue }) => {
  return (
    <div
      key={keyValue}
      data-cy="documents-card-suspense"
      className="padding-top-larger padding-bottom-larger padding-left-large padding-right-large border-bottom-1px flex-justify-content-space-between inherit-parent-width"
    >
      <div className="inherit-parent-width overflow-hidden display-flex flex-direction-column">
        <div className="display-flex flex-justify-content-space-between ">
          <div className="shimmer margin-left-default margin-bottom-default border-radius-default font-size-large font-family-gilroy-medium font-color-secondary padding-default text-overflow-ellipsis white-space-nowrap overflow-hidden width-50-percentage"></div>

          <div className="shimmer margin-left-default margin-bottom-default border-radius-default font-size-large font-family-gilroy-medium font-color-secondary padding-default text-overflow-ellipsis white-space-nowrap overflow-hidden width-20-percentage"></div>
        </div>
        <div className="display-flex padding-top-large flex-align-items-center">
          <RoundedProfilePicture
            size={profilePictureSize ? profilePictureSize : "small"}
            loading={true}
          />
          <div className="display-flex flex-direction-column width-40-percentage">
            <div className="shimmer margin-left-default margin-bottom-default border-radius-default font-size-large font-family-gilroy-medium font-color-secondary padding-default text-overflow-ellipsis white-space-nowrap overflow-hidden width-60-percentage"></div>

            <div className="shimmer margin-left-default border-radius-default font-size-large font-family-gilroy-medium font-color-secondary padding-default text-overflow-ellipsis white-space-nowrap overflow-hidden width-40-percentage"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const NoDocumentsFound = () => {
  return (
    <section
      className="text-align-center max-width-500px flex-place-children-page-center inherit-parent-height"
      data-cy="empty-documents-fallback"
    >
      <img
        src={emptyDocument}
        alt="empty-doc"
        // width="auto"
        height="150px"
        className="padding-default"
      />
      <div className="font-family-gilroy-bold font-size-medium font-color-secondary padding-default">
        No records found.
      </div>
      <div className="font-family-gilroy-medium font-size-medium font-color-secondary padding-default">
        Start by adding your previous prescriptions, reports, or symptoms
      </div>
    </section>
  );
};

const TimelineSuspense = () => {
  return (
    <article
      className="screen-loader-wrapper flex-center-children-horizontally inherit-parent-height inherit-parent-width overflow-hidden"
      data-cy="patient-timeline-suspense"
    >
      <main className="max-width-588px inherit-parent-width">
        {[...Array(5)].map((_data, index) => (
          <div
            className=" padding-top-larger padding-left-large padding-right-large border-bottom-1px-e5e5e5"
            key={index}
          >
            <div className=" display-flex flex-justify-content-space-between ">
              <div className="shimmer  padding-default border-radius-default padding-right-larger padding-left-larger ">
                <div className="padding-left-larger padding-right-larger"></div>
              </div>
              <div className="shimmer  border-radius-default padding-right-larger padding-left-larger padding-top-large ">
                <div className="padding-left-larger padding-right-large"></div>
              </div>
            </div>

            <div className="flex-center-children-vertically padding-top-large  padding-bottom-larger">
              <div className="shimmer padding-large border-radius-50-percentage height-width-16px" />
              <div className=" margin-left-default">
                <div className="shimmer  padding-default border-radius-default width-200px " />
                <div className="shimmer margin-top-default padding-small border-radius-default width-150px ">
                  <div className=" padding-smaller" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </main>
    </article>
  );
};

//Document type filter nav menu
const DocumentsFilterMenu = React.forwardRef((props, ref) => {
  return (
    <div>
      <div
        style={{
          height: "1px"
        }}
        className="grey-line"
      />
      <div className="flex-justify-content-space-between flex-align-items-center font-size-medium font-color-secondary margin-bottom-default padding-right-large padding-left-large">
        <div
          className=" cursor-pointer display-flex flex-align-items-center"
          onClick={() => {
            if (props.sort === "oldestFirst") {
              props.setSort("resentFirst");
            } else {
              props.setSort("oldestFirst");
            }
          }}
          style={{
            width: "90px"
          }}
          data-cy="sortBy"
        >
          <div>
            <span className=" font-family-gilroy-medium">
              {" "}
              {`${
                props.sort === "oldestFirst" ? " Oldest" : " Recent"
              }`} first{" "}
            </span>
          </div>

          <div
            className={`${
              props.sort === "oldestFirst" ? "rotate-180" : ""
            } margin-left-small display-flex flex-align-items-center`}
          >
            <DownArrowIcon />
          </div>
        </div>

        <div
          onClick={props.kycStatusClick}
          className="position-relative cursor-pointer flex-justify-content-center display-flex flex-align-items-center "
        >
          <div className="trapezoid" />
          <div className="position-absolute display-flex white-space-no-wrap">
            {props.kycStatus && props?.kycStatus === "VERIFIED"
              ? "KYC Verified"
              : "Self Declared"}
            <div className="display-flex flex-align-items-center padding-left-default">
              {props?.kycStatus && props?.kycStatus === "VERIFIED" ? (
                <CircleWithTickIcon
                  circleColor={"#f5f5f5"}
                  circleOutline={"#00a000"}
                  tickColor={"#00a000"}
                />
              ) : (
                <ExclamatoryIcon />
              )}
            </div>
          </div>
        </div>
        <div
          className=" font-family-gilroy-medium display-flex flex-align-items-center "
          onClick={() => {
            props.filterButtonOnClick();
          }}
        >
          <div className=" ">
            Add filters{" "}
            {`${
              props.clinicsName.length > 0 && props.doctorsName.length > 0
                ? "(2)"
                : props.clinicsName.length > 0 || props.doctorsName.length > 0
                ? "(1)"
                : ""
            }`}
          </div>
          <div className="display-flex flex-align-items-start">
            <img
              src={filterGrey}
              className="padding-left-default"
              height="12px"
              alt="filter-icon"
            />
          </div>
        </div>
      </div>
      <section className=" padding-horizontal-small display-flex flex-align-items-start height-10px inherit-parent-width flex-justify-content-space-between ">
        <ul
          ref={ref}
          className="display-flex overflow-x-scroll hide-scroll-bar flex-align-items-center margin-vertical-default timeline-menu-scroll-bar"
        >
          <li
            data-cy="all-filter"
            key="all"
            className="flex-place-children-page-center margin-horizontal-small cursor-pointer timeline-menu-item-min-width"
            onClick={(event) => {
              rippleEffect(event);
              props.menuOnClick("allDocuments");
            }}
          >
            <div
              data-cy="all-filter-text"
              className={`${
                props.filterType.length === 0
                  ? "border-1px-F1F1F1 background-color-grey font-color-black "
                  : "border-1px-e5e5e5 font-color-secondary "
              } display-flex flex-align-items-center width-fit-content padding-left-default padding-right-medium padding-top-smaller padding-bottom-smaller font-family-gilroy-regular font-size-small border-radius-default text-transform-capitalize`}
            >
              {props.filterType.length !== 0 ? (
                <img
                  src={selectAllDocumentsIcon}
                  alt="select All Icon"
                  className="filter-icon-height-and-width background-color-transparent  "
                  data-cy={"selectAllDocumentsIcon"}
                />
              ) : (
                <img
                  src={selectAllDocumentsActiveIcon}
                  alt="select All Active Icon"
                  className="filter-icon-height-and-width background-color-transparent"
                  data-cy="selectAllDocumentsActiveIcon"
                />
              )}

              {"All"}
            </div>
          </li>
          {props.menuData.map(
            (data, index) =>
              props.menuItems.some((item) => {
                return item === data.type;
              }) && (
                <li
                  data-cy={`${data.type}-filter`}
                  key={data.type}
                  className="flex-place-children-page-center margin-horizontal-small cursor-pointer timeline-menu-item-min-width"
                  onClick={(event) => {
                    rippleEffect(event);
                    props.menuOnClick(data.type);
                  }}
                >
                  <div
                    data-cy={`${data.type}-filter-text`}
                    className={`${
                      props.filterType.some((text) => text === data.type)
                        ? "border-1px-F1F1F1 background-color-grey font-color-black "
                        : "border-1px-e5e5e5 font-color-secondary "
                    } display-flex flex-align-items-center width-fit-content padding-left-default padding-right-medium padding-top-smaller padding-bottom-smaller font-family-gilroy-regular font-size-small border-radius-default text-transform-capitalize`}
                  >
                    <img
                      src={data.icon}
                      alt={data.alt}
                      className={`${data.widthHeight} background-color-transparent `}
                      data-cy={data.alt}
                    />

                    {data.filterMenuText}
                  </div>
                </li>
              )
          )}
        </ul>
        {/* Filter modal button */}
      </section>
    </div>
  );
});

const DocumentChip = (props) => {
  return (
    <div
      data-cy={`${convertAbdmToNintoDocumentTypes(
        props.document?.documentType
      )}-chip`}
      className={`${
        props.status === "FAILED"
          ? "font-color-red background-failed"
          : "background-color-grey"
      } border-radius-default font-size-smaller padding-left-medium padding-right-medium height-fit-to-content flex-place-children-page-center padding-top-smaller padding-bottom-smaller font-family-gilroy-regular text-transform-capitalize`}
    >
      {props.document?.documentType
        ? convertAbdmToNintoDocumentTypes(props.document?.documentType)
        : "Transfer error"}
    </div>
  );
};

const VitalsCard = (vital) => {
  return (
    <div
      className="inherit-parent-width flex-start flex-wrap"
      onClick={vital.onClick}
    >
      {/*  heartRate */}
      {vital.document.hasOwnProperty("heartRate") && (
        <VitalsDataCard
          unit="BPM"
          src={heartRate}
          value={vital?.document?.heartRate?.value}
          title={"Heart rate"}
          data-cy="heart-rate-card"
        />
      )}

      {/*  body temperature */}
      {vital.document.hasOwnProperty("bodyTemperature") && (
        <VitalsDataCard
          unit="°F"
          src={temperature}
          value={vital?.document?.bodyTemperature?.value}
          title={"Temperature"}
          data-cy="temperature-card"
        />
      )}

      {/*  blood pressure */}
      {vital.document.hasOwnProperty("bloodPressure") && (
        <VitalsDataCard
          unit="mmHg"
          src={bloodPressure}
          value={vital?.document?.bloodPressure?.value}
          title={"Blood pressure"}
          data-cy="blood-pressure-card"
        />
      )}

      {/*  blood oxygen */}
      {vital.document.hasOwnProperty("bloodOxygen") && (
        <VitalsDataCard
          unit="%"
          src={bloodOxygen}
          value={vital?.document?.bloodOxygen?.value}
          title={"SpO2"}
          data-cy="blood-oxygen-card"
        />
      )}

      {/*  blood sugar */}
      {vital.document.hasOwnProperty("bloodSugar") && (
        <VitalsDataCard
          unit="mmol/L"
          src={bloodSugar}
          value={vital?.document?.bloodSugar?.value}
          title={"Blood sugar"}
          data-cy="blood-sugar-card"
        />
      )}

      {/*  height */}
      {vital.document.hasOwnProperty("height") && (
        <VitalsDataCard
          src={height}
          unit="CM"
          value={vital?.document?.height}
          title={"Height"}
          data-cy="height-card"
        />
      )}

      {/*  weight */}
      {vital.document.hasOwnProperty("weight") && (
        <VitalsDataCard
          src={weight}
          unit="KG"
          value={vital?.document?.weight}
          title={"Weight"}
          data-cy="weight-card"
        />
      )}
    </div>
  );
};

const VitalsDataCard = (vitalData) => {
  return (
    <div
      className="width-50-percentage display-flex flex-align-items-center padding-bottom-default padding-top-default font-family-gilroy-regular"
      data-cy={vitalData["data-cy"]}
    >
      <img
        src={vitalData.src}
        alt=""
        className="vital-data-card-icon-size"
        // width="auto"
      />
      <div className="display-flex flex-direction-column padding-left-small">
        <span className="font-color-secondary font-size-smaller">
          {vitalData.title}
        </span>
        <span className="font-size-medium padding-top-small">
          {vitalData.value} {vitalData.unit}
        </span>
      </div>
    </div>
  );
};

const FitnessCard = (fitness) => {
  const [isWeeklyAverage, setIsWeeklyAverage] = useState(false);

  const value = (value) => {
    if (isWeeklyAverage) {
      return Math.round(value / 7);
    } else {
      return value;
    }
  };
  return (
    <React.Fragment>
      <div className=" padding-top-medium font-family-gilroy-regular ">
        {new Date(fitness.document.duration.startingTime).getDate()}-
        {new Date(fitness.document.duration.endingTime).getDate()}{" "}
        {new Date(fitness.document.duration.endingTime).toLocaleString(
          "en-us",
          { month: "short" }
        )}{" "}
        Summary
      </div>
      <div
        className="inherit-parent-width flex-start flex-wrap"
        onClick={fitness.onClick}
        data-cy={"google-fit-data"}
      >
        {fitness.document.hasOwnProperty("heartPoints") && (
          <FitnessDataWidget
            src={heart}
            value={value(fitness?.document?.heartPoints)}
            title={`Heart pts ${isWeeklyAverage ? "(per day)" : ""}`}
            data-cy="heart-card"
          />
        )}
        {fitness.document.hasOwnProperty("stepCount") && (
          <FitnessDataWidget
            src={shoe}
            value={value(fitness?.document?.stepCount)}
            title={`Steps ${isWeeklyAverage ? "(per day)" : ""}`}
            data-cy="steps-card"
          />
        )}
        {fitness.document.hasOwnProperty("caloriesBurned") && (
          <FitnessDataWidget
            src={calorie}
            value={value(fitness?.document?.caloriesBurned)}
            title={`Cal ${isWeeklyAverage ? "(per day)" : ""}`}
            data-cy="calorie-card"
          />
        )}
        {fitness.document.hasOwnProperty("moveMinutes") && (
          <FitnessDataWidget
            src={movement}
            value={value(fitness?.document?.moveMinutes)}
            title={`Move min ${isWeeklyAverage ? "(per day)" : ""}`}
            data-cy="movement-card"
          />
        )}
      </div>
      <section className=" padding-top-large display-flex  flex-align-items-center">
        <ToggleSwitch
          name={
            fitness.document?.documentId
              ? fitness.document?.documentId
              : +new Date()
          }
          label={isWeeklyAverage ? "Weekly average" : "Weekly total"}
          onChange={(event) => {
            setIsWeeklyAverage(event.target.checked);
          }}
        />
      </section>
    </React.Fragment>
  );
};

const FitnessDataWidget = (fitness) => {
  return (
    <div
      className="width-50-percentage flex-start flex-direction-column padding-bottom-default padding-top-default font-family-gilroy-regular"
      data-cy={fitness["data-cy"]}
    >
      <div className="display-flex flex-align-items-center ">
        <img
          src={fitness.src}
          alt=""
          className="fitness-data-icon-height"
          // width="auto"
        />
        <span className="font-size-medium padding-top-small padding-left-small">
          {Math.round(fitness.value)}
        </span>
      </div>
      <span className="font-color-secondary font-size-smaller padding-top-smaller ">
        {fitness.title}
      </span>
    </div>
  );
};

const LockerSubscriptionExpiringSoon = (props) => {
  return (
    <div className=" padding-default ">
      <section className=" margin-default padding-default display-flex  flex-direction-column flex-justify-content-center border-2px-lite-grey border-radius-default">
        <div className="display-flex margin-default flex-direction-row flex-justify-content-space-between">
          <div>
            <h3 className=" font-family-gilroy-medium font-size-medium font-color-secondary">
              Health locker access expiring soon
            </h3>

            <div className=" font-family-gilroy-regular font-color-secondary font-size-small line-height-140-percentage padding-top-small">
              Please re-subscribe to view & add your records.
            </div>
          </div>

          <img
            src={warning_icon}
            alt=""
            draggable={false}
            // width="auto"
            height="14"
            className=" margin-top-medium  "
          />
        </div>

        <div className=" display-flex margin-top-default">
          <Button
            boxShadow={false}
            data-cy="resubscribe-button"
            text="Resubscribe"
            className=" margin-default font-weight-normal"
            onClick={props.resubscribeOnClick}
            loading={props.loading}
          />
          <Button
            boxShadow={false}
            data-cy="remind-me-later-button"
            text="Remind me later"
            className="margin-default font-size-small"
            variant="secondary"
            onClick={props.remindMeLaterOnClick}
          />
        </div>
      </section>
    </div>
  );
};

const LockerSubscriptionExpired = (props) => {
  return (
    <div className=" padding-default margin-default">
      <section className=" padding-large display-flex  flex-direction-column flex-align-items-center flex-justify-content-center border-2px-lite-grey border-radius-default">
        <h3 className=" font-family-gilroy-medium font-size-medium font-color-secondary">
          Ninto health locker access expired!
        </h3>

        <p className=" text-align-center font-family-gilroy-regular font-color-secondary font-size-small line-height-140-percentage padding-top-small display-flex flex-align-items-center flex-justify-content-center">
          Please re-subscribe to view & add your records plus access all health
          locker access
        </p>

        <Button
          boxShadow={false}
          data-cy="connect-now-button"
          text="Resubscribe now"
          className=" margin-top-large"
          onClick={props.resubscribeOnClick}
          loading={props.loading}
        />
      </section>
    </div>
  );
};
