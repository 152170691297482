import React, { useState, useEffect } from "react";
import Button from "../../front-end-global-components/components/Button/Button";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import OTPInputBox from "../../front-end-global-components/components/OTPInputBox/OTPInputBox";
import onBoarding from "../../assets/icons/onBoardTickIcon.svg";
import "./Abha.css";
import CheckBox from "../../front-end-global-components/components/CheckBox/CheckBox";
import { CopyIcon } from "../../assets/assets";
import { setSuccessStatus } from "../../redux/status/actions";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import closeIcon from "../../assets/icons/close.svg";

export default function AbhaAuthentication(props) {
  const [aadhaarVerifyFormData, setAadhaarVerifyFormData] = useState({
    aadhaarNumber: "",
    OTP: "",
    agreeTo: false
  });
  const [showModal, setShowModal] = useState(false);
  const [counter, setCounter] = useState(null);
  const [running, setRunning] = useState(false);
  const [createFormData, setCreateFormData] = useState({
    phoneNumber: "+91",
    otp: ""
  });

  const [abhaFormDataIsValid, setAbhaFormDataIsValid] = useState({
    phoneNumber: true,
    aadhaarNumber: true
  });
  useEffect(() => {
    let counterTimer;
    if (counter && counter !== null && typeof counter === "number" && running) {
      counterTimer = setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    } else {
      setCounter(null);
      setRunning(false);
      return () => clearTimeout(counterTimer);
    }
    // eslint-disable-next-line
  }, [counter]);

  useEffect(() => {
    if (
      props.abha.transactionId &&
      props.abha?.sentAadhaarOtp &&
      !props.abha?.aadhaarVerified
    ) {
      setCounter(60);
      setRunning(true);
    } else if (
      props.abha.transactionId &&
      props.abha?.sentMobileOtp &&
      !props.abha?.mobileVerified
    ) {
      setCounter(60);
      setRunning(true);
    } else {
      if (props.abha?.aadhaarVerified || props.abha?.mobileVerified) {
        setRunning(false);
      }
    }
    // eslint-disable-next-line
  }, [
    props.abha?.sentAadhaarOtp,
    props.abha?.aadhaarVerified,
    props.abha?.sentMobileOtp
  ]);

  return (
    <>
      <div className="inherit-parent-width inherit-parent-height">
        <div className="text-align-center">CREATE ABHA NUMBER</div>
        <form
          onChange={(event) => {
            setAadhaarVerifyFormData({
              [event.target.name]: event.target.value
            });
          }}
          onSubmit={(event) => {
            event.preventDefault();
            if (
              abhaFormDataIsValid.aadhaarNumber === true &&
              aadhaarVerifyFormData.agreeTo === true
            ) {
              props.sendAadhaarOtp(aadhaarVerifyFormData.aadhaarNumber);
            }
          }}
        >
          {(!props.abha.abhaNumberCreated || !props.abha.login) && (
            <>
              <InputBox
                className="inherit-parent-width border-radius-default margin-vertical-large"
                name="aadhaarNumber"
                label="Aadhaar Number"
                type="number"
                value={aadhaarVerifyFormData.aadhaarNumber}
                removeResponsive={true}
                isValidInput={(value) => {
                  setAbhaFormDataIsValid({
                    ...abhaFormDataIsValid,
                    aadhaarNumber: value
                  });
                }}
                buttonTileId="aadhaar-button"
                buttonTile={
                  props.abha?.aadhaarVerified
                    ? false
                    : typeof counter === "number" && counter !== 0
                    ? `${
                        counter?.toString().length === 1 ? "0" : ""
                      }${counter?.toString()} seconds`
                    : props.abha.sentAadhaarOtp === true
                    ? "RESEND OTP"
                    : "GET OTP"
                }
                buttonType={
                  props.abha.sentAadhaarOtp === true ? "button" : "submit"
                }
                onButtonClick={() => {
                  if (
                    typeof props.abha.transactionId === "string" &&
                    props.abha.sentAadhaarOtp === true &&
                    abhaFormDataIsValid.aadhaarNumber === true
                  ) {
                    props.sendAadhaarOtp(aadhaarVerifyFormData.aadhaarNumber);
                    setAadhaarVerifyFormData({
                      ...aadhaarVerifyFormData,
                      agreeTo: false
                    });
                  }
                }}
                required
                disabled={props.abha?.aadhaarVerified ? true : false}
                suffixButtonDisabled={
                  props.abha.aadhaarVerified ||
                  (counter && typeof counter === "number" ? true : false)
                }
              />
            </>
          )}

          {(!props.abha.abhaNumberCreated || !props.abha.login) &&
            props.abha.aadhaarVerified === true && (
              <p className=" font-family-gilroy-medium font-size-small margin-top-default font-color-primary">
                Aadhaar number successfully Verified
              </p>
            )}
        </form>

        {(!props.abha.abhaNumberCreated || !props.abha.login) &&
          props.abha.aadhaarVerified !== true && (
            <form
              onSubmit={(event) => {
                event.preventDefault();
                props.verifyAadhaarOtp(
                  aadhaarVerifyFormData.OTP,
                  props.abha.transactionId
                );
              }}
            >
              {props.abha.sentAadhaarOtp === true && (
                <div>
                  <OTPInputBox
                    size="small"
                    name="otp"
                    className=" margin-top-default margin-bottom-default "
                    setValue={(value) => {
                      setAadhaarVerifyFormData({
                        ...aadhaarVerifyFormData,
                        OTP: value
                      });
                    }}
                    required
                  />
                </div>
              )}
              {!props.abha.sentAadhaarOtp && (
                <div className="inherit-parent-width display-flex text-align-left font-size-small font-family-gilroy-medium margin-bottom-default flex-align-items-center white-space-no-wrap">
                  <CheckBox
                    name="agreeTo"
                    onChange={(event) => {
                      setAadhaarVerifyFormData({
                        ...aadhaarVerifyFormData,
                        agreeTo: event.target.checked
                      });
                    }}
                    checked={aadhaarVerifyFormData.agreeTo}
                    label=""
                    required={true}
                  />
                  <div className="display-flex flex-direction-column font-color-secondary">
                    <div className="margin-left-default">
                      I've received consent from the user
                    </div>
                    <div className="padding-left-default font-color-secondary display-flex">
                      <div>agreeing</div>
                      <div
                        onClick={() => {
                          setShowModal(true);
                        }}
                        className="text-underline-hover font-color-primary padding-left-small cursor-pointer"
                      >
                        ABDM's terms and conditions
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <Button
                className="margin-top-large"
                text="Continue"
                type={props.abha.sentMobileOtp === true ? "button" : "submit"}
                disabled={
                  !(
                    typeof aadhaarVerifyFormData.OTP === "string" &&
                    aadhaarVerifyFormData.OTP.length === 6
                  )
                }
                loading={props.abha.loading}
              />
            </form>
          )}

        {(!props.abha.abhaNumberCreated || !props.abha.login) &&
          props.abha.aadhaarVerified === true && (
            <form
              onSubmit={(event) => {
                event.preventDefault();
                props.createAbhaNumber(
                  createFormData.otp,
                  props.abha.transactionId,
                  createFormData.healthId
                );
              }}
            >
              <InputBox
                removeResponsive={true}
                className="margin-top-large"
                name="phoneNumber"
                label="Phone Number"
                type="text"
                value={createFormData.phoneNumber}
                isValidInput={(value) => {
                  setAbhaFormDataIsValid({
                    ...abhaFormDataIsValid,
                    phoneNumber: value
                  });
                }}
                buttonType={
                  props.abha.sentMobileOtp !== true ? "submit" : "button"
                }
                buttonTile={
                  typeof counter === "number" && counter !== 0
                    ? `${
                        counter?.toString().length === 1 ? "0" : ""
                      }${counter?.toString()} seconds`
                    : props.abha.sentMobileOtp !== true
                    ? "Send OTP"
                    : "RESEND OTP"
                }
                buttonTileId="send-otp-button"
                onButtonClick={(event) => {
                  event.preventDefault();
                  if (abhaFormDataIsValid.aadhaarNumber === true) {
                    props.sendMobileOtp(
                      createFormData.phoneNumber,
                      props.abha.transactionId
                    );
                  }
                }}
                required
                onChange={(event) => {
                  setCreateFormData({
                    ...createFormData,
                    phoneNumber: event.target.value
                  });
                }}
                suffixButtonDisabled={
                  counter && typeof counter === "number" ? true : false
                }
              />
              {props.abha.sentMobileOtp === true && (
                <div>
                  <OTPInputBox
                    size="small"
                    name="otp"
                    className=" margin-top-large margin-bottom-large "
                    setValue={(value) => {
                      setCreateFormData({ ...createFormData, otp: value });
                    }}
                    required
                  />
                </div>
              )}

              <Button
                className="margin-top-larger"
                text="Create ABHA"
                type={props.abha.sentMobileOtp === true ? "submit" : "button"}
                loading={props.abha.loading}
                disabled={
                  props.abha.sentMobileOtp === false
                    ? true
                    : !(
                        typeof createFormData.otp === "string" &&
                        createFormData.otp.length === 6
                      )
                }
              />
            </form>
          )}
        {props.abha.abhaNumberCreated &&
          props.abha.accessToken &&
          props.abha.login && (
            <div className="flex-place-children-page-center inherit-parent-width inherit-parent-height">
              <img src={onBoarding} alt="success" />
              <div className="text-align-center padding-top-larger">
                Patient has been onboarded successfully
              </div>
              {props.abha?.abhaNumberCreated?.healthIdNumber && (
                <div className="text-align-center padding-top-larger display-flex white-space-no-wrap">
                  ABHA Number:{" "}
                  {props.abha?.abhaNumberCreated?.healthIdNumber?.split(
                    "-"
                  )[0] +
                    props.abha?.abhaNumberCreated?.healthIdNumber?.split(
                      "-"
                    )[1] +
                    props.abha?.abhaNumberCreated?.healthIdNumber?.split(
                      "-"
                    )[2] +
                    props.abha?.abhaNumberCreated?.healthIdNumber?.split(
                      "-"
                    )[3]}
                  <div
                    className="padding-left-large cursor-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        props.abha.abhaNumberCreated.healthIdNumber
                      );
                      setSuccessStatus("ABHA Number copied");
                    }}
                  >
                    <CopyIcon />
                  </div>
                </div>
              )}

              {props.abha.abhaNumberCreated.healthId && (
                <div className="text-align-center padding-top-larger display-flex white-space-no-wrap">
                  ABHA Address: {props.abha.abhaNumberCreated.healthId}
                  <div
                    className="padding-left-large cursor-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        props.abha.abhaNumberCreated.healthIdNumber
                      );
                      setSuccessStatus("ABHA Address copied");
                    }}
                  >
                    <CopyIcon />
                  </div>
                </div>
              )}

              <Button
                className="margin-top-larger"
                text="Back"
                type="button"
                onClick={() => {
                  props.resetStatus();
                }}
                loading={props.abha.loading}
              />

              <div className="flex-center-children-vertically margin-vertical-large">
                <hr className="display-inline-block border-color-secondary-lite border-top-left-right-none min-width-100px flex-basis-1" />
                <span className="margin-horizontal-default display-inline-block  font-color-tertiary font-size-small">
                  OR
                </span>
                <hr className="display-inline-block border-color-secondary-lite border-top-left-right-none min-width-100px flex-basis-1" />
              </div>

              <div
                onClick={props.manageABHANumberOnClick}
                className="text-align-center text-underline-hover font-color-primary cursor-pointer"
              >
                Manage ABHA Number
              </div>
            </div>
          )}
      </div>
      <Modal
        show={showModal}
        width="width-30-percent"
        height="height-40-percent"
        maxWidth="max-width-500px"
        modalBodyClassName=" overflow-hidden"
        onClick={() => {
          setShowModal(!showModal);
        }}
      >
        <section className="font-color-secondary flex-justify-content-center padding-top-large padding-bottom-large ">
          <div className=" display-flex padding-left-large padding-right-large">
            USER INFORMATION AGREEMENT
          </div>
          <img
            draggable={false}
            src={closeIcon}
            onClick={() => {
              setShowModal(!showModal);
            }}
            alt="close"
            data-cy="modal-close-button"
            className=" cursor-pointer padding-left-large padding-right-large position-absolute right-0"
          />
        </section>
        <div className="max-height-92-percentage padding-left-larger padding-right-larger hide-scroll-bar overflow-auto inherit-parent-height">
          <div className="inherit-parent-height">
            <div className="inherit-parent-width inherit-parent-height padding-bottom-large">
              <p
                style={{ lineHeight: "20px", textAlign: "justify" }}
                className="padding-bottom-default"
              >
                &nbsp; &nbsp; &nbsp; &nbsp; I, hereby declare that I am
                voluntarily sharing my Aadhaar Number and demographic
                information issued by UIDAI, with National Health Authority
                (NHA) for the sole purpose of creation of ABHA number . I
                understand that my ABHA number can be used and shared for
                purposes as may be notified by ABDM from time to time including
                provision of healthcare services. Further, I am aware that my
                personal identifiable information (Name, Address, Age, Date of
                Birth, Gender and Photograph) may be made available to the
                entities working in the National Digital Health Ecosystem (NDHE)
                which inter alia includes stakeholders and entities such as
                healthcare professionals (e.g. doctors), facilities (e.g.
                hospitals, laboratories) and data fiduciaries (e.g. health
                programmes), which are registered with or linked to the Ayushman
                Bharat Digital Mission (ABDM), and various processes there
                under.
              </p>
              <p style={{ lineHeight: "20px", textAlign: "justify" }}>
                I authorize NHA to use my Aadhaar number for performing Aadhaar
                based authentication with UIDAI as per the provisions of the
                Aadhaar (Targeted Delivery of Financial and other Subsidies,
                Benefits and Services) Act, 2016 for the aforesaid purpose. I
                understand that UIDAI will share my e-KYC details, or response
                of “Yes” with NHA upon successful authentication. I have been
                duly informed about the option of using other IDs apart from
                Aadhaar; however, I consciously choose to use Aadhaar number for
                the purpose of availing benefits across the NDHE. I am aware
                that my personal identifiable information excluding Aadhaar
                number / VID number can be used and shared for purposes as
                mentioned above. I reserve the right to revoke the given consent
                at any point of time as per provisions of Aadhaar Act and
                Regulations.
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
