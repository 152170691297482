import React, { useEffect, useState } from "react";
import Button from "../../front-end-global-components/components/Button/Button";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";

export default function EditEscalation(props) {
  const [isNumberValid, setIsNumberValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isNameValid, setIsNameValid] = useState(true);
  const [currentProfileEscalationData, setCurrentProfileEscalationData] =
    useState(null);
  const [editEscalationFormData, setEditEscalationFormData] = useState({
    fullName: "",
    phoneNumber: "",
    email: ""
  });
  //saves current profile data to state from store
  useEffect(() => {
    if (props.profile.data) {
      const profile = Object.values(props.profile.data)[0];

      if (profile?.escalationContact) {
        setCurrentProfileEscalationData(profile?.escalationContact);
      }
    }
  }, [props.profile.data]);

  //saves store data to the current form data
  useEffect(() => {
    if (currentProfileEscalationData) {
      let escalationObj = {
        fullName: currentProfileEscalationData.fullName,
        phoneNumber: currentProfileEscalationData.phoneNumber,
        email: currentProfileEscalationData.email
      };
      setEditEscalationFormData(escalationObj);
    }
  }, [currentProfileEscalationData]);

  function editEscalationHandlerOnClick(event) {
    event.preventDefault();
    if (isNumberValid && isEmailValid && isNameValid) {
      props.escalationContact(editEscalationFormData);
    }
  }

  function editEscalationContactFormOnChange(event) {
    setEditEscalationFormData({
      ...editEscalationFormData,
      [event.target.name]: event.target.value
    });
  }

  return (
    <section className="padding-larger">
      {props.profile &&
      props.profile.loading &&
      props.profile.loading === true ? (
        <EditEscalationSuspense />
      ) : (
        <>
          <div data-cy="escalation-contact">
            <form
              className="inherit-parent-width inherit-parent-height"
              onChange={(event) => {
                editEscalationContactFormOnChange(event);
              }}
              onSubmit={(event) => {
                event.preventDefault();
                editEscalationHandlerOnClick(event);
              }}
            >
              <div className="font-family-gilroy-bold text-align-center font-color-secondary font-size-medium padding-bottom-larger ">
                This contact will be used for escalation and or emergency
                purposes
              </div>
              <InputBox
                removeResponsive={true}
                className="inherit-parent-width max-width-300px border-radius-default padding-bottom-large"
                name="fullName"
                value={editEscalationFormData.fullName}
                data-cy="typeOperatorName"
                onFullNameValidationError={(isValid) => {
                  setIsNameValid(isValid);
                }}
                label="Operator Name"
                type="text"
                required
              />
              <InputBox
                removeResponsive={true}
                className="inherit-parent-width max-width-300px border-radius-default padding-bottom-large"
                name="phoneNumber"
                value={
                  editEscalationFormData.phoneNumber
                    ? editEscalationFormData.phoneNumber
                    : "+91"
                }
                data-cy="branch-contact-number-field"
                onPhoneNumberValidationError={(isValid) => {
                  setIsNumberValid(isValid);
                }}
                label="Phone Number"
                type="text"
                required
              />
              <InputBox
                removeResponsive={true}
                className="inherit-parent-width max-width-300px border-radius-default padding-bottom-large"
                name="email"
                value={editEscalationFormData.email}
                data-cy="company-email-field"
                onEmailValidationError={(isValid) => {
                  setIsEmailValid(isValid);
                }}
                label="Email"
                type="email"
                required
              />
              <div className="flex-justify-content-space-between padding-top-small">
                <div className="inherit-parent-width font-family-gilroy-bold padding-bottom-large">
                  <Button
                    type="submit"
                    className="max-width-160px max-height-50px"
                    boxShadow={false}
                    text="Save"
                    data-cy="button"
                    disabled={
                      editEscalationFormData?.fullName?.length === 0 ||
                      editEscalationFormData.phoneNumber?.length === 0 ||
                      editEscalationFormData?.email?.length === 0 ||
                      (editEscalationFormData?.fullName ===
                        currentProfileEscalationData?.fullName &&
                        editEscalationFormData?.phoneNumber ===
                          currentProfileEscalationData?.phoneNumber &&
                        editEscalationFormData?.email ===
                          currentProfileEscalationData?.email)
                        ? true
                        : false
                    }
                  ></Button>
                </div>
                <div className="inherit-parent-width padding-left-large font-family-gilroy-medium padding-bottom-large">
                  <Button
                    className="max-width-160px max-height-50px"
                    boxShadow={false}
                    text="Cancel"
                    variant="secondary"
                    onClick={props.cancelButtonClick}
                  ></Button>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </section>
  );
}

function EditEscalationSuspense() {
  return (
    <div>
      <div className="  margin-default">
        <div className="shimmer border-radius-default margin-left-large margin-right-large height-16px" />
        <div className="shimmer margin-top-default margin-left-medium margin-right-medium border-radius-default height-16px" />
        <div className="flex-justify-content-center">
          <div className="shimmer margin-top-default border-radius-default height-16px width-100px" />
        </div>
        <div className="shimmer margin-top-larger border-radius-default height-48px" />
        <div className="shimmer margin-top-medium border-radius-default height-48px" />
        <div className="shimmer margin-top-medium border-radius-default height-48px" />
      </div>

      <div className=" flex-justify-content-space-around margin-default margin-top-large">
        <div className="shimmer width-50-percentage height-48px margin-right-default border-radius-default" />
        <div className="shimmer width-50-percentage height-48px margin-left-default border-radius-default" />
      </div>
    </div>
  );
}

/**
 * when creating abha 
 * entering wrong aadhaar
 * {
  "code": "HIS-400",
  "message": "Request is invalid. Please enter the correct data.",
  "details": [
    {
      "message": "Aadhaar Number/Virtual ID is invalid.",
      "code": "HIS-2016",
      "attribute": {
        "key": "aadhaar",
        "value": "978445854545"
      }
    }
  ]
}
* when entering wrong OTP
* {
  "code": "HIS-422",
  "message": "Unable to process the current request due to incorrect data entered.",
  "details": [
    {
      "message": "Invalid OTP value.",
      "code": "HIS-2022",
      "attribute": null
    }
  ]
}
 */
