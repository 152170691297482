import store from "../store/store";
import { connectionActionTypes } from "./saga";

/**
 * @module ConnectionSaga
 */

/**
 * Accept ninto connection with OTP
 * @param {string} otp
 */
export function acceptInvite(otp) {
  store.dispatch({
    type: connectionActionTypes.ACCEPT_INVITE,
    payload: {
      otp: otp
    }
  });
}

/**
 * Send ninto invites
 */
export function nintoSendInvite() {
  store.dispatch({
    type: connectionActionTypes.NINTO_SEND_INVITE
  });
}

/**
 * Accept ninto connection with OTP
 * @param {string} otp
 * @param {string} requestId
 */
export function nintoAcceptInvite(otp, requestId) {
  store.dispatch({
    type: connectionActionTypes.NINTO_ACCEPT_INVITE,
    payload: {
      otp: otp,
      requestId: requestId
    }
  });
}

/**
 * Set connection
 * @param {string} data
 */
export function setConnections(data) {
  store.dispatch({
    type: connectionActionTypes.SET_CONNECTIONS,
    payload: {
      data: data
    }
  });
}

/**
 * Set selected clinic
 * @param {object} data
 */
export function setSelectedClinic(data) {
  store.dispatch({
    type: connectionActionTypes.SET_SELECTED_CLINIC,
    payload: {
      data: data
    }
  });
}

/**
 * Set current connection
 * @param {object} data
 */
export function setCurrentConnection(data) {
  store.dispatch({
    type: connectionActionTypes.SET_CURRENT_CONNECTION,
    payload: {
      data: data
    }
  });
}

/**
 * Get connected staffs
 * @param {object} data
 */
export function getConnectedStaffs(data) {
  store.dispatch({
    type: connectionActionTypes.GET_CONNECTED_STAFFS,
    payload: {
      data: data
    }
  });
}

/**
 * Get brand download URL for logo
 * @param {object} data
 */
export function getBrandDownloadableUrl(data) {
  store.dispatch({
    type: connectionActionTypes.GET_BRAND_LOGO,
    payload: {
      data: data
    }
  });
}

/**
 * Create new staff
 * @param {object} data
 */
export function createNewStaff(data) {
  store.dispatch({
    type: connectionActionTypes.CREATE_NEW_STAFF,
    payload: {
      data: data
    }
  });
}

/**
 * Remove new staff
 * @param {object} data
 */
export function removeStaff(data) {
  store.dispatch({
    type: connectionActionTypes.REMOVE_STAFF,
    payload: {
      data: data
    }
  });
}

/**
 * Get brand data
 * @param {object} data
 */
export function getBrandData(data) {
  store.dispatch({
    type: connectionActionTypes.GET_BRAND_DATA,
    payload: {
      data: data
    }
  });
}
