const abdmPatientsReducer = (
  state = {
    loading: false,
    searchKey: null,
    type: null,
    selectedPatient: null,
    searchResults: null
  },
  action
) => {
  switch (action.type) {
    case "SET_ABDM_PATIENTS_LOADING":
      return {
        ...state,
        loading: action.payload.loading
      };

    case "SET_ABDM_SEARCHED_PATIENT_SEARCH_KEY":
      return {
        ...state,
        searchKey: action.payload.searchKey
      };

    case "SET_ABDM_SELECTED_PATIENT":
      return {
        ...state,
        selectedPatient: action.payload.selectedPatient
      };

    case "SET_CARE_CONTEXT_TO_PATIENT":
      return {
        ...state,
        selectedPatient: {
          ...state.selectedPatient,
          careContext: action.payload.careContext
        }
      };

    case "SET_PATIENT_CONNECTION_TO_NINTO_STATUS":
      return {
        ...state,
        selectedPatient: {
          ...state.selectedPatient,
          careContext: action.payload.careContext
        }
      };

    case "REMOVE_ABDM_SELECTED_PATIENT": {
      return {
        ...state,
        selectedPatient: null
      };
    }

    case "SET_ABDM_PATIENT_SEARCH_RESULTS":
      return {
        ...state,
        searchResults: action.payload.data
      };

    case "EDIT_ABDM_PATIENT_SEARCH_RESULTS":
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          ...action.payload.data
        }
      };

    case "SET_ABDM_PATIENT_SEARCH_DOCUMENTS":
      return {
        ...state,
        documents: { ...state.documents, data: action.payload.documents }
      };

    case "CLEAR_ABDM_PATIENT_SEARCH_DATA":
      return {
        ...state,
        searchKey: null,
        type: null,
        selectedPatient: null,
        searchResults: null
      };
    case "ABDM_FILTER_BY_DOCUMENTS":
      return {
        ...state,
        documents: {
          ...state.documents,
          filters: {
            ...state.documents.filters,
            documentType: action.payload.documentsType
          }
        }
      };

    case "ABDM_FILTER_BY_DOCTORS":
      return {
        ...state,
        documents: {
          ...state.documents,
          filters: {
            ...state.documents.filters,
            doctors: action.payload.doctorsName
          }
        }
      };

    case "ABDM_FILTER_BY_CLINICS":
      return {
        ...state,
        documents: {
          ...state.documents,
          filters: {
            ...state.documents.filters,
            clinics: action.payload.clinicsName
          }
        }
      };

    case "ABDM_CLEAR_DOCUMENTS_FILTER":
      return {
        ...state,
        documents: {
          ...state.documents,
          filters: {
            documentType: [],
            doctors: [],
            clinics: []
          }
        }
      };

    case "RESET":
      return {
        loading: false,
        searchKey: null,
        type: null,
        selectedPatient: null,
        searchResults: null,
        documents: {
          data: null,
          filters: {
            documentType: [],
            doctors: [],
            clinics: []
          }
        }
      };

    default:
      return state;
  }
};

export default abdmPatientsReducer;
