import { all, put, takeEvery } from "@redux-saga/core/effects";
import { searchDocuments } from "../../services/database";
import { throwError } from "../../services/error";
import { isValidObject } from "../../utils/validators";
import { setErrorStatus } from "../status/actions";

export const documentSearchActionTypes = {
  GET_SEARCH_DOCUMENT_DATA: "GET_SEARCH_DOCUMENT_DATA",
  ADD_SELECTED_DOCUMENT: "ADD_SELECTED_DOCUMENT",
  REMOVE_DOCUMENT_SEARCH_DATA: "REMOVE_DOCUMENT_SEARCH_DATA"
};

function* getSearchDocumentDataWorker(action) {
  try {
    if (
      !(
        typeof action.payload.phoneNumber === "string" &&
        action.payload.phoneNumber.trim().length === 13
      )
    ) {
      throw throwError("custom", "Please enter a valid phone number");
    }

    yield put({
      type: "SET_DOCUMENT_SEARCH_LOADING",
      payload: {
        loading: true
      }
    });

    yield put({
      type: "CLEAR_DOCUMENT_SEARCH_DATA"
    });

    yield put({
      type: "SET_SEARCHED_DOCUMENT_PHONE_NUMBER",
      payload: {
        phoneNumber: action.payload.phoneNumber
      }
    });

    const searchResults = yield searchDocuments(action.payload.phoneNumber);
    if (isValidObject(searchResults)) {
      yield put({
        type: "SET_DOCUMENT_SEARCH_DATA",
        payload: {
          data: searchResults
        }
      });
    } else {
      throw throwError("custom", "No search results found");
    }
    yield put({
      type: "SET_DOCUMENT_SEARCH_LOADING",
      payload: {
        loading: false
      }
    });
  } catch (error) {
    setErrorStatus(error);
    yield put({
      type: "SET_DOCUMENT_SEARCH_LOADING",
      payload: {
        loading: false
      }
    });
  }
}

function* addSelectedDocumentWorker(action) {
  try {
    if (typeof action.payload.documentId !== "string") {
      yield throwError("custom", "Selected Document is not valid");
    }
    yield put({
      type: "SET_SELECTED_DOCUMENT",
      payload: {
        selectedDocument: action.payload.documentId
      }
    });
  } catch (error) {
    setErrorStatus(error);
    yield put({
      type: "SET_DOCUMENT_SEARCH_LOADING",
      payload: {
        loading: false
      }
    });
  }
}

function* removeDocumentSearchDataWorker(action) {
  yield put({
    type: "CLEAR_DOCUMENT_SEARCH_DATA"
  });

  if (action.payload.deepClear === true) {
    yield put({
      type: "CLEAR_SELECTED_DOCUMENT"
    });
  }
}

export function* documentSearchWatcher() {
  yield all([
    takeEvery("GET_SEARCH_DOCUMENT_DATA", getSearchDocumentDataWorker),
    takeEvery("ADD_SELECTED_DOCUMENT", addSelectedDocumentWorker),
    takeEvery("REMOVE_DOCUMENT_SEARCH_DATA", removeDocumentSearchDataWorker)
  ]);
}
