import React, { useState } from "react";
import Button from "../../front-end-global-components/components/Button/Button";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import SplittedInputBox from "../../front-end-global-components/components/SplittedInputBox/SplittedInputBox";

export default function AddVitals(props) {
  const [vitalsFormData, setVitalsFormData] = useState({
    date: new Date().toISOString().split("T")[0],
    time: new Date().toTimeString().slice(0, 5),
    heartRate: "",
    bloodOxygen: "",
    bloodSugar: "",
    bloodPressure: "",
    bodyTemperature: "",
    height: "",
    weight: "",
    bloodPressurePrefix: "",
    bloodPressureSuffix: ""
  });

  const [isValidVitalsFormData, setIsValidVitalsFormData] = useState({
    date: true,
    time: true,
    heartRate: true,
    bloodOxygen: true,
    bloodSugar: true,
    bloodPressure: true,
    bodyTemperature: true,
    height: true,
    weight: true
  });

  //vitals submit handler
  const vitalsSubmitHandler = () => {
    let sortedData = {};

    for (const key in vitalsFormData) {
      if (
        (key === "weight" && vitalsFormData.weight.trim().length > 0) ||
        vitalsFormData[key].trim().length >= 2
      ) {
        if (key === "height" || key === "weight" || key === "bodyTemperature") {
          sortedData = {
            ...sortedData,
            [key]: parseFloat(vitalsFormData[key])
          };
        } else {
          sortedData = {
            ...sortedData,
            [key]: parseInt(vitalsFormData[key])
          };
        }
      }
      sortedData.timestamp = +new Date(
        `${vitalsFormData.date} ${vitalsFormData.time}`
      );
      if (
        String(vitalsFormData?.bloodPressurePrefix).trim().length > 1 &&
        String(vitalsFormData?.bloodPressureSuffix).trim().length > 1
      ) {
        sortedData.bloodPressure = `${parseInt(
          vitalsFormData["bloodPressurePrefix"]
        )}/${parseInt(vitalsFormData["bloodPressureSuffix"])}`;
      } else {
        delete sortedData.bloodPressure;
      }
      delete sortedData.date;
      delete sortedData["bloodPressurePrefix"];
      delete sortedData["bloodPressureSuffix"];
      delete sortedData.time;
    }

    props.addVitals(sortedData);
    if (typeof props.onSubmitVitals === "function") {
      props.onSubmitVitals(false);
    }
  };

  return (
    <div className="padding-larger">
      <div className="font-family-gilroy-medium text-align-center font-color-secondary font-size-medium padding-bottom-larger">
        Add Vitals
      </div>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          if (
            isValidVitalsFormData.date &&
            isValidVitalsFormData.time &&
            isValidVitalsFormData.heartRate &&
            isValidVitalsFormData.bloodOxygen &&
            isValidVitalsFormData.bodyTemperature &&
            isValidVitalsFormData.height &&
            isValidVitalsFormData.weight
          )
            vitalsSubmitHandler();
        }}
        className="padding-bottom-large overflow-scroll"
      >
        <InputBox
          removeResponsive={true}
          label="Date"
          name="date"
          type="date"
          data-cy="vitals-date"
          className="inherit-parent-width max-width-300px border-radius-default padding-bottom-large"
          onDateValidation={(isValid) => {
            setIsValidVitalsFormData({
              ...isValidVitalsFormData,
              date: isValid
            });
          }}
          onChange={(event) => {
            setVitalsFormData({
              ...vitalsFormData,
              date: event.target.value
            });
          }}
          value={vitalsFormData?.date}
          required={true}
        />
        <InputBox
          removeResponsive={true}
          label="Time"
          name="time"
          type="time"
          data-cy="vitals-time"
          className="padding-bottom-large"
          value={vitalsFormData?.time}
          onTimeValidation={(isValid) => {
            setIsValidVitalsFormData({
              ...isValidVitalsFormData,
              time: isValid
            });
          }}
          userEnteredDate={vitalsFormData?.date}
          onChange={(event) => {
            setVitalsFormData({
              ...vitalsFormData,
              time: event.target.value
            });
          }}
          required={true}
        />
        <InputBox
          removeResponsive={true}
          size="small"
          label="Heart rate (BPM)"
          name="heartRate"
          type="number"
          data-cy="vitals-heart-rate"
          className="padding-bottom-large"
          value={vitalsFormData?.heartRate}
          onHeartBeatValidation={(isValid) => {
            setIsValidVitalsFormData({
              ...isValidVitalsFormData,
              heartRate: isValid
            });
          }}
          onChange={(event) => {
            setVitalsFormData({
              ...vitalsFormData,
              heartRate: event.target.value
            });
          }}
          required={false}
        />
        <InputBox
          removeResponsive={true}
          size="small"
          label="Body temperature (°F)"
          name="bodyTemperature"
          type="number"
          data-cy="vitals-body-temperature"
          className="padding-bottom-large"
          value={vitalsFormData?.bodyTemperature}
          onBodyTemperatureValidation={(isValid) => {
            setIsValidVitalsFormData({
              ...isValidVitalsFormData,
              bodyTemperature: isValid
            });
          }}
          onChange={(event) => {
            setVitalsFormData({
              ...vitalsFormData,
              bodyTemperature: event.target.value
            });
          }}
          required={false}
        />
        <SplittedInputBox
          label="Blood pressure (MMHG)"
          prefixValue={vitalsFormData.bloodPressurePrefix}
          suffixValue={vitalsFormData.bloodPressureSuffix}
          name="bloodPressure"
          className="padding-bottom-large"
          size="half"
          infoText="the systolic pressure first (top)
                and the diastolic pressure
                second (below). Ex: 120/80"
          onPrefixChange={(event) => {
            setVitalsFormData({
              ...vitalsFormData,
              bloodPressurePrefix: event.target.value
            });
          }}
          onSuffixChange={(event) => {
            setVitalsFormData({
              ...vitalsFormData,
              bloodPressureSuffix: event.target.value
            });
          }}
          isValidInput={(isValid) => {
            if (
              String(vitalsFormData?.bloodPressurePrefix).trim().length !== 0 ||
              String(vitalsFormData?.bloodPressureSuffix).trim().length !== 0
            ) {
              setIsValidVitalsFormData({
                ...isValidVitalsFormData,
                bloodPressure: isValid
              });
            }
          }}
        />
        <InputBox
          removeResponsive={true}
          size="small"
          label="SPO2 - Blood oxygen (%)"
          name="bloodOxygen"
          type="number"
          data-cy="vitals-blood-oxygen"
          className="padding-bottom-large"
          value={vitalsFormData?.bloodOxygen}
          onBloodOxygenValidation={(isValid) => {
            setIsValidVitalsFormData({
              ...isValidVitalsFormData,
              bloodOxygen: isValid
            });
          }}
          onChange={(event) => {
            setVitalsFormData({
              ...vitalsFormData,
              bloodOxygen: event.target.value
            });
          }}
          required={false}
        />
        <InputBox
          removeResponsive={true}
          size="small"
          label="Blood sugar (fasting)"
          name="bloodSugar"
          type="number"
          data-cy="vitals-blood-sugar"
          className="padding-bottom-large"
          value={vitalsFormData?.bloodSugar}
          onChange={(event) => {
            setVitalsFormData({
              ...vitalsFormData,
              bloodSugar: event.target.value
            });
          }}
          required={false}
        />
        <InputBox
          removeResponsive={true}
          size="small"
          label="Height (CM)"
          name="height"
          type="number"
          data-cy="vitals-height"
          className="padding-bottom-large"
          value={vitalsFormData?.height}
          onHeightValidation={(isValid) => {
            setIsValidVitalsFormData({
              ...isValidVitalsFormData,
              height: isValid
            });
          }}
          onChange={(event) => {
            setVitalsFormData({
              ...vitalsFormData,
              height: event.target.value
            });
          }}
          required={false}
        />
        <InputBox
          removeResponsive={true}
          size="small"
          label="Weight (KG)"
          name="weight"
          type="number"
          data-cy="vitals-weight"
          className="padding-bottom-large"
          value={vitalsFormData?.weight}
          onWeightValidation={(isValid) => {
            setIsValidVitalsFormData({
              ...isValidVitalsFormData,
              weight: isValid
            });
          }}
          onChange={(event) => {
            setVitalsFormData({
              ...vitalsFormData,
              weight: event.target.value
            });
          }}
          required={false}
        />
        <Button
          data-cy="vitals-add-button"
          boxShadow={false}
          text="Add vitals"
          type="submit"
          className="inherit-parent-width padding-medium border-radius-default "
          disabled={
            (vitalsFormData.heartRate?.trim().length < 2 &&
              vitalsFormData.bloodOxygen?.trim().length < 2 &&
              String(vitalsFormData.bloodPressurePrefix)?.trim().length < 2 &&
              String(vitalsFormData.bloodPressureSuffix)?.trim().length < 2 &&
              String(vitalsFormData.weight)?.trim().length < 1 &&
              String(vitalsFormData.height)?.trim().length < 2 &&
              vitalsFormData.bloodSugar?.trim().length < 2 &&
              vitalsFormData.bodyTemperature?.trim().length < 2) ||
            isValidVitalsFormData.bloodPressure !== false
          }
        />
      </form>
    </div>
  );
}
