import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import AddForm from "../../components/AddForm/AddForm";
import AddVitals from "../../components/AddVitals/AddVitals";
import EditEscalation from "../../components/EditEscalation/EditEscalation";
import EditProfile from "../../components/EditProfile/EditProfile";
import EditVerification from "../../components/EditVerification/EditVerification";
import PatientTimelineCardPreview from "../../components/PatientTimelineCardPreview/PatientTimelineCardPreview";
import PinPatientToDoctor from "../../components/PinPatientToDoctor/PinPatientToDoctor";
import UploadComponent from "../../components/UploadComponent/UploadComponent";
import PatientTimeline from "../../front-end-global-components/components/PatientTimeline/PatientTimeline";
import { bucketNames, getFileUrl } from "../../services/storage";
import { isValidArray, isValidObject } from "../../utils/validators";
import bellIcon from "../../assets/icons/Bell.svg";
import ConnectToPatient from "../../components/ConnectToPatient/ConnectToPatient";
import Personnel from "../../components/Personnel/Personnel";
import Button from "../../front-end-global-components/components/Button/Button";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import { subMonths } from "../../utils/constants";
import { setErrorStatus } from "../../redux/status/actions";
import {
  showPrimary,
  showSecondary,
  showTertiary
} from "../../redux/navigation/actions";
import {
  checkIfConsentRequestSentToAbdm,
  checkIfPatientConnectedToAbdm,
  sendConsentRequest,
  sendSubscriptionRequest
} from "../../redux/abdmPatients/actions";
import {
  clearQRaccessToken,
  confirmOtpForLinking,
  connectPatientByDemographics,
  sendOtpForLinking
} from "../../redux/abdmQR/actions";
import {
  addDocumentFilter,
  addVitals,
  getDataTransferInfo,
  removeDocumentFilter,
  retryDataTransfer,
  setDocuments,
  uploadDocument
} from "../../redux/documents/actions";
import {
  getForms,
  removeCurrentForm,
  uploadForm
} from "../../redux/form/actions";
import {
  addSelectedDoctor,
  getSearchDoctorData,
  pinPatient,
  removeDoctorSearchData,
  setSortedPatients,
  unPinPatient
} from "../../redux/doctorSearch/actions";
import {
  getPublicProfileData,
  updatePublicProfileData,
  editPersonalProfileData
} from "../../redux/profile/actions";
import EditPersonalProfile from "../../components/EditPersonalProfile/EditPersonalProfile";
import LinkORUnlinkAbhaAddress from "../../components/ManageABHA/LinkORUnlinkAbhaAddress";
import ShowAbhaCard from "../../components/Abha/ShowAbhaCard";
import {
  createAbhaAddress,
  getPatientQrDetails
} from "../../redux/abha/actions";
import CreateABHAAddress from "../../components/ManageABHA/CreateABHAAddress";
import ConsentHistory from "../../components/ConsentHistory/ConsentHistory";
import TextArea from "../../front-end-global-components/components/TextArea/TextArea";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";

function Tertiary(props) {
  const [isRequiredDataPresent, setIsRequiredDataPresent] = useState(false);
  const [showDocumentPreview, setShowDocumentPreview] = useState(null);
  const [isPatientTimeLineFilterModal, setIsPatientTimeLineFilterModal] =
    useState(false);
  const [patientTimelineDownloadURls, setPatientTimelineDownloadURls] =
    useState({
      patients: {},
      doctors: {},
      clinics: {},
      symptoms: {}
    });
  const [showDateFormModal, setShowDateFormModal] = useState(false);
  const [dateRange, setDateRange] = useState(null);
  const tertiarySectionRef = useRef();
  const [patientTimelineCard, setPatientTimelineCard] = useState({
    documentType: null,
    documentId: null
  });
  const [showModal, setShowModal] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);

  useEffect(() => {
    if (
      props.abdmPatients.selectedPatient &&
      isValidObject(props.abdmPatients.searchResults)
    ) {
      setSelectedPatient(
        props.abdmPatients.searchResults[props.abdmPatients.selectedPatient]
      );
    }
  }, [props.abdmPatients.selectedPatient, props.abdmPatients.searchResults]);

  useEffect(() => {
    setShowModal(false);
  }, [props.navigation]);

  // check if consent request is send for every 5 sec
  useEffect(() => {
    const timeOutSubscriber = setInterval(() => {
      if (
        selectedPatient?.healthId &&
        selectedPatient?.consentRequestStatus === "SENT" &&
        props.navigation.tertiary === "patientTimeline"
      ) {
        props.checkIfConsentRequestSentToAbdm({ removeLoading: true });
      }
    }, 5000);
    return () => {
      clearInterval(timeOutSubscriber);
    };
    // eslint-disable-next-line
  }, [selectedPatient]);

  // check if authorization is present for every 5 sec
  useEffect(() => {
    const timeOutSubscriber = setInterval(() => {
      if (
        selectedPatient?.healthId &&
        selectedPatient?.consentRequestStatus !== "GRANTED" &&
        props.navigation.tertiary === "uploadDocument"
      ) {
        props.checkIfPatientConnectedToAbdm({ removeLoading: true });
      }
    }, 5000);
    return () => {
      clearInterval(timeOutSubscriber);
    };
    // eslint-disable-next-line
  }, [selectedPatient, props.navigation.tertiary]);

  // check if data transfer is present for every 5 sec
  useEffect(() => {
    const timeOutSubscriber = setInterval(() => {
      if (
        (selectedPatient?.healthId &&
          props.navigation.tertiary === "patientTimeline" &&
          !showDocumentPreview &&
          isValidObject(
            props.documents?.dataTransfer?.find(
              (data) => data.status === "REQUESTED"
            )
          )) ||
        (selectedPatient?.healthId &&
          selectedPatient?.consentRequestStatus === "GRANTED" &&
          props.navigation.tertiary === "patientTimeline" &&
          !isValidArray(props.documents?.dataTransfer))
      ) {
        props.getDataTransferInfo({ removeLoading: true });
      }
    }, 5000);
    return () => {
      clearInterval(timeOutSubscriber);
    };
    // eslint-disable-next-line
  }, [
    selectedPatient,
    props.documents.dataTransfer,
    props.navigation.tertiary,
    showDocumentPreview
  ]);

  // get data transfer and check for consent request an authorization
  useEffect(() => {
    if (props.abdmPatients.selectedPatient) {
      switch (props.navigation.tertiary) {
        case "patientTimeline":
          props.setDocuments();
          if (selectedPatient?.healthId) {
            props.getDataTransferInfo();
            props.checkIfConsentRequestSentToAbdm();
          }
          break;
        case "uploadDocument":
          if (selectedPatient?.healthId) {
            props.checkIfPatientConnectedToAbdm();
          }
          break;

        default:
          break;
      }
    }

    // eslint-disable-next-line
  }, [props.navigation.tertiary]);

  useEffect(() => {
    if (isPatientTimeLineFilterModal) {
      tertiarySectionRef.current.scrollTo(0, 0);
    }
  }, [isPatientTimeLineFilterModal]);

  useEffect(() => {
    if (props.navigation.tertiary === "editClinic") {
      props.getPublicProfileData();
    }
    // eslint-disable-next-line
  }, [props.navigation.tertiary, props.connection.selectedClinic]);

  useEffect(() => {
    if (props.navigation.tertiary === "consentHistory") {
      props.checkIfConsentRequestSentToAbdm();
    }
    // eslint-disable-next-line
  }, [props.navigation.tertiary]);

  // For checking tertiary component required data are present
  useEffect(() => {
    const selectedPatientData = () => {
      if (props.abdmPatients.selectedPatient) {
        return true;
      } else {
        return false;
      }
    };

    const selectedDoctorData = () => {
      if (props.doctorSearch.selectedDoctor) {
        return true;
      } else {
        return false;
      }
    };

    const selectedDocumentData = () => {
      if (props.documentSearch.selectedDocument) {
        return true;
      } else {
        return false;
      }
    };

    const accountInformationData = () => {
      if (props.navigation.primary === "accountInformation") {
        return true;
      } else {
        return false;
      }
    };

    const linkAbhaNumberData = () => {
      if (props.navigation.primary === "abha") {
        return true;
      } else {
        return false;
      }
    };

    if (
      selectedPatientData() ||
      selectedDoctorData() ||
      selectedDocumentData() ||
      accountInformationData() ||
      linkAbhaNumberData()
    ) {
      return setIsRequiredDataPresent(true);
    } else {
      return setIsRequiredDataPresent(false);
    }
  }, [
    props.abdmPatients.selectedPatient,
    props.doctorSearch.selectedDoctor,
    props.documentSearch.selectedDocument,
    props.navigation.primary
  ]);

  //get and set download urls
  useEffect(() => {
    if (
      props.navigation.tertiary === "patientTimeline" &&
      Array.isArray(props.abdmPatients?.documents?.data) &&
      props.abdmPatients?.documents?.data.length > 0
    ) {
      (async () => {
        let profilePictures = {
          patients: {},
          doctors: {},
          clinics: {}
        };
        for (const document of props.abdmPatients.documents.data) {
          if (document?.from?.type === "googleFit") continue;
          if (
            document?.from?.type &&
            document?.from?.id &&
            !profilePictures[`${document.from.type}s`].hasOwnProperty(
              document.from.id
            )
          ) {
            try {
              const downloadUrl = await getFileUrl(
                `gs://${bucketNames.nintoProfilePictures}/${document.from.type}s/${document.from.id}.png`
              );
              profilePictures[`${document.from.type}s`][document.from.id] =
                downloadUrl;
            } catch (error) {
              profilePictures[`${document.from.type}s`][document.from.id] =
                null;
            }
          }
        }
        setPatientTimelineDownloadURls((prevState) => {
          return { ...prevState, ...profilePictures };
        });
      })();
    }
  }, [props.navigation.tertiary, props.abdmPatients?.documents?.data]);

  return (
    <div className="height-90-percentage position-relative">
      {props.navigation.primary &&
        props.navigation.secondary &&
        props.navigation.tertiary &&
        isRequiredDataPresent && (
          <div
            ref={tertiarySectionRef}
            className={`${
              isPatientTimeLineFilterModal
                ? "overflow-hidden transform-translateZ-0"
                : ""
            } width-300px min-width-270px card-width background-white border-radius-right box-shadow-left-none card-box-border  font-size-medium inherit-parent-height minimum-height-250px hide-scroll-bar`}
          >
            {props.navigation.tertiary === "uploadDocument" && (
              <>
                {selectedPatient?.isPatientConnectedWithAbdm ||
                !selectedPatient?.healthId ? (
                  <UploadComponent
                    abdmPatients={props.abdmPatients}
                    documents={props.documents}
                    uploadDocument={(data) => props.uploadDocument(data)}
                  />
                ) : (
                  <div className="padding-larger inherit-parent-width inherit-parent-height">
                    <ConnectToPatient
                      showMoreOnclick={(data) => {
                        setShowModal(data);
                      }}
                      abdmPatients={props.abdmPatients}
                      selectedPatient={selectedPatient}
                      showViewMore={
                        selectedPatient?.kyc || selectedPatient?.phoneNumber
                          ? true
                          : false
                      }
                      abdm={props.abdm}
                      abdmQR={props.abdmQR}
                      OTPButtonClick={(event, data) => {
                        event.preventDefault();
                        props.sendOtpForLinking(data);
                      }}
                      onFormSubmit={(event, data) => {
                        event.preventDefault();
                        props.connectPatientByDemographics(data);
                      }}
                      onSubmitOtpLinkingForm={(event, data) => {
                        event.preventDefault();
                        props.confirmOtpForLinking(data);
                      }}
                      clearToken={(data) => {
                        props.clearQRaccessToken();
                      }}
                      requireAuthorization={true}
                    />
                  </div>
                )}
              </>
            )}

            {props.navigation.tertiary === "pinPatientToDoctor" &&
              (typeof selectedPatient?.healthId === "string" ||
                selectedPatient?.isConnectedWithNinto === true) && (
                <PinPatientToDoctor
                  doctorSearch={props.doctorSearch}
                  abdmPatients={props.abdmPatients}
                  getSearchDoctorData={(data, type) => {
                    props.getSearchDoctorData(data, type);
                  }}
                  addSelectedDoctor={(doctorId) => {
                    props.addSelectedDoctor(doctorId);
                  }}
                  removeDoctorSearchData={() => {
                    props.removeDoctorSearchData();
                  }}
                  setSortedPatients={(data) => {
                    props.setSortedPatients(data);
                  }}
                  unPinPatient={(patientId) => {
                    props.unPinPatient(patientId);
                  }}
                  pinPatient={() => {
                    props.pinPatient();
                  }}
                />
              )}

            {props.navigation.tertiary === "patientTimeline" && (
              <>
                <ConditionalRender condition={props.abdm.loading}>
                  <Suspense />
                </ConditionalRender>
                <ConditionalRender condition={!props.abdm.loading}>
                  {typeof selectedPatient?.healthId === "string" &&
                  selectedPatient?.consentRequestStatus === "GRANTED" ? (
                    <PatientTimeline
                      patientAccessOnClick={() => {
                        setShowDateFormModal(true);
                      }}
                      filterModalState={(filterModalState) => {
                        if (isPatientTimeLineFilterModal === filterModalState) {
                          return;
                        }
                        setIsPatientTimeLineFilterModal(filterModalState);
                      }}
                      retryButtonOnClick={() => {
                        props.retryDataTransfer();
                      }}
                      retryButtonLoading={props.documents.loading}
                      transferStatus={
                        props.documents?.dataTransfer &&
                        isValidObject(
                          props.documents?.dataTransfer?.find(
                            (data) => data.status === "REQUESTED"
                          )
                        )
                          ? true
                          : false
                      }
                      lastUpdatedStatus={
                        props.documents?.dataTransfer &&
                        isValidObject(
                          props.documents?.dataTransfer?.find(
                            (data) => data.status === "REQUESTED"
                          )
                        )
                          ? false
                          : true
                      }
                      consentsEnds={true}
                      showRetryButton={
                        isValidObject(
                          props.documents?.dataTransfer?.find(
                            (data) => data.status === "REQUESTED"
                          )
                        ) &&
                        Object.values(props.documents.dataTransfer).every(
                          (element) =>
                            +new Date() - element.timestamp > 1000 * 60 * 10
                        )
                          ? true
                          : false
                      }
                      profilePictureSize="small"
                      documentFilterMenu={[
                        "prescription",
                        "report",
                        "vital",
                        // "insurance",
                        "admission",
                        "vaccine"
                      ]}
                      hideUploadButton={true}
                      filterState={props.documents?.filters}
                      dataTransfer={props.documents?.dataTransfer}
                      documents={props.documents.data}
                      documentCardOnClick={(
                        documentType,
                        documentId,
                        consentId
                      ) => {
                        if (["vitals", "fitness"].includes(documentType)) {
                          return;
                        }
                        if (consentId) {
                          props.retryDataTransfer({ consentId: consentId });
                        } else {
                          setPatientTimelineCard({
                            documentId: documentId,
                            documentType: documentType
                          });
                          setShowDocumentPreview(true);
                        }
                      }}
                      onFilterClick={(type, filterValue) => {
                        props.addDocumentFilter(type, filterValue);
                      }}
                      onClearFilterClick={() => {
                        props.removeDocumentFilter("doctorAndClinic");
                      }}
                      downloads={patientTimelineDownloadURls}
                      getAudioUrl={async (documentId) => {
                        if (
                          !Array.isArray(props.abdmPatients.documents.data) ||
                          typeof patientTimelineDownloadURls?.symptoms?.[
                            documentId
                          ] === "string"
                        )
                          return;
                        const indexOfDocument =
                          props.abdmPatients.documents.data.findIndex(
                            (doc) => doc.documentId === documentId
                          );
                        if (indexOfDocument < 0) return;
                        try {
                          const downloadUrl = await getFileUrl(
                            props.abdmPatients.documents.data[indexOfDocument]
                              ?.url?.audio
                          );
                          setPatientTimelineDownloadURls((prevState) => {
                            return {
                              ...prevState,
                              symptoms: {
                                ...prevState.symptoms,
                                [documentId]: {
                                  downloadUrl: downloadUrl
                                }
                              }
                            };
                          });
                        } catch (error) {
                          setPatientTimelineDownloadURls((prevState) => {
                            return {
                              ...prevState,
                              symptoms: {
                                ...prevState.symptoms,
                                [documentId]: {
                                  downloadUrl: ""
                                }
                              }
                            };
                          });
                        }
                      }}
                      profilePictures={patientTimelineDownloadURls}
                      viewerType={"clinic"}
                      loading={props.abdmPatients.loading}
                    />
                  ) : (
                    <SubscriptionRequired
                      subscribeClick={() => {
                        props.sendSubscriptionRequest();
                      }}
                      consentClick={() => {
                        props.sendConsentRequest();
                      }}
                      // isConsentPresent={
                      //   selectedSubscription &&
                      //   Object.keys(selectedSubscription).includes(
                      //     "consentArtifacts"
                      //   )
                      // }
                      loading={props.abdm.loading}
                      status={selectedPatient?.consentRequestStatus}
                    />
                  )}
                </ConditionalRender>
              </>
            )}

            {props.navigation.tertiary === "addVitals" && (
              <AddVitals
                addVitals={(data) => {
                  props.addVitals(data);
                }}
              />
            )}

            {props.navigation.tertiary === "personnel" &&
              props.connection.data[props.connection.selectedClinic]
                .userType !== "staff" && (
                <Personnel connection={props.connection} />
              )}

            {props.navigation.tertiary === "editProfile" && (
              <EditPersonalProfile
                data={props.profile?.data[Object.keys(props.profile.data)[0]]}
                profilePicture={props.profile?.data.profilePicture}
                loading={props.profile.loading}
                editPersonalProfile={(data) => {
                  props.editPersonalProfileData(data);
                }}
                cancelButtonClick={() => props.showTertiary(null)}
              />
            )}

            {props.navigation.tertiary === "editClinic" && (
              <EditProfile
                data={
                  props.profile?.data[Object.keys(props.profile.data)[0]].public
                }
                loading={props.profile.loading}
                updateProfile={(data) => {
                  props.updatePublicProfileData(data);
                }}
                cancelButtonClick={() => props.showTertiary(null)}
              />
            )}

            {props.navigation.tertiary === "escalationContact" && (
              <EditEscalation
                profile={props.profile}
                cancelButtonClick={() => props.showTertiary(null)}
              />
            )}

            {props.navigation.tertiary === "verification" && (
              <EditVerification
                profile={props.profile}
                cancelButtonClick={() => props.showTertiary(null)}
              />
            )}

            {props.navigation.tertiary === "addForm" && (
              <AddForm
                forms={props.forms}
                status={props.status}
                createNewOnClick={() => {
                  props.showPrimary("forms");
                  props.showSecondary("createEditPreviewForm");
                }}
                backButtonOnclick={() => {
                  props.showTertiary(null);
                }}
                uploadFormOnClick={(selectedDocumentId) => {
                  props.uploadForm(selectedDocumentId);
                }}
                getForms={() => {
                  props.getForms();
                }}
              />
            )}

            {showDocumentPreview && (
              <PatientTimelineCardPreview
                remoteConfig={props.remoteConfig}
                show={showDocumentPreview}
                documentId={patientTimelineCard.documentId}
                documentType={patientTimelineCard.documentType}
                onClickClose={() => {
                  setShowDocumentPreview(false);
                }}
                documents={props.documents}
              />
            )}

            {props.navigation.tertiary === "linkAbhaAddress" && (
              <LinkORUnlinkAbhaAddress />
            )}

            {props.navigation.tertiary === "showAbhaCard" && (
              <ShowAbhaCard
                getPatientQrDetails={() => {
                  props.getPatientQrDetails();
                }}
                loading={props.abha.loading}
                abhaCardData={props.abha?.ABHACard}
              />
            )}

            {props.navigation.tertiary === "createABHAAddress" && (
              <CreateABHAAddress
                createHealthId={(data) => {
                  props.createAbhaAddress(data);
                }}
                loading={props.abha.loading}
              />
            )}

            {props.navigation.tertiary === "consentHistory" && (
              <ConsentHistory
                abdm={props.abdm}
                abdmPatients={props.abdmPatients}
              />
            )}
          </div>
        )}

      <Modal
        onClose={() => {
          setShowDateFormModal(!showDateFormModal);
          setDateRange(null);
        }}
        canIgnore={true}
        show={showDateFormModal}
        background="false"
        boxShadow="false"
        borderRadius="false"
        width={"width-30-percent"}
        // background="background-transparent"
        // height="height-fit-to-content"
      >
        <form
          onSubmit={(event) => {
            event.preventDefault();
            if (dateRange) {
              if (+new Date(subMonths(new Date(), 6)) < dateRange) {
                setErrorStatus({
                  code: "custom",
                  message: "Please select a date before 6 months"
                });
              } else {
                props.sendConsentRequest({
                  dataRange: {
                    to: new Date(+new Date() + 86400000).toISOString(),
                    from: new Date(dateRange).toISOString()
                  }
                });
                setShowDateFormModal(!showDateFormModal);
              }
            }
          }}
        >
          <div
            className={`${"border-radius-default"} background-white inherit-parent-width padding-larger font-family-gilroy-medium`}
          >
            <InputBox
              type="date"
              label="dating From"
              name="datingFrom"
              notes={"Minimum 6 months needed"}
              required
              onChange={(event) => {
                setDateRange(+new Date(new Date(event.target.value)));
              }}
            />

            <div className="flex-justify-content-center margin-top-larger">
              <div className="inherit-parent-width  padding-right-default flex-justify-content-center font-family-gilroy-bold">
                <Button
                  type="submit"
                  boxShadow={false}
                  className="max-width-150px max-height-50px"
                  data-cy="Send-request-button"
                  text="Send request"
                  loading={props.abdmPatients.loading}
                  disabled={dateRange === null ? true : false}
                />
              </div>
              <div className="inherit-parent-width padding-left-default flex-justify-content-center font-family-gilroy-medium ">
                <Button
                  type="submit"
                  boxShadow={false}
                  className="max-width-150px max-height-50px"
                  text="Cancel"
                  variant="secondary"
                  onClick={() => {
                    setShowDateFormModal(!showDateFormModal);
                    setDateRange(null);
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      </Modal>

      <Modal
        show={showModal}
        canIgnore={true}
        onClose={() => {
          setShowModal(!showModal);
        }}
        position={"position-absolute bottom-0 left-0 right-0"}
        modalBackgroundClassName="position-absolute top-0 bottom-0 right-0 left-0 inherit-parent-height inherit-parent-width"
        boxShadow="false"
        height="inherit-parent-hight"
      >
        <div
          className={`background-white padding-larger box-shadow-default font-family-gilroy-regular font-color-secondary inherit-parent-height`}
        >
          <div className="overflow-scroll hide-scroll-bar">
            <div className="display-flex padding-top-large flex-align-items-center">
              <RoundedProfilePicture
                size="small"
                // src={
                //   props.connection.brands[
                //     props.connection.currentConnection &&
                //       props.connection.data[props.connection.currentConnection]
                //         .brandId
                //   ].downloadURL
                // }
              />
              <div className="padding-left-large">
                <div className="font-size-large">{selectedPatient?.name}</div>
                <div className="font-size-medium">{`${
                  selectedPatient?.gender ? selectedPatient?.gender : ""
                } , ${
                  selectedPatient?.dob?.year ? selectedPatient?.dob?.year : ""
                } `}</div>
              </div>
            </div>
            {selectedPatient?.phoneNumber && (
              <div className="display-flex padding-top-large">
                <InputBox
                  className="inherit-parent-width border-radius-default"
                  name="phoneNumber"
                  size="half"
                  label="Phone Number"
                  removeResponsive={true}
                  disabled={true}
                  type="text"
                  autoComplete="on"
                  value={selectedPatient?.phoneNumber}
                  required
                />
              </div>
            )}

            {selectedPatient?.kyc?.address && (
              <div className="display-flex">
                <TextArea
                  className="inherit-parent-width border-radius-default padding-top-large"
                  name="address"
                  size="half"
                  label="Address"
                  removeResponsive={true}
                  disabled={true}
                  type="text"
                  autoComplete="on"
                  value={selectedPatient?.kyc?.address}
                  required
                />
              </div>
            )}

            {selectedPatient?.kyc?.email && (
              <InputBox
                className="inherit-parent-width border-radius-default padding-right-default"
                name="email"
                size="small"
                label="Email"
                removeResponsive={true}
                disabled={true}
                type="text"
                autoComplete="on"
                value={selectedPatient?.kyc?.email}
                required
              />
            )}

            {selectedPatient?.kyc?.districtName && (
              <div className="display-flex padding-top-large">
                <InputBox
                  className="inherit-parent-width border-radius-default"
                  name="city"
                  size="small"
                  label="City"
                  removeResponsive={true}
                  disabled={true}
                  type="text"
                  value={selectedPatient?.kyc?.districtName}
                  required
                />
              </div>
            )}

            <div className="display-flex">
              {selectedPatient?.kyc?.stateName && (
                <InputBox
                  className="inherit-parent-width border-radius-default padding-right-default"
                  name="state"
                  size="small"
                  label="State"
                  removeResponsive={true}
                  disabled={true}
                  type="text"
                  autoComplete="on"
                  value={selectedPatient?.kyc?.stateName}
                  required
                />
              )}
              {selectedPatient?.kyc?.pincode && (
                <InputBox
                  className="inherit-parent-width border-radius-default padding-left-default"
                  name="pincode"
                  size="small"
                  label="Pincode"
                  removeResponsive={true}
                  disabled={true}
                  type="text"
                  autoComplete="on"
                  value={selectedPatient?.kyc?.pincode}
                  required
                />
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    profile: state.profile,
    connection: state.connection,
    navigation: state.navigation,
    documents: state.documents,
    requests: state.requests,
    doctorSearch: state.doctorSearch,
    documentSearch: state.documentSearch,
    abdm: state.abdm,
    abha: state.abha,
    abdmQR: state.abdmQR,
    abdmPatients: state.abdmPatients,
    forms: state.forms,
    status: state.status
  };
};

const mapDispatchToProps = function () {
  return {
    showPrimary: (data) => showPrimary(data),
    showSecondary: (data) => showSecondary(data),
    showTertiary: (data) => showTertiary(data),
    getPatientQrDetails: () => getPatientQrDetails(),
    createAbhaAddress: (data) => createAbhaAddress(data),
    getPublicProfileData: () => getPublicProfileData(),
    updatePublicProfileData: (data) => updatePublicProfileData(data),
    editPersonalProfileData: (data) => editPersonalProfileData(data),
    getDataTransferInfo: (data) => getDataTransferInfo(data),
    retryDataTransfer: (data) => retryDataTransfer(data),
    addDocumentFilter: (type, filterValue) =>
      addDocumentFilter(type, filterValue),
    removeDocumentFilter: (clearFilter) => removeDocumentFilter(clearFilter),
    setDocuments: () => setDocuments(),
    sendSubscriptionRequest: () => sendSubscriptionRequest(),
    sendConsentRequest: (data) => sendConsentRequest(data),
    checkIfConsentRequestSentToAbdm: (data) =>
      checkIfConsentRequestSentToAbdm(data),
    checkIfPatientConnectedToAbdm: (data) =>
      checkIfPatientConnectedToAbdm(data),
    uploadDocument: (data) => uploadDocument(data),
    clearQRaccessToken: (data) => clearQRaccessToken(data),
    sendOtpForLinking: (data) => sendOtpForLinking(data),
    connectPatientByDemographics: (data) => connectPatientByDemographics(data),
    confirmOtpForLinking: (data) => confirmOtpForLinking(data),
    addVitals: (vitalsData) => addVitals(vitalsData),
    getForms: () => getForms(),
    removeCurrentForm: () => removeCurrentForm(),
    uploadForm: (documentId) => uploadForm(documentId),
    getSearchDoctorData: (phoneNumber, searchType) =>
      getSearchDoctorData(phoneNumber, searchType),
    addSelectedDoctor: (doctorId) => addSelectedDoctor(doctorId),
    removeDoctorSearchData: () => removeDoctorSearchData(),
    setSortedPatients: (sortedOrder) => setSortedPatients(sortedOrder),
    unPinPatient: (pid) => unPinPatient(pid),
    pinPatient: () => pinPatient()
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tertiary);

const SubscriptionRequired = (props) => {
  return (
    <section className=" padding-larger inherit-parent-height inherit-parent-width display-flex-direction-column flex-center-children-horizontally flex-center-children-vertically">
      <div>
        <img className=" height-width-32px" src={bellIcon} alt="bellIcon" />
      </div>
      <div className=" padding-top-larger font-family-gilroy-medium font-size-large font-color-secondary">
        {!props.status ? "Patient’s consent needed" : ""}
      </div>
      <div className="padding-top-larger font-family-gilroy-medium text-align-center font-size-large font-color-secondary">
        {props.status === "SENT"
          ? "Timeline can be accessed once the patient as approved the request."
          : ""}
        {!props.status
          ? "Send a consent request to the patient to access and manage their health records & documents."
          : ""}
      </div>
      <div className="padding-top-larger inherit-parent-width text-align-center">
        <Button
          boxShadow={false}
          className="max-height-50px font-size-large"
          variant="primaryBorder"
          text={`${
            props.status === "SENT" ? "Resend Request" : "Send Request"
          }`}
          data-cy={`${
            props.status === "SENT"
              ? "resend-consent-request"
              : "send-consent-request"
          }`}
          loading={props.loading}
          onClick={props.consentClick}
        />
      </div>
    </section>
  );
};

function Suspense() {
  return (
    <article className="padding-larger">
      <div className="inherit-parent-width">
        {[...Array(6).keys()].map((index) => (
          <div
            className="border-bottom-2px flex-center-children-vertically"
            key={index}
          >
            <div className="shimmer inherit-parent-width padding-small margin-vertical-default border-radius-default">
              <div className="padding-default margin-default border-radius-default" />
            </div>
          </div>
        ))}
      </div>
    </article>
  );
}
