const doctorSearchReducer = (
  state = {
    loading: false,
    searchedDoctorPhoneNumber: null,
    selectedDoctor: null,
    data: null
  },
  action
) => {
  switch (action.type) {
    case "SET_DOCTOR_SEARCH_LOADING":
      return {
        ...state,
        loading: action.payload.loading
      };

    case "SET_SEARCHED_DOCTOR_PHONE_NUMBER":
      return {
        ...state,
        searchedDoctorPhoneNumber: action.payload.phoneNumber
      };

    case "SET_SELECTED_DOCTOR":
      return {
        ...state,
        selectedDoctor: action.payload.selectedDoctor
      };

    case "REMOVE_SELECTED_DOCTOR": {
      return {
        ...state,
        selectedDoctor: null
      };
    }

    case "SET_DOCTOR_SEARCH_DATA":
      return {
        ...state,
        data: action.payload.data
      };

    case "CLEAR_DOCTOR_SEARCH_DATA":
      return {
        ...state,
        searchedDoctorPhoneNumber: null,
        selectedDoctor: null,
        data: null
      };

    case "RESET":
      return {
        loading: false,
        data: null,
        searchedDoctorPhoneNumber: null,
        selectedDoctor: null
      };

    default:
      return state;
  }
};

export default doctorSearchReducer;
