const notificationsReducer = (
  state = {
    loading: false,
    paginationLoading: false,
    data: null,
    pagination: null,
    selectedNotification: null
  },
  action
) => {
  switch (action.type) {
    case "SET_NOTIFICATION_LOADING":
      return {
        ...state,
        loading: action.payload.loading
      };
    case "SET_NOTIFICATION_PAGINATION_LOADING":
      return {
        ...state,
        paginationLoading: action.payload.loading
      };
    case "SET_NOTIFICATION_DATA":
      return {
        ...state,
        data: action.payload.data
      };
    case "SET_NOTIFICATION_PAGINATION":
      return {
        ...state,
        pagination: action.payload.data
      };

    case "SET_SELECTED_NOTIFICATION":
      return {
        ...state,
        selectedNotification: action.payload.data
      };
    case "RESET":
      return {
        loading: false,
        paginationLoading: false,
        data: null,
        selectedNotification: null
      };
    default:
      return state;
  }
};
export default notificationsReducer;
