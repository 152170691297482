import { all, put, takeEvery } from "@redux-saga/core/effects";
import store from "../store/store";
import {
  sendAadhaarOtp,
  verifyAadhaarOtp,
  sendMobileOtp,
  verifyMobileOtp,
  createAbhaNumber,
  abhaSearch,
  initializeAbha,
  confirmAbha,
  linkOrUnlinkAbhaAddressFromAbhaNumber,
  getPatientQrDetails,
  createABHAAddressInit,
  createABHAAddressConfirm
} from "../../services/api";
import { throwError } from "../../services/error";
import { setErrorStatus, setSuccessStatus } from "../status/actions";
import { resetStatus, setLoginStatus } from "./actions";

export const abhaActionTypes = {
  CREATE_ABHA_ADDRESS: "CREATE_ABHA_ADDRESS",
  CLEAR_CREATION_DATA: "CLEAR_CREATION_DATA",
  SEND_AADHAAR_OTP: "SEND_AADHAAR_OTP",
  SET_LOGIN_STATUS: "SET_LOGIN_STATUS",
  GET_PATIENT_QR: "GET_PATIENT_QR",
  VERIFY_AADHAAR_OTP: "VERIFY_AADHAAR_OTP",
  SEND_MOBILE_OTP: "SEND_MOBILE_OTP",
  CREATE_ABHA_NUMBER: "CREATE_ABHA_NUMBER",
  SEND_ABHA_OTP: "SEND_ABHA_OTP",
  VERIFY_ABHA_OTP_AND_LOGIN: "VERIFY_ABHA_OTP_AND_LOGIN",
  CONFIRM_LINK_OR_UNLINK_ABHA_ADDRESS: "CONFIRM_LINK_OR_UNLINK_ABHA_ADDRESS",
  RESET_STATUS: "RESET_STATUS"
};

function* sendAadhaarOtpWorker(action) {
  try {
    yield resetStatus();
    yield setABHALoading(true);
    if (action.payload.aadhaarNumber) {
      const accessToken = store.getState().auth.data.accessToken;
      const clinicId = store.getState().connection.currentConnection;

      const sentAadhaarOtpResponse = yield sendAadhaarOtp(
        accessToken,
        action.payload.aadhaarNumber,
        clinicId
      );
      if (
        sentAadhaarOtpResponse.success === true &&
        typeof sentAadhaarOtpResponse.data.transactionId === "string"
      ) {
        yield put({
          type: "SET_AADHAAR_OTP",
          payload: {
            transactionId: sentAadhaarOtpResponse.data.transactionId,
            sentAadhaarOtp: sentAadhaarOtpResponse.success
          }
        });
      }
    }
    yield setABHALoading(false);
  } catch (error) {
    if (
      error.message === "Request is invalid. Please enter the correct data."
    ) {
      setErrorStatus({
        code: "custom",
        message: "Please provide correct Aadhaar Number"
      });
    } else {
      setErrorStatus(error);
    }

    yield setABHALoading(false);
  }
}

function* verifyAadhaarOtpWorker(action) {
  try {
    yield setABHALoading(true);
    const accessToken = store.getState().auth.data.accessToken;
    const clinicId = store.getState().connection.currentConnection;

    const verifyAadhaarOtpResponse = yield verifyAadhaarOtp(
      accessToken,
      action.payload.otp,
      action.payload.transactionId,
      clinicId
    );

    if (verifyAadhaarOtpResponse.success === true) {
      yield put({
        type: "SET_AADHAAR_VERIFIED",
        payload: {
          aadhaarVerified: verifyAadhaarOtpResponse.success
        }
      });
    }

    yield setABHALoading(false);
  } catch (error) {
    if (
      error.message ===
      "Unable to process the current request due to incorrect data entered."
    ) {
      setErrorStatus({
        code: "custom",
        message: "Incorrect OTP"
      });
    } else {
      setErrorStatus(error);
    }
    yield setABHALoading(false);
  }
}

function* sendMobileOtpWorker(action) {
  try {
    yield setABHALoading(true);
    const accessToken = store.getState().auth.data.accessToken;
    const clinicId = store.getState().connection.currentConnection;

    const mobileNumber = action.payload.mobileNumber.substr(3);
    const sentMobileOtpResponse = yield sendMobileOtp(
      accessToken,
      mobileNumber,
      action.payload.transactionId,
      clinicId
    );
    if (sentMobileOtpResponse.success === true) {
      yield put({
        type: "SET_SENT_MOBILE_OTP",
        payload: {
          sentMobileOtp: sentMobileOtpResponse.success
        }
      });
    }

    yield setABHALoading(false);
  } catch (error) {
    if (
      error.message === "Request is invalid. Please enter the correct data."
    ) {
      setErrorStatus({
        code: "custom",
        message: "Incorrect OTP"
      });
    } else {
      setErrorStatus(error);
    }
    yield setABHALoading(false);
  }
}

function* createAbhaNumberWorker(action) {
  try {
    yield setABHALoading(true);

    let verifyMobileOtpResponse = false;
    const accessToken = store.getState().auth.data.accessToken;
    const clinicId = store.getState().connection.currentConnection;

    if (store.getState().abha.mobileVerified === false) {
      verifyMobileOtpResponse = yield verifyMobileOtp(
        accessToken,
        action.payload.otp,
        action.payload.transactionId,
        clinicId
      );
    }

    if (verifyMobileOtpResponse.success === true) {
      yield put({
        type: "SET_MOBILE_VERIFIED",
        payload: {
          mobileVerified: verifyMobileOtpResponse.success
        }
      });
      const createAbhaNumberResponse = yield createAbhaNumber(
        accessToken,
        action.payload.transactionId,
        action.payload.healthId,
        clinicId
      );
      if (createAbhaNumberResponse.success === true) {
        yield put({
          type: "SET_ABHA_NUMBER_DATA",
          payload: {
            abhaNumberCreated: createAbhaNumberResponse.data
          }
        });
        yield put({
          type: "SET_ABHA_NUMBER_ACCESS_TOKEN",
          payload: {
            accessToken: createAbhaNumberResponse.token
          }
        });

        setSuccessStatus("ABHA number successfully Created");
        yield setLoginStatus(true);
      }
    }

    yield setABHALoading(false);
  } catch (error) {
    if (
      error.message ===
      "Unable to process the current request due to incorrect data entered."
    ) {
      setErrorStatus({
        code: "custom",
        message: "Incorrect OTP"
      });
    } else {
      setErrorStatus(error);
    }
    yield setABHALoading(false);
  }
}

function* sendAbhaOtpWorker(action) {
  try {
    yield setABHALoading(true);

    let sentAbhaOtpResponse;
    let searchResponse;
    const mode = action.payload.loginData.useAadhaarPhoneNumber
      ? "AADHAAR_OTP"
      : "MOBILE_OTP";
    const accessToken = store.getState().auth.data.accessToken;
    searchResponse = yield abhaSearch(
      accessToken,
      action.payload.loginData.loginValue,
      store.getState().connection.currentConnection
    );

    if (searchResponse.success) {
      if (
        !searchResponse.data.healthId &&
        !searchResponse.data.healthIdNumber
      ) {
        throw throwError("custom", "HealthId or Abha Number is invalid");
      }
      sentAbhaOtpResponse = yield initializeAbha(
        accessToken,
        action.payload.loginData.loginValue,
        mode,
        store.getState().connection.currentConnection
      );
    } else {
      throw throwError("custom", "Something went wrong");
    }

    if (sentAbhaOtpResponse.success === true) {
      yield put({
        type: "SET_SENT_ABHA_OTP",
        payload: {
          sentLoginOtp: sentAbhaOtpResponse.success,
          transactionId: sentAbhaOtpResponse.data.transactionId
        }
      });
    }

    yield setABHALoading(false);
  } catch (error) {
    if (
      error.message ===
      "Error from ABDM: Please wait for 30 minutes before sending another OTP request."
    ) {
      setErrorStatus({
        code: "custom",
        message: "Please wait for 30 mins before sending another OTP request."
      });
    } else if (
      error.message ===
      "Error from ABDM: No user account can be found with the ABHA Number/Enrolment Number provided. "
    ) {
      setErrorStatus({
        code: "custom",
        message: "No user found"
      });
    } else {
      setErrorStatus(error);
    }

    yield setABHALoading(false);
  }
}

function* verifyAbhaOtpAndLoginWorker(action) {
  try {
    yield setABHALoading(true);

    let verifyAbhaLoginOtpResponse;

    const mode = action.payload.loginData.useAadhaarPhoneNumber
      ? "AADHAAR_OTP"
      : "MOBILE_OTP";

    const accessToken = store.getState().auth.data.accessToken;

    const transactionId = store.getState().abha.transactionId;

    if (/[a-zA-Z]/.test(action.payload.loginData.loginValue)) {
      yield put({
        type: "SET_HEALTH_ID_LOGIN_STATUS",
        payload: {
          data: true
        }
      });
    } else {
      yield put({
        type: "SET_HEALTH_ID_LOGIN_STATUS",
        payload: {
          data: false
        }
      });
    }

    if (transactionId) {
      verifyAbhaLoginOtpResponse = yield confirmAbha(
        accessToken,
        transactionId,
        mode,
        action.payload.otp,
        store.getState().connection.currentConnection
      );
    }

    if (verifyAbhaLoginOtpResponse.success === true) {
      yield put({
        type: "SET_VERIFY_ABHA_OTP",
        payload: {
          verifyLoginOtp: verifyAbhaLoginOtpResponse.success
        }
      });
      yield put({
        type: "SET_ABHA_NUMBER_DATA",
        payload: {
          abhaNumberCreated: verifyAbhaLoginOtpResponse.data.data
        }
      });
      yield put({
        type: "SET_ABHA_NUMBER_ACCESS_TOKEN",
        payload: {
          accessToken: verifyAbhaLoginOtpResponse.data.token
        }
      });
      setSuccessStatus("Login Successfully");
    }

    yield setABHALoading(false);
  } catch (error) {
    if (
      error.message ===
      "Error from ABDM: The OTP that you have entered is incorrect. Please try again."
    ) {
      setErrorStatus({
        code: "custom",
        message: "Incorrect OTP"
      });
    } else {
      setErrorStatus(error);
    }

    yield setABHALoading(false);
  }
}

function* confirmLinkOrUnlinkAbhaAddressWorker(action) {
  try {
    yield setABHALoading(true);

    const result = yield linkOrUnlinkAbhaAddressFromAbhaNumber(
      store.getState().auth.data.accessToken,
      store.getState().abha.accessToken,
      action.payload.healthId,
      action.payload.type ? "link" : "delink"
    );

    if (result.success) {
      action.payload.linkAbhaAddress
        ? yield setSuccessStatus("ABHA address linked successfully")
        : yield setSuccessStatus("ABHA address unlinked successfully");
    }

    yield setABHALoading(false);
  } catch (error) {
    yield setABHALoading(false);
  }
}

function* createABHAAddressWorker(action) {
  try {
    yield setABHALoading(true);

    const result = yield createABHAAddressInit(
      store.getState().auth.data.accessToken,
      store.getState().abha.accessToken,
      store.getState().connection.currentConnection
    );

    if (result.success) {
      const response = yield createABHAAddressConfirm(
        store.getState().auth.data.accessToken,
        store.getState().abha.accessToken,
        store.getState().connection.currentConnection,
        result.data.transactionId,
        action.payload.abhaAddress
      );

      if (response.success) {
        setSuccessStatus("ABHA Address created successfully");
      }
    }

    yield setABHALoading(false);
  } catch (error) {
    yield setABHALoading(false);
  }
}

function* getPatientsQRWorker(action) {
  try {
    yield setABHALoading(true);

    const result = yield getPatientQrDetails(
      store.getState().auth.data.accessToken,
      store.getState().abha.accessToken,
      store.getState().connection.currentConnection
    );

    if (result.success) {
      yield put({
        type: "SET_ABHA_CARD",
        payload: {
          data: `data:image/png;base64,${result.data}`
        }
      });
    }

    yield setABHALoading(false);
  } catch (error) {
    yield setABHALoading(false);
  }
}

function* resetStatusWorker() {
  yield put({
    type: "RESET_ABHA_CREATION"
  });
}

function* setLoginStatusWorker(action) {
  yield put({
    type: "SET_VERIFY_ABHA_OTP",
    payload: {
      verifyLoginOtp: action.payload.data
    }
  });
  if (action.payload.data === false) {
    yield put({
      type: "SET_AADHAAR_VERIFIED",
      payload: {
        aadhaarVerified: false
      }
    });

    yield put({
      type: "SET_AADHAAR_OTP",
      payload: {
        transactionId: null,
        sentAadhaarOtp: false
      }
    });
  }
}

function* clearCreationDataWorker() {
  yield put({
    type: "SET_AADHAAR_OTP",
    payload: {
      transactionId: null,
      sentAadhaarOtp: false
    }
  });
}

export function* abhaWatcher() {
  yield all([
    takeEvery("SEND_AADHAAR_OTP", sendAadhaarOtpWorker),
    takeEvery("CLEAR_CREATION_DATA", clearCreationDataWorker),
    takeEvery("SET_LOGIN_STATUS", setLoginStatusWorker),
    takeEvery("GET_PATIENT_QR", getPatientsQRWorker),
    takeEvery("VERIFY_AADHAAR_OTP", verifyAadhaarOtpWorker),
    takeEvery("SEND_MOBILE_OTP", sendMobileOtpWorker),
    takeEvery("CREATE_ABHA_NUMBER", createAbhaNumberWorker),
    takeEvery("SEND_ABHA_OTP", sendAbhaOtpWorker),
    takeEvery("RESET_STATUS", resetStatusWorker),
    takeEvery("CREATE_ABHA_ADDRESS", createABHAAddressWorker),
    takeEvery(
      "CONFIRM_LINK_OR_UNLINK_ABHA_ADDRESS",
      confirmLinkOrUnlinkAbhaAddressWorker
    ),
    takeEvery("VERIFY_ABHA_OTP_AND_LOGIN", verifyAbhaOtpAndLoginWorker)
  ]);
}

function* setABHALoading(loadingState) {
  yield put({
    type: "SET_ABHA_LOADING",
    payload: {
      loading: loadingState
    }
  });
}
