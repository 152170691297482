import React from "react";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import { useState } from "react";
import Button from "../../front-end-global-components/components/Button/Button";

export default function LinkORUnlinkAbhaAddress() {
  const [abhaAddress, setAbhaAddress] = useState(null);

  return (
    <div className="padding-larger">
      <div className="font-family-gilroy-medium text-align-center font-color-secondary font-size-medium padding-bottom-larger">
        LINK ABHA ADDRESS
      </div>
      <form
        onChange={(event) => {
          setAbhaAddress(event.target.value);
        }}
      >
        <InputBox
          className="inherit-parent-width max-width-300px border-radius-default padding-bottom-large"
          name="abhaAddress"
          removeResponsive={true}
          data-cy="patient-number-field"
          label="ABHA Address"
          type="text"
          autoFocus={"on"}
          value={abhaAddress}
          required
        />
        <div className="margin-bottom-larger">
          <Button
            className="inherit-parent-width max-width-300px border-radius-default"
            data-cy="demographic-add-button"
            boxShadow={false}
            text="Link"
            type="submit"
            disabled={!abhaAddress}
          />
        </div>
      </form>
    </div>
  );
}
