export default function Abha(props) {
  return (
    <div
      className="account-menu padding-top-larger padding-smaller list-style-none text-align-left"
      style={{ overflow: "hidden" }}
    >
      <TileCard
        isSelected={props.navigation.secondary === "createAbhaNumber"}
        title="Create ABHA number"
        subTitle="Create unique ABHA number for your patient"
        onClick={props.createAbhaNumberOnClick}
      />

      <TileCard
        isSelected={props.navigation.secondary === "abhaLogin"}
        title="Manage ABHA"
        subTitle="Onboard patients to Ninto"
        onClick={props.abhaLoginOnClick}
      />
    </div>
  );
}

function TileCard({ isSelected, onClick, title, subTitle }) {
  return (
    <div
      className={`${
        isSelected
          ? "hover-effect cursor-pointer margin-bottom-larger"
          : "box-shadow-default cursor-pointer margin-bottom-larger"
      }`}
      data-cy="Add-report"
      onClick={onClick}
    >
      {title} <br />
      <span className="font-color-secondary font-family-gilroy-medium font-size-small">
        {subTitle}
      </span>
    </div>
  );
}
