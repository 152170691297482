import store from "../store/store";
import { historyActionTypes } from "./saga";

/**
 * @module HistorySaga
 */

/**
 * set upload history from database
 * @param {object} documents
 * @param {object} lastVisible
 * @param {string} clinicId
 */
export function setUploadHistory(documents, lastVisible, clinicId) {
  store.dispatch({
    type: historyActionTypes.SET_UPLOAD_HISTORY,
    payload: {
      data: documents,
      lastVisible: lastVisible,
      clinicId: clinicId
    }
  });
}

/**
 * update the documents from pagination
 * @param {*} data
 */
export function updateUploadHistory(data) {
  store.dispatch({
    type: historyActionTypes.UPDATE_UPLOAD_HISTORY,
    payload: data
  });
}

/**
 * set download url
 * @param {string} url
 * @param {string} documentId
 */
export function setDownloadUrl(url, documentId) {
  store.dispatch({
    type: historyActionTypes.SET_DOWNLOAD_URL,
    payload: {
      url: url,
      documentId: documentId
    }
  });
}
