import React, { useState, useEffect, useRef } from "react";
import { isValidObject } from "../../utils/validators";
import { isValidString } from "../../front-end-global-components/services/validators";
import writingHand from "../../assets/icons/writing_hand.svg";
import Button from "../../front-end-global-components/components/Button/Button";
import NativeSelect from "../../front-end-global-components/components/NativeSelect/NativeSelect";

export default function AddForm(props) {
  const formsNamesList = useRef([]);
  const [isFormUploaded, setIsFormUploaded] = useState(false);
  const [selectedDocumentId, setSelectedDocumentIde] = useState("");

  const formsList = () => {
    const forms = props.forms.data;
    const _result = [];
    if (isValidObject(forms)) {
      for (const key in forms) {
        _result.push({
          displayName: forms[key].name,
          value: forms[key].documentId
        });
      }
    }
    formsNamesList.current = [..._result];
    return _result;
  };

  // to get new forms
  useEffect(() => {
    if (!isValidObject(props.forms.data)) {
      props.getForms();
    }
    // eslint-disable-next-line
  }, [props.forms.data]);

  // if form is uploaded then set form uploaded state to true
  useEffect(() => {
    if (
      selectedDocumentId &&
      props.status.code == null &&
      props.status.message === "Form uploaded successfully!"
    ) {
      setIsFormUploaded(true);
    }
  }, [props.status, selectedDocumentId]);

  return (
    <div
      className="inherit-parent-height padding-larger"
      data-cy="doctorSearch"
    >
      {isFormUploaded === true ? (
        <FormUploadedFallback backButtonOnclick={props.backButtonOnclick} />
      ) : (
        <React.Fragment>
          <NativeSelect
            className="inherit-parent-width margin-top-large"
            name="uploadForm"
            label="SELECT FORM"
            labelClassName="letter-spacing-4-percentage"
            options={["", ...formsList()]}
            onChange={(event) => {
              setSelectedDocumentIde(event.target.value);
            }}
          />

          <div className=" display-flex flex-direction-row flex-justify-content-space-between">
            <Button
              data-cy="upload-form-button"
              loading={props.forms.loading}
              boxShadow={false}
              text="Upload"
              type="button"
              className="inherit-parent-width padding-medium border-radius-default margin-right-default "
              disabled={!isValidString(selectedDocumentId)}
              onClick={() => {
                props.uploadFormOnClick(selectedDocumentId);
              }}
            />
            <Button
              data-cy="create-template-button"
              boxShadow={false}
              text="Create New"
              type="button"
              variant="secondary"
              className="inherit-parent-width padding-medium border-radius-default margin-left-default "
              disabled={false}
              onClick={props.createNewOnClick}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

const FormUploadedFallback = ({ backButtonOnclick }) => {
  return (
    <React.Fragment>
      <section className=" inherit-parent-height flex-place-children-page-center padding-horizonal-larger">
        <img src={writingHand} alt="" height="32px" width="32px" />
        <div className=" font-family-gilroy-medium font-color-secondary font-size-medium padding-vertical-large text-align-center ">
          Form uploaded successfully!
        </div>
        <div className=" font-family-gilroy-regular font-color-secondary font-size-medium padding-bottom-larger text-align-center">
          Form has been added to the patient’s timeline. Please wait till they
          fill it up.
        </div>
        <Button
          data-cy="create-template-button"
          boxShadow={false}
          text="Okay"
          type="button"
          className="inherit-parent-width max-width-150px "
          disabled={false}
          onClick={() => {
            backButtonOnclick();
          }}
        />
      </section>
    </React.Fragment>
  );
};
