const navigationReducer = (
  state = {
    loading: false,
    primary: "home",
    secondary: null,
    tertiary: null
  },
  action
) => {
  switch (action.type) {
    case "SET_NAVIGATION_LOADING":
      return {
        ...state,
        loading: action.payload.loading
      };
    case "SET_NAVIGATION_PRIMARY_DATA":
      return {
        ...state,
        primary: action.payload.data,
        secondary: null,
        tertiary: null
      };
    case "SET_NAVIGATION_SECONDARY_DATA":
      return {
        ...state,
        secondary: action.payload.data
      };
    case "SET_NAVIGATION_TERTIARY_DATA":
      return {
        ...state,
        tertiary: action.payload.data
      };
    case "RESET":
      return {
        loading: false,
        primary: "home",
        secondary: null,
        tertiary: null
      };
    default:
      return state;
  }
};
export default navigationReducer;
