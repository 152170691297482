import React from "react";
import { connect } from "react-redux";
import "./SignUp.css";
import { reCaptchaVerifier } from "../../services/authentication";
import nintoClinicLogo from "../../assets/images/logo/ninto-clinic-logo.png";
import placeholderImage from "../../assets/images/Placeholder_image_clinic.png";
import Button from "../../front-end-global-components/components/Button/Button";
import OTPInputBox from "../../front-end-global-components/components/OTPInputBox/OTPInputBox";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import { useEffect } from "react";
import { useState } from "react";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import {
  clearCredentials,
  sendOtp,
  verifyOtp
} from "../../redux/authentication/actions";
import { setErrorStatus } from "../../redux/status/actions";
import { mockAuthVerifyOtp } from "../../redux/mock/actions";

function SignUp(props) {
  const [signUpFormData, setSignUpFormData] = useState({
    userName: "",
    phoneNumber: "+91",
    OTP: null
  });

  //invisible recaptcha for authentication
  useEffect(() => {
    window.reCaptchaVerifier = reCaptchaVerifier();
  }, []);

  const setOTPValue = (otp) => {
    if (signUpFormData.phoneNumber === null) {
      return;
    }
    setSignUpFormData({
      ...signUpFormData,
      OTP: otp
    });
  };

  const signUpFormOnChange = (event) => {
    //To clear auth verification while user trying to change phone number after otp sent
    if (
      event.target.name === "phoneNumber" &&
      props.auth.credentials.verificationId &&
      signUpFormData.phoneNumber !== event.target.value
    ) {
      //clear auth verification
      props.clearCredentials();
    }
    if (event.target.name.includes("otp")) {
      return;
    } else if (event.target.name === "termsAndConditions") {
      setSignUpFormData({
        ...signUpFormData,
        [event.target.name]: event.target.checked
      });
    } else {
      setSignUpFormData({
        ...signUpFormData,
        [event.target.name]: event.target.value
      });
    }
  };

  const otpRequestHandler = (event) => {
    event.preventDefault();
    if (signUpFormData.userName.trim().length === 0) {
      return props.setErrorStatus({
        code: "custom",
        message: "Please enter a valid name"
      });
    } else {
      props.sendOtp(signUpFormData.phoneNumber, "signUp");
    }
  };

  const signUpHandler = (event) => {
    event.preventDefault();
    if (process.env.REACT_APP_STAGING === "local") {
      props.mockAuthVerifyOtp(
        signUpFormData.OTP,
        signUpFormData.userName,
        signUpFormData.phoneNumber,
        "signUp"
      );
    } else {
      props.verifyOtp(
        signUpFormData.OTP,
        signUpFormData.userName,
        signUpFormData.phoneNumber,
        "signUp"
      );
    }
  };

  return (
    <>
      <div className="grey-background-image" data-cy="signup-screen">
        <div data-cy="background-image">
          <img
            draggable="false"
            data-cy="placeholder-image"
            className="icon-img"
            src={placeholderImage}
            alt="img"
          />
        </div>
        <div className="flex-center-children-horizontally flex-center-children-vertically box-shadow-default inherit-parent-height width-30percent position-fixed background-white ">
          <div className="inherit-parent-width max-width-300px">
            <form
              data-cy="signup"
              className="flex-place-children-page-center padding-top-large"
              onChange={(event) => {
                signUpFormOnChange(event);
              }}
              onSubmit={(event) => event.preventDefault()}
            >
              <img
                draggable="false"
                className="width-155px margin-bottom-larger"
                src={nintoClinicLogo}
                alt="login-logo"
              />
              <div className="font-weight-normal font-family-gilroy-medium font-size-small font-color-secondary inherit-parent-width text-align-center">
                CLINIC SIGN UP
              </div>
              <InputBox
                className="inherit-parent-width border-radius-default margin-vertical-large"
                name="userName"
                removeResponsive={true}
                label="Owner Name"
                value={signUpFormData.userName}
                type="text"
                autoComplete="on"
                required
                data-cy="signup-screen-name-field"
              />
              <InputBox
                className="inherit-parent-width border-radius-default margin-bottom-large"
                name="phoneNumber"
                label="Phone Number"
                value={signUpFormData.phoneNumber}
                removeResponsive={true}
                type="tel"
                autoComplete="off"
                show="otpButton"
                buttonTileId="login-button"
                onButtonClick={(event) => {
                  otpRequestHandler(event);
                }}
                data-cy="signup-screen-phone-number-field"
                suffixButtonDisabled={signUpFormData.phoneNumber?.length !== 13}
                buttonTile={
                  props.auth.credentials.verificationId !== null
                    ? "RESEND OTP"
                    : "GET OTP"
                }
                buttonType={
                  typeof props.auth.credentials.verificationId === "string"
                    ? "button"
                    : "submit"
                }
                required
              />
              <ConditionalRender
                condition={
                  typeof props.auth.credentials.verificationId === "string"
                }
              >
                <OTPInputBox
                  name="otp"
                  setValue={setOTPValue}
                  className="margin-bottom-large"
                  required
                />
              </ConditionalRender>
              <Button
                loading={props.auth.loading}
                type={
                  typeof props.auth.credentials.verificationId === "string"
                    ? "submit"
                    : "button"
                }
                className="margin-bottom-large"
                text="Signup"
                onClick={(event) => {
                  signUpHandler(event);
                }}
                boxShadow={false}
                disabled={
                  !(
                    typeof signUpFormData.OTP === "string" &&
                    typeof signUpFormData.userName === "string" &&
                    signUpFormData.userName &&
                    signUpFormData.OTP.length === 6
                  )
                }
                data-cy={
                  props.auth.loading === true
                    ? "signup-loading-button"
                    : "signup-button"
                }
              />
              <div className="font-weight-normal flex-center-children-horizontally  font-family-gilroy-medium font-size-small font-color-secondary inherit-parent-width text-align-center">
                Already have an account?
                <div
                  data-cy="login-page-link"
                  className={`margin-left-default font-color-primary ${
                    props.auth.loading === true
                      ? "pointer-events-none"
                      : "cursor-pointer"
                  }`}
                  onClick={() => {
                    props.navigate("/login");
                    props.clearCredentials();
                  }}
                >
                  Log in
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div id="recaptcha-placeholder"></div>
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = function () {
  return {
    verifyOtp: (otp, userName, phoneNumber, type) =>
      verifyOtp(otp, userName, phoneNumber, type),
    sendOtp: (phoneNumber, type) => sendOtp(phoneNumber, type),
    clearCredentials: () => clearCredentials(),
    mockAuthVerifyOtp: (otp, userName, phoneNumber, type) =>
      mockAuthVerifyOtp(otp, userName, phoneNumber, type),
    setErrorStatus: (errorObject) => setErrorStatus(errorObject)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
