import store from "../store/store";
import { doctorSearchActionTypes } from "./saga";

/**
 * @module DoctorSearchSaga
 */

/**
 * Get doctor search data
 *  @param {string} phoneNumber
 * @param {string} searchType
 */
export function getSearchDoctorData(phoneNumber, searchType) {
  store.dispatch({
    type: doctorSearchActionTypes.GET_SEARCH_DOCTOR_DATA,
    payload: {
      phoneNumber: phoneNumber,
      searchType: searchType
    }
  });
}

/**
 * Set selected doctor
 * @param {string} doctorId
 */
export function addSelectedDoctor(doctorId) {
  store.dispatch({
    type: doctorSearchActionTypes.ADD_SELECTED_DOCTOR,
    payload: {
      doctorId: doctorId
    }
  });
}

/**
 * clear selected doctor
 */
export function clearSelectedDoctor() {
  store.dispatch({
    type: doctorSearchActionTypes.CLEAR_SELECTED_DOCTOR
  });
}

/**
 * remove doctor search data
 */
export function removeDoctorSearchData() {
  store.dispatch({
    type: doctorSearchActionTypes.REMOVE_DOCTOR_SEARCH_DATA
  });
}

/**
 * sort the pin patients
 * @param {string} sortedOrder
 */
export function setSortedPatients(sortedOrder) {
  store.dispatch({
    type: doctorSearchActionTypes.SET_SORTED_PATIENTS,
    payload: {
      sortedOrder: sortedOrder
    }
  });
}

/**
 * un pin the patient
 * @param {string} pid
 */
export function unPinPatient(pid) {
  store.dispatch({
    type: doctorSearchActionTypes.UN_PIN_PATIENT,
    payload: {
      pid: pid
    }
  });
}

/**
 * pin the patient
 */
export function pinPatient() {
  store.dispatch({
    type: doctorSearchActionTypes.PIN_PATIENT
  });
}
