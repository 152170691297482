import { all, put, takeEvery } from "@redux-saga/core/effects";
import {
  createProfile,
  editProfile,
  createClinicBrand,
  addConnectionsToProfile,
  createClinicProfile,
  getClinicPublicData,
  updateClinicPublicData,
  updatePersonalProfileData
} from "../../services/database";
import {
  uploadProfilePicture,
  uploadClinicStaffProfilePicture,
  getClinicStaffProfilePictureUrl
} from "../../services/storage";
import store from "../store/store";
import { isValidObject } from "../../utils/validators";
import {
  exponentialBackOff,
  getDistrictFromCode,
  getStateFromCode
} from "../../utils/constants";
import { throwError } from "../../services/error";
import { logout, removeDisplayName } from "../authentication/actions";
import { setErrorStatus, setSuccessStatus } from "../status/actions";
import { getPublicProfileData } from "./actions";

export const profileActionTypes = {
  SET_ACCOUNT_PROFILES: "SET_ACCOUNT_PROFILES",
  CREATE_CLINIC_PROFILE: "CREATE_CLINIC_PROFILE",
  EDIT_PROFILE: "EDIT_PROFILE",
  GET_PUBLIC_PROFILE_DATA: "GET_PUBLIC_PROFILE_DATA",
  CLEAR_PUBLIC_PROFILE_DATA: "CLEAR_PUBLIC_PROFILE_DATA",
  UPDATE_PUBLIC_PROFILE_DATA: "UPDATE_PUBLIC_PROFILE_DATA",
  EDIT_PERSONAL_PROFILE_DATA: "EDIT_PERSONAL_PROFILE_DATA"
};

function* setProfilesWorker(action) {
  try {
    let profileData = action.payload.data;

    if (
      isValidObject(profileData) &&
      profileData[Object.keys(profileData)[0]]?.profilePicture
    ) {
      const url = yield getClinicStaffProfilePictureUrl(
        profileData[Object.keys(profileData)[0]]?.profilePicture
      );

      profileData = {
        [Object.keys(profileData)[0]]: {
          ...Object.values(profileData)[0],
          profilePictureUrl: url
        }
      };
    }

    yield put({
      type: "SET_PROFILES",
      payload: profileData
    });

    if (!isValidObject(action.payload.data)) {
      if (typeof store.getState().auth.data.displayName === "string") {
        yield createProfile(store.getState().auth.data.displayName);
        yield removeDisplayName();
      } else {
        logout();
      }
    }
  } catch (error) {
    setErrorStatus(error);
    yield setProfileLoading(false);
  }
}

function* createClinicProfileWorker(action) {
  const profileId = Object.keys(store.getState().profile.data)[0];
  try {
    if (
      isValidObject(store.getState().connection?.data) &&
      Object.values(store.getState().connection?.data)?.find(
        (data) =>
          data.brandId === action.payload.data.brandId &&
          data.userType !== "owner"
      )
    ) {
      throw throwError("custom", "Permission denied");
    }

    yield setProfileLoading(true);

    const modifyStateAndCityInData = {
      ...action.payload.data,
      state: getStateFromCode(action.payload.data.state),
      city: getDistrictFromCode(
        action.payload.data.state,
        action.payload.data.city
      )
    };

    const previousConnectionTotalData = Object.keys(
      store.getState().connection.data
    ).length;

    if (action.payload.data.brandId) {
      const documentId = yield createClinicProfile(
        modifyStateAndCityInData,
        action.payload.data.brandId
      );

      yield addConnectionsToProfile(
        documentId,
        modifyStateAndCityInData,
        profileId,
        action.payload.data.brandId
      );
    } else {
      const brandId = yield createClinicBrand(
        modifyStateAndCityInData,
        profileId
      );
      if (brandId) {
        yield uploadProfilePicture(action.payload.data.companyLogo, brandId);
        const documentId = yield createClinicProfile(
          modifyStateAndCityInData,
          brandId
        );
        yield addConnectionsToProfile(
          documentId,
          modifyStateAndCityInData,
          profileId,
          brandId
        );
      }
    }
    setSuccessStatus("Brand created successfully");

    yield exponentialBackOff(
      () => {
        if (
          previousConnectionTotalData <
          Object.keys(store.getState().connection.data).length
        ) {
          return true;
        } else {
          return false;
        }
      },
      1,
      4
    );
    yield action.payload.navigate("/");
    yield setProfileLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setProfileLoading(false);
  }
}

export function* editProfileWorker(action) {
  try {
    const profileId =
      store.getState().profile.data &&
      Object.keys(store.getState().profile.data)[0];
    const connectionId =
      isValidObject(store.getState().connection?.data) &&
      Object.keys(store.getState().connection?.data);
    yield setProfileLoading(true);
    yield editProfile(action.payload.data, profileId, connectionId);
    setSuccessStatus("Successfully saved");
    yield setProfileLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setProfileLoading(false);
  }
}

export function* getPublicProfileDataWorker(action) {
  try {
    yield setProfileLoading(true);
    const profileId = Object.keys(store.getState().profile.data)[0];
    const profileRootData = store.getState().profile.data[profileId];
    const selectedClinicId = store.getState().connection.selectedClinic;
    const profilePublicData = yield getClinicPublicData(selectedClinicId);
    if (isValidObject(profilePublicData)) {
      yield put({
        type: "SET_PROFILES",
        payload: {
          [profileId]: {
            ...profileRootData,
            public: profilePublicData
          }
        }
      });
    }

    yield setProfileLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setProfileLoading(false);
  }
}
export function* clearPublicProfileDataWorker() {
  try {
    yield setProfileLoading(true);
    const profileId = Object.keys(store.getState().profile.data)[0];
    const profileRootData = store.getState().profile.data[profileId];

    yield put({
      type: "SET_PROFILES",
      payload: {
        [profileId]: {
          ...profileRootData,
          public: null
        }
      }
    });

    yield setProfileLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setProfileLoading(false);
  }
}

export function* updatePublicProfileDataWorker(action) {
  try {
    const connectionId = store.getState().connection?.currentConnection;
    yield setProfileLoading(true);
    const modifyStateAndCityInData = {
      ...action.payload.data,
      state: getStateFromCode(action.payload.data.state),
      city: getDistrictFromCode(
        action.payload.data.state,
        action.payload.data.city
      )
    };
    yield updateClinicPublicData(connectionId, modifyStateAndCityInData);

    setSuccessStatus("Successfully saved");
    yield getPublicProfileData();
    yield setProfileLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setProfileLoading(false);
  }
}

export function* updatePersonalProfileDataWorker(action) {
  try {
    yield setProfileLoading(true);
    const profileId = Object.keys(store.getState().profile.data)[0];
    let profileData = action.payload.data;

    if (profileData?.dateOfBirth) {
      profileData = {
        ...profileData,
        dateOfBirth: +new Date(action.payload.data.dateOfBirth)
      };
    }

    if (profileData?.profilePicture) {
      const response = yield uploadClinicStaffProfilePicture(
        profileData.profilePicture,
        profileId
      );

      if (!!response) {
        const url = yield getClinicStaffProfilePictureUrl(
          response.metadata.fullPath
        );

        yield put({
          type: "SET_PROFILE_PICTURE_URL",
          payload: {
            [Object.keys(store.getState().profile.data)[0]]: {
              ...Object.values(store.getState().profile.data)[0],
              profilePictureUrl: url
            }
          }
        });
      }

      delete profileData.profilePicture;

      profileData = {
        ...profileData,
        profilePicture: response.metadata.fullPath
      };
    }
    yield updatePersonalProfileData(profileId, profileData);

    setSuccessStatus("Successfully saved");
    yield setProfileLoading(false);
  } catch (error) {
    yield setProfileLoading(false);
  }
}

export function* profileWatcher() {
  yield all([
    takeEvery("CREATE_CLINIC_PROFILE", createClinicProfileWorker),
    takeEvery("EDIT_PROFILE", editProfileWorker),
    takeEvery("SET_ACCOUNT_PROFILES", setProfilesWorker),
    takeEvery("GET_PUBLIC_PROFILE_DATA", getPublicProfileDataWorker),
    takeEvery("CLEAR_PUBLIC_PROFILE_DATA", clearPublicProfileDataWorker),
    takeEvery("UPDATE_PUBLIC_PROFILE_DATA", updatePublicProfileDataWorker),
    takeEvery("EDIT_PERSONAL_PROFILE_DATA", updatePersonalProfileDataWorker)
  ]);
}

function* setProfileLoading(bool) {
  yield put({
    type: "SET_PROFILE_LOADING",
    payload: {
      loading: bool
    }
  });
}
