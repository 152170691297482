import React, { useEffect, useState } from "react";
import { DownloadIcon } from "../../front-end-global-components/assets/assets";
import shareIcon from "../../front-end-global-components/assets/shareIcon.svg";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import closeIcon from "../../assets/icons/close.svg";
import "./Abha.css";

export default function ShowAbhaCard(props) {
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    props.getPatientQrDetails();
    // eslint-disable-next-line
  }, []);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = props.abhaCardData;
    link.download = "ABHA card.jpg";
    link.click();
  };

  const handleShare = async () => {
    const blob = await (await fetch(props.abhaCardData)).blob();
    const file = new File([blob], "ABHA Address details.png", {
      type: blob.type
    });
    if (
      !!navigator &&
      navigator.canShare &&
      navigator.canShare({ files: [file] })
    ) {
      await navigator?.share({
        title: "Ninto",
        text: "Ninto - ABHA Address",
        url: window.location.href,
        files: [file]
      });
    }
  };

  return (
    <div className="inherit-parent-height">
      <div className="inherit-parent-width text-align-center padding-larger">
        ABHA ADDRESS INFORMATION
      </div>
      <main className="display-flex flex-justify-content-center overflow-hidden padding-top-larger padding-bottom-larger position-relative ">
        {props.loading === true ? (
          <div className="background-color-white padding-left-default padding-right-default padding-top-small padding-bottom-small border-radius-default ">
            <div className="qr-loader" />
          </div>
        ) : props.loading === false && props.abhaCardData ? (
          <div
            onClick={() => {
              setShowModal(true);
            }}
            className="flex-justify-content-center cursor-pointer"
          >
            <img
              draggable={false}
              className="inherit-parent-height inherit-parent-width"
              alt="qr"
              src={props.abhaCardData}
            />
          </div>
        ) : (
          <p>No QR code Details</p>
        )}
      </main>
      {props.loading === false && props.abhaCardData && (
        <div className="display-flex inherit-parent-width overflow-hidden flex-justify-content-space-around border-top-2px-solid-e5e5e5">
          <div
            className="margin-larger display-flex cursor-pointer"
            onClick={() => {
              handleDownload();
            }}
          >
            <div className="padding-right-default">Download</div>
            <div>
              <DownloadIcon />
            </div>
          </div>
          <div
            className="margin-larger display-flex cursor-pointer"
            onClick={() => {
              handleShare();
            }}
          >
            <div className="padding-right-default">Share</div>
            <img
              data-cy="share-icon"
              className={`cursor-pointer margin-right-medium ${
                props.removeResponsive ? "" : "adaptive-support-icon-height"
              } support-icon-height `}
              src={shareIcon}
              alt="share-icon"
              width="auto"
            />
          </div>
        </div>
      )}
      <Modal
        show={showModal}
        width="width-30-percent"
        height="height-40-percent"
        modalBodyClassName=" overflow-hidden"
        onClick={() => {
          setShowModal(!showModal);
        }}
      >
        <section className="font-color-secondary flex-justify-content-center padding-top-large padding-bottom-medium ">
          <div className=" display-flex padding-left-large padding-right-medium">
            ABHA CARD
          </div>
          <img
            draggable={false}
            src={closeIcon}
            onClick={() => {
              setShowModal(!showModal);
            }}
            alt="close"
            data-cy="modal-close-button"
            className=" cursor-pointer padding-left-large padding-right-large position-absolute right-0"
          />
        </section>
        <div className="max-height-92-percentage padding-left-larger padding-right-larger hide-scroll-bar overflow-auto inherit-parent-height">
          <div className="inherit-parent-height">
            <div className="inherit-parent-width inherit-parent-height padding-bottom-large">
              <img
                draggable={false}
                className="inherit-parent-height inherit-parent-width"
                alt="qr"
                src={props.abhaCardData}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
