import { all, put, takeEvery } from "@redux-saga/core/effects";
import { isValidArray } from "../../front-end-global-components/services/validators";
import {
  createForm,
  getForms,
  updateForm,
  uploadForm
} from "../../services/database";
import { throwError } from "../../services/error";
import { isValidObject } from "../../utils/validators";
import { showSecondary } from "../navigation/actions";
import { setErrorStatus, setSuccessStatus } from "../status/actions";
import store from "../store/store";

export const formsActionTypes = {
  GET_FORMS: "GET_FORMS",
  CREATE_FORM: "CREATE_FORM",
  UPDATE_FORM: "UPDATE_FORM",
  PUT_CURRENT_FORM: "PUT_CURRENT_FORM",
  REMOVE_CURRENT_FORM: "REMOVE_CURRENT_FORM",
  UPLOAD_FORM: "UPLOAD_FORM"
};

function* getFormsWorker() {
  try {
    yield put({
      type: "SET_FORMS_LOADING",
      payload: {
        loading: true
      }
    });
    // GET FORMS and set
    const clinicPhoneNumber = store.getState().auth.data.phoneNumber;
    if (clinicPhoneNumber !== null) {
      const forms = yield getForms(clinicPhoneNumber);
      if (isValidObject(forms)) {
        yield put({
          type: "SET_FORMS_DATA",
          payload: {
            data: { ...forms }
          }
        });
      } else {
        yield put({
          type: "SET_FORMS_DATA",
          payload: {
            data: null
          }
        });
      }
    } else {
      throw throwError("custom", "Please Login");
    }
    yield put({
      type: "SET_FORMS_LOADING",
      payload: {
        loading: false
      }
    });
  } catch (error) {
    setErrorStatus(error);
    yield put({
      type: "SET_FORMS_LOADING",
      payload: {
        loading: false
      }
    });
  }
}

function* createFormWorker(action) {
  try {
    yield put({
      type: "SET_FORMS_LOADING",
      payload: {
        loading: true
      }
    });
    //Create Form
    // GET FORMS and set
    const uid = store.getState().auth.data.uid;
    const phoneNumber = store.getState().auth.data.phoneNumber;
    const name = action.payload.formData.name;
    const fields = action.payload.formData.fields;

    if (
      uid !== null &&
      phoneNumber !== null &&
      name !== null &&
      isValidArray(fields)
    ) {
      const _formData = {
        uid: uid,
        phoneNumber: phoneNumber,
        name: name,
        fields: [...fields],
        timestamp: +new Date()
      };

      const _documentId = yield createForm(_formData);
      const previousForms = store.getState().forms.data;
      yield put({
        type: "SET_FORMS_DATA",
        payload: {
          data: {
            ...(previousForms ? previousForms : {}),
            [_documentId]: {
              ..._formData,
              documentId: _documentId
            }
          }
        }
      });
    } else {
      throw throwError(
        "custom",
        "Something went wrong, Please try again later"
      );
    }
    yield put({
      type: "SET_FORMS_LOADING",
      payload: {
        loading: false
      }
    });
    setSuccessStatus("Successfully Created Template");
  } catch (error) {
    setErrorStatus(error);
    yield put({
      type: "SET_FORMS_LOADING",
      payload: {
        loading: false
      }
    });
  }
}

function* updateFormWorker(action) {
  try {
    yield put({
      type: "SET_FORMS_LOADING",
      payload: {
        loading: true
      }
    });
    // Update Form

    const fields = action.payload.formData.fields;
    const previousForms = store.getState().forms.data;
    const trueFormData = previousForms[store.getState().forms.currentForm];

    // documentId
    if (isValidArray(fields) && trueFormData !== null) {
      const _formData = {
        ...trueFormData,
        fields: [...fields]
      };

      delete _formData["documentId"];
      yield updateForm(_formData, trueFormData.documentId);

      yield put({
        type: "SET_FORMS_DATA",
        payload: {
          data: {
            ...(previousForms ? previousForms : {}),
            [trueFormData.documentId]: {
              ..._formData,
              documentId: trueFormData.documentId
            }
          }
        }
      });
    } else {
      throw throwError(
        "custom",
        "Something went wrong, Please try again later"
      );
    }
    yield put({
      type: "SET_FORMS_LOADING",
      payload: {
        loading: false
      }
    });
    setSuccessStatus("Successfully Updated Template");
  } catch (error) {
    setErrorStatus(error);
    yield put({
      type: "SET_FORMS_LOADING",
      payload: {
        loading: false
      }
    });
  }
}

function* putCurrentFormWorker(action) {
  try {
    yield put({
      type: "SET_FORMS_LOADING",
      payload: {
        loading: true
      }
    });
    // Update Form
    if (typeof action.payload.formDocumentId === "string") {
      yield put({
        type: "SET_CURRENT_FORM",
        payload: {
          currentForm: action.payload.formDocumentId
        }
      });
      showSecondary("createEditPreviewForm");
    } else {
      throw throwError(
        "custom",
        "Something went wrong, Please try again later"
      );
    }

    yield put({
      type: "SET_FORMS_LOADING",
      payload: {
        loading: false
      }
    });
  } catch (error) {
    setErrorStatus(error);
    yield put({
      type: "SET_FORMS_LOADING",
      payload: {
        loading: false
      }
    });
  }
}

function* removeCurrentFormWorker() {
  yield put({
    type: "SET_CURRENT_FORM",
    payload: {
      currentForm: null
    }
  });
}

function* uploadFormWorker(action) {
  yield put({
    type: "SET_FORMS_LOADING",
    payload: {
      loading: true
    }
  });

  try {
    const formDataFromStore =
      store.getState().forms.data[action.payload.documentId];
    const uid = store.getState().auth.data.uid;
    const phoneNumber = store.getState().auth.data.phoneNumber;
    const clinicPublicData = store.getState().profile.data[uid].public;
    const patientData =
      store.getState().abdmPatients.selectedPatient[
        Object.keys(store.getState().abdmPatients.selectedPatient)[0]
      ];

    if (formDataFromStore === null) {
      throw throwError("custom", "In-valid form, Please select another form");
    }
    if (
      uid === null ||
      phoneNumber === null ||
      clinicPublicData === null ||
      patientData === null
    ) {
      throw throwError("custom", "Something went wrong, please re-login");
    }

    const _formData = {
      type: "form",
      from: {
        type: "clinic",
        name: clinicPublicData.clinicName,
        id: uid
      },
      to: {
        fullName: patientData.fullName,
        phoneNumber: patientData.phoneNumber,
        id: patientData.pid
      },
      timestamp: +new Date(),
      name: formDataFromStore.name,
      fields: formDataFromStore.fields
    };
    yield uploadForm(_formData);
    setSuccessStatus("Form uploaded successfully!");
    yield put({
      type: "SET_FORMS_LOADING",
      payload: {
        loading: false
      }
    });
  } catch (error) {
    setErrorStatus(error);
    yield put({
      type: "SET_FORMS_LOADING",
      payload: {
        loading: false
      }
    });
  }
}

export function* formsWatcher() {
  yield all([
    takeEvery(formsActionTypes.GET_FORMS, getFormsWorker),
    takeEvery(formsActionTypes.CREATE_FORM, createFormWorker),
    takeEvery(formsActionTypes.UPDATE_FORM, updateFormWorker),
    takeEvery(formsActionTypes.PUT_CURRENT_FORM, putCurrentFormWorker),
    takeEvery(formsActionTypes.REMOVE_CURRENT_FORM, removeCurrentFormWorker),
    takeEvery(formsActionTypes.UPLOAD_FORM, uploadFormWorker)
  ]);
}
