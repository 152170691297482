const documentsReducer = (
  state = {
    loading: false,
    data: null,
    dataTransfer: null,
    currentDocument: null,
    filters: {
      documentType: [],
      doctors: [],
      clinics: []
    }
  },
  action
) => {
  switch (action.type) {
    case "SET_DOCUMENT_LOADING":
      return {
        ...state,
        loading: action.payload.loading
      };

    case "SET_CURRENT_DOCUMENT":
      return {
        ...state,
        currentDocument: action.payload.currentDocument
      };

    case "SET_DOWNLOAD_URL_LOADING":
      return {
        ...state,
        downloadUrlLoading: action.payload.loading
      };

    case "SET_DOCUMENTS_DATA":
      return {
        ...state,
        data: action.payload.data
      };

    case "SET_DATA_TRANSFER":
      return {
        ...state,
        dataTransfer: action.payload.data
      };

    case "CLEAR_DOCUMENTS_DATA":
      return {
        ...state,
        data: null,
        currentDocument: null
      };

    case "FILTER_BY_DOCUMENTS":
      return {
        ...state,
        filters: {
          ...state.filters,
          documentType: action.payload.documentsType
        }
      };

    case "FILTER_BY_DOCTORS":
      return {
        ...state,
        filters: { ...state.filters, doctors: action.payload.doctorsName }
      };

    case "FILTER_BY_CLINICS":
      return {
        ...state,
        filters: { ...state.filters, clinics: action.payload.clinicsName }
      };

    case "CLEAR_DOCUMENTS_FILTER":
      return {
        ...state,
        filters: {
          documentType: [],
          doctors: [],
          clinics: []
        }
      };

    case "RESET":
      return {
        loading: false,
        data: null,
        dataTransfer: null,
        currentDocument: null,
        filters: {
          documentType: [],
          doctors: [],
          clinics: []
        }
      };
    default:
      return state;
  }
};
export default documentsReducer;
