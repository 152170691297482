import React, { useEffect, useReducer, useRef, useState } from "react";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import closeIcon from "../../assets/icons/close.svg";
import backIcon from "../../assets/icons/Back.svg";
import { getFileUrl } from "../../services/storage";
import { isValidArray, isValidObject } from "../../utils/validators";
import SymptomsRenderer from "../../front-end-global-components/components/SymptomRenderer/SymptomRenderer";
import { InputSlider } from "../../front-end-global-components/components/InputSlider/InputSlider";
import AudioPlayer from "../../front-end-global-components/components/AudioPlayer/AudioPlayer";
import { validDocumentType } from "../../utils/constants";
import FHIRRenderer from "../../front-end-global-components/components/FHIRRenderer/FHIRRenderer";
import PDFRenderer from "../../front-end-global-components/components/PDFRenderer/PDFRenderer";
import { setErrorStatus } from "../../redux/status/actions";

const initialState = {
  loading: false,
  isValidDocument: false,
  data: null,
  downloads: {
    currentURL: null,
    currentNotesId: null,
    URL: null,
    notes: null,
    images: []
  }
};

function reducer(state, action) {
  switch (action.type) {
    case "loading":
      return { ...state, loading: action.payload };
    case "setData":
      return { ...state, data: action.payload };
    case "setDownloads":
      return {
        ...state,
        downloads: {
          ...state.downloads,
          ...action.payload
        }
      };
    case "setIsValidDocument":
      return {
        ...state,
        isValidDocument: action.payload
      };
    case "reset":
      return {
        loading: false,
        isValidDocument: false,
        data: null,
        downloads: {
          currentURL: null,
          currentNotesId: null,
          URL: null,
          notes: null,
          images: []
        }
      };
    default:
      break;
  }
}

export default function PatientTimelineCardPreview(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [selectedFileToViewIndex, setSelectedFileToViewIndex] = useState(null);
  const [show, setShow] = useState("describe");
  const addPicturesElementRef = useRef(null);
  const addPicturesSectionWidthRef = useRef(null);
  const [addedFiles, setAddedFiles] = useState([]);
  const [pdfArrayBuffer, setPdfArrayBuffer] = useState({});
  const [jsonData, setJsonData] = useState(null);
  const [readOnly] = useState(true);
  const [PDFfromFHIR, setPDFfromFHIR] = useState(null);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [fileType, setFileType] = useState("");
  //to add files download URLs
  useEffect(() => {
    addPicturesSectionWidthRef.current =
      addPicturesElementRef.current &&
      addPicturesElementRef.current.offsetWidth;
    if (
      Array.isArray(state.downloads.images) &&
      state.downloads.images?.length > 0
    ) {
      setAddedFiles([...state.downloads.images]);
    }
  }, [state.downloads.images]);

  //To reset the while screen unmount
  useEffect(() => {
    return () => {
      dispatch({ type: "reset" });
    };
    // eslint-disable-next-line
  }, []);

  //used to fetch the symptom voice note url and other pdf document urls
  async function fetchDownloadURL(document) {
    const documentUrl =
      validDocumentType(document.documentType) === "symptoms"
        ? isValidObject(document.url) &&
          typeof document.url.audio === "string" &&
          document.url.audio
        : typeof document.url === "string"
        ? document.url
        : "";

    let url;
    if (documentUrl.length > 0) {
      url = await getFileUrl(documentUrl);
      fetch(url)
        .then(async (res) => {
          const url = res.url.split("?")[0];
          const lastElement = url.split(".").length - 1;
          setFileType(url.split(".")[lastElement]);
          if (url.split(".")[lastElement] === "json") {
            const response = await fetch(res.url, {
              method: "GET"
            });
            const text = await response.json();
            setJsonData(text);
          } else {
            setPdfArrayBuffer({
              ...pdfArrayBuffer,
              base: await res.arrayBuffer()
            });
          }
        })
        .catch((error) => {
          setErrorStatus({
            custom: "custom",
            message: "Unable to download"
          });
          console.error(error);
        });
    }
    let newNotesObj = {};
    if (isValidObject(document.notes)) {
      const doctorsId = document.notes;
      //Looping all doctors in Notes (with doctorsId) and accessing pages
      for (const doctorId in doctorsId) {
        const splittedString = document.url.split("/");
        const defaultPdfName = splittedString[splittedString.length - 1];
        const convertedUrlPath = document.url.replace(
          defaultPdfName,
          `${doctorId}.pdf`
        );
        const downloadUrl = await getFileUrl(convertedUrlPath);
        //Creating notes Object
        newNotesObj[doctorId] = downloadUrl;
      }
    }
    let images = [];
    if (Array.isArray(document.url?.images) && document.url.images.length > 0) {
      //Looping all doctors in Notes (with doctorsId) and accessing pages
      for (const url of document.url.images) {
        const downloadUrl = await getFileUrl(url);
        images.push(downloadUrl);
      }
    }
    return {
      currentURL: url,
      URL: url,
      notes: isValidObject(newNotesObj) ? newNotesObj : null,
      images: images
    };
  }

  const showSymptomScreen = () => {
    if (selectedFileToViewIndex === null) return true;
    return false;
  };

  const showImageFullViewComp = () => {
    if (
      selectedFileToViewIndex !== null &&
      isValidArray(addedFiles) &&
      addedFiles[selectedFileToViewIndex]
    )
      return true;
    return false;
  };

  useEffect(() => {
    (async () => {
      try {
        dispatch({
          type: "loading",
          payload: true
        });
        if (
          props.documentId &&
          props.documentType &&
          Array.isArray(props.documents.data)
        ) {
          const _documentIndex = props.documents.data.findIndex(
            (doc) => doc.documentId === props.documentId
          );
          if (_documentIndex > -1) {
            dispatch({
              type: "setData",
              payload: props.documents.data[_documentIndex]
            });
            const url = await fetchDownloadURL(
              props.documents.data[_documentIndex]
            );
            dispatch({
              type: "setDownloads",
              payload: { ...state.downloads, ...url }
            });
          } else {
            dispatch({
              type: "setIsValidDocument",
              payload: true
            });
          }
        }
        dispatch({
          type: "loading",
          payload: false
        });
      } catch (error) {
        dispatch({
          type: "loading",
          payload: false
        });
      }
    })();
    // eslint-disable-next-line
  }, [props.documentId, props.documentType, props.documents.data]);

  const imgSrc = (file) => {
    if (typeof file === "string") {
      return file;
    } else if (file instanceof File) {
      return window.URL.createObjectURL(file);
    }
  };

  return (
    <Modal
      show={props.show}
      width="width-90-percent"
      height="height-90-percent"
      maxWidth="max-width-800px"
      maxHeight="max-height-850px"
      modalBodyClassName=" overflow-hidden"
      onClick={() => {
        dispatch({
          type: "loading",
          payload: true
        });
      }}
    >
      <section className="font-color-secondary flex-justify-content-center padding-top-large padding-bottom-large ">
        {PDFfromFHIR !== null && (
          <img
            src={backIcon}
            onClick={() => {
              setPDFfromFHIR(null);
            }}
            alt="back"
            data-cy="modal-close-button"
            className=" cursor-pointer padding-left-large padding-right-large position-absolute left-0"
          />
        )}

        <div className=" display-flex padding-left-large padding-right-large">
          <div className="padding-right-large">
            {state.data?.documentName && (
              <div className=" text-transform-capitalize font-family-gilroy-regular font-size-medium">
                {state.data?.documentName}
              </div>
            )}
          </div>
        </div>
        <img
          src={closeIcon}
          onClick={() => {
            props.onClickClose(false);
          }}
          alt="close"
          data-cy="modal-close-button"
          className=" cursor-pointer padding-left-large padding-right-large position-absolute right-0"
        />
      </section>
      <div className="max-height-92-percentage padding-left-larger padding-right-larger hide-scroll-bar overflow-auto inherit-parent-height">
        <div className="inherit-parent-height">
          <div className="inherit-parent-width height-94-percent">
            <section className=" inherit-parent-height inherit-parent-width ">
              {state.data?.documentType &&
                validDocumentType(state.data?.documentType) === "symptom" && (
                  <>
                    {showSymptomScreen() === true ? (
                      <div className="inherit-parent-height">
                        {state.loading === true ? (
                          <ViewSymptomSuspense />
                        ) : (
                          //remaining-body-height overflow-x-scroll
                          <div
                            className={`padding-left-large  overflow-y-auto padding-right-large padding-top-default  max-width-588px margin-horizontal-auto inherit-parent-width inherit-parent-height ${
                              show === "locateSymptom" ? "overflow-hidden" : ""
                            } `}
                            data-cy="documents-view-screen"
                          >
                            {(isValidArray(state.data?.location?.front) ||
                              isValidArray(state.data?.location?.back)) && (
                              <div className="flex-center-children-horizontally">
                                <div className="flex-justify-content-space-between font-size-small border-radius-default box-shadow-default padding-small background-white">
                                  <React.Fragment>
                                    <span
                                      className={` cursor-pointer font-family-gilroy-medium border-radius-default padding-top-small padding-bottom-small padding-right-large padding-left-large ${
                                        show === "locateSymptom"
                                          ? "font-color-secondary background-color-grey"
                                          : "font-color-tertiary"
                                      }`}
                                      onClick={() => {
                                        setShow("locateSymptom");
                                      }}
                                      data-cy="location-btn"
                                    >
                                      Location
                                    </span>

                                    <span
                                      className={`cursor-pointer font-family-gilroy-medium border-radius-default padding-top-small padding-bottom-small padding-right-medium padding-left-medium ${
                                        show === "describe"
                                          ? "font-color-secondary background-color-grey"
                                          : "font-color-tertiary"
                                      }`}
                                      onClick={() => {
                                        setShow("describe");
                                      }}
                                      data-cy="description-btn"
                                    >
                                      Description
                                    </span>
                                  </React.Fragment>
                                </div>
                              </div>
                            )}
                            {show === "locateSymptom" ? (
                              <div className="inherit-parent-height">
                                <SymptomsRenderer
                                  locations={state.data?.location}
                                  readOnly={true}
                                  gender={""}
                                />
                              </div>
                            ) : (
                              <section>
                                <InputField
                                  name={"date"}
                                  value={
                                    new Date(state.data?.timestamp)
                                      .toISOString()
                                      .split("T")[0]
                                  }
                                />
                                <InputField
                                  name={"time"}
                                  value={`${new Date(state.data?.timestamp)
                                    .toTimeString()
                                    .slice(0, 5)} ${
                                    new Date(state.data?.timestamp).getHours() >
                                    12
                                      ? "PM"
                                      : "AM"
                                  }`}
                                />

                                <InputField
                                  name={"description"}
                                  value={state.data?.description}
                                />
                                <InputSlider
                                  label="SEVERITY SCALE"
                                  name="severityScale"
                                  min={1}
                                  value={state.data?.severityScale}
                                  max={10}
                                  className="margin-top-large padding-vertical-default"
                                  disabled
                                />
                                {typeof state.downloads?.currentURL ===
                                  "string" && (
                                  <div className="flex-justify-content-space-between margin-top-larger">
                                    <AudioPlayer
                                      src={state.downloads?.currentURL}
                                    />
                                  </div>
                                )}
                              </section>
                            )}

                            <section
                              ref={addPicturesElementRef}
                              className="inherit-parent-width"
                            >
                              {show !== "locateSymptom" && (
                                <>
                                  {state.loading === true ? (
                                    <SymptomImageSuspense
                                      width={`${
                                        !!addPicturesSectionWidthRef.current
                                          ? addPicturesSectionWidthRef.current /
                                            3
                                          : 80
                                      }px`}
                                      height={`${
                                        !!addPicturesSectionWidthRef.current
                                          ? addPicturesSectionWidthRef.current /
                                            3
                                          : 80
                                      }px`}
                                    />
                                  ) : (
                                    <>
                                      {Array.isArray(addedFiles) &&
                                        addedFiles.length > 0 && (
                                          <>
                                            <h6 className="font-weight-normal font-family-gilroy-regular font-color-secondary font-size-small margin-top-larger margin-bottom-default">
                                              PICTURES
                                            </h6>
                                            <ul className="inherit-parent-width flex-direction-row list-style-type-none column-gap-3-percentage">
                                              {addedFiles.map((file, index) => (
                                                <li
                                                  className="border-radius-default margin-right-large cursor-pointer"
                                                  key={`symptom-image-key-${index}`}
                                                  style={{
                                                    width: `${
                                                      addPicturesSectionWidthRef.current
                                                        ? addPicturesSectionWidthRef.current /
                                                          3
                                                        : 80
                                                    }px`,
                                                    height: `${
                                                      addPicturesSectionWidthRef.current
                                                        ? addPicturesSectionWidthRef.current /
                                                          3
                                                        : 80
                                                    }px`
                                                  }}
                                                >
                                                  <img
                                                    src={file}
                                                    alt="symptom"
                                                    data-cy={`symptom-image-${index}`}
                                                    className="inherit-parent-height border-radius-default inherit-parent-width object-fit-cover border-solid-1px-grey"
                                                    onClick={() => {
                                                      setSelectedFileToViewIndex(
                                                        index
                                                      );
                                                    }}
                                                  />
                                                </li>
                                              ))}
                                            </ul>
                                          </>
                                        )}
                                    </>
                                  )}
                                </>
                              )}
                            </section>
                          </div>
                        )}
                      </div>
                    ) : (
                      showImageFullViewComp() === true && (
                        <>
                          <div
                            data-cy="image-full-view-comp"
                            className="inherit-parent-height inherit-parent-width flex-center-children-horizontally flex-direction-column "
                          >
                            <div className="flex-center-children-horizontally flex-direction-column flex-grow-1 max-height-90-percentage">
                              <img
                                src={imgSrc(
                                  addedFiles[selectedFileToViewIndex]
                                )}
                                alt="symptom"
                                className="inherit-parent-height max-height-90-percentage object-fit-contain"
                              />
                            </div>
                            <footer className="padding-top-large inherit-parent-width flex-align-items-center flex-justify-content-center">
                              <button
                                data-cy="image-full-view-comp-close-btn"
                                type="submit"
                                variant="secondary"
                                className=" cursor-pointer background-transparent font-color-secondary font-family-gilroy-medium font-size-medium"
                                onClick={() => {
                                  setSelectedFileToViewIndex(null);
                                }}
                              >
                                Close
                              </button>
                            </footer>
                          </div>
                        </>
                      )
                    )}
                  </>
                )}
              {state.data?.documentType &&
                validDocumentType(state.data?.documentType) === "form" &&
                Array.isArray(state.data?.fields) &&
                state.data.fields.map((field) => (
                  <React.Fragment key={field.name}>
                    <InputField name={field.name} value={field.value} />
                  </React.Fragment>
                ))}
              {["reports", "prescriptions", "admissions"].includes(
                state.data?.documentType &&
                  validDocumentType(state.data?.documentType)
              ) && (
                <>
                  {fileType === "json" &&
                    jsonData !== null &&
                    PDFfromFHIR === null && (
                      <FHIRRenderer
                        document={state.data}
                        userType="clinic"
                        connection={props.connection}
                        data={jsonData}
                        documentOnClick={(document) => {
                          setPDFfromFHIR(document);
                        }}
                      />
                    )}

                  {((fileType && fileType !== "json") || PDFfromFHIR) && (
                    <>
                      <PDFRenderer
                        readOnly={readOnly}
                        pdfArrayBuffer={PDFfromFHIR && PDFfromFHIR}
                        saveWithBackground={false}
                        onPDFChange={() => {
                          if (isPdfLoading === false) {
                            setIsPdfLoading(true);
                          }
                        }}
                        onRender={() => {
                          if (isPdfLoading === true) {
                            setIsPdfLoading(false);
                          }
                        }}
                      />
                      <section className=" position-absolute bottom-0 left-0 right-0">
                        <PdfNotes
                          loading={!!state.data?.notes && state.loading}
                          document={state.data}
                          show={!!state.data?.notes}
                          currentNotesId={state.downloads?.currentNotesId}
                          notesButtonOnClick={(notesId) => {
                            dispatch({
                              type: "loading",
                              payload: true
                            });
                            if (
                              state.downloads &&
                              !!state.downloads?.notes?.[notesId] &&
                              state.downloads.currentNotesId !== notesId
                            ) {
                              dispatch({
                                type: "setDownloads",
                                payload: {
                                  ...state.downloads,
                                  currentURL: state.downloads.notes[notesId],
                                  currentNotesId: notesId
                                }
                              });
                            } else {
                              dispatch({
                                type: "setDownloads",
                                payload: {
                                  ...state.downloads,
                                  currentURL: state.downloads.URL,
                                  currentNotesId: null
                                }
                              });
                            }
                            dispatch({
                              type: "loading",
                              payload: false
                            });
                          }}
                        />
                      </section>
                    </>
                  )}
                </>
              )}
            </section>
          </div>
        </div>
      </div>
    </Modal>
  );
}

//pdf notes component
const PdfNotes = (props) => {
  /**
   * loading => to show suspense
   * notesButtonOnClick => onClick fun callback of notes button
   * document => document data object
   * currentNotesId => selected notes id
   * addNotesOnClick => add notes button onClick fun
   * showAddNotes => to show add notes button  `true` or `false`
   */
  return props.loading === true ? (
    <PdfNotesSuspense {...props} />
  ) : (
    props.show === true &&
      isValidObject(props.document) &&
      props.document.notes &&
      isValidObject(props.document.notes) && (
        <section
          className={`background-color-secondary margin-horizontal-auto inherit-parent-width padding-left-large padding-right-large footer background-color-grey inherit-parent-width padding-bottom-small`}
          data-cy="doctors-notes-dropdown"
        >
          <p className="font-family-gilroy-regular font-color-secondary font-size-small padding-top-default">
            Notes by doctors
          </p>
          <div className=" inherit-parent-width flex-justify-content-space-between">
            <div
              className={`${
                props.showAddNotes === true
                  ? "width-85-percentage"
                  : "inherit-parent-width "
              } border-radius-default overflow-x-auto padding-top-default padding-bottom-default overflow-x-auto inherit-parent-height display-flex flex-align-items-center`}
            >
              {isValidObject(props.document.notes) &&
                Object.keys(props.document.notes).map((doctorId, index) => {
                  return (
                    <React.Fragment key={doctorId}>
                      <button
                        key={index}
                        className={` cursor-pointer white-space-nowrap padding-top-small padding-bottom-small border-1px-e5e5e5 padding-left-default padding-right-default margin-right-small border-radius-default font-family-gilroy-regular  font-size-small
                                       ${
                                         doctorId === props.currentNotesId
                                           ? "background-primary font-color-white "
                                           : "background-color-secondary font-color-secondary "
                                       }`}
                        onClick={() => {
                          if (typeof props.notesButtonOnClick === "function") {
                            //returns notes id
                            props.notesButtonOnClick(doctorId);
                          }
                        }}
                        data-cy={
                          props.document.notes[doctorId].authorName &&
                          props.document.notes[doctorId].authorName
                            .split(" ")
                            .join("-")
                        }
                      >
                        {props.document.notes[doctorId].authorName &&
                          props.document.notes[doctorId].authorName}
                      </button>
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
        </section>
      )
  );
};

function PdfNotesSuspense(props) {
  return (
    <section
      className="background-color-secondary margin-horizontal-auto inherit-parent-width padding-left-large padding-right-large  inherit-parent-width padding-bottom-large"
      data-cy="pdf-notes-suspense"
    >
      <div
        className="shimmer border-radius-default padding-default"
        style={{ width: "20%" }}
      ></div>
      <div
        className={`display-flex flex-justify-content-space-between ${
          props.showAddNotes !== true ? "padding-top-default" : ""
        }`}
        style={{ alignItems: "flex-end" }}
      >
        <div
          className={`display-flex ${
            props.showAddNotes === true
              ? "width-85-percentage"
              : "inherit-parent-width "
          }`}
          style={{ height: "30%" }}
        >
          <span
            className="shimmer padding-default margin-right-large border-radius-default"
            style={{ width: "100%" }}
          ></span>
        </div>
        {props.showAddNotes === true && (
          <div className="shimmer pdf-add-notes-button-size border-radius-default"></div>
        )}
      </div>
    </section>
  );
}

const InputField = (field) => {
  return (
    <section className=" font-color-secondary font-family-gilroy-regular inherit-parent-width padding-top-medium padding-bottom-medium margin-horizontal-auto max-width-588px ">
      <div className=" letter-spacing-4-percentage font-size-smaller padding-bottom-small text-transform-uppercase font-weight-400 ">
        {field.name}
      </div>
      <div className=" letter-spacing-4-percentage font-size-medium padding-top-small text-transform-capitalize font-weight-400 ">
        {!!field?.value
          ? field.name === "date of birth" || field.type === "timestamp"
            ? new Date(field.value).toISOString().split("T")[0]
            : Array.isArray(field.value) && field.value.length > 0
            ? field.value.map(
                (val, index) =>
                  val + `${index !== field.value.length - 1 ? ", " : ""}`
              )
            : field.value
          : "-"}
      </div>
    </section>
  );
};

function ViewSymptomSuspense() {
  return (
    <article
      data-cy="view-document-suspense"
      className="screen-loader-wrapper inherit-parent-width  flex-center-children-horizontally"
    >
      <main className="inherit-parent-width padding-medium ">
        <section className="inherit-parent-width flex-place-children-page-center height-10vh">
          <div className="shimmer padding-larger margin-default border-radius-default width-80percentage" />
        </section>
        <section className="shimmer height-60vh border-radius-default" />
        <section className="inherit-parent-width flex-place-children-page-center height-10vh ">
          <div className="shimmer padding-larger margin-default border-radius-default width-80percentage" />
        </section>
      </main>
    </article>
  );
}

function SymptomImageSuspense(props) {
  return (
    <article
      data-cy="symptom-images-suspense"
      className="screen-loader-wrapper inherit-parent-width"
    >
      <div className="shimmer border-radius-default padding-default width-40-percentage margin-bottom-medium" />
      <ul className="inherit-parent-width flex-direction-row list-style-type-none column-gap-3-percentage">
        <li
          className="shimmer border-radius-default"
          style={{
            width: `${props.width}px`,
            height: `${props.height}px`
          }}
        />
        <li
          className="shimmer border-radius-default"
          style={{
            width: `${props.width}px`,
            height: `${props.height}px`
          }}
        />
        <li
          className="shimmer border-radius-default"
          style={{
            width: `${props.width}px`,
            height: `${props.height}px`
          }}
        />
      </ul>
    </article>
  );
}
