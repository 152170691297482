const documentSearchReducer = (
  state = {
    loading: false,
    searchedDocumentPhoneNumber: null,
    selectedDocument: null,
    data: null
  },
  action
) => {
  switch (action.type) {
    case "SET_DOCUMENT_SEARCH_LOADING":
      return {
        ...state,
        loading: action.payload.loading
      };

    case "SET_SEARCHED_DOCUMENT_PHONE_NUMBER":
      return {
        ...state,
        searchedDocumentPhoneNumber: action.payload.phoneNumber
      };

    case "SET_SELECTED_DOCUMENT":
      return {
        ...state,
        selectedDocument: action.payload.selectedDocument
      };

    case "SET_DOCUMENT_SEARCH_DATA":
      return {
        ...state,
        data: action.payload.data
      };

    case "CLEAR_DOCUMENT_SEARCH_DATA":
      return {
        ...state,
        searchedDocumentPhoneNumber: null,
        selectedDocument: null,
        data: null
      };

    case "CLEAR_SELECTED_DOCUMENT":
      return {
        ...state,
        searchedDocumentPhoneNumber: null,
        selectedDocument: null
      };

    case "RESET":
      return {
        loading: false,
        data: null,
        searchedDocumentPhoneNumber: null,
        selectedDocument: null
      };

    default:
      return state;
  }
};

export default documentSearchReducer;
