import { all, put, takeEvery } from "@redux-saga/core/effects";
import store from "../store/store";
import { getFileUrl } from "../../services/storage";
import { setErrorStatus } from "../status/actions";
import { getPaginatedDocuments } from "../../services/database";
import { isValidObject } from "../../utils/validators";

export const historyActionTypes = {
  SET_UPLOAD_HISTORY: "SET_UPLOAD_HISTORY",
  UPDATE_UPLOAD_HISTORY: "UPDATE_UPLOAD_HISTORY",
  SET_DOWNLOAD_URL: "SET_DOWNLOAD_URL"
};

export function* setHistoryWorker(action) {
  try {
    yield setHistoryLoading(true);
    yield put({
      type: "SET_HISTORY_DATA",
      payload: {
        data: isValidObject(store.getState().history.documents)
          ? { ...store.getState().history.documents, ...action.payload.data }
          : action.payload.data
      }
    });

    yield put({
      type: "SET_PAGINATION_DATA",
      payload: {
        data:
          store.getState().history.pagination !== null &&
          isValidObject(store.getState().history.pagination)
            ? {
                ...store.getState().history.pagination,
                ...{
                  [action.payload.clinicId]: {
                    0: action.payload.lastVisible
                  }
                }
              }
            : { [action.payload.clinicId]: { 0: action.payload.lastVisible } }
      }
    });

    yield setHistoryLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setHistoryLoading(false);
  }
}

export function* updateUploadHistoryWorker(action) {
  try {
    yield setUpdateHistoryLoading(true);
    const clinicId = store.getState().connection.currentConnection;
    const existingData = store.getState().history.documents[clinicId];
    const existingPagination = store.getState().history.pagination[clinicId];

    const lastDocumentPageNumber = action.payload?.lastDocPageNumber
      ? action.payload?.lastDocPageNumber
      : isValidObject(store.getState().history.documents) &&
        Object.values(store.getState().history?.documents[clinicId])[
          [
            Object.values(store.getState().history.documents[clinicId]).length -
              1
          ]
        ]?.pageNumber;

    const paginationData = action.payload?.lastDocPageNumber
      ? Object.values(existingPagination)[action.payload.lastDocPageNumber - 1]
      : Object.values(existingPagination)[lastDocumentPageNumber];

    if (
      clinicId &&
      paginationData &&
      typeof lastDocumentPageNumber === "number"
    ) {
      const paginationDocument = yield getPaginatedDocuments(
        clinicId,
        paginationData,
        lastDocumentPageNumber,
        action.payload?.lastDocPageNumber ? true : false
      );

      if (paginationDocument.documents) {
        yield put({
          type: "SET_HISTORY_DATA",
          payload: {
            data: {
              ...store.getState().history.documents,
              [store.getState().connection.currentConnection]: {
                ...existingData,
                ...paginationDocument.documents
              }
            }
          }
        });
      }

      if (!action.payload?.lastDocPageNumber) {
        if (
          isValidObject(paginationDocument.lastVisible) ||
          paginationDocument.lastVisible === "end"
        ) {
          yield put({
            type: "SET_PAGINATION_DATA",
            payload: {
              data: {
                ...store.getState().history.pagination,
                [store.getState().connection?.currentConnection]: {
                  ...existingPagination,
                  [lastDocumentPageNumber + 1]: paginationDocument.lastVisible
                }
              }
            }
          });
        }
      }
    }
    yield setUpdateHistoryLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setUpdateHistoryLoading(false);
  }
}

export function* setDownloadUrlWorker(action) {
  try {
    if (action.payload.url) {
      yield setDownloadURLLoading(true);

      const downloadUrl = action.payload.url
        ? yield getFileUrl(action.payload.url)
        : "";

      const history = store.getState().history.documents;

      if (history) {
        const documentData =
          history[store.getState().connection.currentConnection][
            action?.payload?.documentId
          ];

        yield put({
          type: "SET_HISTORY_DATA",
          payload: {
            data: {
              ...history,
              [store.getState().connection.currentConnection]: {
                ...history[store.getState().connection.currentConnection],
                [action.payload.documentId]: {
                  ...documentData,
                  downloadUrl: downloadUrl
                }
              }
            }
          }
        });
      }
    }

    yield setDownloadURLLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setDownloadURLLoading(false);
  }
}
//upload document request worker

export function* historyWatcher() {
  yield all([
    takeEvery("SET_UPLOAD_HISTORY", setHistoryWorker),
    takeEvery("UPDATE_UPLOAD_HISTORY", updateUploadHistoryWorker),
    takeEvery("SET_DOWNLOAD_URL", setDownloadUrlWorker)
  ]);
}

function* setHistoryLoading(value) {
  yield put({
    type: "SET_HISTORY_LOADING",
    payload: {
      loading: value
    }
  });
}

function* setUpdateHistoryLoading(value) {
  yield put({
    type: "SET_UPDATE_HISTORY_LOADING",
    payload: {
      loading: value
    }
  });
}

function* setDownloadURLLoading(value) {
  yield put({
    type: "SET_DOWNLOAD_URL_LOADING",
    payload: {
      loading: value
    }
  });
}
