import React, { useState, useEffect } from "react";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import { isValidObject } from "../../utils/validators";
import Button from "../../front-end-global-components/components/Button/Button";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import CropperModal from "../../front-end-global-components/components/CropperModal/CropperModal";

export default function EditPersonalProfile(props) {
  const [profileData, setProfileData] = useState({});
  const [isValidFormData, setIsValidFormData] = useState({});
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (isValidObject(props.data)) {
      setProfileData({
        fullName: props.data.fullName,
        ...(props.data.email && { email: props.data.email }),
        ...(props.data.dateOfBirth && {
          dateOfBirth: new Date(props.data.dateOfBirth)
            .toISOString()
            .slice(0, 10)
        })
      });

      setIsValidFormData({
        fullName: true,
        ...(props.data.email && { email: true }),
        ...(props.data.dateOfBirth && {
          dateOfBirth: true
        })
      });
    }
  }, [props.data, props.profilePicture]);

  function saveButtonDisable() {
    if (
      isValidObject(profileData) &&
      (!!profileData.profilePicture
        ? (props.data.fullName !== profileData.fullName ||
            props.data.fullName === profileData.fullName) &&
          isValidFormData.fullName
        : props.data.fullName !== profileData.fullName &&
          isValidFormData.fullName)
    ) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <>
      <div className=" padding-larger">
        <div className=" display-flex flex-justify-content-center ">
          <RoundedProfilePicture
            editMode={true}
            size="medium"
            src={
              profileData.profilePicture
                ? URL.createObjectURL(profileData.profilePicture)
                : props.data.profilePictureUrl
            }
            setFile={(file) => {
              setFile(file);
            }}
          />
        </div>

        <form
          onChange={(event) => {
            setProfileData({
              ...profileData,
              [event.target.name]: event.target.value
            });
          }}
          onSubmit={(event) => {
            event.preventDefault();
            props.editPersonalProfile(profileData);
          }}
        >
          <div className="padding-top-larger">
            <InputBox
              removeResponsive={true}
              className="inherit-parent-width  border-radius-default"
              type="text"
              name="fullName"
              label="Full name"
              value={profileData?.fullName}
              data-cy="fullName-field"
              isValidInput={(value) => {
                setIsValidFormData({
                  ...isValidFormData,
                  fullName: value
                });
              }}
            />
          </div>
          <div className="flex-justify-content-space-between  padding-top-large">
            <div className="inherit-parent-width font-family-gilroy-bold padding-bottom-large">
              <Button
                type="submit"
                data-cy="save-button"
                boxShadow={false}
                className="max-width-160px max-height-50px"
                text="Save"
                disabled={saveButtonDisable()}
              />
            </div>

            <div className="inherit-parent-width padding-left-large font-family-gilroy-medium padding-bottom-large">
              <Button
                className="max-width-160px max-height-50px"
                boxShadow={false}
                text="Cancel"
                variant="secondary"
                onClick={props.cancelButtonClick}
              />
            </div>
          </div>
        </form>
      </div>
      <Modal
        show={file !== null}
        canIgnore={true}
        onClose={() => {
          setFile(null);
        }}
        width="inherit-parent-width"
        maxWidth="max-width-800px"
        background="false"
        boxShadow="false"
        borderRadius="false"
        height="height-90-percent"
      >
        <div
          data-cy="cropper-modal"
          className={`background-white inherit-parent-height ${"border-radius-default"} box-shadow-default font-family-gilroy-regular font-color-secondary`}
        >
          <CropperModal
            className=" border-radius-default"
            aspectRatio={1}
            OnBlobCreate={(blob) => {
              const imageFile = new File([blob], `${+new Date()}.png`, {
                type: "image/png"
              });
              setProfileData({ ...profileData, profilePicture: imageFile });
            }}
            file={file}
            // imageFormate="jpeg"
            // exportSettings={{ height: 128, width: 128 }}
            setFile={() => {
              setFile(null);
            }}
          />
        </div>
      </Modal>
    </>
  );
}
