import { all, put, takeEvery } from "@redux-saga/core/effects";
import store from "../store/store";
import {
  getPaginatedNotifications,
  setNotificationRead
} from "../../services/database";
import { setErrorStatus } from "../status/actions";
// import { showPrimary, showSecondary } from "../navigation/actions";
import { addSelectedDocument } from "../documentSearch/actions";
import { isValidObject } from "../../utils/validators";
import { updateNotifications } from "./actions";

export const notificationActionTypes = {
  SET_NOTIFICATION: "SET_NOTIFICATION",
  UPDATE_NOTIFICATION: "UPDATE_NOTIFICATION",
  ADD_SELECTED_NOTIFICATION: "ADD_SELECTED_NOTIFICATION",
  SET_NOTIFICATION_READ: "SET_NOTIFICATION_READ"
};

function* setNotificationWorker(action) {
  try {
    setNotificationLoading(true);

    yield put({
      type: "SET_NOTIFICATION_DATA",
      payload: {
        data: isValidObject(store.getState().notification.data)
          ? { ...store.getState().notification.data, ...action.payload.data }
          : action.payload.data
      }
    });

    yield put({
      type: "SET_NOTIFICATION_PAGINATION",
      payload: {
        data:
          store.getState().notification.pagination !== null &&
          isValidObject(store.getState().notification.pagination)
            ? {
                ...store.getState().notification.pagination,
                ...{
                  0: action.payload.lastVisible
                }
              }
            : { 0: action.payload.lastVisible }
      }
    });

    setNotificationLoading(false);
  } catch (error) {
    setErrorStatus(error);
    setNotificationLoading(false);
  }
}

function* updateNotificationWorker(action) {
  try {
    yield setNotificationPaginationLoading(true);
    const profileId = Object.keys(store.getState().profile.data)[0];
    const existingData = store.getState().notification.data;
    const existingPagination = store.getState().notification.pagination;

    const lastDocumentPageNumber = action.payload?.lastDocPageNumber
      ? action.payload?.lastDocPageNumber
      : isValidObject(store.getState().notification.data) &&
        store.getState().notification?.data[
          Object.keys(store.getState().notification.data).pop()
        ]?.pageNumber;
    const paginationData = action.payload?.lastDocPageNumber
      ? existingPagination[action.payload.lastDocPageNumber - 1]
      : existingPagination[lastDocumentPageNumber];

    if (
      profileId &&
      paginationData &&
      typeof lastDocumentPageNumber === "number"
    ) {
      const paginationNotification = yield getPaginatedNotifications(
        profileId,
        paginationData,
        lastDocumentPageNumber,
        action.payload?.lastDocPageNumber ? true : false
      );

      if (paginationNotification.notifications) {
        yield put({
          type: "SET_NOTIFICATION_DATA",
          payload: {
            data: {
              ...existingData,
              ...paginationNotification.notifications
            }
          }
        });
      }

      if (!action.payload?.lastDocPageNumber) {
        if (
          isValidObject(paginationNotification.lastVisible) ||
          paginationNotification.lastVisible === "end"
        ) {
          yield put({
            type: "SET_NOTIFICATION_PAGINATION",
            payload: {
              data: {
                ...existingPagination,
                [lastDocumentPageNumber + 1]: paginationNotification.lastVisible
              }
            }
          });
        }
      }
    }

    yield setNotificationPaginationLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setNotificationPaginationLoading(false);
  }
}

function* setSelectedNotificationWorker(action) {
  try {
    yield put({
      type: "SET_SELECTED_NOTIFICATION",
      payload: {
        data: action.payload.data.documentId
      }
    });

    if (action.payload.data.title === "Document Error") {
      addSelectedDocument(action.payload.data?.documentId, "history");
    }

    // showPrimary("documentSearch");
    // showSecondary("selectedDocument");
  } catch (error) {
    setErrorStatus(error);
  }
}

function* setNotificationReadWorker(action) {
  try {
    const profileId = Object.keys(store.getState().profile.data)[0];
    if (profileId) {
      const response = yield setNotificationRead(
        action.payload.notification.documentId,
        profileId
      );
      if (response.success === true) {
        yield updateNotifications(action.payload.notification.pageNumber);
      }
    }
  } catch (error) {
    setErrorStatus(error);
  }
}

export function* notificationWatcher() {
  yield all([takeEvery("SET_NOTIFICATION", setNotificationWorker)]);
  yield all([takeEvery("UPDATE_NOTIFICATION", updateNotificationWorker)]);
  yield all([
    takeEvery("ADD_SELECTED_NOTIFICATION", setSelectedNotificationWorker)
  ]);
  yield all([takeEvery("SET_NOTIFICATION_READ", setNotificationReadWorker)]);
}

function* setNotificationLoading(value) {
  yield put({
    type: "SET_NOTIFICATION_LOADING",
    payload: {
      loading: value
    }
  });
}

function* setNotificationPaginationLoading(value) {
  yield put({
    type: "SET_NOTIFICATION_PAGINATION_LOADING",
    payload: {
      loading: value
    }
  });
}
