import store from "../store/store";
import { abdmORActionTypes } from "./saga";

/**
 * @module AbdmQRSaga
 */

/**
 * Send OTP for linking
 * @param {object} data
 */
export function sendOtpForLinking(data) {
  store.dispatch({
    type: abdmORActionTypes.SEND_OTP_FOR_LINKING,
    payload: data
  });
}

/**
 * Confirm OTP for linking
 * @param {object} data
 */
export function confirmOtpForLinking(data) {
  store.dispatch({
    type: abdmORActionTypes.CONFIRM_OTP_LINKING,
    payload: data
  });
}

/**
 * Connect by using patient demographic
 * @param {object} data
 */
export function connectPatientByDemographics(data) {
  store.dispatch({
    type: abdmORActionTypes.CONNECT_USING_DEMOGRAPHIC,
    payload: data
  });
}

/**
 * Clear access token of send OTP for linking
 */
export function clearQRaccessToken() {
  store.dispatch({
    type: abdmORActionTypes.CLEAR_QR_ACCESS_TOKEN
  });
}
