import React, { useState, useEffect } from "react";
// import RoundedProfilePicture from "../roundedProfilePicture/RoundedProfilePicture";
import { isValidObject } from "../../utils/validators";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";

export default function DocumentSearch(props) {
  const [searchHistoryData, setSearchHistoryData] = useState("");
  const [showSearchData, setShowSearchData] = useState(false);
  const [showHistory, setShowHistory] = useState(true);
  const [isNumberValid, setIsNumberValid] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  // set search results from props.documentSearch.data
  useEffect(() => {
    if (isValidObject(props.documentSearch.data)) {
      setSearchResults(
        Object.entries(props.documentSearch.data).map(
          ([documentId, documentData]) => ({
            documentId: documentId,
            ...documentData
          })
        )
      );
    }
  }, [props.documentSearch.data]);

  //onmount remove document search data
  useEffect(() => {
    return () => {
      props.removeDocumentSearchData(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //when clicking on notification open history and show document
  useEffect(() => {
    if (
      isValidObject(props.notification.selectedNotification) &&
      typeof props.notification.selectedNotification.documentId === "string" &&
      typeof props.documentSearch.selectedDocument === "string" &&
      isValidObject(props.history.documents)
    ) {
      const documentHistory = props.history.documents;

      const historyDocumentData = documentHistory.find(
        (documentData) =>
          documentData.documentId ===
          props.notification.selectedNotification.documentId
      );

      if (!isValidObject(historyDocumentData)) {
        if (showHistory) {
          setShowHistory(false);
        }
      } else {
        if (isValidObject(props.documentSearch.data)) {
          props.removeDocumentSearchData(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.documentSearch, props.documentSearch.selectedDocument]);

  function handleSearchOnClick(event) {
    event.preventDefault();
    if (isNumberValid) {
      props.getSearchDocumentData(searchHistoryData);
      setShowSearchData(true);
      setShowHistory(false);
      props.showSecondary(null);
    }
  }

  function searchHistoryFormChange(event) {
    if (
      props.documentSearch.searchedDocumentPhoneNumber !== "" ||
      props.documentSearch.searchedDocumentPhoneNumber !== null
    ) {
      props.removeDocumentSearchData();
    }
    if (event.target.value.length === 13) {
      setSearchHistoryData(event.target.value);
    }
  }

  function dateAndTimeConverter(timestamp) {
    if (!timestamp) return;
    let dateObject = new Date(timestamp);
    const date =
      dateObject.toLocaleString("en-IN", { day: "numeric" }) +
      " " +
      dateObject.toLocaleString("en-IN", { month: "short" }) +
      " " +
      dateObject.toLocaleString("en-IN", { year: "numeric" });
    return date;
  }

  function handleDocumentOnClick(document, type) {
    if (type === "search") {
      props.addSelectedDocument(document.documentId, "search");
      props.showSecondary("selectedDocument");
    }
    if (type === "history") {
      props.addSelectedDocument(document.documentId, "history");
      props.showSecondary("selectedDocument");
    }
  }

  const showSuspense = () => {
    if (
      props.history.loading === true ||
      props.documentSearch.loading === true ||
      props.history.documents === null
    ) {
      return true;
    } else {
      return false;
    }
  };

  function handleScroll(event) {
    event.stopPropagation();
    const bottom =
      parseInt(event.target.scrollHeight - event.target.scrollTop) ===
      event.target.clientHeight;
    if (bottom) {
      if (
        !Object.values(
          props.history.pagination[props.connection.currentConnection]
        ).includes("end")
      ) {
        props.updateUploadHistory();
      }
    }
  }

  return (
    <div className="inherit-parent-height" data-cy="documentSearch">
      <form
        onChange={(event) => {
          searchHistoryFormChange(event);
        }}
      >
        <InputBox
          className="inherit-parent-width max-width-300px border-radius-default padding-bottom-large"
          name="phoneNumber"
          data-cy="upload-history-number-field"
          defaultValue={"+91"}
          label="Phone Number"
          type="text"
          buttonType="submit"
          buttonTile="Search"
          buttonTileId="search-button"
          onButtonClick={(event) => {
            handleSearchOnClick(event);
          }}
          removeResponsive={true}
          isValidInput={(valid) => {
            setIsNumberValid(valid);
          }}
          disabled={true}
          required
        />
      </form>

      <>
        {searchResults && props.documentSearch.searchedDocumentPhoneNumber && (
          <div className="background-white calc-height-100-percentage-50-px overflow-auto invisible-scrollbar border-radius-default">
            <div className="search-patient font-family-gilroy-medium font-size-medium list-style-none text-align-left">
              {showSuspense() ? (
                <DocumentSearchSuspense />
              ) : (
                <div className=" inherit-parent-width">
                  {searchResults &&
                  props.documentSearch.searchedDocumentPhoneNumber &&
                  searchResults.length > 0 ? (
                    searchResults.map((documentData, index) => (
                      <div
                        className="border-radius-default cursor-pointer inherit-parent-width"
                        key={index}
                        data-cy="documentSearchResults"
                      >
                        <div
                          data-cy={`search-result-${documentData.name}`}
                          className={`${
                            isValidObject(
                              props.documentSearch.selectedDocument
                            ) &&
                            Object.keys(
                              props.documentSearch.selectedDocument
                            )[0] === documentData.documentId
                              ? "background-color-grey"
                              : "background-white"
                          } padding-right-large inherit-parent-width border-radius-default  `}
                          onClick={() => {
                            handleDocumentOnClick(documentData, "search");
                          }}
                        >
                          <div className="flex-center-children-vertically">
                            {/* <div className="padding-default">
																			<RoundedProfilePicture size="smaller" />
																		</div> */}
                            <div className=" padding-left-default inherit-parent-width font-family-gilroy-medium ">
                              <div className="font-color-tertiary font-size-small padding-top-default padding-bottom-smaller">
                                {documentData?.patientName
                                  ? documentData.patientName
                                  : "user"}
                                &nbsp;.
                                <time className="padding-left-small">
                                  {dateAndTimeConverter(
                                    documentData &&
                                      documentData.to &&
                                      documentData.timestamp
                                  )}
                                </time>
                              </div>
                              <div
                                data-cy={`searched-doc-${index}`}
                                className={`${
                                  searchResults.length > 1 &&
                                  searchResults.slice(-1)[0].documentId ===
                                    documentData.documentId
                                    ? ""
                                    : "border-bottom-2px"
                                } font-color-secondary font-size-medium padding-bottom-default`}
                              >
                                {documentData.name}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="font-color-secondary font-size-medium padding-default flex-center-children-horizontally">
                      No results found
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </>
      {showHistory &&
        !showSearchData &&
        !isValidObject(props.documentSearch.data) && (
          <div
            className="background-white calc-height-100-percentage-50-px overflow-auto invisible-scrollbar border-radius-default"
            onScroll={(event) => {
              handleScroll(event);
            }}
            data-cy="documentHistoryResults"
          >
            <div className="search-patient font-family-gilroy-medium font-size-medium list-style-none text-align-left">
              {showSuspense() ? (
                <UploadHistorySuspense />
              ) : (
                <div className="inherit-parent-width">
                  {props.history?.documents &&
                  isValidObject(props.history?.documents) &&
                  isValidObject(
                    props.history?.documents[
                      props.connection?.currentConnection
                    ]
                  ) ? (
                    <>
                      {props.connection?.currentConnection &&
                        props.history?.documents[
                          props.connection?.currentConnection
                        ] &&
                        Object.values(
                          props.history?.documents[
                            props.connection?.currentConnection
                          ]
                        ).map((documentData, index) =>
                          documentData?.processing === true ? (
                            <div key={index}>
                              <ProcessingSuspense />
                            </div>
                          ) : (
                            <>
                              <div
                                className={`border-radius-default cursor-pointer inherit-parent-width `}
                                data-cy={`history-result-${documentData?.documentName}`}
                                key={index}
                              >
                                <div
                                  className={`${
                                    props.documentSearch.selectedDocument &&
                                    props.documentSearch.selectedDocument ===
                                      documentData.documentId
                                      ? "background-color-grey"
                                      : "background-white"
                                  }
                                ${
                                  props.documentSearch?.selectedDocument &&
                                  props.documentSearch?.selectedDocument ===
                                    documentData?.documentId
                                    ? ""
                                    : "border-bottom-2px"
                                }
                                padding-right-large inherit-parent-width border-radius-default`}
                                  onClick={() => {
                                    handleDocumentOnClick(
                                      documentData,
                                      "history"
                                    );
                                  }}
                                >
                                  <div
                                    className={`flex-center-children-vertically`}
                                  >
                                    <div className="padding-default">
                                      <RoundedProfilePicture size="smaller" />
                                    </div>
                                    <div className="padding-left-default inherit-parent-width font-family-gilroy-medium ">
                                      <div className="font-color-tertiary font-size-small padding-top-default padding-bottom-smaller">
                                        {documentData?.patient?.name
                                          ? documentData?.patient?.name
                                          : documentData?.patient?.healthId}
                                        &nbsp;.
                                        <time className="padding-left-small">
                                          {dateAndTimeConverter(
                                            documentData &&
                                              documentData?.timestamp
                                          )}
                                        </time>
                                      </div>
                                      <div className="inherit-parent-width display-flex flex-justify-content-space-between">
                                        <div
                                          className={`font-color-secondary font-size-medium padding-bottom-default`}
                                        >
                                          {documentData &&
                                            documentData.documentName}
                                        </div>
                                        {documentData?.redacted &&
                                          isValidObject(
                                            documentData?.redacted
                                          ) && (
                                            <div className="background-failed  font-color-red height-fit-content padding-top-smaller padding-bottom-smaller padding-left-default padding-right-default border-radius-default font-size-smaller">
                                              Redacted
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        )}
                      {props.history.paginationLoading && (
                        <div
                          data-cy="pagination-loading"
                          className="display-flex flex-justify-content-center flex-center-children-vertically padding-top-large padding-bottom-large height-fit-content"
                        >
                          <div className="dot-pulse-primary margin-right-larger inherit-parent-height " />
                          <div className="text-align-center font-size-smaller">
                            LOADING MORE...
                          </div>
                        </div>
                      )}
                      {Object.values(
                        props.history.pagination[
                          props.connection.currentConnection
                        ]
                      ).includes("end") && (
                        <div className="text-align-center padding-top-large padding-bottom-large">
                          No further records available
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="font-color-secondary font-size-medium padding-default flex-center-children-horizontally">
                      No documents uploaded yet
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
    </div>
  );
}

function UploadHistorySuspense() {
  return (
    <article>
      <div className="inherit-parent-width">
        {[...Array(6).keys()].map((index) => (
          <div
            className="border-bottom-2px flex-center-children-vertically"
            key={index}
          >
            <div className="shimmer inherit-parent-width padding-small margin-vertical-default border-radius-default">
              <div className="padding-default margin-default border-radius-default" />
            </div>
          </div>
        ))}
      </div>
    </article>
  );
}

function DocumentSearchSuspense() {
  return (
    <article>
      <div className="inherit-parent-width">
        {[...Array(4).keys()].map((index) => (
          <div
            className="padding-top-default border-bottom-2px padding-bottom-small"
            key={index}
          >
            <div className="shimmer inherit-parent-width padding-small margin-vertical-default border-radius-default">
              <div className="padding-default margin-default border-radius-default" />
            </div>
          </div>
        ))}
      </div>
    </article>
  );
}

function ProcessingSuspense() {
  return (
    <article className="margin-bottom-large">
      <section className="padding-top-default border-bottom-2px padding-bottom-small">
        <div className="shimmer inherit-parent-width padding-small margin-vertical-default border-radius-default">
          <div className="padding-default margin-default border-radius-default" />
        </div>
      </section>
    </article>
  );
}
