import { all, put, takeEvery } from "@redux-saga/core/effects";
import { addStaff, getStaffs, removeStaff } from "../../services/api";
import { getBrandDataById } from "../../services/database";
import { throwError } from "../../services/error";
import { bucketNames, getFileUrl } from "../../services/storage";
import { isValidObject } from "../../utils/validators";
import {
  showPrimary,
  showSecondary,
  showTertiary
} from "../navigation/actions";
import { setErrorStatus, setSuccessStatus } from "../status/actions";
import store from "../store/store";
import {
  getBrandDownloadableUrl,
  getConnectedStaffs,
  setCurrentConnection
} from "./actions";

export const connectionActionTypes = {
  SEND_INVITE: "SEND_INVITE",
  ACCEPT_INVITE: "ACCEPT_INVITE",
  NINTO_SEND_INVITE: "NINTO_SEND_INVITE",
  NINTO_ACCEPT_INVITE: "NINTO_ACCEPT_INVITE",
  SET_CONNECTIONS: "SET_CONNECTIONS",
  SET_SELECTED_CLINIC: "SET_SELECTED_CLINIC",
  SET_CURRENT_CONNECTION: "SET_CURRENT_CONNECTION",
  GET_BRAND_DATA: "GET_BRAND_DATA",
  GET_BRAND_LOGO: "GET_BRAND_LOGO",
  GET_CONNECTED_STAFFS: "GET_CONNECTED_STAFFS",
  CREATE_NEW_STAFF: "CREATE_NEW_STAFF",
  REMOVE_STAFF: "REMOVE_STAFF",
  GET_CORPORATE_PACKAGES: "GET_CORPORATE_PACKAGES"
};

function* setConnectionsWorker(action) {
  try {
    yield setConnectionsLoading(true);
    yield put({
      type: "SET_CONNECTIONS_DATA",
      payload: {
        data: action.payload.data
      }
    });
    const connectionData = store.getState().connection.data;

    if (
      connectionData &&
      isValidObject(connectionData) &&
      !store.getState().connection.currentConnection
    ) {
      const validConnection = Object.values(connectionData).find(
        (data) => typeof data.hfrId === "string" && data.verified === true
      );
      if (isValidObject(connectionData[validConnection?.connectionId])) {
        setCurrentConnection(
          connectionData[validConnection?.connectionId]?.connectionId
        );
      } else {
        setCurrentConnection(
          Object.keys(connectionData)[Object.keys(connectionData).length - 1]
        );
      }
    }

    if (
      Object.keys(connectionData).length === action.payload.data.length + 1 ||
      !isValidObject(connectionData)
    ) {
      let newConnectionId = "";
      const newKeys = Object.keys(action.payload.data);
      Object.values(connectionData).forEach((element) => {
        if (!newKeys.includes(element.connectionId)) {
          return (newConnectionId = element.connectionId);
        }
      });

      setCurrentConnection(newConnectionId);
    }
    yield setConnectionsLoading(false);
  } catch (error) {
    yield setErrorStatus(error);
    yield setConnectionsLoading(false);
  }
}

function* setCurrentConnectionWorker(action) {
  try {
    yield setConnectionsLoading(true);
    yield put({
      type: "SET_CURRENT_CONNECTION_DATA",
      payload: {
        data: action.payload.data
      }
    });
    if (store.getState().navigation.primary !== null) {
      showPrimary(null);
    }
    if (store.getState().navigation.secondary !== null) {
      showSecondary(null);
    }
    if (store.getState().navigation.tertiary !== null) {
      showTertiary(null);
    }
    yield setConnectionsLoading(false);
  } catch (error) {
    yield setErrorStatus(error);
    yield setConnectionsLoading(false);
  }
}

function* getConnectedStaffWorker(action) {
  try {
    // if (isOnline()) {
    // if (
    //   isValidObject(store.getState().connection.data) &&
    //   store.getState().connection.data[
    //     store.getState().connection.currentConnection
    //   ].userType === "staff"
    // ) {
    //   throw throwError("custom", "Permission denied");
    // }
    yield setConnectionsLoading(true);
    const response = yield getStaffs(
      action.payload.data.branchId,
      store.getState().auth.data.accessToken
    );
    let staffs = {};
    response.result.forEach((staff) => {
      staffs[staff.profileId] = staff;
    });
    yield put({
      type: "SET_CONNECTED_STAFF_DATA",
      payload: {
        data: staffs
      }
    });
    yield setConnectionsLoading(false);
    // }
  } catch (error) {
    yield setErrorStatus(error);
    yield setConnectionsLoading(false);
  }
}

function* createNewStaffWorker(action) {
  try {
    if (!isValidObject(store.getState().connection?.data)) {
      return;
    }

    if (
      action.payload.data.phoneNumber ===
        store.getState().auth.data.phoneNumber ||
      (store.getState().connection.connectedStaffs &&
        Object.values(store.getState().connection.connectedStaffs).some(
          (element) => element.phoneNumber === action.payload.data.phoneNumber
        ))
    ) {
      throw throwError("custom", "Phone number already exist");
    }

    yield setConnectionsLoading(true);
    const branchData =
      isValidObject(store.getState().connection?.data) &&
      Object.values(store.getState().connection?.data)?.find(
        (connection) => connection.connectionId === action.payload.data.branchId
      );
    const result = yield addStaff(
      {
        entityData: {
          id: action.payload.data.branchId,
          brandId: branchData.brandId,
          name: branchData.companyName,
          type: "clinic"
        },
        staffData: {
          type: action.payload.data.userType,
          phoneNumber: action.payload.data.phoneNumber
        },
        method: "add"
      },
      store.getState().auth.data.accessToken
    );
    yield getConnectedStaffs({
      branchId: action.payload.data.branchId
    });
    if (result.success === true) {
      setSuccessStatus(
        `${
          action.payload.data.userType.charAt(0).toUpperCase() +
          action.payload.data.userType.slice(1)
        }
         added successfully`
      );
    }
    yield setConnectionsLoading(false);
  } catch (error) {
    yield setErrorStatus(error);
    yield setConnectionsLoading(false);
  }
}

function* removeStaffWorker(action) {
  try {
    if (!isValidObject(store.getState().connection.data)) {
      return;
    }
    yield setConnectionsLoading(true);
    const result = yield removeStaff(
      action.payload.data.branchId,
      action.payload.data.phoneNumber,
      store.getState().auth.data.accessToken
    );
    getConnectedStaffs({
      branchId: action.payload.data.branchId
    });
    if (result.success === true) {
      setSuccessStatus(`Removed successfully`);
    }
    yield setConnectionsLoading(false);
    // }
  } catch (error) {
    yield setErrorStatus(error);
    yield setConnectionsLoading(false);
  }
}

function* getBrandDataWorker(action) {
  try {
    // if (isOnline()) {
    const brandIds = action.payload.data.filter(
      (item, index) => action.payload.data.indexOf(item) === index
    );

    for (let data of Object.values(brandIds)) {
      let brandData = yield getBrandDataById(data);
      getBrandDownloadableUrl({
        ...brandData,
        documentId: data
      });
    }
    // }
  } catch (error) {
    yield setErrorStatus(error);
  }
}

function* getBrandLogoWorker(action) {
  try {
    const data = action.payload.data;
    if (
      store.getState().connection.brands &&
      isValidObject(store.getState().connection.brands) &&
      Object.keys(store.getState().connection.brands).includes(
        data.documentId
      ) &&
      store.getState().connection.brands[data.documentId].downloadURL
    ) {
      return;
    }

    let URL;

    URL = yield getFileUrl(
      `gs://${bucketNames.nintoProfilePictures}/clinicBrands/${data.documentId}.png`
    );

    yield put({
      type: "SET_CONNECTIONS_BRAND_DATA",
      payload: {
        data: {
          [data.documentId]: {
            ...data,
            documentId: data.documentId,
            ...(typeof URL === "string" ? { downloadURL: URL } : {})
          }
        }
      }
    });
  } catch (error) {
    if (error.toString().includes("does not exist")) {
      const data = action.payload.data;
      yield put({
        type: "SET_CONNECTIONS_BRAND_DATA",
        payload: {
          data: {
            [data.documentId]: {
              ...data,
              documentId: data.documentId
            }
          }
        }
      });
    } else {
      yield setErrorStatus(error);
    }
  }
}

function* setSelectedClinicWorker(action) {
  yield put({
    type: "SET_SELECTED_CLINICS",
    payload: {
      data: action.payload.data
    }
  });
}

export function* connectionWatcher() {
  yield all([
    takeEvery("SET_CONNECTIONS", setConnectionsWorker),
    takeEvery("SET_CURRENT_CONNECTION", setCurrentConnectionWorker),
    takeEvery("SET_SELECTED_CLINIC", setSelectedClinicWorker),
    takeEvery("CREATE_NEW_STAFF", createNewStaffWorker),
    takeEvery("REMOVE_STAFF", removeStaffWorker),
    takeEvery("GET_CONNECTED_STAFFS", getConnectedStaffWorker),
    takeEvery("GET_BRAND_DATA", getBrandDataWorker),
    takeEvery("GET_BRAND_LOGO", getBrandLogoWorker)
  ]);
}

function* setConnectionsLoading(bool) {
  yield put({
    type: "SET_CONNECTION_LOADING",
    payload: {
      loading: bool
    }
  });
}
