import React, { useState } from "react";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import backIcon from "../../front-end-global-components/assets/Back.svg";
import { ArrowIcon } from "../../front-end-global-components/assets/assets";
import "./ConsentHistory.css";
import { dateAndTimeConverter } from "../../front-end-global-components/utils/constants";
import { isValidArray, isValidObject } from "../../utils/validators";
import fallback from "../../assets/icons/fallback.svg";

export default function ConsentHistory(props) {
  const [selectedConsent, setSelectedConsent] = useState(null);
  const [showAllData, setShowAllData] = useState(3);

  const data = [
    {
      title: "CONSENT REQUEST ID",
      value:
        selectedConsent &&
        props.abdm?.consentRequest[selectedConsent]?.consentRequestId
          ? props.abdm?.consentRequest[selectedConsent]?.consentRequestId
          : "N/A"
    },
    {
      title: "STATUS",
      value:
        selectedConsent && props.abdm?.consentRequest[selectedConsent]?.status
          ? props.abdm?.consentRequest[selectedConsent]?.status
          : "N/A"
    },
    {
      title: "PROVIDER NAME",
      value:
        selectedConsent && props.abdm?.consentRequest[selectedConsent]?.hiuId
          ? props.abdm?.consentRequest[selectedConsent]?.hiuId
          : "N/A"
    },
    {
      title: "DATE RANGE",
      value:
        selectedConsent &&
        props.abdm?.consentRequest[selectedConsent]?.fromTime &&
        props.abdm?.consentRequest[selectedConsent]?.toTime
          ? {
              from: props.abdm?.consentRequest[selectedConsent]?.fromTime,
              to: props.abdm?.consentRequest[selectedConsent]?.toTime
            }
          : "N/A"
    },
    {
      title: "LAST UPDATED",
      value:
        selectedConsent &&
        props.abdm?.consentRequest[selectedConsent]?.timestamp
          ? `${dateAndTimeConverter(
              props.abdm?.consentRequest[selectedConsent]?.timestamp,
              "cardDate"
            )} , ${dateAndTimeConverter(
              props.abdm?.consentRequest[selectedConsent]?.timestamp,
              "Time"
            )}`
          : "N/A"
    },
    {
      title: "CONSENT ARTIFACTS",
      value:
        selectedConsent &&
        props.abdm?.consentRequest[selectedConsent]?.consentArtefacts
          ? props.abdm?.consentRequest[selectedConsent]?.consentArtefacts
          : "N/A"
    }
  ];

  return (
    <div className="padding-larger inherit-parent-height ">
      {isValidObject(props.abdm.consentRequest) && (
        <div className="display-flex padding-bottom-larger  inherit-parent-width">
          {selectedConsent &&
            !props.abdm.loading &&
            props.abdmPatients?.selectedPatient &&
            props.abdmPatients.searchResults[
              props.abdmPatients?.selectedPatient
            ] &&
            isValidObject(props.abdm.consentRequest) &&
            isValidObject(
              Object.values(props.abdm?.consentRequest).find(
                (data) =>
                  data.healthId ===
                  (
                    props.abdmPatients?.selectedPatient &&
                    props.abdmPatients.searchResults[
                      props.abdmPatients?.selectedPatient
                    ]?.healthId
                  ).toString()
              )
            ) && (
              <img
                src={backIcon}
                alt="back-arrow"
                width="auto"
                onClick={() => {
                  setSelectedConsent(null);
                }}
                className={`cursor-pointer margin-right-large ${
                  props.removeResponsive
                    ? ""
                    : "adaptive-back-arrow-row-icon-size"
                }  back-arrow-row-icon-size`}
                data-cy="secondary-header-back-arrow"
              />
            )}
          <div className="text-align-center inherit-parent-width">
            CONSENT HISTORY
          </div>
        </div>
      )}

      {!selectedConsent &&
        !props.abdm.loading &&
        isValidObject(props.abdm.consentRequest) &&
        props.abdmPatients?.selectedPatient &&
        props.abdmPatients.searchResults[props.abdmPatients?.selectedPatient] &&
        isValidObject(
          Object.values(props.abdm?.consentRequest).find(
            (data) =>
              data.healthId ===
              (
                props.abdmPatients?.selectedPatient &&
                props.abdmPatients.searchResults[
                  props.abdmPatients?.selectedPatient
                ]?.healthId
              ).toString()
          )
        ) && (
          <>
            {Object.values(props.abdm.consentRequest).map((data, index) => (
              <div className="padding-bottom-large" key={index}>
                <ConsentCard
                  cardClick={() => {
                    setSelectedConsent(data.documentId);
                  }}
                  data={data}
                />
              </div>
            ))}
          </>
        )}

      {selectedConsent &&
        !props.abdm.loading &&
        isValidObject(props.abdm.consentRequest) &&
        props.abdmPatients?.selectedPatient &&
        props.abdmPatients.searchResults[props.abdmPatients?.selectedPatient] &&
        isValidObject(
          Object.values(props.abdm?.consentRequest).find(
            (data) =>
              data.healthId ===
              (
                props.abdmPatients?.selectedPatient &&
                props.abdmPatients.searchResults[
                  props.abdmPatients?.selectedPatient
                ]?.healthId
              ).toString()
          )
        ) &&
        data?.map((element, index) => (
          <div className="padding-bottom-large" key={index}>
            <div className=" white-space-no-wrap text-overflow-ellipsis">
              {element.title}
            </div>
            {element.title === "DATE RANGE" ? (
              <>
                <div className="padding-top-default">
                  From:{" "}
                  {dateAndTimeConverter(
                    +new Date(element.value.from),
                    "cardDateWithoutDay"
                  )}{" "}
                  ,{" "}
                  {dateAndTimeConverter(+new Date(element.value.from), "Time")}
                </div>
                <div className="padding-top-default">
                  To:{" "}
                  {dateAndTimeConverter(
                    +new Date(element.value.to),
                    "cardDateWithoutDay"
                  )}{" "}
                  ,{" "}
                  {dateAndTimeConverter(+new Date(element.value.from), "Time")}
                </div>
              </>
            ) : element?.title === "CONSENT ARTIFACTS" ? (
              <div className="padding-top-large padding-bottom-large">
                {element?.value === "N/A" ? (
                  <div>N/A</div>
                ) : (
                  <>
                    {isValidArray(element?.value) &&
                      element?.value
                        ?.slice(0, showAllData)
                        ?.map((data, index) => (
                          <div
                            className="padding-bottom-default white-space-no-wrap text-overflow-ellipsis"
                            key={index}
                          >
                            {data}
                          </div>
                        ))}
                    {element?.value?.length > 3 && (
                      <div
                        onClick={() => {
                          if (showAllData === 3) {
                            setShowAllData(element?.value?.length - 1);
                          } else {
                            setShowAllData(3);
                          }
                        }}
                        className="text-align-center font-color-primary text-underline-hover cursor-pointer"
                      >
                        {showAllData === element?.value?.length - 1
                          ? "Show Less"
                          : "Show More"}
                      </div>
                    )}
                  </>
                )}
              </div>
            ) : (
              <div className=" white-space-no-wrap text-capitalize text-overflow-ellipsis padding-top-default">
                {element?.value}
              </div>
            )}
          </div>
        ))}
      {!props.abdm.loading &&
        (!isValidObject(props.abdm.consentRequest) ||
          (isValidObject(props.abdm.consentRequest) &&
            props.abdmPatients?.selectedPatient &&
            props.abdmPatients.searchResults[
              props.abdmPatients?.selectedPatient
            ] &&
            !isValidObject(
              Object.values(props.abdm?.consentRequest).find(
                (data) =>
                  data.healthId ===
                  (
                    props.abdmPatients?.selectedPatient &&
                    props.abdmPatients.searchResults[
                      props.abdmPatients?.selectedPatient
                    ]?.healthId
                  ).toString()
              )
            ))) && (
          <div className="inherit-parent-height flex-justify-content-center flex-align-items-center">
            <div className="display-flex flex-justify-content-center flex-direction-column flex-align-items-center">
              <img width="148px" height="120px" src={fallback} alt="" />
              <div className=" flex-justify-content-center flex-direction-column">
                <div className="text-align-center padding-top-larger font-family-gilroy-medium">
                  No consent history found
                </div>
                <div className="text-align-center padding-top-large">
                  You can view the consent history once you upload a document
                </div>
              </div>
            </div>
          </div>
        )}
      {props.abdm.loading && <Suspense />}
    </div>
  );
}

const ConsentCard = (props) => {
  return (
    <div
      onClick={props.cardClick}
      className="inherit-parent-width display-flex flex-justify-content-space-between"
    >
      <div>
        <RoundedProfilePicture size="small" />
      </div>
      <div className="display-flex flex-direction-column inherit-parent-width padding-left-large padding-right-large">
        <div className="display-flex inherit-parent-width flex-justify-content-space-between padding-bottom-medium">
          <div
            style={{ width: "50%" }}
            className="text-capitalize height-fit-content margin-top-smaller text-overflow-ellipsis"
          >
            {props.data.hiuId}
          </div>
          <div
            className={`${
              props.data.status && props.data.status === "GRANTED"
                ? "background-color-approved"
                : props.data.status === "SENT"
                ? "background-color-sent"
                : "background-color-denied"
            } padding-top-smaller font-family-RHD-regular text-capitalize padding-bottom-smaller margin-left-small padding-left-default padding-right-default border-radius-default font-size-small height-fit-content`}
          >
            {typeof props.data.status === "string"
              ? props.data.status.charAt(0).toUpperCase() +
                props.data.status.slice(1).toLowerCase()
              : ""}
          </div>
        </div>
        <div className="display-flex inherit-parent-width flex-justify-content-space-between">
          <div className="font-size-small white-space-no-wrap">
            {dateAndTimeConverter(
              +new Date(props.data.fromTime),
              "cardDateWithoutDay"
            )}
          </div>
          <div className="font-size-small">TO</div>
          <div className="font-size-small white-space-no-wrap">
            {dateAndTimeConverter(
              +new Date(props.data.toTime),
              "cardDateWithoutDay"
            )}
          </div>
        </div>
      </div>
      <div className="display-flex flex-justify-content-center flex-align-items-center">
        <ArrowIcon />
      </div>
    </div>
  );
};

const Suspense = () => {
  return (
    <article>
      <div className="inherit-parent-width">
        {[...Array(6).keys()].map((index) => (
          <div
            className="border-bottom-2px flex-center-children-vertically"
            key={index}
          >
            <div className="shimmer inherit-parent-width padding-small margin-vertical-default border-radius-default">
              <div className="padding-default margin-default border-radius-default" />
            </div>
          </div>
        ))}
      </div>
    </article>
  );
};
