import React, { useEffect, useState } from "react";
import closeIcon from "../../assets/icons/Close_green.svg";
import { isValidObject } from "../../utils/validators";
import "./SelectedPatient.css";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";

export default function SelectedPatient(props) {
  const [patient, setPatient] = useState(null);
  const selectedPatientList = [
    patient?.healthId
      ? {
          displayName: "Pin patient",
          id: "pinPatientToDoctor",
          description: "Pin a patient to your doctor schedule",
          datacy: "pin-patient",
          onClick: () => {
            handlePinPatientToDoctor();
          }
        }
      : "",
    patient?.healthId
      ? {
          displayName: "Patient timeline",
          id: "patientTimeline",
          description: "View patient activities and records",
          datacy: "Patient-timeline",
          onClick: () => {
            handlePatientTimeline();
          }
        }
      : "",

    {
      displayName: "Upload document",
      id: "uploadDocument",
      description: "Link a type of document to the patient",
      datacy: "Upload-document",
      onClick: () => {
        handleUploadDocument();
      }
    },

    patient?.healthId
      ? {
          displayName: "Consent history",
          id: "consentHistory",
          description: "View the history of consents artifacts",
          datacy: "Consent-history",
          onClick: () => {
            handleConsentHistoryDocument();
          }
        }
      : ""

    // abdmDocumentsStatus().status === remoteConfigStatus.ALLOW_ALL && {
    // {
    //   displayName: "Add vitals",
    //   id: "addVitals",
    //   description: "Add vitals to the patient",
    //   datacy: "Add vitals",
    //   onClick: ()=>{
    // handleAddVitals()
    // }
    // },
    // {
    //   displayName: "Add form",
    //   id: "addForm",
    //   description: "Send a form to the patient to fill up",
    //   datacy: "Add-form",
    //   onClick:()=>{
    // handleAddForm()
    // }
    // }
  ];

  //set selected patient
  useEffect(() => {
    if (
      props.abdmPatients.selectedPatient &&
      isValidObject(props.abdmPatients.searchResults)
    ) {
      setPatient(
        props.abdmPatients.searchResults[props.abdmPatients.selectedPatient]
      );
    } else {
      setPatient(null);
    }
  }, [props.abdmPatients.searchResults, props.abdmPatients.selectedPatient]);

  function handlePinPatientToDoctor() {
    if (props.navigation.tertiary === "pinPatientToDoctor") {
      return;
    }
    props.showTertiary("pinPatientToDoctor");
    if (
      props.abdmPatients.searchResults[props.abdmPatients.selectedPatient]
        ?.healthId
    ) {
      // props.checkIfPatientConnectedToAbdm();
    }
  }

  // function handleAppointment() {
  //   props.showTertiary("appointment");
  // }

  function handlePatientTimeline() {
    if (props.navigation.tertiary === "patientTimeline") {
      return;
    }
    props.showTertiary("patientTimeline");
    if (
      props.abdmPatients.searchResults[props.abdmPatients.selectedPatient]
        ?.healthId
    ) {
      // props.checkIfPatientConnectedToAbdm();
    }
  }

  function handleConsentHistoryDocument() {
    if (props.navigation.tertiary === "consentHistory") {
      return;
    }
    props.showTertiary("consentHistory");
  }

  // function handleAddPrescription() {
  //   if (props.navigation.tertiary === "addPrescription") {return;}
  //   props.showTertiary("addPrescription");
  // }
  // function handleAddReport() {
  //   if (props.navigation.tertiary === "addReport") {return;}
  //   props.showTertiary("addReport");
  // }
  // function handleAddAdmission() {
  //   props.showTertiary("addAdmission");
  // }
  // function handleAddReceipt() {
  //   props.showTertiary("addReceipt");
  // }

  // function handleAddVitals() {
  //   if (props.navigation.tertiary === "addVitals") {return;}

  //   props.showTertiary("addVitals");
  // }
  // function handleAddForm() {
  //   if (props.navigation.tertiary === "addForm") {return;}

  //   props.showTertiary("addForm");
  // }

  function handleUploadDocument() {
    if (props.navigation.tertiary === "uploadDocument") {
      return;
    }
    props.showTertiary("uploadDocument");
  }

  function handleProfileClose() {
    props.handleProfileClose();
  }

  return (
    <div data-cy="selectedPatient">
      {isValidObject(patient) && (
        <div>
          <div className="padding-vertical-larger padding-bottom-medium">
            <div
              data-cy="selected-patient-tile"
              className="display-flex inherit-parent-width background-color-grey overflow-hidden border-radius-capsule flex-justify-content-space-between flex-align-items-center"
            >
              <section className="display-flex text-overflow-ellipsis flex-justify-content-space-between flex-align-items-center">
                <RoundedProfilePicture size="medium" />
                <div className="background-color-grey padding-left-default text-overflow-ellipsis">
                  <p className="font-color-tertiary font-size-small padding-top-default padding-bottom-small">
                    {patient.healthId}
                  </p>
                  <p className="font-color-secondary font-size-medium padding-bottom-default font-family-gilroy-medium text-overflow-ellipsis">
                    {patient.name}
                  </p>
                </div>
              </section>
              <section className="padding-right-default cursor-pointer">
                <img
                  draggable="false"
                  src={closeIcon}
                  alt="close"
                  onClick={() => {
                    handleProfileClose();
                  }}
                />
              </section>
            </div>
          </div>
          <div
            className="account-menu padding-top-larger padding-smaller list-style-none text-align-left"
            style={{ overflow: "hidden" }}
          >
            {selectedPatientList.map(
              (data, index) =>
                data && (
                  <span data-cy={data?.datacy} key={index}>
                    <TileCard
                      isSelected={props.navigation.tertiary === data?.id}
                      title={data?.displayName}
                      description={data?.description}
                      onClick={data?.onClick}
                    />
                  </span>
                )
            )}
          </div>
        </div>
      )}
    </div>
  );
}

function TileCard({ isSelected, onClick, title, description }) {
  return (
    <div
      className={`${
        isSelected
          ? "hover-effect cursor-pointer margin-bottom-larger"
          : "box-shadow-default cursor-pointer margin-bottom-larger"
      }`}
      onClick={onClick}
    >
      {title} <br />
      <span className="font-color-secondary font-family-gilroy-medium font-size-small">
        {description}
      </span>
    </div>
  );
}
