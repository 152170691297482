import React, { useEffect } from "react";
import Button from "../../front-end-global-components/components/Button/Button";
import { isValidObject } from "../../utils/validators";

export default function FormsListing(props) {
  const formsList = () => {
    const forms = props.forms.data;
    const _result = [];
    if (isValidObject(forms)) {
      for (const key in forms) {
        _result.push({
          ...forms[key],
          documentId: key
        });
      }
    }
    return _result;
  };
  //get forms from database
  useEffect(() => {
    // if (!isValidObject(props.forms.data)) {
    //   props.getForms();
    // }
    props.getForms();
    return () => {
      props.removeCurrentForm();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // }, [props.forms.data]);

  return (
    <div className="inherit-parent-height" data-cy="forms-listing">
      <>
        <Button
          data-cy="create-template-button"
          boxShadow={false}
          text="Create new template"
          type="submit"
          className="inherit-parent-width padding-medium border-radius-default margin-bottom-larger "
          disabled={false}
          onClick={() => {
            props.removeCurrentForm();
            props.showSecondary("createEditPreviewForm");
          }}
        />
        <div className="background-white max-height-calc-percentage overflow-auto invisible-scrollbar border-radius-default">
          <div className="search-patient font-family-gilroy-medium font-size-medium list-style-none text-align-left">
            {formsList().map((formDetails, index) => {
              return (
                <div
                  className="border-radius-default cursor-pointer"
                  key={`created-form-${index}`}
                  data-cy={`created-form-${formDetails.name}`}
                  onClick={() => {
                    props.putCurrentForm(formDetails.documentId);
                  }}
                >
                  <div
                    className={`${
                      props.forms.currentForm === formDetails.documentId
                        ? "background-color-grey"
                        : "background-white"
                    }  padding-left-large padding-right-large border-radius-default padding-top-large`}
                  >
                    <div
                      className={`${
                        Object.keys(formsList()).length > 1 &&
                        formsList().slice(-1)[0].documentId ===
                          formDetails.documentId
                          ? ""
                          : "border-bottom-2px"
                      }  padding-bottom-large`}
                    >
                      {formDetails.name}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    </div>
  );
}
