import { all } from "redux-saga/effects";
import { authWatcher } from "./authentication/saga";
import { statusWatcher } from "./status/saga";
import { mockWatcher } from "./mock/saga";
import { navigationWatcher } from "./navigation/saga";
import { profileWatcher } from "./profile/saga";
import { abdmPatientWorker } from "./abdmPatients/saga";
import { abdmQRWatcher } from "./abdmQR/saga";
import { doctorSearchWatcher } from "./doctorSearch/saga";
import { abdmWatcher } from "./abdm/saga";
import { abhaWatcher } from "./abha/saga";
import { historyWatcher } from "./history/saga";
import { connectionWatcher } from "./connection/saga";
import { notificationWatcher } from "./notification/saga";
import { formsWatcher } from "./form/saga";
import { documentSearchWatcher } from "./documentSearch/saga";
import { documentsWatcher } from "./documents/saga";
import queryWatcher from "./queries/saga";

export default function* rootSaga() {
  yield all([
    authWatcher(),
    queryWatcher(),
    connectionWatcher(),
    documentsWatcher(),
    historyWatcher(),
    mockWatcher(),
    notificationWatcher(),
    profileWatcher(),
    statusWatcher(),
    navigationWatcher(),
    doctorSearchWatcher(),
    documentSearchWatcher(),
    formsWatcher(),
    abdmWatcher(),
    abdmPatientWorker(),
    abdmQRWatcher(),
    abhaWatcher()
  ]);
}
