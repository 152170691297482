import { all, put, takeEvery } from "@redux-saga/core/effects";
import {
  confirmOtpForLinking,
  connectPatientUsingDemographic,
  sendAadhaarOtpForLinking,
  sendMobileOtpForLinking
} from "../../services/api";
import { throwError } from "../../services/error";
import { checkIfPatientConnectedToAbdm } from "../abdmPatients/actions";
import { setErrorStatus, setSuccessStatus } from "../status/actions";
import store from "../store/store";

export const abdmORActionTypes = {
  SEND_OTP_FOR_LINKING: "SEND_OTP_FOR_LINKING",
  CONFIRM_OTP_LINKING: "CONFIRM_OTP_LINKING",
  CONNECT_USING_DEMOGRAPHIC: "CONNECT_USING_DEMOGRAPHIC",
  CLEAR_QR_ACCESS_TOKEN: "CLEAR_QR_ACCESS_TOKEN"
};

function* clearQRaccessTokenWorker() {
  try {
    yield put({
      type: "RESET_ABDM_QR"
    });
  } catch (error) {
    setErrorStatus(error);
  }
}

function* sendOtpForLinkingWorker(action) {
  try {
    if (!action.payload.patientId) {
      throw throwError("custom", "Please enter valid ABHA Address");
    }
    yield abdmQRLoading(true);

    const clinicId = store.getState().connection.currentConnection;
    const accessToken = store.getState().auth.data.accessToken;
    let sendOtpResponse;
    if (action.payload.type === "healthId") {
      sendOtpResponse = yield sendMobileOtpForLinking({
        patientId: action.payload.patientId,
        clinicId: clinicId,
        accessToken: accessToken
      });
    } else if (action.payload.type === "aadhaar") {
      sendOtpResponse = yield sendAadhaarOtpForLinking({
        patientId: action.payload.patientId,
        clinicId: clinicId,
        accessToken: accessToken
      });
    }

    yield put({
      type: "SET_OTP_LINKING",
      payload: {
        transactionId: sendOtpResponse.data.transactionId,
        healthId: action.payload.healthId
      }
    });
    yield abdmQRLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield abdmQRLoading(false);
  }
}

function* confirmOtpForLinkingWorker(action) {
  try {
    yield abdmQRLoading(true);
    if (!action.payload.otp) {
      throw throwError("custom", "Please enter valid ABHA Address");
    }
    const transactionId = store.getState().abdmQR.otpLinking.transactionId;

    const clinicId = store.getState().connection.currentConnection;
    const accessToken = store.getState().auth.data.accessToken;

    const response = yield confirmOtpForLinking({
      transactionId: transactionId,
      otp: action.payload.otp,
      healthId: action.payload.healthId,
      clinicId: clinicId,
      accessToken: accessToken
    });

    yield put({
      type: "RESET_ABDM_QR"
    });

    yield abdmQRLoading(false);

    if (response.success) {
      yield checkIfPatientConnectedToAbdm({
        removeLoading: true
      });
      setSuccessStatus("Connected successfully");
    } else {
      throw throwError("custom", "Something went wrong");
    }
  } catch (error) {
    yield abdmQRLoading(false);
    setErrorStatus(error);
  }
}

function* connectUsingPatientDemographicsWorker(action) {
  try {
    yield abdmQRLoading(true);
    const patientId =
      store.getState().abdmPatients.searchResults[
        store.getState().abdmPatients.selectedPatient
      ].patientId;
    const phoneNumber =
      store.getState().abdmPatients.searchResults[
        store.getState().abdmPatients.selectedPatient
      ].phoneNumber;
    const accessToken = store.getState().auth.data.accessToken;

    const clinicId = store.getState().connection.currentConnection;

    const response = yield connectPatientUsingDemographic(accessToken, {
      name: action.payload.fullName,
      dateOfBirth: action.payload.dateOfBirth,
      gender: action.payload.gender.split("")[0],
      patientId: patientId,
      clinicId: clinicId,
      ...(phoneNumber
        ? {
            identifier: {
              type: "MOBILE",
              value: phoneNumber.includes("+91") ? phoneNumber.split("+91")[1] : phoneNumber
            }
          }
        : {})
    });

    yield put({
      type: "RESET_ABDM_QR"
    });

    yield abdmQRLoading(false);
    if (response.success) {
      yield checkIfPatientConnectedToAbdm({
        removeLoading: true
      });
    } else {
      throw throwError("custom", "Something went wrong");
    }
  } catch (error) {
    yield abdmQRLoading(false);
    setErrorStatus(error);
  }
}

export function* abdmQRWatcher() {
  yield all([
    takeEvery("SEND_OTP_FOR_LINKING", sendOtpForLinkingWorker),
    takeEvery("CONFIRM_OTP_LINKING", confirmOtpForLinkingWorker),
    takeEvery("CLEAR_QR_ACCESS_TOKEN", clearQRaccessTokenWorker),
    takeEvery(
      "CONNECT_USING_DEMOGRAPHIC",
      connectUsingPatientDemographicsWorker
    )
  ]);
}

function* abdmQRLoading(value) {
  yield put({
    type: "SET_ABDM_QR_LOADING",
    payload: {
      loading: value
    }
  });
}
