const abdmQRreducer = (
  state = {
    loading: false,
    data: {
      token: null
    },
    otpLinking: null
  },
  action
) => {
  switch (action.type) {
    case "SET_ABDM_QR_LOADING":
      return {
        ...state,
        loading: action.payload.loading
      };

    case "SET_ABDM_QR_TRANSACTIONS":
      return {
        ...state,
        data: {
          token: action.payload.token
        }
      };

    case "SET_OTP_LINKING":
      return {
        ...state,
        otpLinking: {
          transactionId: action.payload.transactionId,
          healthId: action.payload.healthId
        }
      };

    case "RESET_ABDM_QR":
      return {
        loading: false,
        data: {
          token: null
        },
        otpLinking: null
      };

    case "RESET":
      return {
        loading: false,
        data: {
          token: null
        },
        otpLinking: null
      };

    default:
      return state;
  }
};
export default abdmQRreducer;
