import { all, put, takeEvery } from "@redux-saga/core/effects";
import { setErrorStatus } from "../status/actions";
import { showTertiary } from "./actions";

export const navigationActionTypes = {
  SHOW_PRIMARY: "SHOW_PRIMARY",
  SHOW_SECONDARY: "SHOW_SECONDARY",
  SHOW_TERTIARY: "SHOW_TERTIARY"
};

function* showPrimaryWorker(action) {
  try {
    yield setNavigationLoading(true);
    yield put({
      type: "SET_NAVIGATION_PRIMARY_DATA",
      payload: {
        data: action.payload.primary
      }
    });
    showTertiary(null);
    yield setNavigationLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setNavigationLoading(false);
  }
}

function* showSecondaryWorker(action) {
  try {
    yield setNavigationLoading(true);
    yield put({
      type: "SET_NAVIGATION_SECONDARY_DATA",
      payload: {
        data: action.payload.secondary
      }
    });
    yield setNavigationLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setNavigationLoading(false);
  }
}

function* showTertiaryWorker(action) {
  try {
    yield setNavigationLoading(true);
    yield put({
      type: "SET_NAVIGATION_TERTIARY_DATA",
      payload: {
        data: action.payload.tertiary
      }
    });
    yield setNavigationLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setNavigationLoading(false);
  }
}

export function* navigationWatcher() {
  yield all([takeEvery("SHOW_PRIMARY", showPrimaryWorker)]);
  yield all([takeEvery("SHOW_SECONDARY", showSecondaryWorker)]);
  yield all([takeEvery("SHOW_TERTIARY", showTertiaryWorker)]);
}

function* setNavigationLoading(value) {
  yield put({
    type: "SET_NAVIGATION_LOADING",
    payload: {
      loading: value
    }
  });
}
