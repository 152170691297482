const abhaReducer = (
  state = {
    loading: false,
    transactionId: null,
    sentAadhaarOtp: false,
    aadhaarVerified: false,
    sentMobileOtp: false,
    mobileVerified: false,
    abhaNumberCreated: false,
    ABHACard: null,
    accessToken: null,
    sentAbhaOtp: false,
    isHealthIdLogin: false,
    login: false
  },
  action
) => {
  switch (action.type) {
    case "SET_ABHA_LOADING":
      return {
        ...state,
        loading: action.payload.loading
      };

    case "SET_AADHAAR_OTP":
      return {
        ...state,
        transactionId: action.payload.transactionId,
        sentAadhaarOtp: action.payload.sentAadhaarOtp
      };

    case "SET_HEALTH_ID_LOGIN_STATUS":
      return {
        ...state,
        isHealthIdLogin: action.payload.data
      };

    case "SET_ABHA_CARD":
      return {
        ...state,
        ABHACard: action.payload.data
      };

    case "SET_AADHAAR_VERIFIED":
      return {
        ...state,
        aadhaarVerified: action.payload.aadhaarVerified
      };

    case "SET_SENT_MOBILE_OTP":
      return {
        ...state,
        sentMobileOtp: action.payload.sentMobileOtp
      };

    case "SET_MOBILE_VERIFIED":
      return {
        ...state,
        mobileVerified: action.payload.mobileVerified
      };

    case "SET_ABHA_NUMBER_ACCESS_TOKEN":
      return {
        ...state,
        accessToken: action.payload.accessToken
      };

    case "SET_ABHA_NUMBER_DATA":
      return {
        ...state,
        abhaNumberCreated: action.payload.abhaNumberCreated
      };

    case "SET_SENT_ABHA_OTP":
      return {
        ...state,
        sentAbhaOtp: action.payload.sentLoginOtp,
        transactionId: action.payload.transactionId
      };

    case "SET_VERIFY_ABHA_OTP":
      return {
        ...state,
        login: action.payload.verifyLoginOtp
      };

    case "RESET_ABHA_CREATION":
      return {
        ...state,
        aadhaarVerified: false,
        ABHACard: null,
        abhaNumberCreated: false,
        accessToken: null,
        login: false,
        mobileVerified: false,
        sentAadhaarOtp: false,
        sentAbhaOtp: false,
        sentMobileOtp: false,
        transactionId: null
      };

    case "RESET":
      return {
        loading: false,
        aadhaarVerified: false,
        ABHACard: null,
        abhaNumberCreated: false,
        accessToken: null,
        login: false,
        mobileVerified: false,
        sentAadhaarOtp: false,
        sentAbhaOtp: false,
        sentMobileOtp: false,
        isHealthIdLogin: false,
        transactionId: null
      };
    default:
      return state;
  }
};
export default abhaReducer;
