import React from "react";
import callBackImage from "../../assets/images/SomethingWentWrong_Web.svg";
import Button from "../../front-end-global-components/components/Button/Button";
import { logout } from "../../redux/authentication/actions";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);

    this.props.handleComponentDidCatch(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <main className="inherit-parent-height background-color-white inherit-parent-width flex-place-children-page-center">
          <div>
            <div className=" padding-bottom-larger">
              <img draggable="false" src={callBackImage} alt="call back UI" />
            </div>
            <div>
              <h1 className="font-weight-normal font-family-gilroy-bold text-align-center font-color-secondary font-size-large">
                Something’s broken
              </h1>
            </div>
            <div className=" text-align-center padding-top-larger">
              <Button
                type="submit"
                data-cy="take-me-back-button"
                className="inherit-parent-width height-40px max-width-150px padding-vertical-default padding-horizontal-large "
                text="Take me back"
                onClick={() => {
                  logout();
                  window.location.reload();
                }}
              />
            </div>
          </div>
        </main>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
