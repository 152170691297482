import React, { useState, useEffect } from "react";
import { isValidObject } from "../../utils/validators";
import ConnectToPatient from "../ConnectToPatient/ConnectToPatient";
import Button from "../../front-end-global-components/components/Button/Button";
import NativeSelect from "../../front-end-global-components/components/NativeSelect/NativeSelect";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";

export default function PatientSearch(props) {
  const [searchResults, setSearchResults] = useState(null);
  const [isValidName, setIsValidName] = useState(true);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [searchPatientData, setSearchPatientData] = useState("+91");
  const [demographicsFormData, setDemographicsFormData] = useState({
    fullName: "",
    dateOfBirth: "",
    phoneNumber: "+91",
    gender: ""
  });
  //set search results
  useEffect(() => {
    if (isValidObject(props.abdmPatients.searchResults)) {
      setSearchResults(Object.values(props.abdmPatients.searchResults));
    } else {
      setSearchResults(null);
    }
  }, [props.abdmPatients.searchResults]);

  //remove previous search data
  useEffect(() => {
    return () => {
      props.clearSearchPatientData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSearch(event) {
    event.preventDefault();
    if (searchPatientData && searchPatientData !== "+91") {
      props.SearchPatient(searchPatientData);
    }
    setSearchResults(null);
  }

  const onSubmitPatientDemographicsForm = (event) => {
    event.preventDefault();
    props.createPatientDemographics(demographicsFormData);
    setDemographicsFormData({
      fullName: "",
      dateOfBirth: "",
      phoneNumber: "+91",
      gender: ""
    });
  };

  const handleDemographicsFormOnChange = (event) => {
    setDemographicsFormData({
      ...demographicsFormData,
      [event.target.name]: event.target.value
    });
  };

  function searchPatientFormChange(event) {
    if (
      props.abdmPatients.searchKey !== "" ||
      props.abdmPatients.searchKey !== null
    ) {
      props.clearSearchPatientData();
    }
    setSearchPatientData(event.target.value);
  }

  return (
    <div className="inherit-parent-height" data-cy="patientSearch">
      <form
        onChange={(event) => {
          searchPatientFormChange(event);
        }}
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        <InputBox
          className="inherit-parent-width max-width-300px border-radius-default padding-bottom-large"
          name="searchKey"
          removeResponsive={true}
          data-cy="patient-number-field"
          label="ABHA Address / Mobile"
          type="text"
          autoFocus={"on"}
          value={searchPatientData && searchPatientData}
          otpButtonData-cy="patient-search-button"
          buttonTileId="login-button"
          buttonTile="Search"
          buttonType="submit"
          onButtonClick={(event) => {
            handleSearch(event);
          }}
          required
        />
      </form>

      <div className="background-white max-height-calc-percentage overflow-auto invisible-scrollbar border-radius-default ">
        <div className="search-patient font-family-gilroy-medium font-size-medium list-style-none text-align-left">
          {props.abdmPatients.loading === true ||
          props.history.loading === true ? (
            <PatientSearchSuspense />
          ) : searchResults !== null &&
            Array.isArray(searchResults) &&
            searchResults.length > 0 ? (
            <>
              {searchResults.map((profile, index) => (
                <div
                  className="border-radius-default cursor-pointer "
                  key={index}
                >
                  <div
                    className={`${
                      props.abdmPatients.selectedPatient &&
                      isValidObject(props.abdmPatients.searchResults) &&
                      props.abdmPatients.searchResults[
                        props.abdmPatients.selectedPatient
                      ]?.patientId === profile.patientId
                        ? "background-color-grey"
                        : "background-white"
                    }  padding-left-large padding-right-large border-radius-default padding-top-large border-bottom-1px`}
                    onClick={() => {
                      props.setSelectedPatients(profile.patientId);
                    }}
                  >
                    <div
                      className={`padding-bottom-large display-flex`}
                      key={index}
                      data-cy={`search-result-${profile.name}`}
                    >
                      <div>{`${profile?.name} ${
                        profile?.gender ? "," : ""
                      }`}</div>
                      {profile?.gender && (
                        <div className="padding-left-default">
                          {profile?.gender}
                        </div>
                      )}
                      {profile?.dob && (
                        <div className="padding-left-default">
                          `{profile?.dob?.year?.toString().slice(-2)}
                        </div>
                      )}
                      {profile?.yob && (
                        <div className="padding-left-default">
                          `{profile?.yob?.toString().slice(-2)}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              {!searchPatientData.includes("@") && (
                <>
                  <div className="flex-center-children-vertically margin-vertical-large ">
                    <hr className="display-inline-block border-color-secondary-lite border-top-left-right-none width-100-percent flex-basis-1 " />
                    <span className=" margin-horizontal-default display-inline-block font-color-tertiary font-size-small">
                      OR
                    </span>
                    <hr className="display-inline-block border-color-secondary-lite border-top-left-right-none width-100-percent flex-basis-1 " />
                  </div>

                  <CreatePatientDemographics
                    handleDemographicsFormOnChange={(event) =>
                      handleDemographicsFormOnChange(event)
                    }
                    onSubmitPatientDemographicsForm={(event) => {
                      onSubmitPatientDemographicsForm(event);
                    }}
                    setIsValidPhoneNumber={(data) => {
                      setIsValidPhoneNumber(data);
                    }}
                    setIsValidName={(data) => {
                      setIsValidName(data);
                    }}
                    demographicsFormData={demographicsFormData}
                    setDemographicsFormData={setDemographicsFormData}
                    isValidPhoneNumber={isValidPhoneNumber}
                    isValidName={isValidName}
                  />
                </>
              )}
            </>
          ) : (
            <>
              {
                <>
                  <ConnectToPatient requireAuthorization={false} />
                  <div className="flex-center-children-vertically margin-vertical-large ">
                    <hr className="display-inline-block border-color-secondary-lite border-top-left-right-none width-100-percent flex-basis-1 " />
                    <span className="margin-horizontal-default display-inline-block font-color-tertiary font-size-small">
                      OR
                    </span>
                    <hr className="display-inline-block border-color-secondary-lite border-top-left-right-none width-100-percent flex-basis-1 " />
                  </div>
                  <CreatePatientDemographics
                    handleDemographicsFormOnChange={(event) =>
                      handleDemographicsFormOnChange(event)
                    }
                    onSubmitPatientDemographicsForm={(event) => {
                      onSubmitPatientDemographicsForm(event);
                    }}
                    setIsValidPhoneNumber={(data) => {
                      setIsValidPhoneNumber(data);
                    }}
                    setIsValidName={(data) => {
                      setIsValidName(data);
                    }}
                    demographicsFormData={demographicsFormData}
                    setDemographicsFormData={setDemographicsFormData}
                    isValidPhoneNumber={isValidPhoneNumber}
                    isValidName={isValidName}
                  />
                </>
              }
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function PatientSearchSuspense() {
  return (
    <article>
      <div className="inherit-parent-width">
        {[...Array(4).keys()].map((index) => (
          <div
            className="padding-top-default border-bottom-2px padding-bottom-small"
            key={index}
          >
            <div className="shimmer inherit-parent-width padding-small margin-vertical-default border-radius-default">
              <div className="padding-default margin-default border-radius-default" />
            </div>
          </div>
        ))}
      </div>
    </article>
  );
}

const CreatePatientDemographics = (props) => {
  return (
    <>
      <p className="margin-bottom-larger font-color-secondary text-align-center">
        Create patient profile
      </p>
      <form
        className="padding-smaller"
        onChange={props.handleDemographicsFormOnChange}
        onSubmit={props.onSubmitPatientDemographicsForm}
      >
        <InputBox
          className="inherit-parent-width max-width-300px border-radius-default margin-bottom-larger"
          name="fullName"
          isValidInput={(data) => {
            props.setIsValidName(data);
          }}
          value={props.demographicsFormData.fullName}
          data-cy="demographics-patient-name"
          label="Full Name"
          type="text"
          removeResponsive={true}
          required
        />
        <InputBox
          className="inherit-parent-width max-width-300px border-radius-default margin-bottom-larger"
          name="phoneNumber"
          removeResponsive={true}
          value={props.demographicsFormData.phoneNumber}
          data-cy="demographics-patient-phone-number"
          label="Phone Number"
          type="text"
          isValidInput={(data) => {
            props.setIsValidPhoneNumber(data);
          }}
          required
        />
        <div className="inherit-parent-width max-width-300px border-radius-default margin-bottom-larger flex-justify-content-space-between">
          <NativeSelect
            name="gender"
            label="Gender"
            className="padding-right-default"
            removeResponsive={true}
            size="small"
            data-cy="demographics-patient-gender"
            options={["", "Male", "Female", "Others"]}
            defaultValue={props.demographicsFormData.gender}
            selectedValue={(value) => {
              props.setDemographicsFormData({
                ...props.demographicsFormData,
                gender: value
              });
            }}
            required
          />
          <InputBox
            removeResponsive={true}
            className="margin-right-default "
            name="dateOfBirth"
            label="Date Of Birth"
            size="half"
            value={props.demographicsFormData.dateOfBirth}
            type="date"
            required
          />
        </div>
        <div className="margin-bottom-larger">
          <Button
            className="inherit-parent-width max-width-300px border-radius-default"
            data-cy="demographic-add-button"
            boxShadow={false}
            text="Create Profile"
            type="submit"
            disabled={
              !(
                typeof props.demographicsFormData.fullName === "string" &&
                props.demographicsFormData.fullName.length > 2 &&
                typeof props.demographicsFormData.dateOfBirth === "string" &&
                props.demographicsFormData.phoneNumber.length === 13 &&
                props.demographicsFormData.gender &&
                props.demographicsFormData.dateOfBirth &&
                props.isValidPhoneNumber &&
                props.isValidName
              )
            }
          />
        </div>
      </form>
    </>
  );
};
