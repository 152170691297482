import { all, put, takeEvery } from "@redux-saga/core/effects";
import store from "../store/store";

import {
  addVitals,
  deleteDocument,
  getDataTransferDetails,
  getPatientDocuments
} from "../../services/database";
import { getFileUrl } from "../../services/storage";
import { isValidObject } from "../../utils/validators";
import { throwError } from "../../services/error";
import { isValidArray } from "../../utils/validators";
import { validDocumentType } from "../../utils/constants";
import { retryDataTransferDetails, uploadDocument } from "../../services/api";
import { setErrorStatus, setSuccessStatus } from "../status/actions";
import { showTertiary } from "../navigation/actions";
import { updateUploadHistory } from "../history/actions";
import { removeDocumentSearchData } from "../documentSearch/actions";
import { getDataTransferInfo, setDocuments } from "./actions";

export const documentsActionTypes = {
  GET_DATA_TRANSFER: "GET_DATA_TRANSFER",
  RETRY_DATA_TRANSFER: "RETRY_DATA_TRANSFER",
  SET_DOCUMENTS: "SET_DOCUMENTS",
  SET_SELECTED_DOCUMENTS: "SET_SELECTED_DOCUMENTS",
  SET_DOWNLOAD_URL: "SET_DOWNLOAD_URL",
  UPLOAD_DOCUMENT: "UPLOAD_DOCUMENT",
  DELETE_DOCUMENT: "DELETE_DOCUMENT",
  ADD_VITALS: "ADD_VITALS",
  REMOVE_DOCUMENT_FILTER: "REMOVE_DOCUMENT_FILTER",
  ADD_DOCUMENT_FILTER: "ADD_DOCUMENT_FILTER"
};

export function* setDocumentsWorker(action) {
  try {
    if (!action?.payload?.removeLoading) {
      yield setDocumentLoading(true);
    }

    const selectedPatientData =
      store.getState().abdmPatients.searchResults[
        store.getState().abdmPatients?.selectedPatient
      ];
    const clinicId = store.getState().connection.currentConnection;

    const documents = yield getPatientDocuments(
      selectedPatientData.patientId,
      clinicId
    );

    yield put({
      type: "SET_DOCUMENTS_DATA",
      payload: {
        data: documents
      }
    });

    if (!action?.payload?.removeLoading) {
      yield setDocumentLoading(false);
    }
  } catch (error) {
    setErrorStatus(error);
    yield setDocumentLoading(false);
  }
}

export function* getDataTransferWorker(action) {
  try {
    if (!action.payload?.removeLoading) {
      yield setDocumentLoading(true);
    }
    const selectedPatientData =
      store.getState().abdmPatients.searchResults[
        store.getState().abdmPatients?.selectedPatient
      ];
    const clinicId = store.getState().connection.currentConnection;

    const consentRequestId =
      store.getState().abdm?.consentRequest &&
      isValidObject(store.getState().abdm?.consentRequest)
        ? Object.values(store.getState().abdm?.consentRequest)[0]?.documentId
        : null;

    const data = yield getDataTransferDetails(
      selectedPatientData?.patientId,
      clinicId,
      consentRequestId
    );
    yield put({
      type: "SET_DATA_TRANSFER",
      payload: {
        data: data
      }
    });

    setDocuments({ removeLoading: true });

    if (!action.payload?.removeLoading) {
      yield setDocumentLoading(false);
    }
  } catch (error) {
    setErrorStatus(error);
    yield setDocumentLoading(false);
  }
}

export function* retryDataTransferWorker(action) {
  try {
    yield setDocumentLoading(true);

    const selectedPatientData =
      store.getState().abdmPatients.searchResults[
        store.getState().abdmPatients?.selectedPatient
      ];
    const clinicId = store.getState().connection.currentConnection;
    const accessToken = store.getState().auth.data.accessToken;
    const consentId = action.payload?.consentId;
    const consent = Object.values(store.getState().abdm.consentRequest).find(
      (data) => data.status === "GRANTED"
    );

    const response = yield retryDataTransferDetails(accessToken, {
      hiuClinicId: clinicId,
      consentRequestId: consent.consentRequestId,
      patientId: selectedPatientData.patientId,
      ...(consentId ? { consentId: consentId } : {})
      // subscriptionId: bodyData.subscriptionId,
    });
    if (response.success) {
      getDataTransferInfo({ removeLoading: true });
    }

    yield setDocumentLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setDocumentLoading(false);
  }
}

export function* setSelectedDocumentsWorker(action) {
  try {
    yield setDocumentLoading(true);

    yield put({
      type: "SET_CURRENT_DOCUMENT",
      payload: {
        currentDocument: action.payload.data
      }
    });

    yield setDocumentLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setDocumentLoading(false);
  }
}

export function* setDownloadUrlWorker(action) {
  try {
    if (action.payload.url) {
      yield put({
        type: "SET_DOWNLOAD_URL_LOADING",
        payload: {
          loading: true
        }
      });

      const downloadUrl = action.payload.url
        ? yield getFileUrl(action.payload.url)
        : "";

      const searchedDocumentPhoneNumber =
        store.getState().documentSearch.searchedDocumentPhoneNumber;

      if (searchedDocumentPhoneNumber) {
        const searchData = { ...store.getState().documentSearch.data };
        if (searchData) {
          const searchedDocumentData = {
            ...store.getState().documentSearch.data[action.payload.documentId]
          };

          yield put({
            type: "SET_DOCUMENT_SEARCH_DATA",
            payload: {
              data: {
                ...searchData,
                [action.payload.documentId]: {
                  ...searchedDocumentData,
                  downloadUrl: downloadUrl
                }
              }
            }
          });
        }
      } else {
        const documents = { ...store.getState().history.documents };

        if (documents) {
          const documentData = {
            ...store.getState().history.documents[action.payload.documentId]
          };
          yield put({
            type: "SET_DOCUMENTS_DATA",
            payload: {
              documents: {
                ...documents,
                [action.payload.documentId]: {
                  ...documentData,
                  downloadUrl: downloadUrl
                }
              }
            }
          });
        }
      }
    }

    yield put({
      type: "SET_DOWNLOAD_URL_LOADING",
      payload: {
        loading: false
      }
    });
  } catch (error) {
    setErrorStatus(error);
    yield put({
      type: "SET_DOWNLOAD_URL_LOADING",
      payload: {
        loading: false
      }
    });
  }
}
//upload document request worker
function* uploadDocumentWorker(action) {
  try {
    yield setDocumentLoading(true);
    const clinicId = store.getState().connection.currentConnection;
    const selectedPatientData =
      store.getState().abdmPatients.searchResults[
        store.getState().abdmPatients?.selectedPatient
      ];

    const documentType = validDocumentType(action.payload.data.documentType);
    const documentName = action.payload.data.documentName;
    const issueDate = `${action.payload.data.date} ${action.payload.data.time}`;
    const accessToken = store.getState().auth.data.accessToken;

    let data = new FormData();
    if (action.payload.data.file.type === "image") {
      if (isValidArray(action.payload.data.file.src)) {
        for (let i = 0; i < action.payload.data.file.src.length; i++) {
          data.append("files", action.payload.data.file.src[i]);
        }
        data.append("issueDate", issueDate);
        data.append("clinicId", clinicId);
        data.append("documentType", documentType);
        data.append("patientId", selectedPatientData.patientId);
        data.append("documentName", documentName);
        data.append("fileConversion", true);

        yield uploadDocument(data, accessToken);

        setSuccessStatus("Document uploaded successfully");
      } else {
        throw throwError("custom", "Invalid File");
      }
    } else {
      data.append("issueDate", issueDate);
      data.append("files", action.payload.data.file.src);
      data.append("clinicId", clinicId);
      data.append("documentType", documentType);
      data.append("patientId", selectedPatientData.patientId);
      data.append("documentName", documentName);
      data.append("fileConversion", true);

      yield uploadDocument(data, accessToken);

      setSuccessStatus("Document uploaded successfully");
    }
    yield setDocumentLoading(false);
  } catch (error) {
    yield setErrorStatus(error);
    yield setDocumentLoading(false);
  }
}

export function* deleteDocumentWorker(action) {
  try {
    yield put({
      type: "SET_DELETE_DOCUMENTS_LOADING",
      payload: {
        loading: true
      }
    });

    const selectedDocument =
      store.getState().history.documents[
        store.getState().connection.currentConnection
      ][store.getState().documentSearch.selectedDocument];

    if (isValidObject(selectedDocument)) {
      const selectedDocument =
        store.getState().history.documents[
          store.getState().connection.currentConnection
        ][store.getState().documentSearch.selectedDocument];

      // const fileURL = selectedDocument.url;
      // yield deleteDocumentStorage(fileURL);

      const response = yield deleteDocument(
        selectedDocument.documentId,
        store.getState().connection.currentConnection,
        Object.values(store.getState().profile.data)[0]
      );
      yield removeDocumentSearchData(true);
      yield put({
        type: "SET_DELETE_DOCUMENTS_LOADING",
        payload: {
          loading: false
        }
      });
      yield setSuccessStatus("Deleted successfully");
      yield removeDocumentSearchData(true);
      if (response.success === true) {
        const lastDocumentPageNumber = selectedDocument?.pageNumber;
        yield updateUploadHistory({
          lastDocPageNumber: lastDocumentPageNumber
        });
      }
    } else {
      throw throwError("custom", "Unable to delete Document");
    }
  } catch (error) {
    setErrorStatus(error);
    yield put({
      type: "SET_DELETE_DOCUMENTS_LOADING",
      payload: {
        loading: false
      }
    });
  }
}

function* addVitalsWorker(action) {
  try {
    yield setDocumentLoading(true);

    if (
      !(
        isValidObject(store.getState().abdmPatients.selectedPatient) &&
        isValidObject(store.getState().profile.data) &&
        isValidObject(action.payload.vitalsData)
      )
    ) {
      throw throwError(
        "custom",
        "Something went wrong, please try again later"
      );
    }

    const newObj = {
      from: {
        name: store.getState().profile.data[store.getState().auth.data.uid]
          .public.clinicName,
        type: "clinic",
        id: store.getState().auth.data.uid
      },
      to: {
        fullName:
          store.getState().abdmPatients.selectedPatient[
            Object.keys(store.getState().abdmPatients.selectedPatient)[0]
          ].fullName,
        phoneNumber:
          store.getState().abdmPatients.selectedPatient[
            Object.keys(store.getState().abdmPatients.selectedPatient)[0]
          ].phoneNumber,
        id: Object.keys(store.getState().abdmPatients.selectedPatient)[0]
      },
      timestamp: +new Date(),
      ...(action.payload.vitalsData.height
        ? {
            height: action.payload.vitalsData.height
          }
        : {}),
      ...(action.payload.vitalsData.weight
        ? {
            weight: action.payload.vitalsData.weight
          }
        : {}),
      ...(action.payload.vitalsData.heartRate
        ? {
            heartRate: {
              value: action.payload.vitalsData.heartRate,
              status: ""
            }
          }
        : {}),
      ...(action.payload.vitalsData.bodyTemperature
        ? {
            bodyTemperature: {
              value: action.payload.vitalsData.bodyTemperature,
              status: ""
            }
          }
        : {}),
      ...(action.payload.vitalsData.bloodOxygen
        ? {
            bloodOxygen: {
              value: action.payload.vitalsData.bloodOxygen,
              status: ""
            }
          }
        : {}),
      ...(action.payload.vitalsData.bloodSugar
        ? {
            bloodSugar: {
              value: action.payload.vitalsData.bloodSugar,
              status: ""
            }
          }
        : {}),
      ...(action.payload.vitalsData.bloodPressure
        ? {
            bloodPressure: {
              value: action.payload.vitalsData.bloodPressure,
              status: ""
            }
          }
        : {}),
      type: "vitals",
      processing: true
    };
    yield addVitals(newObj);
    setSuccessStatus("Vitals added successfully");
    showTertiary(null);

    yield setDocumentLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setDocumentLoading(false);
  }
}

function* addDocumentFilterWorker(action) {
  try {
    let filters = store.getState().documents?.filters;
    switch (action.payload.type) {
      //Filter by document type
      case "document":
        //previous document type filter states
        let documentsType = filters.documentType;
        //checks document type filter already exist and remove if exists
        if (action.payload.filterValue === "allDocuments") {
          documentsType = [];
        } else if (documentsType.includes(action.payload.filterValue)) {
          const index = documentsType.indexOf(action.payload.filterValue);
          if (index > -1) {
            documentsType.splice(index, 1);
          }
        } else {
          documentsType.push(action.payload.filterValue);
        }
        //reducer dispatch to store the documentFilter
        yield put({
          type: "FILTER_BY_DOCUMENTS",
          payload: {
            documentsType: documentsType
          }
        });
        return;

      case "doctor":
        //previous document type filter states
        let doctorsName = filters.doctors;
        //checks document type filter already exist and remove if exists
        if (doctorsName.includes(action.payload.filterValue)) {
          const index = doctorsName.indexOf(action.payload.filterValue);
          if (index > -1) {
            doctorsName.splice(index, 1);
          }
        } else {
          doctorsName.push(action.payload.filterValue);
        }
        yield put({
          type: "FILTER_BY_DOCTORS",
          payload: {
            doctorsName: doctorsName
          }
        });
        return;

      case "clinic":
        //previous document type filter states
        let clinicsName = filters.clinics;
        //checks document type filter already exist and remove if exists
        if (clinicsName.includes(action.payload.filterValue)) {
          const index = clinicsName.indexOf(action.payload.filterValue);
          if (index > -1) {
            clinicsName.splice(index, 1);
          }
        } else {
          clinicsName.push(action.payload.filterValue);
        }
        yield put({
          type: "FILTER_BY_CLINICS",
          payload: {
            clinicsName: clinicsName
          }
        });
        return;

      default:
        return;
    }
  } catch (error) {
    setErrorStatus(error);
  }
}

function* removeDocumentFilterWorker(action) {
  try {
    switch (action.payload.clear) {
      case "doctorAndClinic":
        yield put({
          type: "FILTER_BY_DOCTORS",
          payload: {
            doctorsName: []
          }
        });
        yield put({
          type: "FILTER_BY_CLINICS",
          payload: {
            clinicsName: []
          }
        });
        return;
      default:
        break;
    }
  } catch (error) {
    setErrorStatus(error);
  }
}

export function* documentsWatcher() {
  yield all([
    takeEvery("GET_DATA_TRANSFER", getDataTransferWorker),
    takeEvery("RETRY_DATA_TRANSFER", retryDataTransferWorker),
    takeEvery("SET_DOCUMENTS", setDocumentsWorker),
    takeEvery("SET_SELECTED_DOCUMENTS", setSelectedDocumentsWorker),
    takeEvery("SET_DOWNLOAD_URL", setDownloadUrlWorker),
    takeEvery("UPLOAD_DOCUMENT", uploadDocumentWorker),
    takeEvery("DELETE_DOCUMENT", deleteDocumentWorker),
    takeEvery("ADD_VITALS", addVitalsWorker),
    takeEvery("ADD_DOCUMENT_FILTER", addDocumentFilterWorker),
    takeEvery("REMOVE_DOCUMENT_FILTER", removeDocumentFilterWorker)
  ]);
}

function* setDocumentLoading(bool) {
  yield put({
    type: "SET_DOCUMENT_LOADING",
    payload: {
      loading: bool
    }
  });
}
