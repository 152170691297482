import "./UnsupportedDevice.css";
import nintoLogo from "../../assets/ninto_logo_with_name.svg";
import unsupportedDeviceFallBack from "../../assets/unsupportedDeviceFallBack.svg";
import nintoLogoBlack from "../../assets/nintoLogoBlack.svg";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  MediumIcon,
  TwitterIcon,
  WhatsappIcon
} from "../../assets/assets";
import patient_android_QR from "../../assets/QRcode/patient_android_QR.png";
import doctor_android_QR from "../../assets/QRcode/doctor_android_QR.png";
import playstoreDownloadButton from "../../assets/playstoreDownloadButton.png";
import appStoreDownloadButton from "../../assets/appStoreDownloadButton.png";

export default function UnsupportedDevice(props) {
  return (
    <div className=" inherit-parent-height display-flex  flex-direction-column flex-justify-content-space-between">
      <div className=" display-flex flex-justify-content-center border-bottom-1px-e5e5e5">
        <div className="width-1200px padding-top-medium padding-bottom-medium padding-left-large">
          <img src={nintoLogo} alt="ninto logo" />
        </div>
      </div>

      <div className=" display-flex flex-justify-content-center text-align-center inherit-parent-width inherit-parent-height padding-left-larger padding-right-larger unsupported-device-body">
        <div className="display-flex flex-direction-column flex-justify-content-center ">
          <img
            src={unsupportedDeviceFallBack}
            alt="unsupportedDeviceFallBack"
            className=" unsupported-device-fallback-image max-height-250px"
          />
        </div>

        <div className=" display-flex flex-justify-content-center flex-direction-column align-items-center  flex-align-items-center padding-left-larger padding-right-larger unsupported-device-content-area">
          {props.appName !== "clinics" && (
            <div className=" font-family-gilroy-regular unsupported-device-primary-content-web ">
              This <span className=" font-color-primary"> device </span> is{" "}
              <span className=" font-color-primary"> not supported </span>
            </div>
          )}

          {props.appName === "clinics" && (
            <div className=" font-family-gilroy-regular unsupported-device-primary-content-clinics ">
              This <span className=" font-color-primary"> device </span> is{" "}
              <span className=" font-color-primary"> not supported </span>
            </div>
          )}

          {props.appName !== "clinics" && (
            <div className=" font-family-gilroy-regular unsupported-device-primary-content-phone ">
              Web <span className=" font-color-primary"> browser </span> is{" "}
              <span className=" font-color-primary"> not supported </span>
            </div>
          )}

          {props.appName !== "clinics" && (
            <div className=" padding-top-medium font-size-large unsupported-device-secondary-content-web">
              Please scan the QR code to use Ninto application
            </div>
          )}

          {props.appName !== "clinics" && (
            <div className=" padding-top-medium font-size-large unsupported-device-secondary-content-phone">
              Download Ninto application from playstore or app store
            </div>
          )}

          {props.appName === "clinics" && (
            <div className=" padding-top-medium font-size-large unsupported-device-secondary-content-clinics">
              Please switch to your desktop to use Ninto application
            </div>
          )}

          {props.appName !== "clinics" && (
            <div className=" inherit-parent-width display-flex flex-justify-content-space-evenly padding-top-larger">
              <div>
                <img
                  src={
                    props.appName === "patients"
                      ? patient_android_QR
                      : props.appName === "doctors" && doctor_android_QR
                  }
                  alt={`${
                    props.appName === "patients"
                      ? "patient_android_QR"
                      : props.appName === "doctors" && "doctor_android_QR"
                  }`}
                  className=" height-100px margin-top-large unsupported-device-qr-code"
                />
                <div>
                  <img
                    src={playstoreDownloadButton}
                    alt="playstoreDownloadButton"
                    className=" width-100px margin-top-medium app-download-button"
                    onClick={() => {
                      props.appName === "patients" &&
                        window.open(
                          "https://play.google.com/apps/internaltest/4700903875784758003",
                          "_blank"
                        );
                      props.appName === "doctors" &&
                        window.open(
                          "https://play.google.com/apps/internaltest/4700812323498310283",
                          "_blank"
                        );
                    }}
                  />
                </div>
              </div>

              <div>
                <img
                  src={
                    props.appName === "patients"
                      ? patient_android_QR
                      : props.appName === "doctors" && doctor_android_QR
                  }
                  alt={`${
                    props.appName === "patients"
                      ? "patient_android_QR"
                      : props.appName === "doctors" && "doctor_android_QR"
                  }`}
                  className=" height-100px margin-top-large unsupported-device-qr-code"
                />
                <div>
                  <img
                    src={appStoreDownloadButton}
                    alt="playstoreDownloadButton"
                    className=" width-100px margin-top-medium app-download-button"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="unsupported-device-footer">
        <div className="width-1200px padding-top-medium padding-bottom-medium padding-left-large display-flex flex-justify-content-space-between">
          <div className=" display-flex flex-align-items-center">
            <img
              src={nintoLogoBlack}
              alt="ninto logo"
              className=" height-25px"
            />
            <div className=" font-size-10px padding-left-medium">
              © 2021 Ninto. Byepo Technologies Pvt. Ltd.
            </div>
          </div>

          <div className=" display-flex">
            <div className="display-flex flex-align-items-center">
              <FacebookIcon height={18} />
            </div>
            <div className=" padding-left-large display-flex flex-align-items-center">
              <TwitterIcon height={18} />
            </div>
            <div className=" padding-left-large display-flex flex-align-items-center">
              <MediumIcon height={18} />
            </div>
            <div className=" padding-left-large display-flex flex-align-items-center">
              <InstagramIcon height={18} />
            </div>
            <div className=" padding-left-large display-flex flex-align-items-center">
              <LinkedInIcon height={18} />
            </div>
            <div className=" padding-left-large padding-right-large display-flex flex-align-items-center">
              <WhatsappIcon height={18} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
