import React from "react";
import PatientSearch from "../../components/PatientSearch/PatientSearch";
import { connect } from "react-redux";
import DocumentSearch from "../../components/DocumentSearch/DocumentSearch";
import AccountInformation from "../../components/AccountInformation/AccountInformation";
import FormsListing from "../../components/FormsListing/FormsListing";
import Abha from "../../components/Abha/Abha";
import { showSecondary, showTertiary } from "../../redux/navigation/actions";
import {
  addPatientDemographics,
  clearPatientSearchData,
  findPatient,
  setAbdmSelectedPatients
} from "../../redux/abdmPatients/actions";
import {
  addSelectedDocument,
  getSearchDocumentData,
  removeDocumentSearchData
} from "../../redux/documentSearch/actions";
import { updateUploadHistory } from "../../redux/history/actions";
import {
  getForms,
  putCurrentForm,
  removeCurrentForm
} from "../../redux/form/actions";
import { clearPublicProfileData } from "../../redux/profile/actions";
import { clearCreationData, setLoginStatus } from "../../redux/abha/actions";

function Primary(props) {
  return (
    <>
      <div className=" display-flex flex-center-children-vertically inherit-parent-height">
        <>
          <div
            data-cy="settingPrimary"
            className="width-300px min-width-270px inherit-parent-height overflow-auto hide-scroll-bar background-white box-shadow-left-none card-box-border border-radius-right padding-larger font-family-gilroy-bold"
          >
            {props.navigation.primary === "patientSearch" && (
              <PatientSearch
                history={props.history}
                abdmPatients={props.abdmPatients}
                clearSearchPatientData={() => {
                  props.showSecondary(null);
                  props.showTertiary(null);
                  props.removeAbdmPatientSearch();
                }}
                SearchPatient={(phoneNumber) => {
                  props.findPatient(phoneNumber);
                }}
                setSelectedPatients={(patientId) => {
                  props.setAbdmSelectedPatients(patientId);
                }}
                createPatientDemographics={(data) => {
                  props.addPatientDemographics(data);
                }}
              />
            )}
            {props.navigation.primary === "documentSearch" && (
              <DocumentSearch
                notification={props.notification}
                documentSearch={props.documentSearch}
                connection={props.connection}
                showSecondary={(data) => {
                  props.showSecondary(data);
                }}
                history={props.history}
                showPrimary={(data) => {
                  props.showSecondary(data);
                }}
                getSearchDocumentData={(data) => {
                  props.getSearchDocumentData(data);
                }}
                addSelectedDocument={(documentId, type) => {
                  props.addSelectedDocument(documentId, type);
                }}
                removeDocumentSearchData={() => {
                  props.removeDocumentSearchData();
                }}
                updateUploadHistory={() => {
                  props.updateUploadHistory();
                }}
              />
            )}
            {props.navigation.primary === "abha" && (
              <Abha
                navigation={props.navigation}
                createAbhaNumberOnClick={() => {
                  props.showSecondary("createAbhaNumber");
                  props.showTertiary(null);
                  props.clearCreationData();
                  if (typeof props.abha.accessToken === "string") {
                    props.setLoginStatus(false);
                  }
                }}
                abhaLoginOnClick={() => {
                  props.showSecondary("abhaLogin");
                  props.showTertiary(null);
                }}
                abha={props.abha}
              />
            )}
            {props.navigation.primary === "accountInformation" && (
              <AccountInformation
                navigation={props.navigation}
                connection={props.connection}
                profile={props.profile}
                editInformationClick={() => {
                  props.clearPublicProfileData();
                  props.showSecondary("editAccountInformation");
                  props.showTertiary(null);
                }}
                clinicsInformationClick={() => {
                  props.showSecondary("clinicsInformation");
                  props.showTertiary(null);
                }}
                manageClinicClick={() => {
                  props.showSecondary("manageClinic");
                  props.showTertiary(null);
                }}
                // partneredCompanyClick={() => {
                //   props.showSecondary("partneredCompany");
                // }}
                queryClick={() => {
                  props.showSecondary("query");
                  props.showTertiary(null);
                }}
                logoutClick={props.logoutButtonClick}
              />
            )}
            {props.navigation.primary === "form" && (
              <FormsListing
                form={props.form}
                putCurrentForm={(data) => {
                  props.putCurrentForm(data);
                }}
                getForms={() => {
                  props.getForms();
                }}
                removeCurrentForm={() => {
                  props.removeCurrentForm();
                }}
                showSecondary={(data) => {
                  props.showSecondary(data);
                }}
              />
            )}
          </div>
        </>
      </div>
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    navigation: state.navigation,
    profile: state.profile,
    connection: state.connection,
    documentSearch: state.documentSearch,
    history: state.history,
    forms: state.forms,
    abha: state.abha,
    abdmPatients: state.abdmPatients,
    notification: state.notification
  };
};

const mapDispatchToProps = function () {
  return {
    showSecondary: (data) => showSecondary(data),
    showTertiary: (data) => showTertiary(data),
    setLoginStatus: (data) => setLoginStatus(data),
    clearPublicProfileData: () => clearPublicProfileData(),
    clearCreationData: () => clearCreationData(),
    findPatient: (searchKey) => findPatient(searchKey),
    setAbdmSelectedPatients: (patientId) => setAbdmSelectedPatients(patientId),
    addPatientDemographics: (demographicsFormData) =>
      addPatientDemographics(demographicsFormData),
    removeAbdmPatientSearch: () => clearPatientSearchData(),
    getSearchDocumentData: (phoneNumber) => getSearchDocumentData(phoneNumber),
    addSelectedDocument: (documentId, type) =>
      addSelectedDocument(documentId, type),
    removeDocumentSearchData: (deepClear) =>
      removeDocumentSearchData(deepClear),
    updateUploadHistory: (data) => updateUploadHistory(data),
    putCurrentForm: (formDocumentId) => putCurrentForm(formDocumentId),
    getForms: () => getForms(),
    removeCurrentForm: () => removeCurrentForm()
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Primary);
