const formsReducer = (
  state = {
    loading: false,
    data: null,
    currentForm: null
  },
  action
) => {
  switch (action.type) {
    case "SET_FORMS_LOADING":
      return {
        ...state,
        loading: action.payload.loading
      };
    case "SET_FORMS_DATA":
      return {
        ...state,
        data: action.payload.data
      };
    case "SET_CURRENT_FORM":
      return {
        ...state,
        currentForm: action.payload.currentForm
      };
    case "RESET":
      return {
        loading: false,
        data: null,
        currentForm: null
      };
    default:
      return state;
  }
};
export default formsReducer;
