import React, { useState } from "react";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import Button from "../../front-end-global-components/components/Button/Button";
import { Regex } from "../../front-end-global-components/utils/constants";

export default function CreateABHAAddress(props) {
  const [createFormData, setCreateFormData] = useState({
    abhaAddress: ""
  });
  return (
    <div className="inherit-parent-height">
      <div className="inherit-parent-width text-align-center padding-larger">
        CREATE ABHA ADDRESS
      </div>
      <form
        className="padding-right-larger padding-left-larger"
        onChange={(event) => {
          setCreateFormData({
            [event.target.name]: event.target.value
          });
        }}
        onSubmit={(event) => {
          event.preventDefault();
          props.createHealthId(createFormData?.abhaAddress);
        }}
      >
        <InputBox
          className="inherit-parent-width border-radius-default margin-vertical-large"
          name="abhaAddress"
          label="ABHA ADDRESS"
          removeResponsive={true}
          type="text"
          autoComplete="on"
          value={createFormData.abhaAddress}
          required
        />
        <Button
          data-cy="create-button"
          loading={props.loading}
          type="submit"
          className="margin-bottom-large"
          text="Create"
          disabled={
            createFormData.abhaAddress &&
            Regex.healthId.test(createFormData.abhaAddress)
              ? false
              : true
          }
          boxShadow={false}
        />
      </form>
    </div>
  );
}
